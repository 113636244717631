import React from 'react';
import { Loading, NoData } from '@oms/components-core';
import { Table, Th, Td } from '@oms/ui-table';
import { Card, CardProps } from 'components/Card';
import { useHistoricalReturn } from './useHistoricalReturn';
import { useItemSector } from 'context/ItemSectorContext';

export interface HistoricalReturnProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const HistoricalReturn = ({
  itemSector,
  ...props
}: HistoricalReturnProps) => {
  const itemSectorValue = useItemSector(itemSector);
  return (
    <Card title="Historisk avkastning" {...props}>
      <HistoricalReturnComponent itemSector={itemSectorValue} />
    </Card>
  );
};

function HistoricalReturnComponent({ itemSector }: { itemSector: string }) {
  const { data, initialized, resource, emptyData } = useHistoricalReturn({
    itemSector,
  });

  if (!initialized) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    //@ts-ignore
    return <NoData text={`No return found`} />;
  }
  return (
    <Table variant="keyValue">
      <thead>
        <tr>
          <Th type="text">Periode</Th>
          <Th type="percent">+/-%</Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Th scope="row">1 mnd</Th>
          <Td type="percent" trend>
            {data.CHANGE_1MONTH_PCT}
          </Td>
        </tr>
        <tr>
          <Th scope="row">3 mnd</Th>
          <Td type="percent" trend>
            {data.CHANGE_3MONTHS_PCT}
          </Td>
        </tr>
        <tr>
          <Th scope="row">Hittil i år</Th>
          <Td type="percent" trend>
            {data.CHANGE_YEAR_PCT}
          </Td>
        </tr>
        <tr>
          <Th scope="row">1 år</Th>
          <Td type="percent" trend>
            {data.CHANGE_1YEAR_PCT}
          </Td>
        </tr>
        <tr>
          <Th scope="row">3 år</Th>
          <Td type="percent" trend>
            {data.CHANGE_3YEARS_PCT}
          </Td>
        </tr>
      </tbody>
    </Table>
  );
}

export default HistoricalReturn;
