import { Heading } from '@oms/ui-heading';
import { formatNumber } from '@oms/utils';
import { Stack } from '@oms/ui-stack';
import { Wrap } from '@oms/ui-wrap';
import { Text } from '@oms/ui-text';
import { useMarketValue } from 'utils/hooks/useHoldings';

const MarketValue = () => {
  const {
    totalMarketValue: { marketValue, unrealized, unrealizedPct },
  } = useMarketValue();
  return (
    <Wrap gap={8} justifyContent="center">
      <Stack alignment="center" gap={0}>
        <Heading variant="heading5" textAlign="center">
          Markedsverdi
        </Heading>
        <Text variant="heading3" color="secondary" textAlign="center">
          {formatNumber(marketValue)}
        </Text>
      </Stack>
      <Stack alignment="center" gap={0}>
        <Heading variant="heading5" textAlign="center">
          Urealisert +/-
        </Heading>
        <Text variant="heading3" color="secondary" textAlign="center">
          {formatNumber(unrealized)} ({formatNumber(unrealizedPct)}%)
        </Text>
      </Stack>
    </Wrap>
  );
};

export default MarketValue;
