import styled from '@emotion/styled';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import OriginOfFunds from '../OriginOfFunds';
import IsFundsFromAbroad from '../IsFundsFromAbroad';
import { Heading } from '@oms/ui-heading';
import MonthlyTransactions from '../MonthlyTransactions';
import TransactionTradeSize from '../TransactionTradeSize';
import TransactionFundsSize from '../TransactionFundsSize';
import MoveExistingSecurities from '../MoveExistingSecurities';
import { required, notEmptyArray, composeValidators } from '../validate';
import Condition from 'components/FormComponents/Condition';
import { Stack } from '@oms/ui-stack';
import { Box } from '@oms/ui-box';
import { InfoBubble } from '@oms/ui-info-bubble';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

const Divider = styled.hr`
  margin: 1rem 0;
  color: lightgray;
`;
export const Laundering = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <Stack gap={4}>
      <OriginOfFunds />
      <IsFundsFromAbroad />
      <Divider />
      <Heading>
        Skal kundeforholdet brukes til å gjennomføre handel på vegne av andre?
      </Heading>
      <Fieldset
        as={RadioGroup}
        name="kycBusinessRelationship.tradeOnBehalfOthers"
        legend="Velg det som passer best"
      >
        <Field as={Radio} label="Nei" value="false" validate={required} />
        <Field
          as={Radio}
          label="Ja, på vegne av egne mindreårige barn"
          value="trueOnBehalfOfKids"
          validate={required}
        />
        <Field as={Radio} label="Ja" value="true" validate={required} />
      </Fieldset>
      <Condition when="kycBusinessRelationship.tradeOnBehalfOthers" is="true">
        <PdfTextInput
          name="kycBusinessRelationship.tradeOnBehalfOthersDescription"
          label="Beskrivelse"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <Divider />
      <Heading>
        Skal andre benytte ditt kundeforhold i SB1M og handle på dine vegne?
      </Heading>
      <Fieldset
        as={RadioGroup}
        name="kycBusinessRelationship.othersActOnYourBehalf"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="Ja"
          value="true"
          onClick={() =>
            setTimeout(() => {
              (document.querySelector(
                '[aria-label="beskrivelse"]',
              ) as HTMLButtonElement)?.click();
            }, 200)
          }
          validate={required}
        />
        <Field as={Radio} label="Nei" value="false" validate={required} />
      </Fieldset>
      <Condition when="kycBusinessRelationship.othersActOnYourBehalf" is="true">
        <Box display="flex" width="100%">
          <div style={{ width: '100%' }}>
            <PdfTextInput
              name="kycBusinessRelationship.othersActOnYourBehalfDescription"
              label="Beskrivelse"
              isPDFRender={isPDFRender}
            />
          </div>
          <Box display="flex" alignItems="flex-end" mb={1}>
            <InfoBubble aria-label="beskrivelse" ml={4}>
              Spareprogram via arbeidsgiver eller andre anses ikke som handel på
              dine vegne
            </InfoBubble>
          </Box>
        </Box>
      </Condition>
      <Divider />
      <Heading>Skal du oppbevare midler på vegne av andre?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycFunds.heldOnBehalfOthers"
        legend="Velg det som passer best"
      >
        <Field as={Radio} label="Nei" value="false" validate={required} />
        <Field
          as={Radio}
          label="Ja, på vegne av egne mindreårige barn"
          value="trueOnBehalfOfKids"
          validate={required}
        />
        <Field as={Radio} label="Ja" value="true" validate={required} />
      </Fieldset>
      <Condition when="kycFunds.heldOnBehalfOthers" is="true">
        <PdfTextInput
          name="kycFunds.heldOnBehalfOthersDescription"
          label="Beskrivelse"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <Heading>Hva er formålet med ditt kundeforhold hos oss?</Heading>
      <Fieldset
        as={CheckboxGroup}
        name="additionalInformation.laundering.purpose"
        legend="Gjelder bakgrunnen for inngåelse av kundeforholdet."
      >
        <Field
          as={Checkbox}
          label="Trading spekulasjon"
          value="trading"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          validate={composeValidators(required, notEmptyArray)}
          label="Kortsiktig sparing"
          value="shortTermSavings"
        />
        <Field
          as={Checkbox}
          validate={composeValidators(required, notEmptyArray)}
          label="Langsiktig sparing"
          value="longTermSavings"
        />
        <Field
          as={Checkbox}
          label="Pensjon"
          value="pension"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Handel for nærstående, for eksempel barn"
          value="closeRelatives"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Spareprogram via arbeidsgiver eller andre"
          value="savingsProgram"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Annet"
          value="other"
          validate={composeValidators(required, notEmptyArray)}
        />
        {/* <ValidationError name="additionalInformation.laundering.purpose" /> */}
      </Fieldset>
      <Condition
        when="additionalInformation.laundering.purpose"
        includes="other"
      >
        <PdfTextInput
          label="Beskrivelse annet"
          name="additionalInformation.laundering.otherPurposeDescription"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <MonthlyTransactions />
      <TransactionTradeSize />
      <TransactionFundsSize />
      <Divider />
      <MoveExistingSecurities />
    </Stack>
  );
};
export default Laundering;
