import { useSnipletByID } from './admin/useSniplets';

type FAQ = {
  question: string;
  answer: string;
};

export const useFAQ = () => {
  const { data: faqSniplet, ...queryState } = useSnipletByID(5);

  const body = faqSniplet?.bodies?.NO?.body;
  let faqs: FAQ[] = [];
  if (body) {
    try {
      const parsedBody = JSON.parse(body);
      faqs = parsedBody.faqs;
    } catch (e) {
      // TODO: Should probably throw error, depends on how well we end up using ErrorBoundaries.
      // Perhaps with a toast pop-in?
      console.error('Failed parsing faq sniplet');
    }
  }

  return {
    faqs,
    ...queryState,
  };
};

export default useFAQ;
