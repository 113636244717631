import React from 'react';
import { REPORT_URL } from 'constants/Jaws';
import { useReports, ReportType, Report } from './useReports';
import { Link } from '@oms/ui-link';
import { Text } from '@oms/ui-text';
import { Table, Td, Th } from '@oms/ui-table';
import {
  useTableDisclosure,
  useTableDisclosureHeading,
  TableDisclosureButton,
  TableDisclosureContent,
  TableDisclosureHeading,
  UseTableDisclosureHeadingReturn,
} from 'components/TableDisclosure';
import { SkeletonTable } from '@oms/ui-skeleton';
import { handleBlankPdfLink } from 'utils/app';
export interface ReportListProps {
  type: ReportType;
}

const LoadingState = () => <SkeletonTable animated columns={3} rows={3} />;

export const ReportList = ({ type }: ReportListProps) => {
  return (
    <React.Suspense fallback={<LoadingState />}>
      <ReportListComponent type={type} />
    </React.Suspense>
  );
};

export const ReportListComponent = ({
  type = 'MORNINGREPORT',
}: ReportListProps) => {
  const headers = useTableDisclosureHeading();

  const { data: reports = [] } = useReports({ type, count: 10 });
  if (reports.length === 0) {
    return null;
  }

  return (
    <Table>
      <thead>
        <tr>
          <Th width="10rem">Dato</Th>
          <Th>Title</Th>
          <TableDisclosureHeading width="2rem" {...headers}>
            Mer
          </TableDisclosureHeading>
        </tr>
      </thead>
      <tbody>
        {reports.map(report => (
          <Row {...report} {...headers} />
        ))}
      </tbody>
    </Table>
  );
};

function Row({ headers, ...report }: Report & UseTableDisclosureHeadingReturn) {
  const disclosure = useTableDisclosure();

  return (
    <>
      <tr>
        <Td type="dateTime">{report.startTime}</Td>
        <Td type="text">{report.title}</Td>
        <TableDisclosureButton {...disclosure} />
      </tr>
      <TableDisclosureContent colSpan={3} {...disclosure} headers="a">
        <Text as="pre" fontFamily="body" py={2}>
          {report?.ingress}
        </Text>
        <Link
          as="a"
          variant="link"
          href={`${REPORT_URL}/file/${report.document?.id}`}
          onClick={handleBlankPdfLink}
        >
          Last ned analysen (PDF)
        </Link>
      </TableDisclosureContent>
    </>
  );
}
