import { useQuery } from 'react-query';
import { EXCHANGE_URL } from 'constants/Jaws';
import moment from 'moment';

const getCloseDates = () => {
  const start = moment().format('YYYY-MM-DD');
  const stop = moment()
    .add(14, 'days')
    .format('YYYY-MM-DD');

  return fetch(
    `${EXCHANGE_URL}/OSE/closed-dates?start=${start}&stop=${stop}`,
  ).then(res => {
    if (!res.ok) {
      return;
    }
    return res.json();
  });
};

export const useGetCloseDates = () => {
  return useQuery(['closedDates'], () => getCloseDates(), {
    suspense: true,
  });
};
