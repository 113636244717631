import React, { useEffect } from 'react';
import { forwardRefWithAs } from '@oms/ui-utils';
import { TextInput, TextInputProps } from '@oms/ui-text-input';

function setCharAt(str: string, index: number, chr: string) {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
}

type TextInputPropsWithoutType = Omit<TextInputProps, 'type'>;

export interface NumberInputProps extends TextInputPropsWithoutType {
  /**
   * default `nb` which supports both comma's and periods as a decimal separator
   * @see https://www.ctrl.blog/entry/html5-input-number-localization.html */
  lang?: string;
  inputMode?: 'decimal' | 'numeric';
}

/**
 * Temporary replace for NumberInput from '@oms/ui-number-input'
 * @deprecated
 */
export const NumberInput = forwardRefWithAs<NumberInputProps, 'input'>(
  function NumberInput(
    {
      inputMode = 'numeric',
      step = inputMode === 'numeric' ? 1 : 0.0001,
      lang = 'nb',
      as: _as,
      type: _type,
      onChange: propsOnChange,
      value: propsValue,
      ...props
    },
    ref,
  ) {
    const valueRef = React.useRef('');

    useEffect(() => {
      if (propsValue === '') {
        valueRef.current = '';
      }
    }, [propsValue]);

    const onChange: React.InputHTMLAttributes<
      HTMLInputElement
    >['onChange'] = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist(); // not sure if this is needed
        let value = event.target.value;
        value = value.replace(/[^\d.,-]/g, '');
        if (valueRef.current !== value) {
          const comma = value.indexOf(',');
          const dot = value.indexOf('.');
          // dot is used, dot occurs before comma or comma is unused
          if (dot !== -1 && (dot < comma || comma === -1)) {
            value = setCharAt(value, dot, '©');
            value = value.replace(/[.,]/g, '');
            value = setCharAt(value, dot, '.');
            // comma is used, comma occurs before dot or dot is unused
          } else if (comma !== -1 && (comma < dot || dot === -1)) {
            value = setCharAt(value, comma, '©');
            value = value.replace(/[.,]/g, '');
            value = setCharAt(value, comma, ',');
          }
          event.target.value = value;
          valueRef.current = value;
          propsOnChange?.(event);
        }
      },
      [propsOnChange],
    );
    return (
      <TextInput
        ref={ref}
        {...props}
        type="text"
        lang={lang}
        inputMode={inputMode}
        pattern="[0-9]+([\.|,][0-9]+)?"
        formNoValidate
        autoComplete="off"
        onChange={onChange}
        value={propsValue}
        style={
          {
            speakAs: 'numbers',
          } as any
        }
      />
    );
  },
);
NumberInput.displayName = 'NumberInput';
