import { useState } from 'react';

export const useSortable = (initialKey = 'ITEM', initialReversed = false) => {
  const [sortBy, setSortBy] = useState(initialKey);
  const [reverseData, setReverse] = useState(initialReversed);

  const handleSort = (sortByKey: string) => () => {
    /* When new column to sort, reset "reverseData", else toggle it. */
    setReverse(sortByKey === sortBy && !reverseData);
    setSortBy(sortByKey);
  };

  return {
    sortByKey: sortBy,
    isReversed: reverseData,
    handleSort,
  };
};

export default useSortable;
