import { light } from '@oms/ui-icon';
import { AlarmButton, IconButton } from '@oms/ui-icon-button';
import { Stack } from '@oms/ui-stack';
import { Table, Tbody, Td, Th, Thead, Tr } from '@oms/ui-table';
import { useState } from 'react';
import { getAlertCriteriaFromParams } from 'utils/alerts';
import { Alert, useAlerts } from 'utils/hooks/useAlerts';

type Props = {
  handleToggleAlert: (alert: Alert) => Promise<any>;
  handleEditAlert: (alert: Alert) => any;
  handleDeleteAlert: (alert: Alert) => any;
};

export const AlertsList = ({
  handleToggleAlert,
  handleEditAlert,
  handleDeleteAlert,
}: Props) => {
  const [isPending, setIsPending] = useState<number>();
  const { data: alerts = [] } = useAlerts();
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Endret</Th>
          <Th type="symbol">Ticker</Th>
          <Th>Kriterie</Th>
          <Th>Status</Th>
          <Th children="" width="8rem" />
        </Tr>
      </Thead>
      <Tbody>
        {alerts.map((alert, index) => {
          const { active } = alert;
          const criteria = getAlertCriteriaFromParams(alert);
          return (
            <Tr key={index}>
              <Td type="dateTime">{alert.modifiedTime}</Td>
              <Td
                type="symbol"
                ticker={alert.item}
                name={alert.instrumentDescription}
              />
              <Td>
                {criteria || <pre>{JSON.stringify(alert, undefined, 2)}</pre>}
              </Td>
              <Td>
                {alert.active ? 'Aktiv' : 'Inaktiv'}
                {alert.violationTime && ' - varsling sendt'}
              </Td>
              <Td width="8rem">
                <Stack orientation="horizontal" gap={2}>
                  <AlarmButton
                    tooltip={pressed =>
                      `Skru ${pressed ? 'av' : 'på'} varsling`
                    }
                    pressed={active}
                    onClick={() => {
                      // TODO: Also show loading state
                      if (isPending === alert.id) return;
                      setIsPending(alert.id);
                      handleToggleAlert(alert).then(() =>
                        setIsPending(undefined),
                      );
                    }}
                  />
                  <IconButton
                    tooltip="Rediger alarm"
                    icon={light.faEdit}
                    onClick={() => handleEditAlert(alert)}
                  />
                  <IconButton
                    tooltip="Slett alarm"
                    icon={light.faTrashAlt}
                    onClick={() => handleDeleteAlert(alert)}
                  />
                </Stack>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export default AlertsList;
