import styled from '@emotion/styled';

const LogoHeading = styled.div`
  width: 162px;
  display: block;
  margin: 0 auto;

  img {
    height: 45px;
  }
`;

const Heading = styled.div`
  background-color: #002775;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  left: 0;
  width: calc(100% - 0px);
  z-index: 5;
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 23px;
  color: white;
  display: block;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const HtestInfo = styled.p`
  color: white;
  display: block;
  margin: 0 auto;
  padding: 0px 0px 10px 0px;

  @media (min-width: 768px) {
    width: 660px;
  }
`;

const HtestWhiteBox = styled.div`
  background-color: white;
  margin-bottom: 20px;
  padding: 10px;
`;

const headings: Record<number, string> = {
  0: 'Kundeopplysningsskjema (KOS)',
  1: 'Yrke, utdanning og erfaring',
  2: 'Kunnskap og erfaring med finansielle instrumenter',
  3: 'Midlenes opprinnelse',
  4: 'Politisk eksponert person',
  5: 'Vilkår',
  6: 'Oppsummering',
};

const hInfo: Record<number, string> = {
  0: 'Det er på tide å oppdatere kundeopplysningene dine. SpareBank 1 Markets er underlagt hvitvaskingsreglene og står under tilsyn av Finanstilsynet. Det betyr blant annet at vi må ha oppdaterte kundeopplysninger. Før du logger deg på aksjehandelstjenesten må du fylle ut kundeopplysningsskjemaet.',
  3: 'I forbindelse med antihvitvasking er vi pålagt å innhente informasjon relatert til investeringsmidlenes opprinnelse og formålet med investeringene.',
};

type Props = {
  step: number;
};
export const PageHeader = ({ step }: Props) => (
  <>
    <LogoHeading>
      <img id="sbenLogo" src="/logo.svg" alt="Sparebank1 Logo" />
    </LogoHeading>
    <Heading>
      <Title>{headings[step]}</Title>
      {step === 0 || step === 3 ? <HtestInfo>{hInfo[step]}</HtestInfo> : ''}
      {step === 0 ? (
        <HtestWhiteBox>
          KOS-skjema er kun nødvendig å svare på om du ønsker å handle AKSJER i
          SpareBank 1 Markets.
          <br />
          Om du skal kjøpe FOND kan du{' '}
          <a href="https://www.sparebank1.no/nb/bank/privat/sparing/spare-i-fond.html">
            klikke her.
          </a>
        </HtestWhiteBox>
      ) : (
        ''
      )}
    </Heading>
  </>
);

export default PageHeader;
