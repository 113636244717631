import styled from '@emotion/styled';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import { TextInput } from '@oms/ui-text-input';
import OriginOfFunds from '../OriginOfFunds';
import IsFundsFromAbroad from '../IsFundsFromAbroad';
import { Heading } from '@oms/ui-heading';
import MonthlyTransactions from '../MonthlyTransactions';
import TransactionTradeSize from '../TransactionTradeSize';
import TransactionFundsSize from '../TransactionFundsSize';
import { required, notEmptyArray, composeValidators } from '../validate';
import Condition from 'components/FormComponents/Condition';
import { Stack } from '@oms/ui-stack';
import CompanyOtherInformation from '../CompanyOtherInformation/CompanyOtherInformation';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

const Divider = styled.hr`
  margin: 1rem 0;
  color: lightgray;
`;
export const Laundering = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <Stack gap={4}>
      <OriginOfFunds />
      <IsFundsFromAbroad />
      <Divider />
      <Heading>Hva er formålet med ditt kundeforhold hos oss?</Heading>
      <Fieldset
        as={CheckboxGroup}
        name="additionalInformation.laundering.purpose"
        legend="Gjelder bakgrunnen for inngåelse av kundeforholdet."
      >
        <Field
          as={Checkbox}
          label="Investering"
          value="investment"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Annet"
          value="other"
          validate={composeValidators(required, notEmptyArray)}
        />
        {/* <ValidationError name="additionalInformation.laundering.purpose" /> */}
      </Fieldset>
      <Condition
        when="additionalInformation.laundering.purpose"
        includes="other"
      >
        <PdfTextInput
          label="Beskrivelse annet"
          name="additionalInformation.laundering.otherPurposeDescription"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <MonthlyTransactions />
      <TransactionTradeSize />
      <TransactionFundsSize />
      <CompanyOtherInformation />
      <Divider />
    </Stack>
  );
};
export default Laundering;
