/* eslint-disable */

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true,
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

import { enforce } from './util/enforce';

function mergeNewMBCommunicationInstanceWithWindowInstance(
  newInstance,
  caller,
) {
  var newCallbacks = _extends(
    window.MBCommunication.callbacks,
    newInstance.callbacks || {},
  );
  if (caller) {
    newCallbacks = _extends(
      newCallbacks,
      _defineProperty(
        {},
        caller,
        _extends(
          window.MBCommunication.callbacks[caller],
          newInstance.callbacks[caller],
        ),
      ),
    );
  }
  window.MBCommunication = _extends(window.MBCommunication, newInstance, {
    callbacks: newCallbacks,
  });
}

function updateWindowMBCommunicationInstance(newInstance, caller) {
  if (window.MBCommunication) {
    mergeNewMBCommunicationInstanceWithWindowInstance(newInstance, caller);
  } else {
    window.MBCommunication = newInstance;
  }
}

var MBCommunication = {
  trigger: function trigger(caller, action, json) {
    this.triggerCallback(caller, action, json);
  },
  triggerCallback: function triggerCallback(caller, action, json) {
    if (!this.callbacks[caller] || !this.callbacks[caller][action]) {
      return;
    }
    var jsonObject = void 0;
    try {
      if (json) {
        jsonObject = JSON.parse(json);
      }
    } finally {
      this.callbacks[caller][action](jsonObject);
    }
  },
};

/**
 * Registers a callback to the window.MBCommunication object that can be called from native.
 * @param {string} caller - A string identifying who is registering the callback.
 * @param {string} action - A string identifying the action the callback performs.
 * @param {function} func - The callback function.
 */
export function registerCallback() {
  var caller =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('caller');
  var action =
    arguments.length > 1 && arguments[1] !== undefined
      ? arguments[1]
      : enforce('action');
  var func =
    arguments.length > 2 && arguments[2] !== undefined
      ? arguments[2]
      : enforce('func');

  if (!MBCommunication.callbacks) {
    MBCommunication.callbacks = {};
  }
  if (!MBCommunication.callbacks.caller) {
    MBCommunication.callbacks[caller] = {};
  }
  MBCommunication.callbacks[caller][action] = func;
  updateWindowMBCommunicationInstance(MBCommunication, caller);
}
