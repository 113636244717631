import React from 'react';

import { useMedia } from '@oms/ui-media';
import { Box } from '@oms/ui-box';
import { SmartSearch } from 'components/SmartSearch';
import { QuickTrade } from 'components/Trade/QuickTrade/QuickTrade';
import { AccountSwitcherDialog } from 'components/AccountSwitcher';
import { useCustomerInformation } from 'utils/hooks/useUser';
import { useUser } from 'utils/hooks/useUser';
import { Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  DrawerDisclosure,
  useDrawer,
  DrawerHeader,
  DrawerContent,
  DRAWER_HEADER_ID,
} from '@oms/ui-drawer';

import { User } from 'components/User';
import * as S from './styles';
import logo from './logo.svg';
import { useContentStateContext } from 'context/ContentStateContext';
import { useAccounts, useSelectedAccount } from 'utils/hooks/useAccounts';
import { BankIDButton } from 'components/Buttons';
import { Stack } from '@oms/ui-stack';
import { RenderField } from '@oms/ui-field';
import { Switch } from '@oms/ui-switch';
import { useUserPreferences } from 'context/UserPreferencesContext';
import { Sniplet } from 'components/Sniplet';
import LogoutButton from 'components/Buttons/LogoutButton';
import { ActionDrawerButtons } from 'components/Actions';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { MineOrder } from './MineOrder';
import EmailAlerts from 'components/User/EmailAlerts';
import { Notification } from '@oms/ui-notification';
import useIsStatusU from 'utils/hooks/useIsStatusU';

export interface HeaderProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  actions?: JSX.Element;
}

const useNavLinks = () => {
  const { group } = useCustomerInformation();
  const {
    data: { accounts },
  } = useAccounts();

  const onlyAsk = accounts.every(account => account.ask);

  if (group === '40' || !onlyAsk) {
    return [
      { to: '/', label: 'Min oversikt' },
      { to: '/market', label: 'Aksjehandel' },
      { to: '/analysis', label: 'Analyser' },
      { to: '/quotelist', label: 'Kursliste' },
      { to: '/alerts', label: 'Alarmer' },
      { to: '/news', label: 'Børsmeldinger' },
      { to: '/transactions', label: 'Transaksjoner' },
      // { to: '/margin', label: 'Belåning' },
      //{ to: '/detailed-chart', label: 'Avansert graf' },
    ];
  } else
    return [
      { to: '/', label: 'Min oversikt' },
      { to: '/market', label: 'Aksjehandel' },
      { to: '/analysis', label: 'Analyser' },
      { to: '/quotelist', label: 'Kursliste' },
      { to: '/alerts', label: 'Alarmer' },
      { to: '/news', label: 'Børsmeldinger' },
      { to: '/transactions', label: 'Transaksjoner' },
      //{ to: '/detailed-chart', label: 'Avansert graf' },
    ];
};

export function Header({ actions, ...props }: HeaderProps) {
  const { hideQuickTrade } = useContentStateContext();
  const { preferences, updatePreference } = useUserPreferences();
  const {
    data: { hasTradingRights, hasStreaming },
  } = useUser();
  const { id } = useCustomerInformation();
  const links = useNavLinks();
  const { isMobile, isTablet } = useMedia();
  const { pathname } = useLocation();
  const drawer = useDrawer();
  const {
    data: { selectedAccount },
  } = useSelectedAccount();
  const isAccountBlocked = useIsStatusU();

  const accountType = selectedAccount?.ask ? 'accountASK' : 'accountAH';
  const showQuickTrade =
    !hideQuickTrade && ['/', '/market', '/quotelist'].includes(pathname);

  const blockedNotification = (
    <Notification title="Kontoen din er blokkert" status="error">
      Sett hen til sanksjonsregelverket har SpareBank 1 Markets besluttet å
      foreta en screening av alle nye kunder opp mot gjeldende sanksjonslister
      før det åpnes for handel. Siste frist for å bli med i dagens (bankdag)
      kjøring mot sanksjonslistene er kl. 07:00. Dette betyr at dersom du
      registrerer deg før kl. 07:00, vil vi åpne din konto senest kl. 12:00
      samme dag. Dersom du registrerer deg etter kl. 07:00, vil dette medføre +1
      bankdag i behandlingstid.
    </Notification>
  );

  if (isMobile && !isTablet) {
    return (
      <S.Header {...props}>
        <ErrorBoundary>
          <Sniplet title="Driftsmelding" name="maintMessages" />
        </ErrorBoundary>
        <ErrorBoundary>
          <Sniplet title="Informasjon" name="infoMessages" enableHideButton />
        </ErrorBoundary>
        <S.MobileContainer>
          <ErrorBoundary>
            <SmartSearch />
          </ErrorBoundary>
          <Link to="/">
            <Box as="img" src={logo} maxHeight="28px" width="100%" />
          </Link>
          <DrawerDisclosure as={S.HamburgerButton} {...drawer}>
            <S.Hamburger />
          </DrawerDisclosure>
        </S.MobileContainer>
        <Drawer {...drawer} aria-labelledby={DRAWER_HEADER_ID}>
          <DrawerHeader action={<LogoutButton />}>Meny</DrawerHeader>
          <DrawerContent p={0}>
            <Stack justifyContent="center" p="1rem">
              {links.map(link => (
                <S.DrawerLink
                  key={link.to}
                  exact
                  to={link.to}
                  onClick={drawer.hide}
                >
                  {link.label}
                </S.DrawerLink>
              ))}
              <ActionDrawerButtons />
            </Stack>
            <S.DrawerSection mt="auto">
              <AccountSwitcherDialog />
            </S.DrawerSection>
            <S.DrawerSection pb={16}>
              <Stack>
                {hasStreaming && (
                  <RenderField
                    name="streaming"
                    label="Streaming"
                    as={Switch}
                    checked={preferences.streaming}
                    onChange={(event: React.FormEvent<HTMLInputElement>) =>
                      updatePreference('streaming', event.currentTarget.checked)
                    }
                  />
                )}
                <RenderField
                  name="alerts"
                  label="Ordrevarsler"
                  as={Switch}
                  checked={!preferences.notifications?.disable}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    updatePreference('notifications', {
                      disable: !event.currentTarget.checked,
                    })
                  }
                />
              </Stack>
              <EmailAlerts hideLabels />
            </S.DrawerSection>
            <div
              // spacer div :(
              // adds some space for the opened date picker
              aria-hidden={true}
              style={{ height: 240, minHeight: 240 }}
            ></div>
          </DrawerContent>
        </Drawer>
        {isAccountBlocked && blockedNotification}
      </S.Header>
    );
  }

  if (isTablet) {
    return (
      <S.Header {...props}>
        <div />
        <ErrorBoundary>
          <Sniplet title="Driftsmelding" name="maintMessages" />
        </ErrorBoundary>
        <ErrorBoundary>
          <Sniplet title="Informasjon" name="infoMessages" enableHideButton />
        </ErrorBoundary>
        <S.Main>
          <>
            <S.MobileContainer>
              <div />
              <Link to="/">
                <Box as="img" src={logo} maxHeight="28px" width="100%" />
              </Link>
              <DrawerDisclosure as={S.HamburgerButton} {...drawer}>
                <S.Hamburger />
              </DrawerDisclosure>
            </S.MobileContainer>
            <Drawer {...drawer} aria-labelledby={DRAWER_HEADER_ID}>
              <DrawerHeader action={<LogoutButton />}>Meny</DrawerHeader>
              <DrawerContent p={0}>
                <Stack justifyContent="center" p="1rem">
                  {links.map(link => (
                    <S.DrawerLink
                      key={link.to}
                      exact
                      to={link.to}
                      onClick={drawer.hide}
                    >
                      {link.label}
                    </S.DrawerLink>
                  ))}
                </Stack>
                <S.DrawerSection pb={16}>
                  <Stack mb="1.5rem">
                    {hasStreaming && (
                      <RenderField
                        name="streaming"
                        label="Streaming"
                        as={Switch}
                        checked={preferences.streaming}
                        onChange={(event: React.FormEvent<HTMLInputElement>) =>
                          updatePreference(
                            'streaming',
                            event.currentTarget.checked,
                          )
                        }
                      />
                    )}
                    <RenderField
                      name="alerts"
                      label="Ordrevarsler"
                      as={Switch}
                      checked={!preferences.notifications?.disable}
                      onChange={(event: React.FormEvent<HTMLInputElement>) =>
                        updatePreference('notifications', {
                          disable: !event.currentTarget.checked,
                        })
                      }
                    />
                  </Stack>
                  <EmailAlerts hideLabels />
                </S.DrawerSection>
              </DrawerContent>
            </Drawer>
          </>
        </S.Main>
        <S.Main className={accountType}>
          <S.SubHeader gridTemplateColumns="1fr 1fr 8fr 1fr 1fr">
            <Box center>
              <AccountSwitcherDialog showBorder />
            </Box>
            <Box display={'flex'}>
              <MineOrder />
            </Box>
            <ErrorBoundary>
              <SmartSearch />
            </ErrorBoundary>
            <div>{/*  Empty div to align items*/}</div>
            <Box center>{actions}</Box>
          </S.SubHeader>
        </S.Main>
        {isAccountBlocked && blockedNotification}
      </S.Header>
    );
  }
  return (
    <S.Header {...props}>
      <Stack maxWidth="50rem" mx="auto" my={2} gap={2}>
        <Sniplet title="Driftsmelding" name="maintMessages" />
        <Sniplet title="Informasjon" name="infoMessages" enableHideButton />
      </Stack>
      <S.Main>
        <S.Grid>
          <S.Logo
            pt="10px"
            gridArea="logo"
            maxHeight="60px"
            width="100%;"
            placeSelf="end"
            src={logo}
          />
          <Box gridArea="user" center>
            <User />
          </Box>

          <S.Nav gridArea="nav">
            <S.LinkList>
              {links.map(link => (
                <S.LinkListItem key={link.to}>
                  <S.Link exact to={link.to}>
                    {link.label}
                  </S.Link>
                </S.LinkListItem>
              ))}
            </S.LinkList>
          </S.Nav>
        </S.Grid>
      </S.Main>
      <S.Main className={accountType}>
        <S.SubHeader gridTemplateColumns="1fr 1fr 8fr 1fr 1fr">
          <Box center>
            <AccountSwitcherDialog showBorder />
          </Box>
          <Box display={'flex'}>
            <MineOrder />
          </Box>
          <ErrorBoundary>
            <SmartSearch />
          </ErrorBoundary>
          <Box center />
          <Box center>{actions}</Box>
        </S.SubHeader>
      </S.Main>
      {isAccountBlocked && blockedNotification}
      {showQuickTrade && (
        <S.MainQuickTrade>
          <S.QuickTrade gridTemplateColumns="1fr">
            <Box center>
              <ErrorBoundary>
                {hasTradingRights ? <QuickTrade id={id} /> : <BankIDButton />}
              </ErrorBoundary>
            </Box>
          </S.QuickTrade>
        </S.MainQuickTrade>
      )}
    </S.Header>
  );
}
