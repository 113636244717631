import { useQuery } from 'react-query';
import { EXCHANGE_URL } from 'constants/Jaws';
import { ONE_MINUTE } from 'constants/timeInMs';

type UseTickSizeOptions = {
  ITEM: string;
  SECTOR: string;
};

export const getTickSize = async ({ ITEM, SECTOR }: UseTickSizeOptions) => {
  const response = await fetch(
    `${EXCHANGE_URL}/${SECTOR}/ticker/${ITEM}/limit-rules`,
  );
  if (!response.ok) {
    return 'fetchTick GET tickSize failed';
  }
  return await response.json();
};

export const tickQueryKey = ({ ITEM, SECTOR }: UseTickSizeOptions) =>
  ['tickSize', { ITEM, SECTOR }] as const;

export const useTickSize = ({ ITEM, SECTOR }: UseTickSizeOptions) => {
  return useQuery(
    tickQueryKey({ ITEM, SECTOR }),
    () => getTickSize({ ITEM, SECTOR }),
    {
      suspense: true,
      enabled: !!ITEM && !!SECTOR,
      staleTime: ONE_MINUTE * 5, // Don't really need to keep refetching for the same item sector.
    },
  );
};
