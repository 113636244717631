import { useJaws } from '@oms/jaws-react';
import { getTrendColor } from 'utils/trend';
import { useTheme } from '@xstyled/emotion';

export type TickerMap = Record<string, string>;

export const tickerMapDefault = {
  'OSEBX.OSE': 'Oslo Børs',
  'I:DAX.GER': 'DAX 30',
  'NI225.OSA': 'Nikkei 225',
  'C:PBROUSDBR\\SP.IDCENE': 'Brent spot',
  'I:COMP.NAS': 'Nasdaq',
  'X:SUSDNOK.IDCFX': 'US Dollar',
  'X:SEURNOK.IDCFX': 'EUR',
  'X:SGBPNOK.IDCFX': 'GBP',
} as TickerMap;

export const getLastId = (itemSector: string) =>
  `tickertape-last-${itemSector}`;
export const getChangeId = (itemSector: string) =>
  `tickertape-change-${itemSector}`;

export type Entry = { ITEM_SECTOR: string; LAST: number; CHANGE_PCT: number };
type Entries = Record<string, Entry>;

export const useTickers = ({
  tickerMap = tickerMapDefault,
}: {
  tickerMap?: TickerMap;
}) => {
  const jaws = useJaws({
    itemSector: Object.keys(tickerMap).join(','),
    columns: 'ITEM_SECTOR,LAST,CHANGE_PCT',
  });

  const entries: Entries = jaws.hasData ? jaws.items.toJS() : {};
  return { ...jaws, entries };
};

export const useTrendColor = () => {
  const theme = useTheme();
  return (value: number) => theme.colors[getTrendColor(value)];
};
