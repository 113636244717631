import {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import * as React from 'react';
import useUser from 'utils/hooks/useUser';
import { endTakeover, startTakeover } from './utils';
import { useQueryClient } from 'react-query';
import { useSessionStorage } from 'react-use';

type TakeoverContextType = {
  takeoverTab: Window | null;
  handleStartTakeover: (customerId: string) => void;
  handleEndTakeover: () => void;
  focusTakeoverTab: () => void;
  hasSupportRole: boolean;
  status: Status;
  isTakeover: boolean;
};

type Status = 'idle' | 'pending' | 'active' | 'error';

export const TakeoverContext = createContext<TakeoverContextType | null>(null);
const useTakeover = () => {
  const ctx = useContext(TakeoverContext);
  if (ctx === null)
    throw new Error('useTakeover has to be used inside TakeoverProvider');
  return ctx;
};

// TODO: Mostly copied from Norne, perhaps adjustment/refactor is needed? Also keep admin-library in mind
// This is a mess, rethink it all?
export const TakeoverProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient();
  const {
    data: { hasRole, isOwnTakeover, customerData, isTakeover },
  } = useUser();
  const hasSupportRole = hasRole('support');
  const [takeoverTab, setTakeoverTab] = useSessionStorage<Window | null>(
    'takeoverTab',
    null,
  );

  const [error, setError] = useState<string>();
  const [status, setStatus] = useState<Status>('idle');

  const focusTakeoverTab = useCallback(() => {
    if (!takeoverTab || takeoverTab.closed) {
      const newReference = window.open('', 'takeoverTab');
      setTakeoverTab(newReference);
      if (!newReference) throw new Error("Couldn't open tab for takeover");

      newReference.location.href = '/';
      return;
    }

    takeoverTab.focus();
  }, [setTakeoverTab, takeoverTab]);

  const handleEndTakeover = useCallback(async () => {
    if (!customerData) throw new Error('Ingen customerData');
    const {
      masterAccount: { ownerId },
    } = customerData;
    if (!ownerId) return console.error(`No customerId? "${ownerId}"`);

    setStatus('pending');
    endTakeover(ownerId).then(() => {
      setStatus('idle');
      if (takeoverTab) {
        takeoverTab.close();
      }
      queryClient.invalidateQueries('me');
      setTakeoverTab(null);
    });
  }, [customerData, queryClient, setTakeoverTab, takeoverTab]);

  const handleStartTakeover = useCallback(
    async (customerId: string) => {
      setError(undefined);
      setStatus('pending');

      // If existing takeover tab is open, close it.
      if (takeoverTab) takeoverTab.close();
      const newTakeoverTab = window.open('', 'takeoverTab');

      try {
        startTakeover(customerId).then(() => {
          queryClient.invalidateQueries('me');

          setStatus('active');
          if (newTakeoverTab && !newTakeoverTab.closed) {
            setTakeoverTab(newTakeoverTab);
            newTakeoverTab.location.href = '/';
          } else {
            console.error('Fant ikke support-takeover tab');
            handleEndTakeover();
            setStatus('error');
            setError('Fant ikke support-takeover tab');
            return;
          }
        });
      } catch (error) {
        setStatus('error');
        setError(`Feil skjedde ved takeover: ${error}`);
      }
    },
    [takeoverTab, queryClient, setTakeoverTab, handleEndTakeover],
  );

  useEffect(() => {
    // Handle incoming messages
    window.onmessage = (event: MessageEvent) => {
      if (
        event.origin === window.location.origin &&
        event.data === 'endTakeover'
      ) {
        handleEndTakeover();
      }
    };
  }, [handleEndTakeover]);

  // Set up interval to check if tab is closed
  // Note: If we lose contact with tab (ie. if this admin tab is reloaded) this doesn't do much.
  //       You can still end takeover in the user menu in the top right, so this'll be fine for now.
  useEffect(() => {
    let checkTabInterval: NodeJS.Timeout;

    if (isOwnTakeover) {
      checkTabInterval = setInterval(() => {
        if (takeoverTab?.closed) {
          handleEndTakeover();
          clearInterval(checkTabInterval);
        }
      }, 500);
    }

    return () => clearInterval(checkTabInterval);
  }, [isOwnTakeover, takeoverTab, handleEndTakeover]);

  const value = useMemo(
    () => ({
      error,
      status,
      isTakeover,
      takeoverTab,
      handleStartTakeover,
      handleEndTakeover,
      hasSupportRole,
      focusTakeoverTab,
    }),
    [
      error,
      status,
      isTakeover,
      takeoverTab,
      handleStartTakeover,
      handleEndTakeover,
      hasSupportRole,
      focusTakeoverTab,
    ],
  );

  return (
    <TakeoverContext.Provider value={value}>
      {children}
    </TakeoverContext.Provider>
  );
};

export default useTakeover;
