import { Box } from '@oms/ui-box';
import { Quotelist as OMSQuotelist } from '@oms/components-quotelist';
import { ErrorBoundary } from '@oms/ui-error-boundary';

export default function Quotelist() {
  return (
    <Box width="98vw" maxWidth="70rem" overflowX="scroll" mx="auto" mt={12}>
      <ErrorBoundary>
        <OMSQuotelist />
      </ErrorBoundary>
    </Box>
  );
}

export { Quotelist };
