import { Page } from 'components/Page';
import { GridMyOverview, GridContainer } from 'components/Grid';
import { AccountSwitcher } from 'components/AccountSwitcher';
import { CustomerInformation } from 'components/CustomerInformation';
import { ImportantInfo } from 'components/ImportantInfo';
import { ContactForm } from 'components/ContactForm';
import { UserServices } from 'components/UserServices';
import { RealtimeInfo } from 'components/RealtimeInfo';
import { Card, CardWithAccount } from 'components/Card';
import { Scroll } from '@oms/ui-scroll';
import { MarketToday } from 'components/MarketToday';
import { AccountHoldings } from 'components/AccountHoldings';
import { ROIChart } from 'components/Charts/ROIChart';
import MyOverviewHeader from './MyOverviewHeader';
import { MarginCalculator } from 'components/MarginCalculator';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { MarginOverview } from 'components/MarginOverview';
import { ErrorBoundary } from '@oms/ui-error-boundary';

export interface MyOverviewProps {}

export default function MyOverview(_props: MyOverviewProps) {
  const {
    data: { selectedAccount },
  } = useSelectedAccount();

  return (
    <Page documentTitle="Min oversikt" topElement={<MyOverviewHeader />}>
      <GridContainer>
        <GridMyOverview>
          <ErrorBoundary>
            <Card title="Mine konti" gridColumn={{ sm: 'auto / span 2' }}>
              <AccountSwitcher />
              {selectedAccount?.isMarginAccount && <MarginOverview />}
            </Card>
          </ErrorBoundary>
          <ErrorBoundary>
            <CardWithAccount
              display="flex"
              title="Beholdning"
              gridColumn={{ sm: 'auto / span 2' }}
              height={{ sm: '30rem' }}
              stackProps={{ flex: 1 }}
            >
              <Scroll>
                <AccountHoldings />
              </Scroll>
            </CardWithAccount>
          </ErrorBoundary>
          <MarketToday gridColumn={{ sm: 'auto / span 2' }} />
          <ErrorBoundary>
            <CardWithAccount
              title="Avkastning"
              gridColumn={{ sm: 'auto / span 2' }}
            >
              <ROIChart />
            </CardWithAccount>
          </ErrorBoundary>
          <ErrorBoundary>
            <CustomerInformation gridColumn={{ sm: 'auto / span 2' }} />
          </ErrorBoundary>
          {selectedAccount?.isMarginAccount && (
            <ErrorBoundary>
              <CardWithAccount
                title="Marginkalkulator"
                gridColumn={{ sm: 'auto / span 2' }}
              >
                <MarginCalculator showTradeButton />
              </CardWithAccount>
            </ErrorBoundary>
          )}
          <ErrorBoundary>
            <UserServices gridColumn={{ sm: 'auto / span 2' }} />
          </ErrorBoundary>
          <ErrorBoundary>
            <RealtimeInfo gridColumn={{ sm: 'auto / span 2' }} />
          </ErrorBoundary>
          <ErrorBoundary>
            <ImportantInfo gridColumn={{ sm: 'auto / span 2' }} />
          </ErrorBoundary>
          <ErrorBoundary>
            <ContactForm gridColumn={{ sm: 'auto / span 2' }} />
          </ErrorBoundary>
        </GridMyOverview>
      </GridContainer>
    </Page>
  );
}

export { MyOverview };
