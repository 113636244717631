import { useQuery } from 'react-query';
import { REPORT_URL } from 'constants/Jaws';

export type Document = {
  id: number;
  mediaType: string;
  fileName: string;
  file: any;
};

export type Report = {
  reportId: number;
  type: string;
  title: string;
  ingress: string;
  analystName: string;
  startTime: string;
  endTime: string;
  document?: Document;
};

export type Analysis = {
  id: number;
  recommendation: string;
  ticker: string;
  exchange: string;
  report: Report;
  recommendationPrice?: number;
};

type UseInstrumentOptions = {
  item: string;
  from: string;
  count: number;
};

const getAnalysis = (options: UseInstrumentOptions) => {
  const { item, from, count = 20 } = options;
  return fetch(
    `${REPORT_URL}/recommendation/${item}.OSE?from=${from}&count=${count}`,
  )
    .then(res => {
      if (!res.ok) {
        return;
      }
      return res.json();
    })
    .then(data => data as Analysis[]);
};

const getAllInstrumentWithAnalysis = () => {
  return fetch(`${REPORT_URL}/recommendation/all?count=3000`)
    .then(res => {
      if (!res.ok) {
        return;
      }
      return res.json();
    })
    .then(data => data as Analysis[]);
};

const getAllAnalysis = () => {
  return fetch(`${REPORT_URL}/recommendation/all?count=20`)
    .then(res => {
      if (!res.ok) {
        return;
      }
      return res.json();
    })
    .then(data => data as Analysis[]);
};

export const useInstrumentAnalysis = (
  item: string,
  /** Date */
  from: string,
  count: number,
) => {
  return useQuery(
    ['instrumentAnalysis', { item, from, count }],
    () => getAnalysis({ item, from, count }),
    {
      suspense: true,
    },
  );
};

export const useAllAnalysis = () => {
  return useQuery(['allAnalysis'], () => getAllAnalysis(), {
    suspense: true,
  });
};

export const useAllInstrumentWithAnalysis = () => {
  return useQuery(
    ['allInstrumentWithAnalysis'],
    () => getAllInstrumentWithAnalysis(),
    {
      suspense: true,
    },
  );
};
