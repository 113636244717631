import { Field, Fieldset } from '@oms/ui-field';
import Condition from 'components/FormComponents/Condition';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import { required, notEmptyArray, composeValidators } from '../validate';
import { InfoBubble } from '@oms/ui-info-bubble';
import { Box } from '@oms/ui-box';
import { NumberInput } from 'components/NumberInput';
import styled from '@emotion/styled';
import { Select } from '@oms/ui-select';
import { monthsOptions } from 'utils/locales';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

const SubForm = styled.div`
  padding-left: 20px;
  padding-block: 10px;
`;

export const OriginOfFunds = () => {
  const currentYear = new Date().getFullYear();
  const yearsOptions = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <Stack gap={4}>
      <Heading>Hvor kommer beløpet som du skal investere fra? </Heading>
      <Fieldset
        as={CheckboxGroup}
        name="kycOriginOfFunds"
        legend="Du kan velge flere alternativer"
      >
        <Field
          as={Checkbox}
          label="Lønn, næringsvirksomhet, studielån"
          value="SALARY_BUSINESS_STUDENT_LOAN"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Pensjon"
          value="PENSION"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Midler fra NAV (Dagpenger, trygd el.lign.)"
          value="FUNDS_FROM_NAV"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Sparing eller gevinst fra verdipapirhandel"
          value="SAVINGS"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Arv eller gave"
          value="INHERITANCE_OR_GIFT"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Condition when="kycOriginOfFunds" includes="INHERITANCE_OR_GIFT">
          <SubForm>
            <PdfTextInput
              name="kycInheritanceOrGiftFrom"
              label="Hvem kommer arv/gave fra?"
              isPDFRender={isPDFRender}
            />
            <Field
              name="kycInheritanceOrGiftValue"
              as={NumberInput}
              label="Sum"
              validate={required}
            />
          </SubForm>
        </Condition>
        <Field
          as={Checkbox}
          label="Salg av eiendom"
          value="SALE_OF_PROPERTY"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Condition when="kycOriginOfFunds" includes="SALE_OF_PROPERTY">
          <SubForm>
            <Heading>Salgssum</Heading>
            <Field
              name="kycPropertySoldValue"
              as={NumberInput}
              label=""
              validate={required}
              width="55%"
            />
            <Box display="flex" width="100%">
              <Field
                name="kycPropertySoldMonth"
                as={Select}
                items={monthsOptions}
                validate={required}
                placeholder="Mnd"
                label=""
                width="30%"
              />
              <Field
                name="kycPropertySoldYear"
                as={Select}
                items={yearsOptions}
                validate={required}
                placeholder="År"
                label=""
                width="25%"
              />
            </Box>
          </SubForm>
        </Condition>
        <Field
          as={Checkbox}
          label="Salg av eiendeler"
          value="SALE_OF_ASSETS"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Condition when="kycOriginOfFunds" includes="SALE_OF_ASSETS">
          <SubForm>
            <Box display="flex" width="90%">
              <PdfTextInput
                name="kycAssetsSold"
                label="Hvilke eiendeler har du solgt?"
                isPDFRender={isPDFRender}
              />
              <Box display="flex" alignItems="flex-end" mb={2}>
                <InfoBubble aria-label="beskrivelse" ml={4}>
                  Salg av eiendeler kan for eksempel være salg av bolig,
                  eiendom, foretak, bil eller båt.
                </InfoBubble>
              </Box>
            </Box>
            <Field
              name="kycAssetsSoldValue"
              as={NumberInput}
              label="Sum"
              validate={required}
            />
          </SubForm>
        </Condition>
        <Field
          as={Checkbox}
          label="Forsikringsutbetaling"
          value="INSURANCE_PAYOUT"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Condition when="kycOriginOfFunds" includes="INSURANCE_PAYOUT">
          <SubForm>
            <Field
              name="kycInsurancePayoutValue"
              as={NumberInput}
              label="Forsikringssum"
              validate={required}
            />
          </SubForm>
        </Condition>
        <Field
          as={Checkbox}
          label="Annet"
          value="OTHER"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Condition when="kycOriginOfFunds" includes="OTHER">
          <SubForm>
            <PdfTextInput
              name="kycExplainOriginOfFunds"
              label="Skriv hvor beløpet kommer fra"
              isPDFRender={isPDFRender}
            />
          </SubForm>
        </Condition>
      </Fieldset>
    </Stack>
  );
};

export default OriginOfFunds;
