import React from 'react';
import { Card } from '../Card';
import { Field } from '@oms/ui-field';
import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { FieldArray } from 'react-final-form-arrays';
import { InstrumentsEquityField } from './InstrumentsEquityField';
import { Text } from '@oms/ui-text';

import { Box } from '@oms/ui-box';

export interface EquityCashProps {}

export function EquityCash(_props: EquityCashProps) {
  return (
    <Card title="Egenkapital består av">
      <Box
        display="grid"
        gridGap={4}
        px={{ base: 3, sm: 'initial' as any }}
        pb={{ base: 3, sm: 'initial' as any }}
      >
        <Field
          as={NumberInput}
          lang="nb"
          name="equityCash"
          label="Kontant kr."
        />
        <FieldArray name="instruments" component={InstrumentsEquityField} />
        <Text>** Minimum kr. 100.000,-</Text>
        <Text>
          *** Egenkapitalen må pantsettes til fordel for Sparebank 1 Markets
        </Text>
      </Box>
    </Card>
  );
}

export type EquityCashPropsType = {
  equityCash: number;
  [key: string]: any;
};

export const validationRules = (values: EquityCashPropsType) => {
  let errors: Record<keyof EquityCashPropsType, string | undefined> = {};
  if (!values.equityCash) {
    errors['equityCash'] = 'Påkrevd';
  }

  return errors;
};
