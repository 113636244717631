import styled from '@xstyled/emotion';
import { NavLink } from 'react-router-dom';

export const Header = styled.header``;

export const Main = styled.divBox`
  background-color: white;
  border-bottom: sm;
  border-bottom-color: border;
  //padding: 0 20px;
  &.accountAH {
    background-color: surface-5;
  }
  &.accountASK {
    background-color: surface-4;
  }
`;

export const Grid = styled.div`
  display: grid;
  height: 130px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    '. logo user'
    'nav nav nav';
  max-width: 103rem;
  width: 100%;
  margin: auto;
`;

export const Logo = styled.imgBox`
  height: auto;
`;

export const Nav = styled.navBox`
  place-self: start center;
`;

export const LinkList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  margin: 0;
`;

export const LinkListItem = styled.li`
  padding: 1rem 0;
`;

export const Link = styled(NavLink)`
  color: #020a0a;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    color: primary;
  }
  &[aria-current='page'] {
    color: secondary;
    font-weight: 500;
  }
`;

export const SubHeader = styled.divBox`
  display: grid;
  height: 4.5rem;
  grid-template-areas: 'switch search actions';
  max-width: 103rem;
  grid-gap: 2%;
  width: 100%;
  margin: auto;
  align-items: center;
  padding-left: 0.5rem;
`;

export const MainQuickTrade = styled.divBox`
  background-color: white;
  border-bottom: sm;
  border-bottom-color: border;
  padding: 10px 20px;
`;

export const QuickTrade = styled.divBox`
  display: grid;
  grid-template-areas: 'quicktrade';
  max-width: 103rem;
  grid-gap: 2%;
  width: 100%;
  margin: auto;
  align-items: center;
`;

// Keep these styles aligned with components/Actions/styles.ts > DrawerButton
export const DrawerLink = styled(NavLink)`
  font-size: 1.675rem;
  text-decoration: none;
  color: link;

  &:visited {
    color: link;
  }
`;

export const MobileContainer = styled.div`
  box-sizing: border-box;
  height: 60px;
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  /*padding: 0 1.25rem;*/
  align-items: center;
  background-color: white;
  border-bottom: sm;
  border-bottom-color: border;
`;

export const HamburgerButton = styled.button`
  background-color: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
  height: 100%;
  padding-right: 1.25rem;
`;

export const Hamburger = styled.div`
  pointer-events: none;
  &,
  &::before,
  &::after {
    display: block;
    position: relative;
    height: 4px;
    border-radius: 4px;
    width: 25px;
    background-color: secondary;
    content: '';
    transition: all 0.2s;
  }
  &::before {
    position: absolute;
    top: -8px;
  }

  &::after {
    position: absolute;
    top: 8px;
  }
`;

export const DrawerSection = styled.divBox`
  justify-content: flex-end;
  justify-self: flex-end;
  border-top: sm;
  padding: 0.75rem 0.75rem;
  border-top-color: border;
`;
