import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';

import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useUser from 'utils/hooks/useUser';
import { useParams } from 'react-router-dom';

import { Link } from '@oms/ui-link';
import { useOrderDetails } from 'utils/hooks/useOrders';
import { Caption, Table, Tbody, Td, Th, Tr } from '@oms/ui-table';
import { mapOrderStatus } from 'utils/orders';
import { decimalPlaces } from '@oms/utils';
import { Heading } from '@oms/ui-heading';

interface Props {}

export const OrderDetails = (props: Props) => {
  const { orderId } = useParams<any>();
  const { selectedAccountId } = useSelectedAccountId();
  const {
    data: { userId = '' },
  } = useUser();
  const { data } = useOrderDetails({
    userId,
    accountId: selectedAccountId,
    orderId: orderId,
  });
  if (!data) return null;

  const {
    TYPE,
    INSTRUMENT_NAME,
    TICKER_CODE,
    ORDER_STATUS,
    CREATED_DATE,
    EXPIRATION_DATE,
    STOCK_EXCHANGE_ID,
    REMAINING_VOLUME,
    TOTAL_VOLUME,
    LIMIT,
    MEAN_RATE,
  } = data;

  return (
    <Stack margin="auto" p={12}>
      <Heading variant="heading3" textAlign="center">
        Ordrenummer: {orderId}
      </Heading>
      <Box p={4} width="100%" maxWidth="30rem" mx="auto" boxShadow="menu">
        <Table variant="keyValue">
          <Caption p={4} fontSize="lg">{`${
            TYPE === 0 ? 'Kjøp' : 'Salg'
          } av ${INSTRUMENT_NAME} (${TICKER_CODE})`}</Caption>
          <Tbody>
            <Tr>
              <Th>Ordrestatus</Th>
              <Td>{mapOrderStatus(ORDER_STATUS)}</Td>
            </Tr>
            <Tr>
              <Th>Ordredato</Th>
              <Td type="dateTime" formatString="DD.MM.YYYY LT">
                {CREATED_DATE}
              </Td>
            </Tr>
            <Tr>
              <Th>Utløpsdato</Th>
              <Td type="dateTime" formatString="DD.MM.YYYY LT">
                {EXPIRATION_DATE}
              </Td>
            </Tr>
            <Tr>
              <Th>Handelsplass</Th>
              <Td>{STOCK_EXCHANGE_ID}</Td>
            </Tr>
            <Tr>
              <Th>Gjenstående volum</Th>
              <Td type="integer">{REMAINING_VOLUME}</Td>
            </Tr>
            <Tr>
              <Th>Totalt volum</Th>
              <Td type="integer">{TOTAL_VOLUME}</Td>
            </Tr>
            <Tr>
              <Th>Limit</Th>
              <Td type="number" fractionDigits={decimalPlaces(LIMIT)}>
                {LIMIT}
              </Td>
            </Tr>
            <Tr>
              <Th>Snittpris</Th>
              <Td type="number" fractionDigits={decimalPlaces(MEAN_RATE)}>
                {MEAN_RATE}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box mx="auto">
        <Link to="/transactions">Tilbake</Link>
      </Box>
    </Stack>
  );
};

export default OrderDetails;
