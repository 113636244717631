import { Button } from '@oms/ui-button';
import { Icon, light } from '@oms/ui-icon';
import useUser from 'utils/hooks/useUser';
import {
  useWatchlist,
  useAddToWatchlist,
  useRemoveFromWatchlist,
} from 'utils/hooks/useWatchlist';

type Props = {
  itemSector: string;
};
export const WatchlistButton = ({ itemSector }: Props) => {
  const {
    data: { userId = '' },
  } = useUser();

  const { checkIsInWatchlist } = useWatchlist();
  const {
    mutateAsync: addToWatchlist,
    isLoading: addIsLoading,
  } = useAddToWatchlist();

  const {
    mutateAsync: removeFromWatchlist,
    isLoading: removeIsLoading,
  } = useRemoveFromWatchlist();

  const isInWatchlist = checkIsInWatchlist(itemSector);

  const handleWatchlistToggle = () => {
    if (isInWatchlist) return removeFromWatchlist({ itemSector, userId });

    addToWatchlist({ itemSector, userId });
  };

  return (
    <Button
      variant="link"
      leftElement={
        <Icon
          icon={isInWatchlist ? light.faTrashAlt : light.faPlusCircle}
          color="primary"
        />
      }
      onClick={handleWatchlistToggle}
      isPending={addIsLoading || removeIsLoading}
    >
      {!isInWatchlist ? 'Legg til i min liste' : 'Fjern fra min liste'}
    </Button>
  );
};
