import { Notification, NotificationProps } from '@oms/ui-notification';
import { isMarkup, sanitize } from '@oms/utils';
import moment from 'moment';
import { useSessionStorage } from 'react-use';
import { useSniplets } from 'utils/hooks/admin/useSniplets';
import { Sniplet as SnipletType } from 'utils/hooks/admin/useSniplets/helpers';
import styled from '@xstyled/emotion';

function isSnipletPublished(sniplet: SnipletType) {
  if (!sniplet) return false;
  if (!sniplet.published) return false;

  const now = moment();
  const from = sniplet.publishedFrom;
  const to = sniplet.publishedTo;

  return (
    (!from ||
      moment(from)
        .startOf('day')
        .isBefore(now)) &&
    (!to ||
      moment(to)
        .endOf('day')
        .isAfter(now))
  );
}

interface Props {
  name: string;
  enableHideButton?: boolean;
}

export const useHiddenSniplets = () => {
  return useSessionStorage<number>('@oms/sb1-hidden-sniplet');
};

const StyledNotification = styled(Notification)`
  div {
    min-width: 0;
  }
  a {
    display: inline-block;
    overflow-wrap: anywhere;
  }
  img {
    max-width: 100%;
  }
`;

export const Sniplet = ({
  name,
  enableHideButton,
  ...props
}: Props & Partial<NotificationProps>) => {
  const [hidden, setHidden] = useHiddenSniplets();
  const { data: sniplets } = useSniplets();

  const sniplet = sniplets?.find(sniplet => sniplet.name === name);
  if (!sniplet) return null;
  if (!isSnipletPublished(sniplet)) return null;
  const body = sniplet?.bodies?.NO?.body;
  if (!body) return null;
  if (hidden === sniplet.id) return null;

  const onClose = enableHideButton
    ? () => {
        setHidden(sniplet?.id);
      }
    : undefined;

  return (
    <StyledNotification title="" onClose={onClose} {...props}>
      {!isMarkup(body) ? (
        body
      ) : (
        <div dangerouslySetInnerHTML={sanitize(body)} />
      )}
    </StyledNotification>
  );
};
