import React from 'react';
import { Tabs, TabList, Tab, TabPanel } from '@oms/ui-tabs';
import { Stack } from '@oms/ui-stack';
import { Reports } from '../Reports/Reports';
import { WeeklyPortfolio } from 'components/WeeklyPortfolio/WeeklyPortfolio';
import { CardBox, CardTabHeader, CardBoxProps } from 'components/Card';
import { Box } from '@oms/ui-box';
import { Spinner } from '@oms/ui-spinner';
import { useUnseenReports } from 'utils/hooks/useSeenTracker';
import { SkeletonTable } from '@oms/ui-skeleton';
import CustomQuoteList from 'components/QuoteListToggle';
import { ErrorBoundary } from '@oms/ui-error-boundary';
export interface ReportsAndPortfolioProps
  extends Omit<CardBoxProps, 'children'> {
  /** The itemSector to be used in fetching data */
}

type TabState = {
  selectedId: string;
};

export const ReportsAndPortfolio = (props: ReportsAndPortfolioProps) => {
  return (
    <React.Suspense
      fallback={
        <CardBox {...props}>
          <SkeletonTable columns={5} rows={6} animated />
        </CardBox>
      }
    >
      <ErrorBoundary>
        <ReportsAndPortfolioComponent {...props} />
      </ErrorBoundary>
    </React.Suspense>
  );
};

const ReportsAndPortfolioComponent = (props: ReportsAndPortfolioProps) => {
  const {
    morningReportUnseen,
    weekReportUnseen,
    strategyReportUnseen,
    setReportAsSeen,
  } = useUnseenReports();

  return (
    <CardBox {...props}>
      <Tabs selectedId="quotelist">
        <Stack gap={4}>
          <CardTabHeader>
            <TabList aria-label="tabs">
              <Tab id="quotelist">Kursliste</Tab>
            </TabList>
          </CardTabHeader>
          <React.Suspense
            fallback={
              <Box center height="100%">
                <Spinner size="lg" />
              </Box>
            }
          >
            <TabPanel>
              {(tab: TabState) =>
                tab.selectedId === 'quotelist' ? (
                  <ErrorBoundary>
                    <CustomQuoteList />
                  </ErrorBoundary>
                ) : null
              }
            </TabPanel>
            <TabPanel>
              {(tab: TabState) =>
                tab.selectedId === 'reports' ? (
                  <ErrorBoundary>
                    <Reports
                      unseenState={{
                        morningReportUnseen,
                        weekReportUnseen,
                        strategyReportUnseen,
                      }}
                      setReportAsSeen={setReportAsSeen}
                    />
                  </ErrorBoundary>
                ) : null
              }
            </TabPanel>

            <TabPanel>
              {(tab: TabState) =>
                tab.selectedId === 'weeklyPortfolio' ? (
                  <ErrorBoundary>
                    <WeeklyPortfolio />
                  </ErrorBoundary>
                ) : null
              }
            </TabPanel>
          </React.Suspense>
        </Stack>
      </Tabs>
    </CardBox>
  );
};
