import { Box } from '@oms/ui-box';
import { Button } from '@oms/ui-button';
import { Field, Fieldset } from '@oms/ui-field';
import { Select } from '@oms/ui-select';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Stack } from '@oms/ui-stack';
import { FieldArray } from 'react-final-form-arrays';
import Condition from 'components/FormComponents/Condition';
import { Heading } from '@oms/ui-heading';
import { countryOptions } from 'utils/locales';
import { required } from '../validate';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';
import styled from '@emotion/styled';

type Props = { name: string };

const Space = styled.div`
  padding: 12px 4px;
`;
const SubForm = styled.div`
  padding-left: 20px;
  padding-block: 10px;
`;

const RightHolderArray = ({ name }: Props) => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {fields.map((rrhFieldName: string, index: number) => (
            <Stack
              my={4}
              as="fieldset"
              key={rrhFieldName}
              gap={2}
              border="sm"
              p={4}
            >
              <Box>
                <label>Person nr. {index + 1}</label>
                {index !== 0 && (
                  <Button ml={4} size="xs" onClick={() => fields.remove(index)}>
                    Fjern person
                  </Button>
                )}
              </Box>

              <PdfTextInput
                name={`${rrhFieldName}.rightHolderPerson.name`}
                label="Navn"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${rrhFieldName}.rightHolderPerson.address`}
                label="Adresse"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${rrhFieldName}.rightHolderPerson.personalNumber`}
                label="Personnummer"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${rrhFieldName}.rightHolderPerson.epost`}
                label="Epost"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${rrhFieldName}.rightHolderPerson.mobileNumber`}
                label="Mobilnummer"
                isPDFRender={isPDFRender}
              />
              <PdfTextInput
                name={`${rrhFieldName}.rightHolderPerson.whyRealHolderOfRights`}
                label="Beskivelse av hvorfor personen er reell rettighetshaver (for eksempel eierandel/kontroll)"
                isPDFRender={isPDFRender}
              />
              <Space />
              <Box>
                <Fieldset
                  as={RadioGroup}
                  validate={required}
                  name={`${rrhFieldName}.rightHolderPerson.taxOutsideNorway`}
                  legend="Er vedkommende skattepliktig utenfor Norge?"
                >
                  <Field as={Radio} label="Ja" value="true" />
                  <Field as={Radio} label="Nei" value="false" />
                </Fieldset>
                <Condition
                  when={`${rrhFieldName}.rightHolderPerson.taxOutsideNorway`}
                  is="true"
                >
                  <SubForm>
                    <Heading>Land</Heading>
                    <Field
                      name={`${rrhFieldName}.rightHolderPerson.taxOutsideNorwayCountry`}
                      as={Select}
                      items={countryOptions}
                      validate={required}
                      placeholder="Velg land"
                      label="Statsborgerskap"
                    />
                    <PdfTextInput
                      name={`${rrhFieldName}.rightHolderPerson.taxOutsideNorwaySkattenummer`}
                      label="skattenummer"
                      isPDFRender={isPDFRender}
                    />
                  </SubForm>
                </Condition>
              </Box>
              <Space />
            </Stack>
          ))}
          <Button
            type="button"
            variant="tertiary"
            onClick={() => fields.push({})}
          >
            Legg til person
          </Button>
        </>
      )}
    </FieldArray>
  );
};

export default RightHolderArray;
