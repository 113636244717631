import { useState, useEffect, Suspense } from 'react';
import { useIndexSwitcher, IndexSwitcher } from 'components/IndexSwitcher';
import { TickerTable } from '@oms/components-ticker-table';
import { InstrumentChart } from '@oms/components-instrument-chart';
import { initialSelected, instrumentSet } from './utils';
import { getChartOptions } from 'utils/graph/ChartOptions';
import styled from '@xstyled/emotion';
import { IndexMap } from 'components/IndexMap';
import { useMedia } from '@oms/ui-media';
import { Box } from '@oms/ui-box';
import { Spinner } from '@oms/ui-spinner';
import { SkeletonTable } from '@oms/ui-skeleton';
import {
  useTimeSeriesSelect,
  TimeSeriesSelect,
} from 'components/TimeSeriesSelect';

const TickerTableWrapper = styled.div`
  [data-row]:focus,
  [data-row]:hover {
    background-color: #e6eff6 !important;
    color: #262626 !important;
  }
  [data-row]:active,
  [data-row][aria-checked='true'] {
    color: #262626 !important;
    background-color: #e6eff6 !important;
  }
`;

export interface MarketOverviewProps {}

export function MarketOverview(_props: MarketOverviewProps) {
  const { isMobile } = useMedia();
  const switcher = useIndexSwitcher(isMobile);
  const menuSelection = switcher.selected;
  const [market, setMarket] = useState<string>(initialSelected(menuSelection));
  const timeSeries = useTimeSeriesSelect({
    initialSelected: 'SELECTION_INTRADAY',
  });

  useEffect(() => {
    setMarket(initialSelected(menuSelection));
  }, [menuSelection]);

  return (
    <div>
      <IndexSwitcher hideMapOption={isMobile} {...switcher} />
      <Suspense
        fallback={
          <Box center minHeight="400px">
            <Spinner />
          </Box>
        }
      >
        {menuSelection === 'indexMap' && !isMobile ? (
          <IndexMap />
        ) : (
          <Box display="flex" flexDirection={{ base: 'column', md: 'row' }}>
            <>
              <Box width={{ base: '100%', md: '30%' }}>
                <Suspense
                  fallback={<SkeletonTable animated columns={3} rows={7} />}
                >
                  <TickerTableWrapper>
                    <TickerTable
                      onSelect={itemSector => setMarket(itemSector)}
                      itemSector={instrumentSet(menuSelection)}
                      initialSelected={market}
                    />
                  </TickerTableWrapper>
                </Suspense>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                pl={{ md: 4 }}
                width={{ base: '100%', md: '70%' }}
                minHeight="400px"
              >
                <Suspense fallback={null}>
                  <InstrumentChart
                    // Without this the labels wouldn't update when switching timeSeries
                    key={timeSeries.selected}
                    itemSector={market}
                    selection={timeSeries.selected}
                    seriesColors={['#03236B']}
                    options={getChartOptions()}
                    enableStreaming
                  />
                </Suspense>
                <Box ml="auto" mt="auto" px={3}>
                  <TimeSeriesSelect {...timeSeries} />
                </Box>
              </Box>
            </>
          </Box>
        )}
      </Suspense>
    </div>
  );
}
