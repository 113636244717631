import { Stack } from '@oms/ui-stack';

import KnowledgeLevelEquityCertificate from './KnowledgeLevelEquityCertificate';
import KnowledgeLevelStocks from './KnowledgeLevelStocks';
import ExchangTradedFunds from './ExchangTradedFunds';

const Knowledge = () => {
  return (
    <Stack gap={8}>
      <KnowledgeLevelStocks />
      <KnowledgeLevelEquityCertificate />
      <ExchangTradedFunds />
    </Stack>
  );
};

export default Knowledge;
