import { MostTraded as OMSMostTraded } from '@oms/components-most-traded';
import { Card, CardProps } from 'components/Card';
import { generatorSpec } from 'utils/Spec';

export interface MostTradedProps extends Omit<CardProps, 'children'> {}

export const MostTraded = (props: MostTradedProps) => (
  <Card title="Mest omsatt" {...props}>
    <OMSMostTraded showMoreButton spec={generatorSpec()} />
  </Card>
);
