import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';

export const MonthlyTransactions = () => {
  return (
    <Stack gap={4}>
      <Heading>
        Hvor mange handler forventer du å gjennomføre i løpet av en måned?
      </Heading>
      <Fieldset
        as={RadioGroup}
        name="kycMonthlyTransactions"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="0 - 1"
          value="ZERO_TO_ONE"
          validate={required}
        />
        <Field
          as={Radio}
          label="2 - 9"
          value="TWO_TO_NINE"
          validate={required}
        />
        <Field
          as={Radio}
          label="10 - 50"
          value="TEN_TO_FIFTY"
          validate={required}
        />
        <Field
          as={Radio}
          label="Over 50"
          value="OVER_FIFTY"
          validate={required}
        />
      </Fieldset>
    </Stack>
  );
};

export default MonthlyTransactions;
