import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';

export const TransactionFundsSize = () => {
  return (
    <Stack gap={4}>
      <Heading>Hvor mye midler forventes investert pr. år?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycTransactionFundsSize"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="Under 100.000 kr."
          value="UNDER_100K"
          validate={required}
        />
        <Field
          as={Radio}
          label="Mellom 100.001-500.000 kr."
          value="BETWEEN_100K_AND_500K"
          validate={required}
        />
        <Field
          as={Radio}
          label="Mellom 500.001-1.000.000 kr."
          value="BETWEEN_500K_AND_1M"
          validate={required}
        />
        <Field
          as={Radio}
          label="1-5 mill."
          value="BETWEEN_1M_AND_5M"
          validate={required}
        />
        <Field
          as={Radio}
          label="5-10 mill."
          value="BETWEEN_5M_AND_10M"
          validate={required}
        />
        <Field
          as={Radio}
          label="10 mill. kr. eller mer"
          value="10M_OR_ABOVE"
          validate={required}
        />
      </Fieldset>
    </Stack>
  );
};

export default TransactionFundsSize;
