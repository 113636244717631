import { Suspense } from 'react';
import { Heading } from '@oms/ui-heading';
import { Stack, StackProps } from '@oms/ui-stack';
import { Spinner } from '@oms/ui-spinner';
import { Box } from '@oms/ui-box';
import { CardBox, CardBoxProps } from './CardBox';

export interface CardProps extends CardBoxProps {
  title: string | JSX.Element;
  children: React.ReactNode;
  stackProps?: StackProps;
  headingElement?: JSX.Element | JSX.Element[];
}

export function Card({
  children,
  title,
  stackProps,
  headingElement,
  ...props
}: CardProps) {
  return (
    <CardBox {...props}>
      <Stack gap={4} {...stackProps}>
        <Stack orientation="horizontal" gap={0}>
          <Heading
            as="h2"
            variant="heading5"
            mt={{ base: 2, sm: 'initial' } as any}
            // same as padding-left on a table cell
            ml={{ base: '0.675rem', sm: 'initial' } as any}
          >
            {title}
          </Heading>
          {!!headingElement && <>{headingElement}</>}
        </Stack>
        <Suspense
          fallback={
            <Box minHeight="10rem" center>
              <Spinner size="lg" />
            </Box>
          }
        >
          {children}
        </Suspense>
      </Stack>
    </CardBox>
  );
}
