import styled from '@xstyled/emotion';
import { HeaderQuote } from '@oms/components-header-quote';

export const CustomHeaderQuote: typeof HeaderQuote = styled(HeaderQuote)`
  div:nth-of-type(1) {
    // Flag container
      div[width="42"]{
        align-self: center;
        border: none;
      }
    }
    div:nth-of-type(2) {
     div:nth-of-type(1) {
       align-items: inherit;
     }
  }
  div:nth-of-type(2) {
    div {
      table tbody tr {
        font-size: 19px;
      }
    }
  }

  @media only screen and (max-width: 514px) {
  div:nth-of-type(3) {
    display:none;
  }
`;
