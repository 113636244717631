import { enforce } from '../util/enforce';
import { sendIosEvent, sendAndroidEvent } from '../util/send-event';
import {
  iosAppVersionIsEqualOrAbove,
  androidAppVersionIsEqualOrAbove,
} from '../util/device-helper';

var IOS_APP_VERSION = '2.3.4';
var ANDROID_APP_VERSION = '2.3.3';

var IOS_EVENT_ID = 'openPdf';
var ANDROID_EVENT_ID = 'openPdf';

export function appSupportsOpenPdf() {
  return (
    iosAppVersionIsEqualOrAbove(IOS_APP_VERSION) ||
    androidAppVersionIsEqualOrAbove(ANDROID_APP_VERSION)
  );
}

/**
 * Open a PDF url in a pop-up view.
 * @param {string} url - The url path to the pdf file
 * @param {string} [title] - The title to be displayed at the pop-up view
 */
export function openPdf() {
  var url =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('url');
  var title = arguments[1];

  if (iosAppVersionIsEqualOrAbove(IOS_APP_VERSION)) {
    sendIosEvent({
      action: IOS_EVENT_ID,
      url: url,
      title: title,
    });
  } else if (androidAppVersionIsEqualOrAbove(ANDROID_APP_VERSION)) {
    sendAndroidEvent(ANDROID_EVENT_ID, url, title);
  }
}
