import { openPdf, appSupportsOpenPdf } from 'external/mobilbank-communication';

/* eslint-disable import/prefer-default-export */
/** Open PDFs in SB1 mobilbank app */
export function handleBlankPdfLink(event) {
  if (appSupportsOpenPdf()) {
    event.preventDefault();
    event.stopPropagation();

    openPdf(event.target.href);
  }
}
