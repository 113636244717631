import styled from '@xstyled/emotion';

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
`;

export const AppContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div``;

export const Row = styled.box`
  display: flex;
  flex: 1;
`;

export const ContentColumn = styled.box`
  flex: 1;
  display: grid;
`;

export const ContentArea = styled.div`
  display: block;
`;
