import { useQuery } from 'react-query';
import { COMPONENTS_URL } from 'constants/Jaws';

const getPeakSize = async (ITEM: string, SECTOR: string) => {
  const response = await fetch(
    `${COMPONENTS_URL}?columns=EXCHANGE_MARKET_SIZE&itemSector=${ITEM}.${SECTOR}`,
  );
  if (!response.ok) {
    return 'fetch peakSize failed';
  }
  return await response.json();
};

export const usePeakSize = (ITEM: string, SECTOR: string) => {
  return useQuery(['peakSize'], () => getPeakSize(ITEM, SECTOR), {
    suspense: true,
    enabled: !!ITEM && !!SECTOR,
  });
};
