import { ALERTS_URL } from 'constants/Jaws';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { del, FetchError, get, post, put } from 'utils/fetch';
import useUser from '../useUser';

export type ThresholdTrigger = {
  field: 'CHANGE' | 'CHANGE_PCT' | 'LAST';
  trigger: 'GT' | 'GTE' | 'LTE' | 'LT';
  value: number;
};

// TODO: Separate into AlertTypes
export type Alert = {
  item: string;
  sector: string;
  active: boolean;
  rearmWhenTriggered: boolean;
  targets: ('sms' | 'email')[];
  thresholdTrigger?: ThresholdTrigger;
  intradayTrigger?: { field: 'HIGH_5YEARS' };
  id: number;
  userId: string;
  type: 'THRESHOLD' | 'NEWS' | 'INTRADAY';
  instrumentDescription: string;
  createdTime: string;
  modifiedTime: string;
  violationTime: string;
  newsType: 'ALL' | 'INSIDER_TRADES' | 'FINANCIAL_REPORTING';
};

export const getAlerts = async (userId: string) => {
  const response = await get(ALERTS_URL + `/${userId}`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av marginsøknader feilet',
      response,
    });
  }
  const data: Alert[] = await response.json();
  const formattedData = data.map(alert => {
    return {
      ...alert,
      createdTime: alert.createdTime.split('[')[0],
      modifiedTime: alert.modifiedTime.split('[')[0],
    };
  }) as Alert[];

  formattedData.sort((a, b) => b.modifiedTime.localeCompare(a.modifiedTime));

  return formattedData;
};

type AlertPayload = any;
type CreateAlertVariables = {
  type: Alert['type'];
  payload: AlertPayload;
};
export const createAlert = async (
  userId: string,
  variables: CreateAlertVariables,
) => {
  const response = await post(
    `${ALERTS_URL}/${userId}/${variables.type}`,
    JSON.stringify(variables.payload),
  );
  if (!response.ok) {
    throw new FetchError({
      message: 'Lagring av alarm feilet',
      response,
    });
  }
  return response;
};

type UpdateAlertVariables = {
  payload: Partial<AlertPayload>;
  type: 'THRESHOLD' | 'NEWS' | 'INTRADAY';
  id: number;
};
export const updateAlert = async (
  userId: string,
  variables: UpdateAlertVariables,
) => {
  const { payload, type, id } = variables;
  const response = await put(
    `${ALERTS_URL}/${userId}/${type}/${id}`,
    JSON.stringify(payload),
  );
  if (!response.ok) {
    throw new FetchError({
      message: 'Lagring av alarm feilet',
      response,
    });
  }
  return response;
};

type DeleteAlertVariables = {
  type: 'THRESHOLD' | 'NEWS' | 'INTRADAY';
  id: number;
};
export const deleteAlert = async (
  userId: string,
  variables: DeleteAlertVariables,
) => {
  const { type, id } = variables;
  const response = await del(`${ALERTS_URL}/${userId}/${type}/${id}`);
  if (!response.ok) {
    throw new FetchError({
      message: 'Sletting av alarm feilet',
      response,
    });
  }
  return response;
};

export const useAlerts = () => {
  const {
    data: { userId = '' },
  } = useUser();
  return useQuery('alerts', () => getAlerts(userId), { enabled: !!userId });
};

export const useUpdateAlert = () => {
  const queryClient = useQueryClient();
  const {
    data: { userId = '' },
  } = useUser();
  return useMutation(
    (variables: UpdateAlertVariables) => updateAlert(userId, variables),
    { onSuccess: () => queryClient.invalidateQueries('alerts') },
  );
};

export const useCreateAlert = () => {
  const {
    data: { userId = '' },
  } = useUser();
  const queryClient = useQueryClient();

  return useMutation(
    (variables: CreateAlertVariables) => createAlert(userId, variables),
    { onSuccess: () => queryClient.invalidateQueries('alerts') },
  );
};

export const useDeleteAlert = () => {
  const {
    data: { userId = '' },
  } = useUser();
  const queryClient = useQueryClient();

  return useMutation(
    (variables: DeleteAlertVariables) => deleteAlert(userId, variables),
    { onSuccess: () => queryClient.invalidateQueries('alerts') },
  );
};
