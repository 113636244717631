import React from 'react';
import { Field } from 'react-final-form';

interface PropsForPrimitive {
  when: string;
  is: any;
  includes?: never;
}

interface PropsForArray {
  when: string;
  includes: any;
  is?: never;
}

type Props = PropsForArray | PropsForPrimitive;

const Condition: React.FC<Props> = ({ when, is, includes, children }) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => {
        if (!!includes && Array.isArray(value)) {
          return value.includes(includes) ? children : null;
        }
        return value === is ? children : null;
      }}
    </Field>
  );
};

export default Condition;
