import { Button } from '@oms/ui-button';
import { Icon } from '@oms/ui-icon';
import { useUserLogout } from 'utils/hooks/useAuth';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

export const LogoutButton = () => {
  const { mutate: logout, isLoading } = useUserLogout();

  return (
    <Button
      variant="link"
      isPending={isLoading}
      onClick={logout}
      rightElement={<Icon icon={faSignOutAlt as any} />}
    >
      Logg ut
    </Button>
  );
};

export default LogoutButton;
