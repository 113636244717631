import { Stack } from '@oms/ui-stack';
import { Text } from '@oms/ui-text';
import { TickerTable } from 'components/TickerTable';
import { useWatchlist } from 'utils/hooks/useWatchlist';
import { WatchListDialog } from 'components/WatchlistDialog';
import { Scroll } from '@oms/ui-scroll';

const MyWatchlist = () => {
  const { data: itemSectors = [] } = useWatchlist();

  if (itemSectors && !itemSectors.length)
    return (
      <Stack gap={2} px={{ base: 3, sm: 0 }}>
        <Text fontWeight="bold">Ingen instrumenter</Text>
        <Text>
          Legg til instrumenter i Min liste gjennom søket på toppen av siden,
          instrumentside eller i kurslisten.
        </Text>
      </Stack>
    );

  return (
    <Stack maxHeight="35rem">
      <WatchListDialog watchlist={itemSectors} />
      <Scroll>
        <TickerTable itemSectors={itemSectors} showTradeButton={true} />
      </Scroll>
    </Stack>
  );
};

export default MyWatchlist;
