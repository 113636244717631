import React from 'react';
import {
  ContentSwitcher,
  ContentSwitcherOption,
  useContentSwitcher,
} from '@oms/ui-content-switcher';
import { Box } from '@oms/ui-box';

type UseContentSwitcherStateReturn = ReturnType<typeof useContentSwitcher>;

export interface TradeSwitcherProps extends UseContentSwitcherStateReturn {
  selected: 'buy' | 'sell';
}

export const useTradeSwitcher = () =>
  (useContentSwitcher({
    orientation: 'horizontal',
    initialSelected: 'buy',
  }) as unknown) as TradeSwitcherProps;

export const TradeSwitcher = (props: TradeSwitcherProps) => {
  return (
    <div>
      <Box display="inline-block">
        <ContentSwitcher variant="primary" {...props}>
          <ContentSwitcherOption value="buy">Kjøp</ContentSwitcherOption>
          <ContentSwitcherOption value="sell">Selg</ContentSwitcherOption>
        </ContentSwitcher>
      </Box>
    </div>
  );
};
