import { ADMIN_CUSTOMERS_URL } from 'constants/Jaws';
import { FetchError, post } from 'utils/fetch';

export const startTakeover = (customerId: string | number) =>
  post(
    `${ADMIN_CUSTOMERS_URL}/${customerId}/support-takeover?enabled=true`,
    '',
  ).then(res => {
    if (!res.ok)
      throw new FetchError({ message: 'Takeover feilet', response: res });
    return res;
  });

export const endTakeover = (customerId: string | number) =>
  post(
    `${ADMIN_CUSTOMERS_URL}/${customerId}/support-takeover?enabled=False`,
    '',
  ).then(res => {
    if (!res.ok)
      throw new FetchError({
        message: 'Avslutting av takeover feilet',
        response: res,
      });
    return res;
  });
