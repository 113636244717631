import logo from 'assets/infront.png';
import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';
import { Link } from '@oms/ui-link';
import styled from '@xstyled/emotion';

export const Footer = () => {
  const Container = styled.div`
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.125rem;
  `;

  return (
    <Box backgroundColor="#d9e6f1" center>
      <Box
        as="footer"
        p={4}
        mx="auto"
        display="flex"
        alignItems="center"
        flexDirection={{ base: 'column-reverse', md: 'row' }}
      >
        <img src={logo} alt="Infront Finance logo" height="30%" width="30%" />
        <Container>
          Produsert og driftes av{' '}
          <Link
            as="a"
            variant="link"
            href="https://www.infrontfinance.com/"
            pb={2}
          >
            Infront Finance.
          </Link>
          <br />
          Kurser er levert av{' '}
          <Link as="a" variant="link" pb={2} href="https://www.oslobors.no">
            Oslo Børs
          </Link>{' '}
          ,{' '}
          <Link
            as="a"
            variant="link"
            pb={2}
            href="https://www.infrontfinance.com/"
          >
            Infront Finance.
          </Link>{' '}
          og{' '}
          <Link
            variant="link"
            as="a"
            pb={2}
            href="https://www.theice.com/solutions/data"
          >
            ICE Data Services
          </Link>{' '}
          <br />
          Det tas forbehold om eventuelle feil og mangler i data.
          <Text pt={2}>Nikkei 225 ©Nikkei Inc.</Text>
        </Container>
      </Box>
    </Box>
  );
};
