/** Sources for OSE, MERK, and OAX */
export const generatorSpec = () => {
  return {
    generators: [
      { source: 'feed.ose.quotes.EQUITIES' },
      { source: 'feed.oax.quotes.EQUITIES' },
      { source: 'feed.merk.quotes.EQUITIES' },
    ],
  };
};
