import {
  Table,
  Tbody,
  MemoizedTd as Td,
  Th,
  Thead,
  Tr,
  ThSortable,
} from '@oms/ui-table';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { useMemo } from 'react';
import { useJaws } from '@oms/jaws-react';
import { decimalPlaces } from '@oms/utils';
import { TradeButton } from 'components/Buttons';
import { useMedia } from '@oms/ui-media';
import { SkeletonTable } from '@oms/ui-skeleton';
import { useSortable } from 'utils/hooks/useSortable';

export type TickerTableProps = {
  itemSectors: string[] | undefined;
  showTradeButton: boolean;
};

export const TickerTable = ({
  itemSectors,
  showTradeButton,
}: TickerTableProps) => {
  const { isMobile } = useMedia();
  const { sortByKey, isReversed, handleSort } = useSortable('ITEM');

  const spec = useMemo(() => {
    if (!itemSectors || itemSectors.length === 0) return undefined;

    return {
      columns:
        'ITEM, SECTOR, ITEM_SECTOR, LONG_NAME, LAST, LASTNZ_DIV, CLOSE, CHANGE_PCT, CHANGE, ASK, BID',
      itemSector: itemSectors.join(),
    };
  }, [itemSectors]);

  const { items, jawsFetching } = useJaws(spec);

  if (jawsFetching)
    return <SkeletonTable animated columns={isMobile ? 4 : 6} rows={6} />;

  const sortedData = Object.values(
    items
      .sortBy((item: any) => {
        if (sortByKey === 'LAST') {
          return (
            item.get('LAST') || item.get('LASTNZ_DIV') || item.get('CLOSE')
          );
        }
        return item.get(sortByKey);
      })
      .toJS(),
  );

  const orderedData = isReversed ? sortedData.reverse() : sortedData;

  return (
    <Table layout="auto" stickyHeader>
      <Thead>
        <Tr>
          <ThSortable
            isSorted={sortByKey === 'ITEM'}
            isSortedDesc={isReversed}
            onClick={handleSort('ITEM')}
          >
            Ticker
          </ThSortable>
          {!isMobile && (
            <ThSortable
              type="number"
              isSorted={sortByKey === 'LAST'}
              isSortedDesc={isReversed}
              onClick={handleSort('LAST')}
            >
              Siste
            </ThSortable>
          )}
          <ThSortable
            type="percent"
            isSorted={sortByKey === 'CHANGE_PCT'}
            isSortedDesc={isReversed}
            onClick={handleSort('CHANGE_PCT')}
          >
            +/-%
          </ThSortable>
          {!isMobile && (
            <ThSortable
              type="percent"
              isSorted={sortByKey === 'CHANGE'}
              isSortedDesc={isReversed}
              onClick={handleSort('CHANGE')}
            >
              +/-
            </ThSortable>
          )}
          <ThSortable
            type="number"
            isSorted={sortByKey === 'BID'}
            isSortedDesc={isReversed}
            onClick={handleSort('BID')}
          >
            Kjøp
          </ThSortable>
          <ThSortable
            type="number"
            isSorted={sortByKey === 'ASK'}
            isSortedDesc={isReversed}
            onClick={handleSort('ASK')}
          >
            Selg
          </ThSortable>
          {showTradeButton && (
            <Th width={!isMobile && '9rem'}>
              <VisuallyHidden>Meny</VisuallyHidden>
            </Th>
          )}
        </Tr>
      </Thead>
      <Tbody>
        {items.isEmpty() && (
          <Tr>
            <Td colSpan={isMobile ? 5 : 7}>Ingen instrumenter</Td>
          </Tr>
        )}
        {orderedData.map((values: any) => {
          const {
            ITEM,
            SECTOR,
            ITEM_SECTOR,
            LONG_NAME,
            CHANGE_PCT,
            CHANGE,
            LASTNZ_DIV,
            CLOSE,
            LAST = LASTNZ_DIV || CLOSE || 0,
            ASK,
            BID,
          } = values;
          return (
            <Tr key={ITEM_SECTOR}>
              <Td
                type="symbol"
                linkTo={
                  SECTOR !== 'UNKNOWN' ? `/instrument/${ITEM_SECTOR}` : null
                }
                ticker={ITEM}
                name={LONG_NAME}
              />
              {!isMobile && (
                <Td type="number" fractionDigits={decimalPlaces(LAST)} flash>
                  {LAST}
                </Td>
              )}
              <Td textAlign="right" type="percent" trend flash>
                {CHANGE_PCT}
              </Td>
              {!isMobile && (
                <Td textAlign="right" type="number" trend flash>
                  {CHANGE}
                </Td>
              )}
              <Td textAlign="right" type="number" flash>
                {BID}
              </Td>
              <Td textAlign="right" type="number" flash>
                {ASK}
              </Td>
              {showTradeButton && (
                <Td width={!isMobile && '25%'}>
                  <TradeButton itemSector={ITEM_SECTOR} isMobile={isMobile} />
                </Td>
              )}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
