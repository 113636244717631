import React from 'react';
import styled, { Global, css } from '@xstyled/emotion';
import {
  useAppShellObserver,
  RightRegion,
  Id,
  AppLayoutProps,
  ASIDE_WIDTH,
} from '@oms/ui-app-shell';
import * as S from './styles';
import { isIOSWebview } from 'utils/webview';

const Blur = styled.div`
  position: fixed;
  top: 0;
  z-index: 999;
  height: env(safe-area-inset-top);
  display: block;
  backdrop-filter: blur(4px);
  background-color: rgba(216, 233, 242, 0.3);
  width: 100%;
`;

export interface AppShellProps extends AppLayoutProps {}

/**
 * `Layout approximation`
 *
 * | col1              | col2        | col3           |
 * | -------------    | ------------- | ------------- |
 * | [--------------- | statusBar     | ---------------] |
 * | [--------------- | navigationBar | ---------------] |
 * | [- leftRegion -] | contentArea   | [- rightRegion -]   |
 * | [- leftRegion -] | [- footer -]  | [- rightRegion -]   |
 * */
export function AppShell({
  skipLinks = <div />,
  status = <div />,
  navigationBar = <header />,
  footer = <footer />,
  children,
}: AppShellProps) {
  const { appRef, topRef } = useAppShellObserver();

  return (
    <>
      <Global
        /**
         * There's a bit of css trickery involved to make this work, so bear with me:
         * =========================================================================
         * DOM Structure:
         *  [LeftAside]
         *    [content]
         *    [span data-apply-width]
         *  [ContentColumn]
         *  [RightAside
         *    [content]
         *    [span data-apply-width]
         * ]
         *  When the sheet/aside content is *NOT* hidden we set the width of it's sibling element (span[data-apply-width]).
         *  The width of this sentinel element is animated and helps set the dimensions of
         *  the Aside nodes (#${Id.rightAside}, #${Id.leftAside}), the dimensions of ContentArea are affected by the dimensions of the Aside nodes .
         *
         *  Below the min-width of 52.5em we don't set the width of the sibling element, thus Aside nodes have a width of 0.
         *  Now the sheet/content will simply overflow it's parent container (#${Id.rightAside}) and render (partially) on top of ContentArea.
         *
         *  On the smallest of screen sizes the aside content should *NOT* render inside of the Aside nodes; It should render layered on top of ContentArea when used as a Sheet or in the flow of
         *  content inside of ContentArea.
         */
        styles={css`
          #${Id.rightAside}, #${Id.leftAside} {
            position: relative;

            span[data-apply-width] {
              height: 0px;
              display: block;
              width: 0;
              will-change: width;
              transition: width 200ms ease-in-out;
            }

            @media only screen and (min-width: 52.5em) {
              section:not([hidden]) ~ span[data-apply-width],
              div:not([hidden]) ~ span[data-apply-width],
              aside:not([hidden]) ~ span[data-apply-width] {
                width: ${ASIDE_WIDTH};
              }
            }
          }
        `}
      />
      {isIOSWebview() ? <Blur /> : null}
      {skipLinks}
      <S.Wrapper>
        <S.AppContainer id={Id.app} ref={appRef}>
          <div
            // we don't need an offset for the sheet components,
            // but sheets do rely on a measurement, hence we measure something with zero height.
            id={Id.top}
            ref={topRef}
          />
          <S.Top>
            {status && React.cloneElement(status, { id: Id.status })}
            {navigationBar &&
              React.cloneElement(navigationBar, {
                id: Id.header,
              })}
          </S.Top>
          <S.ContentColumn id={Id.row}>
            <S.ContentArea id={Id.contentArea}>{children}</S.ContentArea>
            {footer && React.cloneElement(footer, { id: Id.footer })}
          </S.ContentColumn>
        </S.AppContainer>
        <RightRegion.Target />
      </S.Wrapper>
    </>
  );
}
