import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import useUser from '../useUser';
import {
  delExpediencyTest,
  getExpediencyFlag,
  corpoAccount,
  getExpediencyTest,
  getPersonalInfo,
  getSigningStatus,
  postExpediencyTest,
  postSigning,
} from './helpers';
import { Account } from 'types/auth';

export const useGetExpediencyTest = (
  accountId?: string,
  corpoAccount?: Boolean,
  queryOptions?: UseQueryOptions,
) => {
  const {
    data: { userId = '' },
  } = useUser();

  return useQuery(
    ['expediency-test', userId, accountId, corpoAccount],
    () => getExpediencyTest(userId, accountId, corpoAccount),
    queryOptions,
  );
};

export const usePostExpediencyTest = (
  account: Account,
  isCorpoAccount: boolean,
) => {
  const queryClient = useQueryClient();
  return useMutation(postExpediencyTest, {
    onSuccess: () => {
      queryClient.invalidateQueries('expediency-test');
    },
  });
};

export const selAccountData = (accountId?: string, accounts?: Account[]) => {
  let result = null;
  if (accounts && accountId) {
    result = accounts.find(item => item.id === accountId);
  }
  return result;
};

export const useCheckExpediencyTest = (
  userId: string,
  selectedAccount?: Account,
  accounts?: Account[],
  queryOptions?: UseQueryOptions,
) => {
  const isCorpoAccount = corpoAccount(selectedAccount?.id, accounts);
  const query = useQuery(
    ['expediency-flag', userId, isCorpoAccount, selectedAccount?.id],
    () => getExpediencyFlag(userId, isCorpoAccount, selectedAccount?.id),
    { cacheTime: 0, staleTime: 1000, ...queryOptions },
  );
  return {
    ...query,
    isCorpoAccount,
  };
};

export const useDelExpediencyTest = () => {
  const queryClient = useQueryClient();
  return useMutation(delExpediencyTest, {
    onSuccess: () => {
      queryClient.invalidateQueries('expediency-test');
    },
  });
};

export const usePostSigning = () => {
  const queryClient = useQueryClient();
  return useMutation(postSigning, {
    onSuccess: () => {
      queryClient.invalidateQueries('signing-status');
    },
  });
};

export const useSigningStatus = (corpoAccount: boolean) => {
  const {
    data: { userId = '' },
  } = useUser();

  return useQuery('signing-status', () =>
    getSigningStatus(userId, corpoAccount),
  );
};

export const useGetPersonalInfo = (account: Account, corpoAccount: boolean) => {
  const {
    data: { userId = '' },
  } = useUser();

  return useQuery(
    ['expediency-test', 'personal-info', userId, account, corpoAccount],
    () => getPersonalInfo(userId, account?.id, corpoAccount),
    { suspense: true },
  );
};
