import { PRODUCTS_URL } from 'constants/Jaws';
import { del, get, post } from './fetch';

const isProd = window.location.host.includes('aksjehandel.sparebank1.no');

// SBEN have different IDs for prod and test
const productMap = {
  [isProd ? 2 : 3]: {
    name: 'REAL_TIME_LEVEL1',
    label: 'Streaming uten ordredybde',
    price: 30,
  },
  [isProd ? 3 : 4]: {
    name: 'REAL_TIME_LEVEL2',
    label: 'Streaming med ordredybde',
    price: 135,
  },
  [isProd ? 4 : 5]: {
    name: 'REAL_TIME_LEVEL2_PROFESSIONAL',
    label: 'Streaming med ordredybde (Profesjonell)',
    price: 640,
  },
};

export function getProducts(customerId, accountId) {
  return get(
    `${PRODUCTS_URL}/${customerId}/account/${accountId}`,
  ).then(response => response.json());
}

export function orderProduct(productId, customerId, accountId) {
  return post(
    `${PRODUCTS_URL}/${customerId}/account/${accountId}/${productId}`,
  );
}

export function removeProduct(productId, customerId, accountId) {
  return del(`${PRODUCTS_URL}/${customerId}/account/${accountId}/${productId}`);
}

export function mapIdToProduct(productId) {
  return productMap[productId] || {};
}

// transaction status mapping
export const SENT_TO_RESERVATION = 'N';
export const RESERVATION_DECLINED = 'J';
export const PAYMENT_DECLINED = 'R';
