import { Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@oms/ui-table';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { SkeletonTable } from '@oms/ui-skeleton';
import { useMedia } from '@oms/ui-media';

import useUser from 'utils/hooks/useUser';
import { useSettledOrders } from 'utils/hooks/useOrders';
import { mapOrderStatus, mapOrderType } from 'utils/orders';
import { decimalPlaces } from '@oms/utils';
import { useMemo } from 'react';
import { useJaws } from '@oms/jaws-react';

export const SettledOrdersTable = () => {
  const {
    data: { userId = '' },
  } = useUser();
  const { isMobile } = useMedia();

  const { selectedAccountId } = useSelectedAccountId();
  const { data: settledOrders = [], isLoading } = useSettledOrders({
    userId,
    accountId: selectedAccountId,
  });

  const spec = useMemo(() => {
    if (!settledOrders.length) return undefined;
    return {
      itemSector: settledOrders
        .map(order => `${order.TICKER_CODE}.${order.STOCK_EXCHANGE_ID}`)
        .join(),
      columns: 'ITEM_SECTOR, LAST, LAST_NZ',
    };
  }, [settledOrders]);
  const { items } = useJaws(spec);

  if (isLoading)
    return <SkeletonTable animated columns={isMobile ? 5 : 7} rows={6} />;

  return (
    <Table layout="auto" stickyHeader>
      <Thead>
        <Tr>
          <Th>Type</Th>
          <Th type="symbol">Ticker</Th>
          {!isMobile && <Th>Status</Th>}
          <Th type="dateTime">Oppdatert</Th>
          <Th type="integer">Antall</Th>
          <Th type="number">Pris/Limit</Th>
          {!isMobile && <Th type="number">Siste</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {!settledOrders.length && (
          <Tr>
            <Td colSpan={isMobile ? 5 : 7}>Fant fullførte ordre</Td>
          </Tr>
        )}
        {settledOrders.map(order => {
          const {
            TOTAL_VOLUME,
            TICKER_CODE,
            MEAN_RATE,
            STOCK_EXCHANGE_ID,
            LAST_MODIFIED_DATE,
            ORDER_STATUS,
            LIMIT,
            TYPE,
          } = order;
          const itemSector = `${TICKER_CODE}.${STOCK_EXCHANGE_ID}`;
          const jawsItem = items?.find(
            item => item.get('ITEM_SECTOR') === itemSector,
          );
          const last = jawsItem?.get('LAST') || jawsItem?.get('LAST_NZ') || 0;
          const price = MEAN_RATE || LIMIT || 0;
          return (
            <Tr>
              <Td>{mapOrderType(TYPE)}</Td>
              <Td
                type="symbol"
                linkTo={`/instrument/${itemSector}`}
                ticker={TICKER_CODE}
                name={STOCK_EXCHANGE_ID}
              />
              {!isMobile && (
                <Td data-header="Status" className="status">
                  {mapOrderStatus(ORDER_STATUS)}
                </Td>
              )}
              <Td type="dateTime">{LAST_MODIFIED_DATE}</Td>
              <Td type="integer">{TOTAL_VOLUME}</Td>

              <Td type="number" fractionDigits={decimalPlaces(price)}>
                {price}
              </Td>
              {!isMobile && (
                <Td type="number" fractionDigits={decimalPlaces(last)}>
                  {last}
                </Td>
              )}
            </Tr>
          );
        })}
      </Tbody>
      <Tfoot>
        <Tr>
          <Td
            p={4}
            colSpan={isMobile ? 5 : 7}
            linkTo="/transactions"
            textAlign="center"
          >
            Gå til mine transaksjoner
          </Td>
        </Tr>
      </Tfoot>
    </Table>
  );
};

export default SettledOrdersTable;
