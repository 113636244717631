import { ACCOUNTS_URL } from 'constants/Jaws';
import { useQuery } from 'react-query';
import { FetchError, get } from 'utils/fetch';
import { useAccount } from './useAccounts';
import useUser from './useUser';

export type MarginInfo = {
  equity: number;
  collateral: number;
  capital: number;
  exposure: number;
  marketValue: number;
  marginValue: number;
  creditLimit: number;
  creditLimitExtra: number;
  exposureLimit: number;
  ledgerBalance: number;
  bankBalance: number;
  accruedInterest: number;
  otcFwdMarketClaim: number;
  otcFwdExposure: number;
  otcFwdPLUnreal: number;
  shortClaimValue: number;
  externalMargin: number;
  shortExposure: number;
  shortMarketValue: number;
  currencyCode: string;
  loanToValue: number;
  sumLoan: number;
  buyingPowerMin: number;
  buyingPowerMax: number;
  maxMargin: number;
};

const getMarginInfo = async (accountId: string, userId: string) => {
  if (!accountId || !userId)
    throw new Error('Mangler konto eller kundeid ved henting av margin-info');

  const response = await get(
    `${ACCOUNTS_URL}/${userId}/account/${accountId}/margin-info`,
  );
  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av margin-info feilet',
      response,
    });
  }
  const { data } = await response.json();
  return data as MarginInfo;
};

export const useMarginInfo = ({ accountId }: { accountId: string }) => {
  const {
    data: { account },
  } = useAccount(accountId);
  const {
    data: { userId = '' },
  } = useUser();
  return useQuery(
    ['margin-info', accountId, userId],
    () => getMarginInfo(accountId, userId),
    { enabled: account?.isMarginAccount },
  );
};

export default useMarginInfo;
