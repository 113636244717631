import React, { Suspense } from 'react';

import { useMemo } from 'react';
import { ItemSectorProvider } from 'context/ItemSectorContext';
import { Box } from '@oms/ui-box';
import { normalizeNumber } from 'utils/form';
import { StockOrder } from 'utils/hooks/useActiveOrders';
import moment from 'moment';
import { Sell } from '../Sell';
import { Buy } from '../Buy';
import { Spinner } from '@oms/ui-spinner';

const getOrderTradeType = (order: StockOrder) => {
  if (order.TYPE === 0) return 'BUY';
  if (order.TYPE === 1) return 'SELL';

  throw new Error(`Ukjent ordretype: ${order.TYPE}`);
};

type Props = {
  accountId: string;
  order: StockOrder;
  onClose: () => void;
};
export const Edit = ({ accountId, order, onClose }: Props) => {
  const tradeType = getOrderTradeType(order);

  const initialValues = useMemo(() => {
    const { TICKER_CODE: ITEM, STOCK_EXCHANGE_ID: SECTOR } = order;

    return {
      tradeType,
      useOpenVolume: !!order.HIDDEN_VOLUME,
      useStopLoss: order.STOP_LOSS_LIMIT > 0,
      stopLossLimit: order.STOP_LOSS_LIMIT,
      account: accountId,
      useTriggerCriterion: !!order.STOP_LOSS_TRIGGER_CRITERION,
      expirationDate: moment(order.EXPIRATION_DATE).format('YYYY-MM-DD'),
      ticker: ITEM,
      exchange: SECTOR,
      volume: normalizeNumber(order.TOTAL_VOLUME),
      openVolume:
        order.HIDDEN_VOLUME !== 0
          ? normalizeNumber(order.TOTAL_VOLUME - order.HIDDEN_VOLUME)
          : undefined,
      limit: normalizeNumber(order.LIMIT),
    };
  }, [accountId, order, tradeType]);

  const itemSector = `${initialValues.ticker}.${initialValues.exchange}`;

  return (
    <Suspense
      fallback={
        <Box minHeight="10rem" center>
          <Spinner size="lg" />
        </Box>
      }
    >
      <ItemSectorProvider itemSector={itemSector}>
        {tradeType === 'BUY' && (
          <Buy
            initialValues={initialValues}
            orderId={order.ORDER_ID}
            onSubmitCompleted={onClose}
            includeInstrumentOverview={true}
          />
        )}
        {tradeType === 'SELL' && (
          <Sell
            initialValues={initialValues}
            orderId={order.ORDER_ID}
            onSubmitCompleted={onClose}
            includeInstrumentOverview={true}
          />
        )}
      </ItemSectorProvider>
    </Suspense>
  );
};

export default React.memo(Edit);
