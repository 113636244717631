import { useJaws, Spec, Options } from '@oms/jaws-react';

const defaultSpec = {
  initiatorComponent: 'HistoricalReturn',
  columns:
    'CHANGE_1MONTH_PCT, CHANGE_3MONTHS_PCT, CHANGE_YEAR_PCT, CHANGE_1YEAR_PCT, CHANGE_3YEARS_PCT, CHANGE_5YEARS_PCT',
};

export interface UseHistoricalReturnArguments {
  spec?: Spec;
  itemSector: string;
  options?: Options;
}

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `HistoricalReturn` component.
 */
export const useHistoricalReturn = ({
  spec,
  itemSector,
  options,
}: UseHistoricalReturnArguments) => {
  const { items, ...values } = useJaws(
    { itemSector, ...defaultSpec, ...spec },
    options,
  );

  return {
    ...values,
    data: items.first()?.toJS(),
  };
};
