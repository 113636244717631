import { Box } from '@oms/ui-box';
import { Link } from '@oms/ui-link';
import { Button } from '@oms/ui-button';
import { Icon, light } from '@oms/ui-icon';
import { Menu, MenuItem } from '@oms/ui-menu';
import styled from '@xstyled/emotion';
import { useHistory } from 'react-router-dom';
import useUser from 'utils/hooks/useUser';
import useWatchlist, {
  useAddToWatchlist,
  useRemoveFromWatchlist,
} from 'utils/hooks/useWatchlist';

const defaultProps = {
  variant: 'sell',
  size: 'xs',
};

const LeftButton = styled(Link)`
  border-right: none;
  border-top-right-radius: none;
  border-bottom-right-radius: none;
  height: 1.75rem;
`;
LeftButton.defaultProps = defaultProps;

const RightButton = styled(Button)`
  border-top-left-radius: none;
  border-bottom-left-radius: none;
  padding: 0 0.5rem 0 0.25rem;
  /** isPending inserts a span with loading icon, this removes the margin from that */
  span {
    margin-right: 0;
  }
  height: 1.75rem;
`;
RightButton.defaultProps = defaultProps;

export type Props = {
  itemSector: string;
  /** If true, hides LeftButton */
  isMobile?: boolean;
};
export const TradeButton = ({ itemSector, isMobile }: Props) => {
  const history = useHistory();
  const {
    data: { userId = '' },
  } = useUser();
  const { data: watchList } = useWatchlist();

  const {
    mutate: addToWatchlist,
    isLoading: addIsLoading,
  } = useAddToWatchlist();

  const {
    mutate: removeFromWatchlist,
    isLoading: removeIsLoading,
  } = useRemoveFromWatchlist();

  const isInWatchlist = watchList?.includes(itemSector);
  const rightButtonIsPending = addIsLoading || removeIsLoading;

  return (
    <Box display="inline-flex" alignItems="center">
      {!isMobile && (
        <LeftButton flex={1} to={`/instrument/${itemSector}`}>
          Handle
        </LeftButton>
      )}
      <Menu
        disclosure={
          <RightButton
            aria-label="Meny"
            isPending={rightButtonIsPending}
            leftElement={<Icon icon={light.faCaretDown} />}
          />
        }
      >
        {isMobile && (
          <MenuItem onSelect={() => history.push(`/instrument/${itemSector}`)}>
            Handle
          </MenuItem>
        )}
        <MenuItem
          onSelect={() => history.push(`/alerts?itemSector=${itemSector}`)}
        >
          Sett alarm
        </MenuItem>
        <MenuItem
          onSelect={() => history.push(`/news?itemSector=${itemSector}`)}
        >
          Nyheter
        </MenuItem>
        <MenuItem
          onSelect={() =>
            !isInWatchlist
              ? addToWatchlist({ itemSector, userId })
              : removeFromWatchlist({ itemSector, userId })
          }
        >
          {isInWatchlist ? 'Fjern fra min liste' : 'Legg til i min liste'}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TradeButton;
