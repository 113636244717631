import { Badge } from '@oms/ui-badge';
import {
  ContentSwitcher,
  ContentSwitcherOption,
  useContentSwitcher,
} from '@oms/ui-content-switcher';
import { ReportType } from './useReports';
import { Box } from '@oms/ui-box';

type UseContentSwitcherStateReturn = ReturnType<typeof useContentSwitcher>;

export interface ReportsSwitcherProps extends UseContentSwitcherStateReturn {
  selected: ReportType;
}

export const useReportsSwitcher = () =>
  (useContentSwitcher({
    orientation: 'horizontal',
    initialSelected: 'MORNINGREPORT',
  }) as unknown) as ReportsSwitcherProps;

type State = any;
export type UnseenReportsState = {
  unseenState: {
    morningReportUnseen: boolean;
    weekReportUnseen: boolean;
    strategyReportUnseen: boolean;
  };
  setReportAsSeen: (state: 'MORNINGREPORT' | 'WEEKREPORT' | 'STRATEGY') => void;
};
const NotificationBadge = () => (
  <Badge ml={2} variant="notification">
    1
  </Badge>
);
export const ReportsSwitcher = ({
  setReportAsSeen,
  unseenState,
  ...props
}: ReportsSwitcherProps & UnseenReportsState) => {
  const handleChange = (state: State) => {
    props.onChange(state);
    if (state) {
      setReportAsSeen(state);
    }
  };

  return (
    <Box overflow="hidden">
      <Box px={{ base: 3, md: 0 }} pb={3} overflow="auto">
        <ContentSwitcher variant="primary" {...props} onChange={handleChange}>
          <ContentSwitcherOption value="MORNINGREPORT">
            Morgenrapport{' '}
            {unseenState.morningReportUnseen && <NotificationBadge />}
          </ContentSwitcherOption>
          <ContentSwitcherOption value="WEEKREPORT">
            Månedsportefølje{' '}
            {unseenState.weekReportUnseen && <NotificationBadge />}
          </ContentSwitcherOption>
          <ContentSwitcherOption value="STRATEGY">
            Strategi {unseenState.strategyReportUnseen && <NotificationBadge />}
          </ContentSwitcherOption>
        </ContentSwitcher>
      </Box>
    </Box>
  );
};
