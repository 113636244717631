import React from 'react';
import { Dividends as OMSDividends } from '@oms/components-dividends';
import { SkeletonTable } from '@oms/ui-skeleton';
import { Card, CardProps } from 'components/Card';
import { useItemSector } from 'context/ItemSectorContext';

export interface DividendsProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const Dividends = ({ itemSector, ...props }: DividendsProps) => {
  const itemSectorValue = useItemSector(itemSector);
  return (
    <Card title="Utbytter" {...props}>
      <React.Suspense
        fallback={<SkeletonTable rows={10} columns={2} animated />}
      >
        <OMSDividends
          itemSector={itemSectorValue}
          // TODO: This should be in COMP
          spec={{ initiatorComponent: 'Dividends' }}
        />
      </React.Suspense>
    </Card>
  );
};
