import { WEBTV_URL } from 'constants/Jaws';
import { ONE_MINUTE } from 'constants/timeInMs';
import { useQuery } from 'react-query';
import { FetchError } from 'utils/fetch';

export type WebTVLink = {
  id: number;
  title: string;
  updated: string;
  url: string;
  itemSector: string;
};

/**
 * Hook for customer-side. Admin-side is found in admin-folder.
 */
export const useWebTVLinks = () =>
  useQuery(
    'WebTV-links',
    async () => {
      const response = await fetch(WEBTV_URL);
      if (!response.ok)
        throw new FetchError({
          message: 'Henting av WebTV-linker feilet',
          response,
        });
      const { data } = (await response.json()) as { data: WebTVLink[] };

      data.sort((a, b) => b.updated.localeCompare(a.updated));

      return data;
    },
    { staleTime: ONE_MINUTE },
  );

export const useWebTVLinkOnInstrument = (itemSector: string | undefined) => {
  const { data: webTVLinks } = useWebTVLinks();
  if (!itemSector) return undefined;
  const result = webTVLinks?.find(link => link.itemSector === itemSector);
  return result;
};

export const useWebTVLinkByID = (id: number) =>
  useQuery(
    ['WebTV-links', id],
    async () => {
      const response = await fetch(`${WEBTV_URL}/${id}`);
      if (!response.ok)
        throw new FetchError({
          message: `Henting av WebTV-link med id: ${id} feilet`,
          response,
        });
      const { data } = await response.json();
      return data as WebTVLink;
    },
    { staleTime: ONE_MINUTE },
  );
