import { Button } from '@oms/ui-button';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { Box } from '@oms/ui-box';
import moment from 'moment';
import { WebTVLink } from 'utils/hooks/useWebTV';
import { Text } from '@oms/ui-text';
import { useMedia } from '@oms/ui-media';
import { Link } from '@oms/ui-link';
import { Icon } from '@oms/ui-icon';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
interface Props {
  webTvLink: WebTVLink;
  isActive: boolean;
  toggleActive: () => void;
}

const WebTVCard = ({ isActive, toggleActive, webTvLink }: Props) => {
  const { isMobile } = useMedia();

  return (
    <Box py={4} px={3} borderRadius="lg" border="none" boxShadow="menu">
      <Stack gap={4} px={1}>
        <Stack
          orientation="horizontal"
          justifyContent="space-between"
          alignment="center"
        >
          <Heading as="h3" variant="heading6" aria-label="Oppdatert">
            {moment(webTvLink.updated).format('lll')}
          </Heading>
          {isMobile ? (
            <Link
              size="xs"
              as="a"
              href={webTvLink.url}
              rightElement={<Icon icon={faExternalLinkAlt as any} />}
            >
              Åpne
            </Link>
          ) : (
            <Button size="xs" onClick={toggleActive}>
              {isActive ? 'Lukk' : 'Spill av'}
            </Button>
          )}
        </Stack>
        <Text>{webTvLink.title}</Text>
      </Stack>
    </Box>
  );
};

export default WebTVCard;
