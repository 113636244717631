import React from 'react';
import { Field } from 'react-final-form';

interface Props {
  name: string;
  label: string;
  show: any;
}

const WrappedText: React.FC<Props> = ({ name, label, show }) => {
  return (
    <Field name={name} label={label} subscription={{ value: true }}>
      {({ input: { value } }) => {
        return show === true ? (
          <>
            <div
              style={{
                fontSize: '14px',
                marginTop: '10px',
              }}
            >
              {label}
            </div>
            <div
              style={{
                fontSize: '14px',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                border: '1px solid rgb(211, 211, 211)',
                marginTop: '10px',
                padding: '10px',
              }}
            >
              {value}
            </div>
          </>
        ) : null;
      }}
    </Field>
  );
};

export default WrappedText;
