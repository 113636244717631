import styled, { css } from '@xstyled/emotion';
import { breakpoints } from '@xstyled/system';
import { BoxProps } from '@oms/ui-box';

const Box = styled.box`
  border: sm;
  border-color: border;
  overflow: hidden;
  background-color: #eef4f9;

  ${breakpoints({
    base: css`
      border-top-left-radius: none;
      border-top-right-radius: none;
    `,
    sm: css`
      border-top-left-radius: lg;
      border-top-right-radius: lg;
      margin-top: calc(-1rem - 1px);
      margin-right: calc(-0.75rem - 1px);
      margin-left: calc(-0.75rem - 1px);
    `,
  })}
`;

export interface CardTabHeaderProps extends BoxProps {}

export function CardTabHeader({ children, ...props }: CardTabHeaderProps) {
  return <Box {...props}>{children}</Box>;
}
