import styled from '@xstyled/emotion';

const Center = styled.div`
  height: 60vh;
  width: 100%;
  display: grid;
  place-items: center center;
`;

const Spinner = styled.img`
  height: 50px;
  width: 50px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
/** TODO: This is more of a temp "playing around" loading-state */
const LoadingPage = () => (
  <Center>
    <Container>
      <img id="sbenLogo" src="/logo.svg" alt="Sparebank1 Logo" />
      <Spinner id="loadingGif" src="/loading.gif" alt="Spinner" />
    </Container>
  </Center>
);

export default LoadingPage;
