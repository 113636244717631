import { createContext, useContext, useMemo, useState } from 'react';

export type WebTVContextType = {
  webTVUrl: string | undefined;
  setWebTVUrl: (newUrl: string | undefined) => void;
};

const context = createContext<WebTVContextType | null>(null);
const { Provider } = context;

export const useWebTVContext = () => {
  const ctx = useContext(context);
  if (ctx === null)
    throw new Error('useWebTVContext must be used within WebTVProvider');
  return ctx;
};

export const WebTVProvider: React.FC = ({ children }) => {
  const [webTVUrl, setWebTVUrl] = useState<WebTVContextType['webTVUrl']>();

  const value = useMemo(() => ({ webTVUrl, setWebTVUrl }), [webTVUrl]);

  return <Provider value={value}>{children}</Provider>;
};

context.displayName = 'WebTV';
