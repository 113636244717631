import { Redirect, Route } from 'react-router-dom';
import { App } from 'components/App';
import { RouteProps } from './types';
import LoadingPage from 'components/TEMP/LoadingPage';
import { gdprTermsUpdated } from 'utils/terms';
import moment from 'moment';
import useUser from 'utils/hooks/useUser';
import { useCheckExpediencyTest } from 'utils/hooks/useExpediencyTest';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { Account } from 'types/auth';

export const PrivateRoute = ({
  component: Component,
  path,
  exact,
  noWrapper,
}: RouteProps) => {
  const userData = useUser();
  const { data, isError, isLoading } = userData;
  const {
    data: { selectedAccount },
  } = useSelectedAccount();

  let { data: expediencyTestFlag, isFetched } = useCheckExpediencyTest(
    data?.userId as string,
    selectedAccount as Account,
    data.accounts as Account[],
  );

  const {
    data: { userId = '', accounts },
  } = userData;
  const selAccount = selectedAccount as Account;
  let { isCorpoAccount } = useCheckExpediencyTest(
    userId as string,
    selAccount,
    accounts,
  );

  if (userData?.data?.customerData?.mustTakeExpediencyTest) {
    expediencyTestFlag = true;
  }

  if (isError) {
    return <Redirect to="/login?message=GENERIC_ERROR" />;
  }

  if (!data?.me?.authenticated && !isLoading) {
    return <Redirect to="/login" />;
  }

  if (!data?.me || isLoading || expediencyTestFlag === undefined || !isFetched)
    return <LoadingPage />;

  if (expediencyTestFlag && !isCorpoAccount) {
    return <Redirect to="/expediency-test/page/0" />;
  }

  if (expediencyTestFlag && isCorpoAccount) {
    return <Redirect to="/expediency-test-corpo/page/0" />;
  }

  const principal = data?.me?.principals[0];
  const gdprTermsAccepted = principal
    ? principal.terms?.[0]?.type === 'GDPR'
    : undefined; // FIXME: I added some quick undefined-checks to fix login, should probably fix this further

  const acceptedTimestamp = moment(
    principal?.terms?.[0]?.acceptedTimestamp,
  ).format('YYYY-MM-DDTHH:mmZ');

  const principalTypeCustomer = data?.me?.principals.some(
    (principal: unknown) => (principal as any)?.principalType === 'customer',
  );

  const gdprTermsUpdatedSinceAccept = moment(gdprTermsUpdated).isAfter(
    acceptedTimestamp,
  );
  const showGDPR = gdprTermsAccepted === false || gdprTermsUpdatedSinceAccept;

  if (data.isAdmin && !data.isTakeover) {
    return <Redirect to="/admin" />;
  }

  if (principalTypeCustomer && showGDPR) {
    return <Redirect to="/gdpr" />;
  }

  return (
    <Route
      path={path}
      exact={exact}
      render={props =>
        noWrapper ? (
          <Component {...props} />
        ) : (
          <App {...props}>
            <Component {...props} />
          </App>
        )
      }
    />
  );
};
