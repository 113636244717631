import { Box } from '@oms/ui-box';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { MarginApply as MarginApplyComponent } from 'components/MarginApply/MarginApply';

// TODO: Probably do with something else than Box, <Page /> has styling but might be ok
export default function MarginApply() {
  return (
    <Box maxWidth="70rem" width="100%" mx="auto" mt={12}>
      <ErrorBoundary>
        <MarginApplyComponent />
      </ErrorBoundary>
    </Box>
  );
}

export { MarginApply };
