import React from 'react';

export default function renderText(name, type) {
  switch (type) {
    case 'ExpediencyTest':
      return (
        <div>
          <p>
            På bakgrunn av de opplysningene vi har om ditt kunnskaps- og
            erfaringsnivå med {name}, vurderer vi dette finansielle instrumentet
            å ikke være hensiktsmessig for deg. Det er viktig at du som kunde
            har kunnskap og erfaring til å forstå hvilken risiko du påtar deg
            ved handel i {name}. Vi vil derfor fraråde deg å handle dette
            finansielle instrumentet.
          </p>
          <p>
            Dersom du likevel ønsker det, kan du gjennomføre handel via vår
            netthandelsløsning. Vi oppfordrer deg til å lese vårt{' '}
            <a
              href="https://sb1markets.no/vilkar-og-disclaimer"
              target="blank"
              rel="noopener noreferrer"
            >
              «Informasjonsnotat egenskaper og risiko knyttet til finansielle
              instrumenter».
            </a>
          </p>
        </div>
      );
    case 'QuickTradeForm':
    case 'BuyForm':
    case 'SellForm':
      return (
        <div>
          <p>
            På bakgrunn av de opplysningene vi har om ditt kunnskaps- og
            erfaringsnivå med {name}, vurderer vi dette finansielle instrumentet
            å ikke være hensiktsmessig for deg. Det er viktig at du som kunde
            har kunnskap og erfaring til å forstå hvilken risiko du påtar deg
            ved handel i {name}. Vi vil derfor fraråde deg å handle dette
            finansielle instrumentet.
          </p>
          <p>
            Dersom du likevel ønsker det, kan du gjennomføre handel via vår
            netthandelsløsning.
          </p>
        </div>
      );
    case 'CompetencyAssessmentTest':
      return (
        <div>
          <p>
            På bakgrunn av de opplysningene vi har om ditt kunnskaps- og
            erfaringsnivå med {name}, vurderer vi dette finansielle instrumentet
            å ikke være hensiktsmessig for deg. Vi vil derfor fraråde deg å
            handle {name}.
          </p>
          <p>
            Dersom ditt kunnskaps og erfaringsnivå endrer seg kan du gå inn på
            Mine Sider og oppdatere dette ved et senere tidspunkt.
          </p>
        </div>
      );
    default:
      throw new Error(`Unrecognized type: ${type}`);
  }
}

/**
 * Compliance in SB1 would like proof that the user has seen the text,
 * so this method extracts the actual text that was shown to the user from
 * the react children property
 */
export function buildString(element) {
  const elements = React.Children.map(element.props.children, child =>
    typeof child === 'string' ? child : buildString(child),
  );

  return elements.join(' ');
}
