import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';
import { Page } from 'components/Page';
import { Grid, GridContainer } from 'components/Grid';
import { Scroll } from '@oms/ui-scroll';
import styled from '@xstyled/emotion';
import {
  TransactionsFilters,
  TransactionsTable,
} from '@oms/components-transactions';
import { Heading } from '@oms/ui-heading';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useUser from 'utils/hooks/useUser';
import camelCase from 'camelcase';
import { formatBankAccountNo } from '@oms/utils';
import {
  useQueryParams,
  DateParam,
  withDefault,
  StringParam,
} from 'use-query-params';
import moment from 'moment';
import { Suspense } from 'react';
import { SkeletonTable } from '@oms/ui-skeleton';
import { ErrorBoundary } from '@oms/ui-error-boundary';

export interface TransactionsProps {}

const FiltersContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem auto 4rem;
  padding: 0 1rem;
  max-width: 70rem;

  div[class$='-Wrap'] {
    flex: 1 0 18rem;
  }
`;

const TickerParam = {
  encode: (suggestion: any) => suggestion?.ITEM,
  decode: (item: any) => item,
};

// TODO: Make a little more readable?
/** Converts UPPER_CASE keys to camelCase, while also adjusting some values to fit COMP */
export const transactionNormalizer = (transaction: any) =>
  Object.fromEntries(
    Object.entries(transaction).map(([key, value]) => {
      if (key === 'TYPE') return ['tradeType', value === 0 ? 'BUY' : 'SELL'];
      if (key === 'BANK_ACCOUNT') {
        return ['bankAccount', formatBankAccountNo(value as string)];
      }
      return [camelCase(key), value];
    }),
  );

const responseNormalizer = (data: any) => {
  const result = data?.data?.map((transaction: any) =>
    transactionNormalizer(transaction),
  );
  return result || [];
};

export default function Transactions(_props: TransactionsProps) {
  const {
    data: { userId = '' },
  } = useUser();
  const { selectedAccountId } = useSelectedAccountId();

  const [filters, setFilters] = useQueryParams({
    fromDate: withDefault(
      DateParam,
      moment()
        .subtract(1, 'month')
        .toDate(),
    ),
    toDate: withDefault(DateParam, new Date()),
    ticker: TickerParam,
    tradeType: StringParam,
    transactionId: StringParam,
    orderId: StringParam,
  });

  return (
    <Page
      documentTitle="Transaksjoner"
      topElement={
        <FiltersContainer>
          <Heading as="h1" variant="heading2" textAlign="center" mb={8}>
            Transaksjoner
          </Heading>
          <TransactionsFilters
            onFiltersChange={setFilters}
            dateFormat="dd.MM.yyyy"
            //@ts-ignore
            initialFilters={filters}
            enableSearchButton
          />
        </FiltersContainer>
      }
    >
      <GridContainer>
        <Grid>
          <Scroll orientation="horizontal" showFade={false}>
            <Box
              p={4}
              width="100%"
              minWidth="52rem"
              maxWidth="70rem"
              minHeight="15rem"
              mx="auto"
            >
              <Suspense
                fallback={<SkeletonTable animated columns={8} rows={4} />}
              >
                {selectedAccountId ? (
                  <ErrorBoundary>
                    <TransactionsTable
                      //@ts-ignore
                      filters={filters}
                      userId={userId}
                      accountId={selectedAccountId}
                      type="stock"
                      responseNormalizer={responseNormalizer}
                      transactionLink="/order/{orderId}/transaction/{transactionId}"
                      orderLink="/order/{orderId}"
                      suspense
                    />
                  </ErrorBoundary>
                ) : (
                  <Text>Ingen konto valgt</Text>
                )}
              </Suspense>
            </Box>
          </Scroll>
        </Grid>
      </GridContainer>
    </Page>
  );
}

export { Transactions };
