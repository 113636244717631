/* Start polyfills */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ExceptionCatcher } from 'components/ExceptionCatcher';
import { setup } from 'utils/sentry';
import ensureIntlSetup from 'utils/ensureIntlSetup';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Main from './main';
import LoadingPage from 'components/TEMP/LoadingPage';
import { ONE_SECOND } from 'constants/timeInMs';

if (process.env.NODE_ENV === 'production') {
  // Bootstrap raven-js
  // eslint-disable-next-line global-require

  setup({
    prodUrl: 'aksjehandel.sparebank1.no$',
    prodDSN:
      'https://428f62a3f24b490384e3658cd883bedd@sentry.asp.manamind.com/12',
    devDSN:
      'https://f38bb4ebc09d45909d1f718a74c01b2c@sentry.asp.manamind.com/11',
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: ONE_SECOND * 3,
      staleTime: ONE_SECOND * 30,
    },
  },
});

const elem = document.getElementById('root');
const render = () => {
  ReactDOM.render(
    <Router>
      <ExceptionCatcher>
        <Suspense fallback={LoadingPage}>
          <QueryClientProvider client={queryClient}>
            <Main />
          </QueryClientProvider>
        </Suspense>
      </ExceptionCatcher>
    </Router>,
    elem,
  );
};

ensureIntlSetup().then(render);

// Boots service worker. In case of emergency, change register to unregister
// below and re-deploy. This will kill the service workers. Do NOT simply remove
// this line or delete the public/service-worker.js file from the server,
// explicitly unregistring is required, anything else is a noop.
// Learn more about service workers: https://bit.ly/CRA-PWA
//
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  // Override default behavior of waiting for all tabs to close before
  // refreshing the cached files. The below callback will force a reload when
  // a new service worker is detected.
  //
  // TODO Probably better to ask user first, example "A new version is
  // available, reload now?"
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
