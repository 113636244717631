import { ORDERS_URL } from 'constants/Jaws';
import { getDetails } from 'utils/errorMessage';
import { del } from 'utils/fetch';

type MutationVariables = {
  userId: string;
  orderId: string;
  accountId: string;
};
export const deleteActiveOrder = async ({
  userId,
  orderId,
  accountId,
}: MutationVariables) => {
  const response = await del(
    `${ORDERS_URL}/${userId}/orders/${orderId}?accountId=${accountId}`,
  );
  if (!response.ok) {
    const errorDetail = getDetails(await response.text())?.[0];
    throw new Error(errorDetail);
  }
  return response;
};
