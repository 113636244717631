import { useEffect } from 'react';
import { Box } from '@oms/ui-box';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Page } from 'components/Page';
import { NewsArchive } from '@oms/components-news-archive';
import { Heading } from '@oms/ui-heading';
import { CardBox } from 'components/Card';
import { Button } from '@oms/ui-button';
import { Icon, light } from '@oms/ui-icon';

type PossibleParams = {
  referenceId?: string;
  item?: string;
};

export default function News() {
  const history = useHistory();
  const { referenceId, item } = useParams<PossibleParams>();

  let initialFilters;
  if (referenceId) {
    initialFilters = {
      referenceId: decodeURIComponent(referenceId),
      start: moment()
        .subtract(10, 'year')
        .toDate(),
    };
  } else if (item) {
    initialFilters = {
      ticker: item,
      start: moment()
        .subtract(1, 'month')
        .toDate(),
    };
  }

  useEffect(() => {
    if (item) {
      const element = document.getElementById('NewsFilters-company');
      if (element) {
        element.parentElement?.replaceWith(`${item}`);
      }
    }
  }, [item]);

  return (
    <Page
      documentTitle="Børsmeldinger"
      topElement={
        <Box center>
          <Heading as="h1" variant="heading2">
            Børsmeldinger
          </Heading>
        </Box>
      }
    >
      <CardBox maxWidth="70rem" mx="auto" mt={12}>
        {initialFilters && (
          <Button
            variant="link"
            size="sm"
            onClick={() => history.push('/news')}
            leftElement={<Icon icon={light.faArrowLeft} />}
            mb={4}
          >
            Tilbake til alle nyheter
          </Button>
        )}
        <NewsArchive
          key={referenceId || item}
          initialFilterValues={initialFilters}
          filterProps={{ clearFilterText: '' }}
        />
      </CardBox>
    </Page>
  );
}

export { News };
