import { ORDERS_URL } from 'constants/Jaws';
import moment from 'moment';
import { useQuery } from 'react-query';
import { get } from 'utils/fetch';

type Arguments = {
  userId: string;
  accountId: string;
  orderId: string;
};

type OrderDetails = {
  CREATED_DATE: number;
  STOP_LOSS_LIMIT: number;
  TOTAL_VOLUME: number;
  ORDER_TYPE: number;
  LAST_MODIFIED_DATE: number;
  LIMIT: number;
  MEAN_RATE: number;
  ORDER_SPECIFICATION: number;
  INSTRUMENT_ID: string;
  TICKER_CODE: string;
  HIDDEN_VOLUME: number;
  INSTRUMENT_NAME: string;
  EXPIRATION_DATE: number;
  ORDER_ID: string;
  ORDER_SOURCE: string;
  STOCK_EXCHANGE_ID: string;
  STOP_LOSS_TRIGGER_CRITERION?: any;
  REMAINING_VOLUME: number;
  ORDER_STATUS: number;
  TYPE: number;
};

export const useOrderDetails = ({ userId, accountId, orderId }: Arguments) => {
  return useQuery(['orders', orderId], () =>
    get(`${ORDERS_URL}/${userId}/orders/${orderId}?accountId=${accountId}`)
      .then(res => res.json())
      .then(result => result.data as OrderDetails),
  );
};

type UseSettledOrdersArgs = {
  accountId: string;
  userId: string;
};
export const useSettledOrders = ({
  accountId,
  userId,
}: UseSettledOrdersArgs) => {
  const query = {
    accountId,
    fromDate: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    status: 'EXECUTED',
  };

  return useQuery(['settledOrders', accountId], () =>
    get({
      url: `${ORDERS_URL}/${userId}/orders/search{?query*}`,
      query,
    })
      .then(res => res.json())
      .then(res => {
        const orders = res.data as OrderDetails[];
        orders?.sort(
          (a, b) =>
            new Date(b.LAST_MODIFIED_DATE).getTime() -
            new Date(a.LAST_MODIFIED_DATE).getTime(),
        );
        return orders;
      }),
  );
};
