import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import {
  required,
  posNumber,
  lessThanMaxYear,
  composeValidators,
  noDecimals,
  amountMax,
} from '../validate';
import { Stack } from '@oms/ui-stack';
import { Heading } from '@oms/ui-heading';
import Condition from 'components/FormComponents/Condition';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

export const KnowledgeLevelStocks = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <Stack gap={1}>
      <Fieldset
        as={RadioGroup}
        validate={required}
        name="profession.relevantExperience"
        legend="Arbeider/har du arbeidet innen finansiell sektor i minst ett år i en
        stilling som forutsetter kjennskap til de påtenkte finansielle
        plasseringene?"
      >
        <Field as={Radio} label="Ja" value="true" />
        <Field as={Radio} label="Nei" value="false" />
      </Fieldset>
      <Condition when="profession.relevantExperience" is="true">
        <PdfTextInput
          name="profession.relevantExperienceDescription"
          label="Beskrivelse"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <br />
      <Heading>Aksjer</Heading>
      <Fieldset
        as={RadioGroup}
        name="knowledge.stocks.knowledgeLevel"
        legend="Kunnskapsnivå"
      >
        <Field as={Radio} validate={required} label="Høy" value="HIGH" />
        <Field as={Radio} validate={required} label=" Middels" value="MEDIUM" />
        <Field as={Radio} validate={required} label=" Lav" value="LOW" />
      </Fieldset>
      <Field
        as={NumberInput}
        lang="nb"
        validate={composeValidators(noDecimals, required, posNumber, amountMax)}
        name="knowledge.stocks.numberOfTrades"
        label="Antall handler siste år"
        inputMode="numeric"
      />
      <Field
        as={NumberInput}
        lang="nb"
        name="knowledge.stocks.yearsExperience"
        label="Antall år(s) erfaring"
        inputMode="numeric"
        validate={composeValidators(
          noDecimals,
          required,
          lessThanMaxYear,
          posNumber,
        )}
      />
    </Stack>
  );
};

export default KnowledgeLevelStocks;
