import { Table, Tbody, Th, Td, Thead, Tr, ThSortable } from '@oms/ui-table';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useHoldings from 'utils/hooks/useHoldings';
import HoldingsRow from './HoldingsRow';
import { HoldingsFooter } from './HoldingsFooter';
import { useMedia } from '@oms/ui-media';
import { useSortable } from 'utils/hooks/useSortable';
import { getSorted } from './helpers';
import {
  useTableDisclosureHeading,
  TableDisclosureHeading,
} from 'components/TableDisclosure';

export type Props = {
  accountId?: string;
};

export const AccountHoldings = ({ accountId: propAccountId }: Props) => {
  const { isMobile } = useMedia();
  const { selectedAccountId: contextAccountId } = useSelectedAccountId();
  const { handleSort, isReversed, sortByKey } = useSortable('LONG_NAME');
  const accountId = propAccountId || contextAccountId;
  const { data } = useHoldings({ accountId });
  const headers = useTableDisclosureHeading();
  const noData = data?.rows.length === 0;

  const sortData = getSorted([...(data?.rows || [])], sortByKey, isReversed);

  return (
    <Table layout="auto" stickyHeader>
      <Thead>
        <Tr>
          <ThSortable
            isSorted={sortByKey === 'ITEM'}
            isSortedDesc={isReversed}
            onClick={handleSort('ITEM')}
            type="text"
          >
            Symbol
          </ThSortable>
          {!isMobile && (
            <ThSortable
              isSorted={sortByKey === 'CHANGE_PCT'}
              isSortedDesc={isReversed}
              onClick={handleSort('CHANGE_PCT')}
              type="percent"
            >
              +/-%
            </ThSortable>
          )}
          {!isMobile && (
            <ThSortable
              isSorted={sortByKey === 'LAST'}
              isSortedDesc={isReversed}
              onClick={handleSort('LAST')}
              type="percent"
              textAlign="right"
            >
              Kurs
            </ThSortable>
          )}

          <ThSortable
            isSorted={sortByKey === 'MARKET_VALUE'}
            isSortedDesc={isReversed}
            onClick={handleSort('MARKET_VALUE')}
            type="percent"
            textAlign="right"
          >
            Markedsverdi
          </ThSortable>

          <ThSortable
            isSorted={sortByKey === 'UNREALIZED_PROFIT_PCT'}
            isSortedDesc={isReversed}
            onClick={handleSort('UNREALIZED_PROFIT_PCT')}
            type="percent"
            textAlign="right"
          >
            <abbr title="Urealisert gevinst eller tap i prosent">
              Ureal. gev./tap (%)
            </abbr>
          </ThSortable>
          {!isMobile ? <Th textAlign="right">Antall</Th> : null}
          <TableDisclosureHeading {...headers}>Vis mer</TableDisclosureHeading>
        </Tr>
      </Thead>
      <Tbody>
        {isMobile && noData ? (
          <Td colSpan={isMobile && 4}>Fant ingen beholdning</Td>
        ) : (
          sortData?.map((row, index) => {
            return (
              <HoldingsRow
                row={row}
                key={index}
                isMobile={isMobile}
                {...headers}
              />
            );
          })
        )}
      </Tbody>
      <HoldingsFooter accountId={accountId} isMobile={isMobile} />
    </Table>
  );
};

export default AccountHoldings;
