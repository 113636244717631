import { useHeaderQuote } from '@oms/components-header-quote';
import { Box } from '@oms/ui-box';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { Flag } from '@oms/ui-flag';
import { Text } from '@oms/ui-text';
import { formatNumber, trendClass } from '@oms/utils';
import { Button } from '@oms/ui-button';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@xstyled/emotion';
import SparklineTresholdGraph from 'components/Charts/SparklineThresholdGraph';
import { WatchlistButton } from 'components/Buttons';
import { useJaws } from '@oms/jaws-react';

const getTextColor = (value: number) => {
  const trend = trendClass(value);
  if (trend.pos) return 'pos';
  if (trend.neg) return 'neg';
  return 'text';
};

type Item = {
  ITEM_SECTOR: string;
  LONG_NAME: string;
  ITEM: string;
  LAST: number;
  COUNTRY: string;
  CHANGE: number;
  CHANGE_PCT: number;
};
type Props = {
  item: Item;
  hide: () => void;
};
const Preview = ({ item, hide }: Props) => {
  const { colors } = useTheme();
  const { items } = useJaws({
    itemSector: item.ITEM_SECTOR,
    columns: 'CHANGE_PCT,CHANGE,LAST',
  });
  const fetchedItem = items.first()?.toJS();

  const history = useHistory();
  const { data } = useHeaderQuote({ itemSector: item?.ITEM_SECTOR });

  return (
    <Stack p={4} bg="surface-1" flex={1} borderTop="sm" borderTopColor="border">
      <Box display="flex" justifyContent="space-between">
        <Stack gap={2} maxWidth="14rem">
          <Heading variant="heading5" color="text-label">
            {item.LONG_NAME}
          </Heading>
          <Stack orientation="horizontal" gap={2}>
            <Flag countryCode={item.COUNTRY} />
            <Text color="text-message">{item.ITEM}</Text>
          </Stack>
          <Stack orientation="horizontal" alignment="end" gap={2}>
            <Text fontSize="xl">{formatNumber(fetchedItem?.LAST)}</Text>
            <Text color={getTextColor(fetchedItem?.CHANGE_PCT)}>
              {formatNumber(fetchedItem?.CHANGE)}
            </Text>
            <Text color={getTextColor(fetchedItem?.CHANGE_PCT)}>
              {formatNumber(fetchedItem?.CHANGE_PCT)}%
            </Text>
          </Stack>
        </Stack>
        <Stack>
          <WatchlistButton itemSector={item.ITEM_SECTOR} />
          <Box center>
            <SparklineTresholdGraph
              height={40}
              width={150}
              itemSector={item.ITEM_SECTOR}
              options={{
                color: colors['primary'],
                negColor: colors['text-neg'],
                posColor: colors['text-pos'],
                negFillColor: colors['neg'],
                posFillColor: colors['pos'],
                strokeWidth: 1,
              }}
            />
          </Box>
        </Stack>
      </Box>
      <Stack orientation="horizontal" gap={2}>
        <Button
          variant="secondary"
          onClick={() => {
            history.push(`/instrument/${item.ITEM_SECTOR}`);
            hide();
          }}
        >
          Gå til
        </Button>
        {/* <Button
          variant="secondary"
          onClick={() => {
            navigate(sheetId.trade, { itemSector: item.ITEM_SECTOR });
            hide();
          }}
        >
          Handle
        </Button> */}
      </Stack>
      <Text color="text-message">{data?.DESCRIPTION_NO}</Text>
    </Stack>
  );
};

export default Preview;
