import { useState } from 'react';
import { useSuggest } from '@oms/components-suggest';
import { Stack } from '@oms/ui-stack';
import { MarginInfo } from './MarginInfo';
import { Text } from '@oms/ui-text';
import { RenderField } from '@oms/ui-field';

type Props = {
  showTradeButton?: boolean;
};
export const MarginCalculator = ({ showTradeButton }: Props) => {
  const [itemSector, setItemSector] = useState<string>();
  const Suggest = useSuggest();

  return (
    <Stack>
      <Stack orientation="horizontal" alignment="end">
        <RenderField
          as={Suggest}
          placeholder={itemSector}
          name="itemSector"
          label="Ticker / Selskap"
          onChange={(value: any) =>
            setItemSector(`${value.ITEM}.${value.SECTOR}`)
          }
        />
      </Stack>
      <Text>
        Beregn hvor mye du kan handle for og finn margingrad for enkeltaksjer
      </Text>
      {itemSector ? (
        <MarginInfo itemSector={itemSector} showTradeButton={showTradeButton} />
      ) : null}
    </Stack>
  );
};
