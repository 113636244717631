import { useUserPreferences } from 'context/UserPreferencesContext';
import useUser from 'utils/hooks/useUser';
import LogoutButton from 'components/Buttons/LogoutButton';
import { PersonIkon } from '@sb1/ffe-icons-react';
import { RenderField } from '@oms/ui-field';
import { Switch } from '@oms/ui-switch';
import { Popover, PopoverActions } from '@oms/ui-popover';
import { Stack } from '@oms/ui-stack';
import { IconButton } from 'components/Buttons';
import EmailAlerts from './EmailAlerts';

export interface UserProps {}

export function User(_props: UserProps) {
  const { preferences, updatePreference } = useUserPreferences();
  const {
    data: { hasStreaming },
  } = useUser();

  return (
    <>
      <Popover
        disclosure={
          <IconButton aria-label="Bruker">
            <PersonIkon height={24} />
          </IconButton>
        }
      >
        <Stack minWidth="18rem" gap={2} mb="1.5rem">
          {hasStreaming ? (
            <RenderField
              name="streaming"
              label="Streaming"
              as={Switch}
              stateLabel={{
                on: 'på',
                off: 'av',
              }}
              checked={preferences.streaming}
              onChange={(event: React.FormEvent<HTMLInputElement>) =>
                updatePreference('streaming', event.currentTarget.checked)
              }
            />
          ) : null}
          <RenderField
            name="alerts"
            label="Ordrevarsler"
            as={Switch}
            stateLabel={{
              on: 'på',
              off: 'av',
            }}
            checked={!preferences.notifications?.disable}
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              updatePreference('notifications', {
                disable: !event.currentTarget.checked,
              })
            }
          />
        </Stack>
        <EmailAlerts />
        <PopoverActions>
          <LogoutButton />
        </PopoverActions>
      </Popover>
    </>
  );
}
