import { MarginInfo } from 'utils/hooks/useMarginInfo';
import { Caption, Table, Tbody, Td, Tfoot, Th, Tr } from '@oms/ui-table';
import { Box } from '@oms/ui-box';
import { Wrap } from '@oms/ui-wrap';
import { trendClass } from '@oms/utils';

import { useResizeObserver } from '@oms/ui-element-query';
interface Props {
  marginInfo: MarginInfo;
}

const MarginDetails = ({ marginInfo }: Props) => {
  const [ref, observedEntry] = useResizeObserver();
  const width = observedEntry?.contentRect?.width || 720;
  const columnLayout = width < 720;

  const {
    ledgerBalance = 0,
    accruedInterest = 0,
    creditLimitExtra = 0,
    marketValue = 0,
    marginValue = 0,
    collateral = 0,
    creditLimit = 0,
    capital = 0,
  } = marginInfo;

  // TODO: marketValue streaming
  const totalEquity =
    marketValue + marginInfo.ledgerBalance + marginInfo.accruedInterest;
  return (
    <Wrap gap={4} ref={ref} flexDirection={columnLayout ? 'column' : 'row'}>
      <Box
        minWidth="14rem"
        flex={1}
        boxShadow="menu"
        py={4}
        px={columnLayout ? 4 : 1}
      >
        <Table variant="keyValue">
          <Caption color="secondary" fontSize="lg" pb={1}>
            Egenkapital
          </Caption>
          <Tbody>
            <Tr>
              <Th>Markedsverdi</Th>
              <Td type="number">{marketValue}</Td>
            </Tr>
            <Tr>
              <Th>Reskontrosaldo</Th>
              <Td type="number">{ledgerBalance}</Td>
            </Tr>
            <Tr>
              <Th>Påløpt rente</Th>
              <Td type="number">{accruedInterest}</Td>
            </Tr>
            <EmptyRow />
          </Tbody>
          <Tfoot>
            <FooterRow value={totalEquity} />
          </Tfoot>
        </Table>
      </Box>
      <Box
        minWidth="14rem"
        flex={1}
        boxShadow="menu"
        py={4}
        px={columnLayout ? 4 : 1}
      >
        <Table variant="keyValue">
          <Caption color="secondary" fontSize="lg" pb={1}>
            Sikkerhet
          </Caption>
          <Tbody>
            <Tr>
              <Th>Belåningsverdi</Th>
              <Td type="number">{marginValue}</Td>
            </Tr>
            <Tr>
              <Th>Reskontrosaldo</Th>
              <Td type="number">{ledgerBalance}</Td>
            </Tr>
            <Tr>
              <Th>Påløpt rente</Th>
              <Td type="number">{accruedInterest}</Td>
            </Tr>
            <EmptyRow />
          </Tbody>
          <Tfoot>
            <FooterRow value={collateral} />
          </Tfoot>
        </Table>
      </Box>
      <Box
        minWidth="14rem"
        flex={1}
        boxShadow="menu"
        py={4}
        px={columnLayout ? 4 : 1}
      >
        <Table variant="keyValue">
          <Caption color="secondary" fontSize="lg" pb={1}>
            Kapital
          </Caption>
          <Tbody>
            <Tr>
              <Th>Kredittramme</Th>
              <Td type="number">{creditLimit}</Td>
            </Tr>
            <Tr>
              <Th>Reskontrosaldo</Th>
              <Td type="number">{ledgerBalance}</Td>
            </Tr>
            <Tr>
              <Th>Påløpt rente</Th>
              <Td type="number">{accruedInterest}</Td>
            </Tr>
            <Tr>
              <Th>Tilleggskreditt</Th>
              <Td type="number">{creditLimitExtra}</Td>
            </Tr>
          </Tbody>
          <Tfoot>
            <FooterRow value={capital} />
          </Tfoot>
        </Table>
      </Box>
    </Wrap>
  );
};

const EmptyRow = () => (
  <Tr aria-label="Tom rad">
    <Th color="transparent" children="-" />
    <Td color="transparent" children="-" />
  </Tr>
);
const FooterRow = ({ value }: { value: number }) => {
  return (
    <Tr
      borderBottom="0.25rem solid"
      borderColor={trendClass(value).neg ? 'neg' : 'pos'}
    >
      <Td
        pb={4}
        colSpan={2}
        fontSize="lg"
        type="number"
        textAlign="center"
        trend
      >
        {value}
      </Td>
    </Tr>
  );
};

export default MarginDetails;
