import React from 'react';
import { Clickable } from '@oms/ui-clickable';
import {
  useDisclosure,
  DisclosureButton,
  DisclosureContent,
} from '@oms/ui-disclosure';
import { IconButton } from '@oms/ui-icon-button';
import { light } from '@oms/ui-icon';
import { useId } from '@oms/ui-utils';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { Th, ThProps } from '@oms/ui-table';
import { Box } from '@oms/ui-box';

/** Returns the disclosure state of a single row.
 * Should be instantiated for each set of row and expandable row.  */
export const useTableDisclosure = useDisclosure;

/** Returns the header id shared by the th element and all expandable rows.
 * Should only be instantiated once at the top of your table component.   */
export const useTableDisclosureHeading = () => {
  const id = useId();
  return { headers: id };
};
export type UseTableDisclosureHeadingReturn = ReturnType<
  typeof useTableDisclosureHeading
>;
export type DisclosureStateReturn = ReturnType<typeof useDisclosure>;

export interface TableDisclosureHeadingProps
  extends UseTableDisclosureHeadingReturn,
    Omit<ThProps, 'headers'> {
  hidden?: boolean;
}
/** Th element for the column containing the expand button */
export function TableDisclosureHeading({
  children,
  hidden = true,
  headers,
  ...thProps
}: TableDisclosureHeadingProps) {
  return (
    <Th id={headers} {...thProps}>
      {hidden ? <VisuallyHidden>{children}</VisuallyHidden> : children}
    </Th>
  );
}

export interface TableDisclosureButtonProps extends DisclosureStateReturn {}
/** Td element containing the expand button */
export function TableDisclosureButton(disclosure: TableDisclosureButtonProps) {
  return (
    <Clickable as="td" onClick={disclosure.toggle} tabIndex={-1}>
      <DisclosureButton
        as={IconButton}
        size="sm"
        icon={disclosure.visible ? light.faAngleUp : light.faAngleDown}
        aria-label={disclosure.visible ? 'Veksle: Lukk' : 'Veksle: Vis mer'}
        tooltip={disclosure.visible ? 'Lukk' : 'Vis mer'}
        {...disclosure}
        toggle={() => void {}}
      />
    </Clickable>
  );
}

function AutoScrollTr({
  children,
  visible,
}: React.PropsWithChildren<{ visible: boolean }>) {
  const ref = React.useRef<HTMLTableRowElement>(null);
  React.useLayoutEffect(() => {
    let timeout: NodeJS.Timeout;
    const row = ref.current!;
    if (visible) {
      timeout = setTimeout(() => {
        const scrollContainer = row.closest('[data-fade-start]');

        scrollContainer?.scroll({
          top: row.offsetTop - 84,
          behavior: 'smooth',
        });
      }, 200);
    }

    return () => clearTimeout(timeout);
  }, [visible]);
  return (
    <tr ref={ref} data-is-expanded={visible}>
      {children}
    </tr>
  );
}

export interface TableDisclosureContentProps
  extends DisclosureStateReturn,
    UseTableDisclosureHeadingReturn {
  children: React.ReactNode;
  colSpan: number;
}
/** Disclosure row, wraps a single td and the disclosure content element */
export function TableDisclosureContent({
  children,
  colSpan,
  headers,
  ...disclosure
}: TableDisclosureContentProps) {
  return disclosure.visible ? (
    <AutoScrollTr visible={disclosure.visible}>
      <td headers={headers} colSpan={colSpan}>
        <DisclosureContent {...(disclosure as any)}>
          <Box display="flex" justifyContent="flex-start" mb={4}>
            <DisclosureButton
              as={IconButton}
              size="md"
              icon={light.faTimes}
              aria-label="Lukk"
              {...disclosure}
            />
          </Box>
          {children}
        </DisclosureContent>
      </td>
    </AutoScrollTr>
  ) : null;
}
