import { Suspense, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from '@oms/ui-tabs';
import { Stack } from '@oms/ui-stack';
import { CardBox, CardTabHeader, CardBoxProps } from 'components/Card';
import { Box } from '@oms/ui-box';
import { Spinner } from '@oms/ui-spinner';
import MyInstruments from './MyInstruments';
import MyWatchlist from './MyWatchlist';
import MyOrders from './MyOrders';
import ActiveOrdersBadge from 'components/Badges/ActiveOrdersBadge';
import { Scroll } from '@oms/ui-scroll';
import { AccountHoldings } from 'components/AccountHoldings';
import React from 'react';
import { ErrorBoundary } from '@oms/ui-error-boundary';
export interface MarketInstrumentTablesProps
  extends Omit<CardBoxProps, 'children'> {
  /** The itemSector to be used in fetching data */
}

/** Ids for available tabs in MarketInstrumentTables */
export const tabIds = [
  'myHoldings',
  'myInstruments',
  'myWatchlist',
  'myOrders',
];

const getTabIdFromHash = (hash: string) => {
  const parsedId = hash.replace('#', '');
  if (tabIds.includes(parsedId)) return parsedId;
  return undefined;
};

export const MarketInstrumentTables = (props: MarketInstrumentTablesProps) => {
  // Handle navigating to a specific tab, i.e hash "#myWatchlist" initializes watchlist-tab
  // Setting key={tabId} on Tabs makes sure this tab-swap works even after mount (Tabs is not controllable)
  const history = useHistory();
  const { hash } = useLocation<string>();
  const [tabId, setTabId] = useState(getTabIdFromHash(hash) || 'myHoldings');

  useEffect(() => {
    if (hash) {
      const newTabId = getTabIdFromHash(hash);
      if (newTabId) setTabId(newTabId);
    }
  }, [hash]);

  // Clean the url so that this navigation can happen again even without navigating away from the page
  const removeLocationHash = (e: React.MouseEvent<HTMLButtonElement>) => {
    const clickedId = e.currentTarget.id;
    if (!!tabId && tabId !== clickedId) {
      history.push({ hash: undefined });
      setTabId(clickedId);
    }
  };

  return (
    <CardBox id="marketInstrumentTable" {...props}>
      <Tabs selectedId={tabId} key={tabId}>
        <Stack gap={4}>
          <CardTabHeader overflow="auto">
            <TabList aria-label="tabs">
              <Tab id="myHoldings" onClick={removeLocationHash}>
                Min beholdning
              </Tab>
              <Tab id="myInstruments" onClick={removeLocationHash}>
                Mine instrumenter
              </Tab>
              <Tab id="myWatchlist" onClick={removeLocationHash}>
                Min liste
              </Tab>
              <Tab id="myOrders" onClick={removeLocationHash}>
                Mine ordre{' '}
                <ErrorBoundary fallbackRenderer={() => <React.Fragment />}>
                  <ActiveOrdersBadge />
                </ErrorBoundary>
              </Tab>
            </TabList>
          </CardTabHeader>
          <Suspense
            fallback={
              <Box center height="100%">
                <Spinner size="lg" />
              </Box>
            }
          >
            <TabPanel>
              <Scroll maxHeight="35rem">
                <ErrorBoundary>
                  <AccountHoldings />
                </ErrorBoundary>
              </Scroll>
            </TabPanel>
            <TabPanel>
              <Scroll maxHeight="35rem">
                <ErrorBoundary>
                  <MyInstruments />
                </ErrorBoundary>
              </Scroll>
            </TabPanel>
            <TabPanel>
              <ErrorBoundary>
                <MyWatchlist />
              </ErrorBoundary>
            </TabPanel>
            <TabPanel>
              <Scroll maxHeight="35rem">
                <ErrorBoundary>
                  <MyOrders />
                </ErrorBoundary>
              </Scroll>
            </TabPanel>
          </Suspense>
        </Stack>
      </Tabs>
    </CardBox>
  );
};
