import { Sniplet } from 'components/Sniplet';

import { SIGNICAT_URL } from 'constants/Jaws';

/* CC0 http://finda.photo/image/17006 */
import background from 'images/cityscape.jpg';
import appThumb from 'images/appThumb.png';

import { useQuery } from 'react-query';
import { get } from 'utils/fetch';
import { useLocation } from 'react-router-dom';
import { Stack } from '@oms/ui-stack';
import styled from '@xstyled/emotion';
import { Box } from '@oms/ui-box';
import { Text } from '@oms/ui-text';
import { Notification } from '@oms/ui-notification';
import { Link } from '@oms/ui-link';
import { Skeleton } from '@oms/ui-skeleton';

const Background = styled.img`
  width: 100%;
`;
const Logo = styled.img`
  width: 200px;
`;
const AppThumb = styled.img`
  height: 100%;
`;

export const LoginPage = () => {
  const { search } = useLocation();
  const { data: urls, isError, error } = useQuery('signicat', () =>
    get(SIGNICAT_URL).then(res => res.json()),
  );

  return (
    <Stack width="100%" height="100%" pb={12}>
      <Box maxHeight="400px" overflow="hidden" height="calc(100% - 210px)">
        <Logo src="/logo.svg" alt="Sparebank1 Logo" />
        <Background
          src={background}
          alt="Skyskraper med himmel og skyer som bakgrunn"
        />
      </Box>

      <Stack
        background="white"
        borderRadius="md"
        boxShadow="2xl"
        margin="auto"
        marginTop="-60px !important"
        padding="2rem"
        maxWidth="30rem"
        width="90%"
      >
        <Stack orientation="horizontal" height="70px" alignment="center">
          <AppThumb src={appThumb} alt="Mobil med teksten KR" />
          <Stack gap={0}>
            <Text variant="heading4">Aksjehandel</Text>
            <Text variant="heading5" fontWeight="normal">
              Velg login
            </Text>
          </Stack>
        </Stack>

        {!!search && (
          <Notification title="Kunne ikke logge inn" status="error">
            <Text>
              Første gang du logger inn må aksjetjenesten bestilles i nettbanken
              via en PC. Du bestiller den under sparing {'->'} aksjer {'->'}{' '}
              bestill aksjehandel. Deretter kan du logge inn via mobil og
              nettbrett.
            </Text>

            <Text marginTop={2}>
              Hvis du har benyttet denne tjenesten tidligere, kan årsaken være
              at din VPS konto er sperret. Hvis du har fått beskjed om dette fra
              din bank, ta kontakt med aktuelle bank/kontofører.
            </Text>

            <Text marginTop={2}>
              Send mail til netthandel@sb1markets.no for mer informasjon.
            </Text>
          </Notification>
        )}

        <Stack gap={2}>
          {!urls ? (
            <Skeleton height="4rem" animated />
          ) : (
            <>
              <Link
                as="a"
                href={urls['nbidHigh']}
                rel={undefined}
                target={undefined}
              >
                BankID
              </Link>
            </>
          )}
        </Stack>

        {isError && (
          <Notification
            status="error"
            title="Kunne ikke hente innloggings-lenker"
          >
            Det oppstod et problem ved henting av lenker. Prøv igjen senere.
            <details>
              <summary>Detaljer:</summary>
              <p>{`${error}`}</p>
            </details>
          </Notification>
        )}
        <Sniplet title="Driftsmelding" name="maintMessages" />
      </Stack>
    </Stack>
  );
};
