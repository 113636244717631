import { useInitializeSheet } from '@oms/ui-sheet';
import { theme } from 'theme';

export const sheetId = {
  help: 'help',
  trade: 'trade',
  tv: 'tv',
  marginDefinitions: 'marginDefinitions',
};

export type SheetId = keyof typeof sheetId;

const sheetOptions = { modalViewportWidth: theme.breakpoints.sm };

export const useSheets = () => {
  // Sheets are a collection of modals.
  // When one is activated the others are closed,
  // hence we need to initialize the modals/sheets state upfront.
  const help = useInitializeSheet(sheetOptions);
  const trade = useInitializeSheet(sheetOptions);
  const marginDefinitions = useInitializeSheet(sheetOptions);
  const tv = useInitializeSheet(sheetOptions);
  return { help, trade, marginDefinitions, tv };
};
