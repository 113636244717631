// TODO: Hard coded map? :S Look into expanding BankServlet/-Service?
export const bankAccountMap = [
  {
    from: 1030,
    to: 1031,
    label: 'SpareBank 1 Østfold Akershus  (ikke i bruk)',
  },
  { from: 1080, to: 1089, label: 'SpareBank 1 Østfold Akershus' },
  { from: 1800, to: 1829, label: 'SpareBank 1 Østlandet' },
  { from: 1840, to: 1868, label: 'SpareBank 1 Østlandet' },
  { from: 1890, to: 1917, label: 'SpareBank 1 Østlandet' },
  { from: 2020, to: 2020, label: 'SpareBank 1 Ringerike Hadeland' },
  { from: 2030, to: 2030, label: 'SpareBank 1 Ringerike Hadeland' },
  { from: 2085, to: 2085, label: 'SpareBank 1 Lom Og Skjåk' },
  { from: 2095, to: 2095, label: 'SpareBank 1 Gudbrandsdal' },
  { from: 2125, to: 2125, label: 'SpareBank 1 Gudbrandsdal' },
  { from: 2135, to: 2135, label: 'SpareBank 1 Hallingdal Valdres' },
  { from: 2270, to: 2270, label: 'Modum Sparebank' },
  { from: 2280, to: 2281, label: 'SpareBank 1 Ringerike Hadeland' },
  { from: 2291, to: 2291, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2320, to: 2329, label: 'SpareBank 1 Hallingdal Valdres' },
  { from: 2367, to: 2367, label: 'SpareBank 1 Hallingdal Valdres' },
  { from: 2470, to: 2475, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2480, to: 2489, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2505, to: 2505, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2540, to: 2544, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2551, to: 2551, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2610, to: 2610, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2650, to: 2650, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2665, to: 2665, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 2670, to: 2670, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 3126, to: 3126, label: 'SpareBank 1 SR-Bank' },
  { from: 3132, to: 3132, label: 'SpareBank 1 SR-Bank' },
  { from: 3180, to: 3230, label: 'SpareBank 1 SR-Bank' },
  { from: 3250, to: 3250, label: 'SpareBank 1 SR-Bank' },
  { from: 3270, to: 3280, label: 'SpareBank 1 SR-Bank' },
  { from: 3295, to: 3295, label: 'SpareBank 1 SR-Bank' },
  { from: 3310, to: 3315, label: 'SpareBank 1 SR-Bank' },
  { from: 3330, to: 3350, label: 'SpareBank 1 SR-Bank' },
  { from: 3364, to: 3373, label: 'SpareBank 1 SR-Bank' },
  { from: 3450, to: 3450, label: 'Sparebanken Vest' },
  { from: 3460, to: 3460, label: 'SpareBank 1 SR-Bank' },
  { from: 3535, to: 3535, label: 'SpareBank 1 SR-Bank' },
  { from: 3930, to: 3943, label: 'SpareBank 1 Nordvest' },
  { from: 3991, to: 3998, label: 'SpareBank 1 Søre Sunnmøre' },
  { from: 4068, to: 4068, label: 'SpareBank 1 SMN' },
  { from: 4090, to: 4090, label: 'SpareBank 1 Nordvest' },
  { from: 4130, to: 4130, label: 'SpareBank 1 Nordvest' },
  { from: 4200, to: 4227, label: 'SpareBank 1 SMN' },
  { from: 4240, to: 4250, label: 'SpareBank 1 SMN' },
  { from: 4319, to: 4319, label: 'SpareBank 1 SMN' },
  { from: 4342, to: 4342, label: 'SpareBank 1 SMN' },
  { from: 4349, to: 4352, label: 'SpareBank 1 SMN' },
  { from: 4360, to: 4361, label: 'SpareBank 1 SMN' },
  { from: 4400, to: 4440, label: 'SpareBank 1 SMN' },
  { from: 4451, to: 4464, label: 'SpareBank 1 SMN' },
  { from: 4466, to: 4480, label: 'SpareBank 1 SMN' },
  { from: 4482, to: 4482, label: 'SpareBank 1 SMN' },
  { from: 4500, to: 4509, label: 'SpareBank 1 Nord-Norge' },
  { from: 4510, to: 4519, label: 'SpareBank 1 Helgeland' },
  { from: 4521, to: 4536, label: 'SpareBank 1 Helgeland' },
  { from: 4540, to: 4585, label: 'SpareBank 1 Nord-Norge' },
  { from: 4599, to: 4602, label: 'SpareBank 1 Nord-Norge' },
  { from: 4612, to: 4634, label: 'SpareBank 1 Nord-Norge' },
  { from: 4638, to: 4638, label: 'SpareBank 1 Nord-Norge' },
  { from: 4648, to: 4648, label: 'SpareBank 1 Nord-Norge' },
  { from: 4651, to: 4651, label: 'SpareBank 1 SMN' },
  { from: 4657, to: 4657, label: 'SpareBank 1 Nord-Norge' },
  { from: 4700, to: 4729, label: 'SpareBank 1 Nord-Norge' },
  { from: 4740, to: 4965, label: 'SpareBank 1 Nord-Norge' },
  { from: 9001, to: 9008, label: 'SpareBank 1 Østlandet' },
  { from: 9011, to: 9011, label: 'SpareBank 1 SR-Bank' },
  { from: 9012, to: 9012, label: 'SpareBank 1 Østlandet' },
  { from: 9015, to: 9015, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 9016, to: 9016, label: 'SpareBank 1 Østfold Akershus' },
  { from: 9017, to: 9017, label: 'SpareBank 1 Sørøst-Norge' },
  { from: 9019, to: 9019, label: 'SpareBank 1 Nord-Norge' },
  { from: 9211, to: 9211, label: 'SpareBank 1 Hallingdal Valdres' },
  { from: 9650, to: 9662, label: 'SpareBank 1 SMN' },
  { from: 9841, to: 9841, label: 'SpareBank 1 SR-Bank' },
  { from: 9851, to: 9895, label: 'SpareBank 1 Østlandet' },
] as const;

export function getLabel(accountNo: string) {
  if (!accountNo) {
    return null;
  }

  const accPrefix = Number(accountNo.substr(0, 4));

  const result = bankAccountMap.find(
    series => accPrefix >= series.from && accPrefix <= series.to,
  );

  return result ? result.label : null;
}
