import { Buy } from 'components/Trade/Buy';
import { Sell } from 'components/Trade/Sell';
import { Stack } from '@oms/ui-stack';
import {
  TradeSwitcher,
  useTradeSwitcher,
} from 'components/Trade/TradeSwitcher';
import { AccountSwitcherDialog } from 'components/AccountSwitcher';
import { useSelectedAccount } from '../../../utils/hooks/useAccounts';

export function TradeForm() {
  const tradeSwitcher = useTradeSwitcher();
  const {
    data: { selectedAccount },
  } = useSelectedAccount();

  const isAsk = selectedAccount?.ask;
  const bannerColor = isAsk ? 'surface-4' : 'surface-5';
  return (
    <Stack>
      <TradeSwitcher {...tradeSwitcher} />
      <Stack p={2} backgroundColor={bannerColor} borderRadius={'md'}>
        <AccountSwitcherDialog includeActions={false} showBorder />
      </Stack>
      {tradeSwitcher.selected === 'buy' ? <Buy /> : <Sell />}
    </Stack>
  );
}
