import { useEffect } from 'react';
import objectAssign from 'object-assign';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { audit } from 'utils/audit';
import useUser from 'utils/hooks/useUser';
import renderText, { buildString } from './renderText';

/**
 * This component audits whenever it mounts so that compliance in SB1 can
 * use this as proof that the customer has seen the warning.
 * Pass whatever you want as props and it will be included in the audit data
 */

const setAudit = (userId, accountId, name, location) => {
  const textAndKey = {
    key: 'expediencyWarningDisplayed',
    text: JSON.stringify(
      objectAssign({}, name, location, {
        text: buildString(renderText(name, location)),
      }),
    ),
  };
  return audit(userId, accountId, textAndKey);
};

const ExpediencyWarning = ({ name, location }) => {
  const { selectedAccountId: accountId } = useSelectedAccountId();
  const {
    data: { userId = '' },
  } = useUser();

  useEffect(() => {
    setAudit(userId, accountId, name, location);
  }, [accountId, location, name, userId]);

  return renderText(name, location);
};

export default ExpediencyWarning;
