import { DOMAIN_URL } from 'constants/Jaws';
import { useQuery } from 'react-query';
import { get } from 'utils/fetch';

type InstrumentMetaData = {
  item: string;
  sources: string[];
  itemSector: string;
  newsweb: string;
  company_info: string;
  groups: string[];
  trades: string;
  graph_data_tick: string;
  type: string;
  financial_calendar: string;
  recommendations: string;
  substitute_type: string;
  brokerstats: string;
  lists: string[];
  dividends: string;
  orders: string;
  sector: string;
  order_log: string;
  estimates: string;
};

export const useInstrumentMetaData = (itemSector: string) =>
  useQuery(['instrumentMetaData', itemSector], () =>
    get(`${DOMAIN_URL}?itemSector=${itemSector}`)
      .then(res => res.json())
      .then(json => json[0] as InstrumentMetaData),
  );
