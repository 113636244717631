import { Page } from 'components/Page';
import { Grid, GridContainer } from 'components/Grid';
import { MarketOverview as Overview } from 'components/MarketOverview';
import { HotStocks } from 'components/HotStocks';
import { MostTraded } from 'components/MostTraded';
import { Winners } from 'components/Winners';
import { Losers } from 'components/Losers';
import { Calendar } from 'components/Calendar';
import { MarketNews } from 'components/News';
import { Sectors } from 'components/Sectors';
import { ReportsAndPortfolio } from 'components/ReportsAndPortfolio';
import { MarketInstrumentTables } from 'components/MarketInstrumentTables';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { Cards } from 'components/TickerTape';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { useMedia } from '@oms/ui-media';
export interface MarketOverviewProps {}

export default function MarketOverview(_props: MarketOverviewProps) {
  const media = useMedia();

  return (
    <Page
      documentTitle="Aksjehandel"
      topElement={
        <>
          <VisuallyHidden>Aksjehandel</VisuallyHidden>
          {media.isMobile ? <Cards /> : <Overview />}
        </>
      }
    >
      <GridContainer>
        <Grid>
          <ErrorBoundary>
            <MarketInstrumentTables
              gridColumn={{ sm: 'auto / span 2' }}
              minHeight="20rem"
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <ReportsAndPortfolio
              gridColumn={{ sm: 'auto / span 2' }}
              minHeight="20rem"
            />
          </ErrorBoundary>
          <HotStocks />
          <MostTraded />
          <Winners />
          <Losers />
          <Calendar />
          <Sectors />
          <MarketNews gridColumn={{ sm: 'auto / span 2' }} />
          {media.isMobile ? <Overview /> : null}
        </Grid>
      </GridContainer>
    </Page>
  );
}

export { MarketOverview };
