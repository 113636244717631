export function parseErrors(input) {
  if (typeof input === 'object') return input;
  if (!input || typeof input !== 'string') {
    return {};
  }

  try {
    return JSON.parse(input);
  } catch (e) {
    return {};
  }
}

export function errorsContain(detail, errors) {
  let result = errors;

  if (typeof errors === 'string') {
    result = parseErrors(result).errors;
  }

  if (!Array.isArray(result)) {
    return false;
  }

  return result.some(e => e.detail && e.detail.includes(detail));
}

export function getErrorParamsFromError(detail, errors) {
  let result = errors;

  if (typeof result === 'string') {
    result = parseErrors(result).errors;
  }

  if (!Array.isArray(result)) {
    return null;
  }

  const match = result.find(e => e.detail === detail);
  return match ? match.params : null;
}

export function getDetails(errors) {
  let result = errors;

  if (typeof result === 'string') {
    result = parseErrors(result).errors;
  }

  if (!Array.isArray(result)) {
    return [];
  }

  return result.map(e => e.detail);
}
