import { Winners as OMSWinners } from '@oms/components-winners';
import { Card, CardProps } from 'components/Card';
import { generatorSpec } from 'utils/Spec';
export interface WinnersProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const Winners = (props: WinnersProps) => (
  <Card title="Vinnere" {...props}>
    <OMSWinners showMoreButton spec={generatorSpec()} />
  </Card>
);
