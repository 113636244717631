import { Box } from '@oms/ui-box';
import { Select } from '@oms/ui-select';
import { TextInput } from '@oms/ui-text-input';
import { ClearButton } from '@oms/ui-icon-button';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { Markets, markets } from './helpers';
import { useMedia } from '@oms/ui-media';

type Props = {
  filter: string;
  setFilter: (newFilter: string) => void;
  market: Markets;
  setMarket: (newMarket: Markets) => void;
};

const Inputs = ({ filter, market, setMarket, setFilter }: Props) => {
  const { isMobile } = useMedia();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      py={2}
      pr={{ sm: 3 }}
    >
      <VisuallyHidden as="label" htmlFor="marketPicker">
        Velg marked
      </VisuallyHidden>
      <Select
        minWidth={{ base: '100%', sm: '11rem' }}
        name="marketPicker"
        onChange={(option: any) => {
          setMarket(option.value);
        }}
        //@ts-ignore
        items={markets}
        value={markets.find(opt => opt.value === market)}
        px={isMobile ? 0 : 4}
      />
      {!isMobile && (
        <>
          <VisuallyHidden as="label" htmlFor="marketPicker">
            Søk i listen
          </VisuallyHidden>
          <TextInput
            minWidth="200px"
            name="filter"
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder="Søk i listen"
            rightElement={<ClearButton onClick={() => setFilter('')} />}
          />
        </>
      )}
    </Box>
  );
};

export default Inputs;
