import { useMemo } from 'react';
import { useField } from 'react-final-form';
import { Link } from 'react-router-dom';
import { OMSContext } from '@oms/components-context';
import { defaultContextValues } from '@oms/components-config-context';
import Jaws from '@oms/jaws';

import { theme, Sparebank1Global } from 'theme';
import {
  BASE_SECURE_URL,
  BASE_URL,
  CUSTOMERTRANSACTIONS_URL,
} from 'constants/Jaws';
import useUser from 'utils/hooks/useUser';
import { useUserPreferences } from './UserPreferencesContext';

const configValue = {
  ...defaultContextValues,
  transactionSearchUrl: `${CUSTOMERTRANSACTIONS_URL}/{userId}/transactions/search{?accountId,filters*}`,
  graphdataUrl:
    '{+baseUrl}/secure/components/graphdata/{series}/{space}/{itemSector}{?query*}',
};

export const OMSProvider: React.FC = ({ children, omitLink = false }: any) => {
  const {
    data: { hasStreaming, isAuthenticated },
  } = useUser();
  const { preferences } = useUserPreferences();

  const jaws = useMemo(() => {
    const instance = new Jaws({
      url: isAuthenticated ? BASE_SECURE_URL : BASE_URL,
      useWebSockets: hasStreaming && preferences.streaming,
    });
    return { instance };
  }, [preferences, hasStreaming, isAuthenticated]);

  return (
    <OMSContext
      jaws={jaws}
      language={navigator.language}
      //@ts-ignore // TODO Gerrit?
      theme={theme}
      global={<Sparebank1Global />}
      components={{
        useField,
        Link: omitLink ? undefined : Link,
      }}
      config={configValue}
    >
      {children}
    </OMSContext>
  );
};
