import { del, get, post, put } from './fetch';
import { normalizeNumber } from 'utils/form';
import { H_TEST_URL } from 'constants/Jaws';
import { ALL_PRODUCT_GROUPS, productTypes } from 'constants/hTestGroups';

export function getHTest(userId) {
  return get(`${H_TEST_URL}/${userId}`);
}

export function createHTest(userId, payload) {
  const data = {
    ...payload,
  };
  return post(`${H_TEST_URL}/${userId}`, JSON.stringify({ data }));
}

export function deleteHTest(userId) {
  return del(`${H_TEST_URL}/${userId}`);
}

export function sign(userId) {
  return post(`${H_TEST_URL}/${userId}/sign`);
}

export function signingStatus(ownerId) {
  return get(`${H_TEST_URL}/${ownerId}/signing-status`);
}

export function modifyHTest(userId, data) {
  return put(`${H_TEST_URL}/${userId}`).send({ data });
}

export function modifyHTestProduct(userId, data) {
  return put(`${H_TEST_URL}/${userId}/product`).send({ data });
}

/** Finds a valid HTest group in the instrument's groups */
export function findHTestGroup(groups) {
  return groups.find(group => productTypes.includes(group));
}

export function getName(type) {
  const product = ALL_PRODUCT_GROUPS.find(group => group.type === type);
  return product ? product.name : null;
}

export function getDefaultProduct(type) {
  return {
    name: getName(type),
    type,
    knowledgeLevel: null,
    noOfTrades: null,
    yearsExperience: null,
    expediency: 'NOT_CONSIDERED',
  };
}

export function calculateExpediency(product) {
  const { knowledgeLevel, yearsExperience } = product;
  const years = normalizeNumber(yearsExperience);

  return knowledgeLevel === 'LOW' || years === 0 ? 'NO' : 'YES';
}

/**
 * Used for determining wether or not an instrument is "simple", i.e. should be
 * shown the H-test for when on ASK.
 *
 * Note that it is not enough to use this function, you need to look at the
 * instrument set of the instrment as well.
 *
 * Defaults to true for falsey inputs.
 */

export const isSimpleProduct = instrument => {
  if (!instrument) {
    return true;
  }
  const { INSTRUMENT_TYPE, SECTOR } = instrument;

  if (INSTRUMENT_TYPE === 'ETNS') {
    return false;
  }

  return SECTOR === 'OAX' || SECTOR === 'OSE' || SECTOR === 'MERK';
};
