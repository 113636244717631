import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AssessmentTest, editTest, fetchTest, fetchTests } from './helpers';

export const useAssessmentTests = () =>
  useQuery('assessmentTests', () => fetchTests());

export const useAssessmentTestByID = (id?: string | number) =>
  useQuery(['assessmentTests', id], () => fetchTest(id as number), {
    enabled: id !== undefined,
  });

export const useEditAssessmentTest = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (variables: { assessmentTest: AssessmentTest }) =>
      editTest(variables.assessmentTest),
    { onSuccess: () => queryClient.invalidateQueries('assessmentTests') },
  );
};
