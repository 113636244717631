import { TickerTable } from 'components/TickerTable';
import { useQueries } from 'react-query';
import { getHoldings } from 'utils/hooks/useHoldings';
import { useAccounts } from 'utils/hooks/useAccounts';
import { Stack } from '@oms/ui-stack';
import { Text } from '@oms/ui-text';

const MyInstruments = () => {
  const {
    data: { accounts },
  } = useAccounts();

  const allHoldings = accounts.map(account => ({
    queryKey: ['holdings', account.id, undefined],
    queryFn: () => getHoldings(account.id),
  }));

  const queries = useQueries(allHoldings);
  const allRows = queries
    .flatMap((query: any) => query?.data?.rows)
    .filter(Boolean);

  const itemSectors = allRows
    .map(item => item.ITEM_SECTOR)
    .filter(Boolean) as string[]; // Filter out any undefined values

  if (itemSectors && !itemSectors.length)
    return (
      <Stack gap={2} px={{ base: 3, sm: 0 }}>
        <Text fontWeight="bold">Ingen instrumenter</Text>
        <Text>
          Her presenteres dine aksjer som du har i din beholding. Du har per nå
          ingen aksjer/instrumenter i din beholding på dette konti.
        </Text>
      </Stack>
    );

  return <TickerTable itemSectors={itemSectors} showTradeButton={false} />;
};

export default MyInstruments;
