import { Table, Tbody, Td, Th, Tr } from '@oms/ui-table';
import { CardProps, CardWithAccount } from 'components/Card';
import { useCustomerInformation } from 'utils/hooks/useUser';

export interface CustomerInformationProps extends Omit<CardProps, 'children'> {}
export function CustomerInformation(props: CustomerInformationProps) {
  const {
    fullName,
    bankAccountNo,
    vpsAccountNo,
    bankName,
    phoneNumber,
    email,
  } = useCustomerInformation();

  return (
    <CardWithAccount title="Din informasjon" {...props}>
      <Table variant="keyValue">
        <Tbody>
          <Tr>
            <Th scope="row">Navn</Th>
            <Td type="text">{fullName}</Td>
          </Tr>
          <Tr>
            <Th scope="row">Bankkonto</Th>
            <Td type="text">{bankAccountNo}</Td>
          </Tr>
          <Tr>
            <Th scope="row">VPS konto</Th>
            <Td>{vpsAccountNo}</Td>
          </Tr>
          <Tr>
            <Th scope="row">Kunde hos</Th>
            <Td>{bankName}</Td>
          </Tr>
          <Tr>
            <Th scope="row">Kundeklassifisering</Th>
            {/* TODO: Can this really stay hard coded? */}
            <Td>Ikke-profesjonell</Td>
          </Tr>
          <Tr>
            <Th scope="row">Tlf</Th>
            <Td>{phoneNumber}</Td>
          </Tr>
          <Tr>
            <Th scope="row">E-post</Th>
            <Td
              title={email}
              style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            >
              {email}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </CardWithAccount>
  );
}
