const valueToText = (number: string | number) => {
  const text = {
    '-1': 'neg',
    '0': 'flat',
    '1': 'pos',
  };
  return text[number.toString() as keyof typeof text] as 'neg' | 'flat' | 'pos';
};

export const getTrendColor = (value: number) =>
  valueToText(Math.sign(value).toString(10));
