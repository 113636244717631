import { FIVE_MINUTES } from 'constants/timeInMs';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useUser from 'utils/hooks/useUser';
import {
  editSniplet,
  fetchPublicSniplets,
  fetchSniplet,
  fetchSniplets,
  Sniplet,
} from './helpers';

export const useSniplets = () => {
  const { data: { isAuthenticated = false } = {} } = useUser();

  return useQuery(['sniplets', isAuthenticated], () => {
    if (isAuthenticated) return fetchSniplets();
    return fetchPublicSniplets();
  });
};

export const useSnipletByID = (id?: string | number) =>
  useQuery(['sniplets', id], () => fetchSniplet(id as number), {
    enabled: id !== undefined,
    staleTime: FIVE_MINUTES,
  });

type EditSnipletVariables = {
  sniplet: Sniplet;
};
export const useEditSniplet = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ sniplet }: EditSnipletVariables) => editSniplet(sniplet),
    { onSuccess: () => queryClient.refetchQueries('sniplets') },
  );
};
