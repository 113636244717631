import { IconButton } from '@oms/ui-icon-button';
import { useWebTVContext } from 'context/WebTVContext';
import { Rnd } from 'react-rnd';
import styled from '@xstyled/emotion';
import { Heading } from '@oms/ui-heading';
import { ComponentProps, FC, useEffect, useState, useCallback } from 'react';
import { light, Spinner } from '@oms/ui-icon';
import { Box } from '@oms/ui-box';

const Header = styled.header`
  height: 40px;
  padding: 0 12px;
  display: flex;
  background-color: surface-2;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Draggable: FC<ComponentProps<typeof Rnd>> = styled(Rnd)`
  display: flex;
  flex-direction: column;
  z-index: 2;

  iframe {
    height: calc(100% - 40px);
    width: 100%;
  }
`;

/** Makes the Draggable-component maintain position when scrolling, resizing and other things */
const DraggableLayer = styled.div`
  position: fixed;
  right: 320px;
  bottom: 240px;
  z-index: 2;
`;

const id = 'video-close-button';

const WebTVPlayer = () => {
  const { webTVUrl, setWebTVUrl } = useWebTVContext();
  const [isDragging, setIsDragging] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
  }, [webTVUrl]);

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      let element = event.target as HTMLButtonElement;
      if (element && element?.id === id) {
        setWebTVUrl(undefined);
      }
    },
    [setWebTVUrl],
  );

  if (!webTVUrl) return null;
  return (
    <DraggableLayer>
      <Draggable
        //allowAnyClick={true}
        //enableUserSelectHack={true}
        cancel="cancel"
        onDragStart={() => setIsDragging(true)}
        onDragStop={() => setIsDragging(false)}
        onMouseDown={onMouseDown}
        lockAspectRatio={16 / 9}
        lockAspectRatioExtraHeight={40}
        bounds="#root"
        default={{
          x: 0,
          y: 0,
          width: 320,
          height: 240,
        }}
        minWidth={150}
      >
        <Header>
          <Heading as="h2">Videospiller</Heading>
          <IconButton id={id} icon={light.faTimes} aria-label="Lukk" />
        </Header>
        {!isLoaded && (
          <Box
            height="calc(100% - 40px)"
            width="100%"
            center
            backgroundColor="surface-1"
          >
            <Spinner />
          </Box>
        )}
        <iframe
          // If we're dragging we don't want anything in the iframe interrupting that
          // Dragging into the iframe locked the Draggable container to the mouse and was generally annoying in normal use.
          style={{
            pointerEvents: isDragging ? 'none' : undefined,
            visibility: isLoaded ? 'visible' : 'hidden',
          }}
          className="cancel"
          src={webTVUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Video player"
          onLoad={() => setIsLoaded(true)}
          onLoadStart={() => setIsLoaded(false)}
        />
      </Draggable>
    </DraggableLayer>
  );
};

export default WebTVPlayer;
