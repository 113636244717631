import { Box, BoxProps } from '@oms/ui-box';
import { ErrorBoundary } from '@oms/ui-error-boundary';
export interface CardBoxProps extends BoxProps {}

export function CardBox({ children, ...props }: CardBoxProps) {
  return (
    <Box
      py={{ sm: 4 }}
      px={{ sm: 3 }}
      borderRadius={{ sm: 'lg' }}
      border={{ sm: 'sm' }}
      borderColor={{ sm: 'border' }}
      backgroundColor="surface-1"
      {...props}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </Box>
  );
}
