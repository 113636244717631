import { css, Global } from '@xstyled/emotion';
import { BoxSizingStyles } from '@oms/ui-theming';
import { isIOSWebview } from 'utils/webview';

// This file contains some iOS webview workarounds

export const colors = {
  modes: {},
  primary: '#005AA4',
  secondary: '#002776',
  tertiary: '#7EB5D2',
  danger: '#e44244',
  buy: '#33AF85',
  sell: '#e44244',

  'surface-1': '#fff',
  'surface-2': '#D9E6F1',
  'surface-3': '#FDF8F5',
  'surface-4': '#E1F4E3',
  'surface-5': '#D8E9F2',
  'surface-6': '#FAE4E0',
  'surface-7': '#e44244',
  'surface-8': '#33AF85',
  'surface-hover': '#e6eff677',

  border: '#D7D2CB',
  'contrast-border': '#9B9797',
  'border-focus': '#848383',
  'border-error': '#e44244', //wcag version of '#e44244',
  'border-disabled': '#dfdfdf',
  'inverse-border': '#fff',

  text: '#262626',
  'text-light': '#676767',
  'text-label': '#252631',
  'text-placeholder': '#676767',
  'text-message': '#676767',
  'text-disabled': '#676767',
  'text-error': '#E12D30',
  'inverse-text': '#fff',

  link: '#005AA4',

  input: '#fff',
  'input-focus': '#FFF',
  disabled: '#dfdfdf',

  'icon-primary': '#4e7d87',
  'icon-secondary': '#395b72',
  'icon-hover': '#F8FAFB',
  'icon-focus': '#F8FAFB',
  'icon-active': '#b0dbd7',

  pos: '#33AF85',
  'text-pos': '#278565', //text wcag version of '#33AF85',
  neg: '#e44244',
  'text-neg': '#E12D30', //text wcag version of '#e44244',
  flat: '#D9E6F1',
  'flat-text': '#f8e71c',
  'flat-map': '#fff',
  blend: '#fff',
  /** Not a color, but a value used to blend colors */
  blendTreshold: 1,

  backdrop: '#354c6724',
  black: '#000',
  white: '#fff',
  current: 'currentColor',
  transparent: 'hsla(0,0%,0%,0)',
  highlight: '#0071eb',

  success: '#37B441',
  warning: '#AD6500',
  error: '#da3d00',
  information: '#0071eb',
  question: '#0071eb',

  'success-hover': '#37B441',
  'warning-hover': '#f1c21b',
  'error-hover': '#da3d00',
  'information-hover': '#0071eb',
  'questions-hover': '#0071eb',

  'inverse-success': '#37B441',
  'inverse-warning': '#f1c21b',
  'inverse-error': '#da3d00',
  'inverse-information': '#2cc4f3',
  'inverse-question': '#2cc4f3',

  selected: '#005AA4',
  'selected-hover': '#002776',

  skeleton: '#D8D8D8',
  'text-skeleton': '#676767',
  'skeleton-base': '#D8D8D8',
  'skeleton-highlight': '#F3F2EF',

  'accent-1': '',
  'accent-2': '',
  'accent-3': '',
  'accent-4': '',
  'accent-5': '',

  'chart-1': 'rgb(0, 90, 164)',
  'chart-2': '',
  'chart-3': '',
  'chart-4': '',
  'chart-5': '',
  'chart-fill-start-1': '',
  'chart-fill-start-2': '',
  'chart-fill-start-3': '',
  'chart-fill-start-4': '',
  'chart-fill-start-5': '',
  'chart-fill-end-1': '',
  'chart-fill-end-2': '',
  'chart-fill-end-3': '',
  'chart-fill-end-4': '',
  'chart-fill-end-5': '',

  'scroll-thumb': 'rgba(215, 210, 203, 0.9)',
  'scroll-track': 'rgba(215, 210, 203, 0.3)',
  'scroll-thumb-idle': 'rgba(215, 210, 203, 0.3)',
  'scroll-track-idle': 'rgba(0, 0, 0, 0)',
  'scroll-fade-in': 'rgba(255, 255, 255,1)',
  'scroll-fade-out': 'rgba(255, 255, 255,0)',

  page: '#d9e6f1',
};

export const shadows = {
  focus: '0 0 0 1px #fff, 0 0 0 4px #a1dfff',
  'focus-error': '0 0 0 3px #e0bbbe',
  'focus-inset': 'inset 0 0 0 1px #fff, inset 0 0 0 4px #a1dfff',
  menu: '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15)',
};

export const fonts = {
  heading: `Sparebank1Title, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  body: `Sparebank1, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  label: `Sparebank1, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
};

const noDefault = {};

/**
 * Component style config
 * @memberof theming
 */
export const components = {
  accordion: {
    container: noDefault,
    header: css`
      min-height: 4.5rem;
      color: primary;
      font-weight: bold;
      &[data-open='true'] {
        color: tertiary;
      }
      &:focus {
        box-shadow: focus-inset;
      }
    `,
    content: css`
      padding: 2 4 6 4;
    `,
  },

  appShell: {
    header: noDefault,
    main: noDefault,
    status: {
      info: {
        color: 'inverse-text',
        backgroundColor: 'inverse-info',
      },
      success: {
        color: 'inverse-text',
        backgroundColor: 'inverse-success',
      },
      warning: {
        color: 'inverse-text',
        backgroundColor: 'inverse-warning',
      },
      error: {
        color: 'inverse-text',
        backgroundColor: 'inverse-error',
      },
      question: {
        color: 'inverse-text',
        backgroundColor: 'inverse-question',
      },
    },
  },
  backdrop: noDefault,
  badge: {
    default: css`
      margin: auto;
      font-size: inherit;
      font-weight: bold;
      padding: 0.1rem 0.5rem;
      color: primary;
      background-color: surface-2;
      border-radius: md;
      width: fit-content;
      white-space: nowrap;
    `,
    variants: {
      default: css`
        color: primary;
        background-color: surface-5;
      `,
      ask: css`
        color: text-pos;
        background-color: surface-4;
      `,
      notification: css`
        color: white;
        background-color: surface-7;
      `,
    },
  },
  breadcrumb: {
    default: noDefault,
    item: noDefault,
  },
  button: {
    default: css`
      font-size: lg;
      border-radius: rounded;
      &[aria-disabled='true'] {
        color: #5c5c5c;
      }
    `,
    sizes: {
      xs: css`
        padding-top: 0.1875rem;
        padding-bottom: 0.1875rem;
      `,
      sm: css`
        padding-top: 0.4375rem;
        padding-bottom: 0.4375rem;
        min-height: 2.375rem;
      `,
      md: css`
        min-height: 2.75rem;
        padding-top: 0.5625rem;
        padding-bottom: 0.5625rem;
      `,
      lg: css`
        min-height: 2.75rem;
        padding-top: 0.8125rem;
        padding-bottom: 0.8125rem;
      `,
      xl: css`
        padding-top: 1.3125rem;
        padding-bottom: 1.3125rem;
      `,
    },
    variants: {
      primary: css`
        color: inverse-text;
        background-color: #005aa4;
        border: sm;
        border-color: primary;

        &:hover {
          background-color: #002776;
        }
        &:active {
          background-color: #002776;
        }
      `,
      secondary: css`
        color: #005aa4;
        border: sm;
        border-color: border;
        &:hover {
          color: #002776;
          border-color: #002776;
        }
        &:active {
          background-color: #fff;
          border-color: transparent;
        }
      `,
      tertiary: css`
        color: #005aa4;
        border: sm;
        border-color: transparent;
        &:hover {
          color: #002776;
        }
        &:active {
          border-color: #002776;
        }
        &::after {
          content: ' ';
          border-bottom: 1px solid currentColor;
          display: block;
          width: 100%;
          transform: translateY(4px);
        }
      `,
      link: css`
        padding: 0;
        min-height: 0;
        color: #005aa4;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        &:hover {
          color: #002776;
          border-color: currentColor;
        }
        &::after {
          content: ' ';
          //border-bottom: 1px solid currentColor;
          display: block;
          width: 100%;
          transform: translateY(4px);
        }
      `,
      inherit: css`
        all: inherit;
      `,
      plain: {},
      buy: css`
        color: #001032;
        background-color: #33af85;
        &:hover {
          color: inverse-text;
          background-color: #285949;
        }
        &:focus {
          color: inverse-text;
          background-color: #285949;
        }
        &:active {
          color: inverse-text;
          background-color: #285949;
        }
      `,
      sell: css`
        color: #001032;
        border: sm;
        border-color: #33af85;
        &:hover {
          border-color: transparent;
          background-color: #33af85;
        }

        &:focus {
          border-color: transparent;
          background-color: #33af85;
        }
        &:active {
          border-color: transparent;
          background-color: #33af85;
        }
        svg {
          color: #33af85;
        }

        &:active,
        &:focus,
        &:hover {
          svg {
            color: #001032;
          }
        }
      `,
      danger: css`
        color: #001032;
        background-color: #e44244;
        &:hover {
          color: inverse-text;
          background-color: #873953;
        }
        &:focus {
          color: inverse-text;
          background-color: #873953;
        }
        &:active {
          color: inverse-text;
          background-color: #873953;
        }
      `,
    },
  },
  card: {
    default: css`
      padding: 2 3;
      border: sm;
      border-color: border;
      border-radius: lg;

      /*box-shadow: 0 1px 4px 0 rgb(38 38 38 / 30%);*/
    `,
    actions: noDefault,
  },
  checkbox: {
    container: css`
      flex-shrink: 0;
    `,
    default: css`
      border: md;
      border-color: border;
      :disabled {
        border-color: disabled;
      }
    `,
    checked: css`
      border-color: selected;

      & + [role='presentation'] {
        background-color: transparent;
        &::after {
          /*
          * The checkmark is made off a border
          */
          border-color: selected;
          border-width: 3px;
        }
      }
    `,
    sizes: {
      md: css`
        width: 1.25rem;
        height: 1.25rem;
      `,
      lg: css`
        width: 1.5rem;
        height: 1.5rem;
      `,
    },
  },
  chip: {
    default: {
      borderRadius: 'rounded',
    },
    sizes: {
      sm: css`
        height: 1.25rem;
      `,
      md: css`
        height: 1.75rem;
      `,
      lg: css`
        height: 2rem;
      `,
    },
    variants: {
      primary: css`
        background-color: surface-hover;
        border: sm;
        border-color: surface-hover;
      `,
      secondary: css`
        border: sm;
        border-color: primary;
      `,
      tertiary: noDefault,
    },
  },
  choice: {
    default: css`
      border-radius: none;
      border: sm;
      border-color: border;
    `,
    checked: css`
      &[data-orientation='horizontal'] {
        border-left: lg;
        border-left-color: primary;
      }
      &[data-orientation='vertical'] {
        border-bottom: lg;
        border-bottom-color: primary;
      }
    `,
    hover: noDefault,
    'focus-within': noDefault,
  },
  combobox: {
    default: noDefault,
    button: {
      default: noDefault,
      open: css`
        svg {
          transform: rotate(180deg);
        }
      `,
    },
  },
  contentSwitcher: {
    default: noDefault,
    variants: {
      primary: css`
        display: inline-flex;
        justify-self: flex-start;
        border: sm;
        border-color: primary;
        border-radius: rounded;
        padding: 2px;
        width: initial;
        li {
          padding: 0.675rem;
          padding-bottom: 0.6125rem;
          min-width: 3rem;
          border: sm;
          border-color: transparent;
          border-radius: rounded;
        }
        li:hover {
          background-color: surface-hover;
        }
        &[data-orientation='horizontal'] li[aria-checked='true'] {
          color: inverse-text;
          border-bottom-width: md;
          border-bottom-style: solid;
          border-color: primary;
          background-color: primary;
        }
        &[data-orientation='vertical'] li[aria-checked='true'] {
          color: inverse-text;
          border-right-width: md;
          border-right-style: solid;
          border-color: primary;
          background-color: primary;
        }
      `,
      secondary: css`
        li {
          padding: 0.675rem;
          padding-bottom: 0.6125rem;
          min-width: 3rem;
        }
        li:hover {
          background-color: surface-hover;
        }
        &[data-orientation='horizontal'] li[aria-checked='true'] {
          color: primary;
          border-bottom-width: md;
          border-bottom-style: solid;
          border-color: primary;
        }
        &[data-orientation='vertical'] li[aria-checked='true'] {
          color: primary;
          border-right-width: md;
          border-right-style: solid;
          border-color: primary;
        }
      `,
    },
  },
  dateInput: {
    default: noDefault,
    day: {
      default: css`
        &:hover {
          color: icon-1;
          background-color: icon-hover;
          border-radius: rounded;
        }
        &.react-datepicker__day--disabled {
          opacity: 0.1;
        }
      `,
      today: noDefault,
      selected: css`
        border-radius: rounded;
      `,
      weekday: noDefault,
      weekend: noDefault,
      blocked: noDefault,
      inRange: noDefault,
    },
  },
  descriptionList: {
    default: noDefault,
    term: noDefault,
    definition: noDefault,
  },
  dialog: {
    default: css`
      border: sm;
      border-color: border;
      border-radius: none;
      box-shadow: lg;
    `,
    header: css`
      z-index: 2;
    `,
    content: noDefault,
    actions: noDefault,
  },
  drawer: {
    default: css`
      box-shadow: lg;
    `,
    header: css`
      z-index: 1;
    `,
    content: noDefault,
    actions: noDefault,
    placement: {
      top: css`
        border-bottom-left-radius: lg;
        border-bottom-right-radius: lg;
      `,
      right: noDefault,
      bottom: css`
        border-top-left-radius: lg;
        border-top-right-radius: lg;
      `,
      left: noDefault,
    },
  },
  field: {
    label: css`
      font-size: md;
    `,
    message: css`
      font-size: sm;
      &:empty {
        display: none;
      }
    `,
    error: css`
      font-size: sm;
    `,
  },
  formError: {
    default: noDefault,
    item: noDefault,
  },
  heading: {
    default: css`
      color: #002776;
    `,
  },
  icon: {
    default: noDefault,
    variants: {
      primary: css`
        color: primary;
      `,
      secondary: css`
        color: secondary;
      `,
      tertiary: noDefault,
    },
  },
  iconButton: {
    default: noDefault,
    variants: {
      primary: css`
        color: primary;
        border: md;
        border-color: border;
        border-radius: rounded;
        background-color: #fff;
        &:focus {
        }
        &:hover {
          border-color: #005aa4;
        }
        &:hover:not(:focus) {
          box-shadow: 0 1px 2px 1px rgb(0 0 0 / 20%);
        }
        &:active:not(:focus) {
          box-shadow: inset 0 1px 1px 0 rgb(0 0 0 / 25%);
        }
      `,
      secondary: css`
        /**
          * Secondary icons are mend to be used as part of a larger
          * component, e.g. DateInput, Combobox.
          * The icon buttons usually lack expressive focus styles.
          */
        color: secondary;
        svg,
        &::after {
          color: secondary;
        }
        &:hover::before,
        &:hover::after {
          background-color: surface-hover;
        }
      `,
      tertiary: noDefault,
    },
    sizes: {
      sm: css`
        height: 1.125rem;
        width: 1.125rem;
      `,
      md: css`
        height: 2rem;
        width: 2rem;
      `,
      lg: css`
        height: 2.5rem;
        width: 2.5rem;
      `,
    },
  },
  infoBubble: {
    variants: {
      default: css`
        height: 1.75rem;
        width: 1.75rem;
        color: primary;
        border: md;
        border-color: border;
        border-radius: rounded;
        background-color: #fff;
        &:focus {
        }
        &:hover {
          border-color: #005aa4;
        }
        &:hover:not(:focus) {
          box-shadow: 0 1px 2px 1px rgb(0 0 0 / 20%);
        }
        &:active:not(:focus) {
          box-shadow: inset 0 1px 1px 0 rgb(0 0 0 / 25%);
        }
      `,
      label: css`
        margin-left: 2;
        color: secondary;
        border-radius: rounded;
        svg,
        &::after {
          color: secondary;
        }
        &:hover {
          background-color: surface-hover;
        }
      `,
    },
  },
  interactiveList: {
    /**
     * It's recommended to keep these styles similar to `table`
     */
    default: css`
      [data-row-group] {
        line-height: 1.125rem;
        font-size: md;
      }
      [data-row] {
      }
      [data-row]:focus,
      [data-row]:hover {
        background-color: surface-hover;
      }
      [data-row]:active,
      [data-row][aria-checked='true'] {
        background-color: selected;
      }
      [data-row] {
        [data-cell] {
          border-top: sm;
          border-bottom: sm;
          border-color: border;
        }
      }
      [data-row] {
        [data-cell] {
          border-top: sm;
          border-bottom: sm;
          border-color: border;
        }
      }
      [data-row]:last-of-type:not(:only-of-type) {
        [data-cell] {
          border-bottom: none;
        }
      }
      [data-row]:first-of-type:not(:only-of-type) {
        [data-cell] {
          border-top: none;
        }
      }
      [data-heading-cell] {
        background-color: #e6eff6;
        vertical-align: middle;
        font-weight: 500;
        color: #002776;
      }

      [data-heading-cell]:first-of-type {
        border-top-left-radius: sm;
        border-bottom-left-radius: sm;
      }
      [data-heading-cell]:last-of-type {
        border-top-right-radius: sm;
        border-bottom-right-radius: sm;
      }
      [data-heading-cell],
      [data-cell] {
        padding: 0.675rem;
        padding-bottom: 0.6125rem;
        height: 2.75rem;
      }
    `,
    variants: {
      primary: noDefault,
      secondary: noDefault,
      tertiary: noDefault,
    },
  },
  loading: {
    /** dot background-color */
    backgroundColor: 'primary',
  },
  menu: {
    default: noDefault,
    item: {
      default: css`
        white-space: nowrap;
        text-align: left;
      `,
      highlighted: css`
        background-color: surface-hover;
      `,
    },
    separator: noDefault,
    anchor: noDefault,
    link: noDefault,
    checkbox: {
      default: noDefault,
      checked: noDefault,
    },
    radio: {
      default: noDefault,
      checked: noDefault,
    },
  },
  menuBar: {
    default: css`
      border: sm;
      border-color: border;
      padding: 2;
      height: 2.5rem;
    `,
    barItem: css`
      color: text;
      padding: 0 3;
      display: flex;
      align-items: center;
      &:focus,
      &[aria-expanded='true'] {
        background-color: selected;
        color: inverse-text;
      }
      & svg {
        font-size: 125%;
        margin-right: 6px;
      }
    `,
  },
  notification: {
    default: css`
      border-radius: md;
      svg[role='img']:not(button svg) {
        padding: 2;
        background-color: white;
        border-radius: rounded;
        width: 2rem;
        height: 2rem;
        align-self: center;
      }
    `,
    info: css`
      background-color: surface-5;
    `,
    success: css`
      background-color: surface-4;
    `,
    error: css`
      background-color: surface-6;
    `,
    warning: css`
      background-color: surface-3;
    `,
    question: css`
      background-color: surface-5;
    `,
  },
  /**
   * Panel is shared by Select, Combobox, DateInput, Menu
   * Popover, InfoBubble,
   */
  panel: css`
    color: text;
    background-color: surface-1;
    border-radius: md;
    box-shadow: menu;
    border: sm;
    border-color: border;
  `,
  popover: {
    panel: noDefault,
    arrow: noDefault,
    actions: noDefault,
  },
  progressBar: {
    trail: css`
      border-radius: md;
      background-color: #c8d1da;
    `,
    stroke: css`
      background-color: primary;
    `,
  },
  radio: {
    default: css`
      border: md;
      border-color: selected;

      :disabled {
        border-color: disabled;
      }

      & + [role='presentation'] {
        /**
          * This makes sure the element with role="presentation"
          * is visible when hovered
          */
        background-color: selected;
      }
    `,
    checked: css`
      /** This targets the element with role="presentation" */
      background-color: selected;
    `,
    container: css`
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      padding: 5px;
    `,
  },
  rating: {
    default: noDefault,
    leafs: noDefault,
    bars: noDefault,
    stars: noDefault,
  },
  search: {
    default: noDefault,
  },
  searchDialog: {
    dialog: noDefault,
    header: css`
      background-color: surface-1;
    `,
    closeButton: noDefault,
    footer: css`
      background-color: #eef4f9;
    `,
    kbd: css`
      margin: 0 0.1rem;
      padding: 0.1rem 0.6rem;
      border-radius: sm;
      border: sm;
      border-color: border;
      color: text;
      line-height: 1.4;
      font-family: body;
      font-size: 0.625rem;
      font-weight: bold;
      display: inline-block;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2),
        inset 0px 0px 0px 1px rgba(255, 255, 255, 0.1);
      background-color: surface-1;
      line-height: 0.625rem;
    `,
  },
  segment: {
    wrapper: css`
      border: sm;
      border-color: primary;

      &:not(:first-of-type):not(:last-of-type) {
        border-right-width: 0;
        border-left-width: 0;
      }
    `,
    default: css`
      color: primary;
      border-radius: none;
    `,
    checked: css`
      background-color: primary;
      fill: white;
      color: white;
    `,
  },
  select: {
    default: css`
      // Fix until https://jira.oslomarketsolutions.no/browse/DES-296 is resolved
      input[type='button'] {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    `,
    listBox: noDefault,
    item: {
      default: noDefault,
      focus: css`
        background-color: #ebf6f5;
      `,
      highlighted: css`
        background-color: surface-hover;
      `,
      selected: css`
        color: selected;
        > svg {
          color: selected;
        }
      `,
      selectedDisabled: css`
        background-color: #0071eb33;
      `,
    },
    groupHeader: css`
      font-size: sm;
      color: text-label;
      border-top: sm;
      border-color: border;
      font-weight: bold;
      text-transform: uppercase;
    `,
  },
  separator: noDefault,
  slider: {
    default: noDefault,
    sizes: {
      sm: css`
        height: 0.125rem;
      `,
      md: css`
        height: 0.25rem;
      `,
      lg: css`
        height: 0.5rem;
      `,
    },
    track: css`
      background-color: border;
      &.slider__track-0,
      &.slider__range-track-1 {
        background-color: secondary;
      }
    `,
    thumb: css`
      background-color: white;
      border-radius: rounded;
      box-shadow: md;
    `,
    value: css`
      color: white;
      background-color: secondary;
      border-radius: md;
    `,
  },
  spinner: {
    /** track color */
    borderColor: 'border',
    /** segment color */
    borderTopColor: 'secondary',
  },
  stepper: {
    default: css``,
    item: css``,
  },
  switch: {
    default: css`
      border: sm;
      border-color: border;
      border-radius: rounded;
      height: 1.25rem;
      width: 2.5rem;
      & + [data-indicator] {
        width: 1.25rem;
        height: 1.25rem;
        left: 0;
        top: 0;
        border: sm;
        border-radius: rounded;
        background-color: primary;
        border-color: primary;
      }
      &:focus {
        box-shadow: focus;
      }
    `,
    checked: css`
      background-color: primary;
      & + [data-indicator] {
        transform: translateX(1.4rem);
        background-color: white;
      }
    `,
  },
  table: {
    default: css`
      thead tr th {
        background-color: #e6eff6;
        vertical-align: middle;
      }

      thead tr th:first-of-type {
        border-top-left-radius: sm;
        border-bottom-left-radius: sm;
      }

      thead tr th:last-of-type {
        border-top-right-radius: sm;
        border-bottom-right-radius: sm;
      }

      thead th,
      tbody th {
        font-weight: 500;
        color: #002776;
      }

      tbody {
        line-height: 1.125rem;
        font-size: md;
      }

      tbody tr:not([data-is-expanded]):hover {
        background-color: surface-hover;
      }

      tbody tr[aria-selected='true'] {
        background-color: #eef4f9;
      }

      tbody tr:first-of-type th,
      tbody tr:first-of-type td {
        border-bottom: none;
        border-top: none;
      }

      tbody tr:last-of-type th,
      tbody tr:last-of-type td {
        border-bottom: none;
      }

      /** align tickers in symbol cells on safari */
      a p[data-name] {
        left: 0;
      }
    `,
    variants: {
      normal: css`
        tbody th,
        tbody td {
          border-top: sm;
          border-bottom: sm;
          border-color: border;
        }
      `,
      keyValue: css`
        tbody th,
        tbody td {
          border-top: sm;
          border-bottom: sm;
          border-color: border;
        }
      `,
      flipped: {
        'th, td': {
          borderLeft: 'sm',
          borderColor: 'border',
        },
      },
    },
    densities: {
      dense: css`
        tbody td,
        tbody th {
          padding: 0.455rem;
          padding-bottom: 0.4125rem;
          height: 2rem;
        }
      `,
      normal: css`
        thead th {
          padding: 0.675rem;
          height: 2.5rem;
        }

        tfoot td,
        tfoot th,
        tbody td,
        tbody th {
          padding: 0.675rem;
          padding-bottom: 0.6125rem;
          height: 2.75rem;
          /** This td has an irregular shape */
          &[data-type='symbol'] {
            padding-bottom: 0.925rem;
          }
        }
      `,
      spacious: css`
        td,
        th {
          padding: 1.175rem;
          padding-bottom: 1.1125rem;
          height: 3.5rem;
        }
      `,
    },
    // FIXME: This is isn't consistent across browsers, get Gerrit?
    rowSelected: css`
      border: none;
      border-radius: sm;
      outline: 2px solid;
      outline-color: highlight;
      background-color: #dff1f9;
    `,
    rowExpanded: noDefault,
    stickyHeader: {
      zIndex: 1,
      backgroundColor: '#FFFFFFEE',
    },
    toggleRowsButton: css`
      display: table-cell;
      vertical-align: middle;
      color: primary;
      height: 2.5rem;
      border-bottom: sm;
      border-color: border;
      &:hover,
      &:focus {
        background-color: surface-hover;
        text-decoration: underline;
      }
    `,
    cells: {
      sortableHeader: css`
        &:focus,
        &:hover {
          color: secondary;
        }
      `,
      link: css`
        &:hover,
        &:focus {
          color: link;
          text-decoration: underline;
        }
      `,
      trend: {
        pos: {
          color: 'text-pos',
        },
        neg: {
          color: 'text-neg',
        },
      },
      flash: {
        pos: css`
          background-color: pos;
          color: inverse-text;
        `,
        neg: css`
          background-color: neg;
          color: inverse-text;
        `,
        flat: css`
          background-color: flat;
          color: text;
        `,
      },
    },
  },
  tabs: {
    default: noDefault,
    variants: {
      primary: css`
        font-size: md;
        color: text;
        border-right: sm;
        border-right-color: border;
        background-color: #eef4f9;
        padding: 3 4 3;
        border-top-right-radius: lg;

        &:last-of-type {
          border-right-width: 0;
        }
        &:focus {
          box-shadow: none;
          /*box-shadow: focus-inset;*/
        }
        &:hover {
          background-color: surface-hover;
        }
        &[aria-selected='true'] {
          background-color: white;
          color: secondary;
          font-weight: bold;
          border-bottom-color: transparent;

          &::before {
            content: '';
            position: absolute;
            width: 16px;
            height: 8px;
            background-color: transparent;
            top: 0;
            left: 0;
            transform: translateX(-100%) translateY(-1px);
            border-top-right-radius: 8px;
            box-shadow: 8px 0 0 0 #fff; /* This is where the magic happens! */
            border-right: sm;
            border-top: sm;
            border-right-color: border;
            border-top-color: border;
          }
        }
      `,
      secondary: css`
        padding: 2 4;
        border-radius: rounded;
        border: md;
        color: text-light;
        border-color: border;
        box-shadow: 0 1px 2px 1px rgb(0 0 0 / 5%);
        & + & {
          margin-left: 2;
        }
        &:focus {
        }
        &:hover {
          color: tertiary;
          border-color: tertiary;
        }
        &[aria-selected='true'] {
          color: white;
          background-color: tertiary;
          border-color: tertiary;
        }
      `,
    },
  },
  text: {
    default: noDefault,
    variants: {
      heading1: css`
        font-size: 4xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading2: css`
        font-size: 3xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading3: css`
        font-size: 2xl;
        font-weight: light;
        line-height: 1.25;
      `,
      heading4: css`
        font-size: xl;
        font-weight: normal;
        line-height: 1.25;
      `,
      heading5: css`
        font-size: lg;
        font-weight: bold;
        line-height: 1.25;
      `,
      heading6: css`
        font-size: md;
        font-weight: bold;
        line-height: 1.25;
      `,
      body1: css`
        font-size: sm;
        line-height: 1rem;
      `,
      body2: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      body3: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      subtitle1: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      subtitle2: css`
        font-size: lg;
        line-height: 1.5rem;
      `,
      button: css`
        font-size: md;
        line-height: 1.125rem;
      `,
      caption: noDefault,
      overline: noDefault,
      label: noDefault,
      message: noDefault,
      error: noDefault,
    },
    highlighted: css`
      color: selected;
      font-weight: bold;
    `,
  },
  textInput: {
    input: css`
      /** avoid weird iOS zoom issues */
      font-size: lg;

      @media only screen and (min-width: 26.25em) {
        font-size: md;
      }
    `,
    wrapper: {
      /**
       * The wrapping element should reflect
       * the state of the input.
       * */
      default: css`
        height: 2.75rem;
        border: md;
        border-color: border;
        border-radius: md;
        background-color: input;
        box-shadow: 0 1px 2px 0 #f4f4f4 inset;
      `,
      hover: css`
        border-color: contrast-border;
      `,
      focus: css`
        background-color: input-focus;
        border-color: border-focus;
        box-shadow: focus;
      `,
      error: css`
        border-color: border-error;
      `,
      focusError: css`
        background-color: input-focus;
        border-color: border-error;
        box-shadow: focus-error;
      `,
      disabled: css`
        color: text-disabled;
        background-color: #f2f4f6;
        cursor: not-allowed;
      `,
    },
  },
  tooltip: {
    default: css`
      color: inverse-text;
      font-size: sm;
      background-color: #333;
      opacity: 0.95;
      padding: 1 2;
      border-radius: md;
    `,
  },
};

const defaults = {
  fonts: {
    heading: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    label: `-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  shadows: {
    sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
    none: 'none',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.25',
    short: '1.375',
    base: '1.5',
    tall: '1.625',
    taller: '2',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  fontSizes: {
    xs: '0.625rem',
    sm: '0.75rem',
    md: '0.875rem',
    lg: '1.125rem',
    xl: '1.5rem',
    '2xl': '1.875rem',
    '3xl': '2.25rem',
    '4xl': '3rem',
  },
  space: {
    px: '1px',
    0: '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '32': '8rem',
    '40': '10rem',
    '48': '12rem',
    '56': '14rem',
    '64': '16rem',
  },
  breakpoints: {
    0: 0,
    1: '27.5em',
    2: '52.5em',
    3: '80em',
    4: '120em',
    5: '160em',
    base: 0,
    sm: '27.5em',
    md: '52.5em',
    lg: '80em',
    xl: '120em',
    '2xl': '160em',
  },
  zIndices: {
    hide: -1,
    auto: 'auto',
    '0': 0,
    '10': 10,
    '20': 20,
    '30': 30,
    '40': 40,
    '50': 50,
    '60': 60,
    '70': 70,
    '80': 80,
  },
  radii: {
    none: '0',
    sm: '0.125rem',
    md: '0.25rem',
    lg: '0.5rem',
    rounded: '9999px',
  },
  duration: {
    instant: '150ms',
    normal: '300ms',
    long: '450ms',
  },
  timing: {
    spring: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',
    lineair: 'lineair',
  },
  opacity: {
    '0': '0',
    '20%': '0.2',
    '40%': '0.4',
    '60%': '0.6',
    '80%': '0.8',
    '100%': '1',
  },
  borders: {
    none: 0,
    sm: '1px solid',
    md: '2px solid',
    lg: '4px solid',
  },
  borderWidths: {
    none: 0,
    sm: '1px',
    md: '2px',
    lg: '4px',
  },
};

export const theme = {
  ...defaults,
  fonts: {
    ...defaults.fonts,
    ...fonts,
  },
  shadows: {
    ...defaults.shadows,
    ...shadows,
  },
  colors,
  ...components,
};

export function Sparebank1Global() {
  return (
    <>
      <BoxSizingStyles />
      {isIOSWebview() ? (
        <Global
          styles={css`
            :root {
              margin-top: env(safe-area-inset-top) !important;
              background-color: #fff;
            }
            [data-dialog-ref] {
              top: env(safe-area-inset-top);
              right: env(safe-area-inset-right);
              bottom: env(safe-area-inset-bottom);
              left: env(safe-area-inset-left);
            }
            [data-drawer][data-placement='right'] {
              top: env(safe-area-inset-top) !important;
              max-height: calc(100vh - env(safe-area-inset-top)) !important;
            }
            [data-drawer][data-placement='sheet'] {
              max-height: calc(95vh - env(safe-area-inset-top)) !important;
            }
          `}
        />
      ) : null}
      <Global
        styles={css`
          body {
            margin: 0;
            padding: 0;
          }
          .sb-show-main.sb-main-padded {
            margin: 0;
            padding: 0;
          }

          @font-face {
            font-family: Sparebank1Title;
            font-weight: 400;
            font-display: swap;
            src: local('Sparebank1Title-Regular'),
              url('fonts/SpareBank1Title-Regular-Web.woff2') format('woff2'),
              url('fonts/SpareBank1Title-Regular-Web.woff') format('woff');
          }
          @font-face {
            font-family: Sparebank1Title;
            font-weight: 500;
            font-display: swap;
            src: local('Sparebank1Title-Medium'),
              url('fonts/SpareBank1Title-Medium-Web.woff2') format('woff2'),
              url('fonts/SpareBank1Title-Medium-Web.woff') format('woff');
          }

          @font-face {
            font-family: Sparebank1;
            font-weight: 400;
            font-display: swap;
            src: local('Sparebank1-Regular'),
              url('fonts/SpareBank1-Regular-Web.woff2') format('woff2'),
              url('fonts/SpareBank1-Regular-Web.woff') format('woff');
          }

          @font-face {
            font-family: Sparebank1;
            font-weight: 500;
            font-display: swap;
            src: local('Sparebank1-Medium'),
              url('fonts/SpareBank1-Medium-Web.woff2') format('woff2'),
              url('fonts/SpareBank1-Medium-Web.woff') format('woff');
          }

          body {
            margin: 0;
            padding: 0;
            font-family: Sparebank1, -apple-system, BlinkMacSystemFont,
              'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
              'Helvetica Neue', sans-serif;
            font-variant-numeric: tabular-nums;
          }
        `}
      />
    </>
  );
}
