import { WATCHLIST_URL } from 'constants/Jaws';
import { del, FetchError, get, post } from 'utils/fetch';

/**
 * This is also fetchable using jaws,
 * but when I tried it, it seemed to be missing i ticker, so I implemented it using the watchlist-endpoint instead
 * Here's the spec I tried:
 * {
    type: 'secure/watchlist',
    watchlistName: listName,
    columns: 'ITEM, SECTOR, ITEM_SECTOR, LONG_NAME',
    initiatorComponent: 'useWatchlist',
  }
 * 
 */

export type Watchlist = Array<string>;

export const getWatchlist = async (userId: string, watchlistName: string) => {
  const response = await get(`${WATCHLIST_URL}/${userId}/${watchlistName}`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av watchlist feilet',
      response,
    });
  }
  const data = await response.json();
  return data as Watchlist;
};

type MutateWatchlistVariables = {
  userId: string;
  itemSector: string;
  watchlistName?: string;
};

export const addToWatchlist = async ({
  userId,
  watchlistName = 'watchlist',
  itemSector,
}: MutateWatchlistVariables) => {
  const response = await post(
    `${WATCHLIST_URL}/${userId}/${watchlistName}/${itemSector}`,
    '',
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Registrering av watchlist-instrument feilet',
      response,
    });
  }

  return response;
};

export const removeFromWatchlist = async ({
  userId,
  watchlistName = 'watchlist',
  itemSector,
}: MutateWatchlistVariables) => {
  const response = await del(
    `${WATCHLIST_URL}/${userId}/${watchlistName}/${itemSector}`,
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Fjerning av watchlist-instrument feilet',
      response,
    });
  }

  return response;
};
