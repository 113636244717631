import moment from 'moment';
import { TERMS_URL } from 'constants/Jaws';
import { get, post } from './fetch';

export const gdprTermsUpdated = moment('2018-06-21').format(
  'YYYY-MM-DDTHH:mmZ',
);

export function getTerms(sub, type) {
  return get(`${TERMS_URL}/users/${sub}types/${type}`);
}

export function createTermsTimestamp(sub, type) {
  return post(`${TERMS_URL}/users/${sub}/types/${type}`);
}
