import { News as OMSNews } from '@oms/components-news';
import { Card, CardProps } from 'components/Card';
import { useItemSector } from 'context/ItemSectorContext';
import { getItemFromItemSector } from '@oms/utils';
export interface NewsProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const News = ({ itemSector, ...props }: NewsProps) => {
  const itemSectorValue = useItemSector(itemSector);
  const ITEM = getItemFromItemSector(itemSectorValue);
  return (
    <Card title="Børsmeldinger" {...props}>
      <OMSNews
        itemSector={itemSectorValue}
        newsItemLink="/news/article/{REFERENCEID}"
        archiveLink={`/news/company/${ITEM}`}
        spec={{ filter: `COMPANY_TICKER==s${ITEM}&ATTRIBTN==s{OBI,OBIOAM}` }}
        showMoreButton
      />
    </Card>
  );
};

export const MarketNews = (props: Omit<NewsProps, 'itemSector'>) => {
  return (
    <Card title="Børsmeldinger" showMoreButton {...props}>
      <OMSNews showMoreButton />
    </Card>
  );
};
