import { AppShellProvider } from '@oms/ui-app-shell';
import { SheetProvider } from '@oms/ui-sheet';
import { AppShell } from 'components/AppShell';
import { Header } from 'components/Header';
import { Actions } from 'components/Actions';
import { useSheets } from 'components/Sheets';
import { HelpSheet } from 'components/Help';
import { MarginDefinitionsSheet } from 'components/MarginDefinitions';
import { WebTVSheet } from 'components/WebTV';
import { Footer } from 'components/Footer';
import WebTVPlayer from 'components/WebTV/WebTVPlayer';
import { OrderUpdateNotifier } from 'components/OrderUpdateNotifier';
import useScrollHandling from './useScrollHandling';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { SideBarTradeSheet } from '../Trade/SideBarTradeSheet';
export interface AppProps {
  children: React.ReactNode;
}

export function App(props: AppProps) {
  const sheets = useSheets();
  useScrollHandling();

  return (
    <AppShellProvider>
      <SheetProvider sheets={sheets}>
        <AppShell
          navigationBar={
            <ErrorBoundary>
              <Header actions={<Actions />} />
            </ErrorBoundary>
          }
          footer={<Footer />}
        >
          <ErrorBoundary>{props.children}</ErrorBoundary>
          <SideBarTradeSheet />
          <HelpSheet />
          <WebTVSheet />
          <ErrorBoundary>
            <MarginDefinitionsSheet />
          </ErrorBoundary>
          <ErrorBoundary>
            <WebTVPlayer />
          </ErrorBoundary>
          <ErrorBoundary>
            <OrderUpdateNotifier />
          </ErrorBoundary>
        </AppShell>
      </SheetProvider>
    </AppShellProvider>
  );
}
