import moment from 'moment';
import { Notification } from '@oms/ui-notification';
import { Button } from '@oms/ui-button';
import { Box } from '@oms/ui-box';

type TradeSuccessMessageProps = {
  orderType: 'SELL' | 'BUY';
  isHidden: boolean;
  isEdit: boolean;
  ticker: string;
  expirationDate: string;
  onClose?: () => void;
  toMyOrders?: () => void;
};

export const TradeSuccessMessage = ({
  orderType,
  isHidden,
  isEdit,
  ticker,
  expirationDate,
  onClose,
  toMyOrders,
}: TradeSuccessMessageProps) => {
  if (isHidden) return null;
  const order = orderType === 'SELL' ? 'Salgsordre' : 'Kjøpsordre';

  return (
    <Notification
      title={isEdit ? 'Endring sendt!' : `${order} sendt!`}
      status={'success'}
      onClose={onClose}
    >
      Du har lagt inn en {order.toLowerCase()} i {ticker}. Ordren er gyldig frem
      til børsen stenger den {moment(expirationDate).format('DD-MM-YYYY')}.
      <br />
      <Box orientation={'horizontal'} mt={4} flexWrap={'wrap'}>
        <Button mt={2} mr={2} variant="primary" onClick={toMyOrders}>
          Gå til aktive ordre
        </Button>
        <Button mt={2} variant="secondary" onClick={onClose}>
          Lukk
        </Button>
      </Box>
    </Notification>
  );
};
