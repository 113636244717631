import { Field, Fieldset } from '@oms/ui-field';
import Condition from 'components/FormComponents/Condition';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required, requiredCheckboxGroup } from '../validate';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

export const EmploymentStatus = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <Stack gap={4}>
      <Heading>Hva er ditt arbeidsforhold?</Heading>
      <Fieldset
        as={CheckboxGroup}
        name="kycEmploymentStatus"
        legend="Velg det som passer best"
        validate={requiredCheckboxGroup}
      >
        <Field
          as={Checkbox}
          label="Fast eller midlertidig ansatt"
          value="PERMANENT_OR_TEMP_EMPLOYEE"
          validate={required}
        />
        <Field
          as={Checkbox}
          label="Eier eget foretak"
          value="OWN_MY_OWN_COMPANY"
          validate={required}
        />
        <Field
          as={Checkbox}
          label="Student"
          value="STUDENT"
          validate={required}
        />
        <Field
          as={Checkbox}
          label="Pensjonist"
          value="PENSIONER"
          validate={required}
        />
        <Field
          as={Checkbox}
          label="Mottaker av midler fra NAV (Dagpenger, trygd el.lign.)"
          value="RECEIVING_FUNDS_FROM_NAV"
          validate={required}
        />
        <Field
          as={Checkbox}
          label="Førstegangstjeneste"
          value="FIRST_TIME_SERVICE"
          validate={required}
        />
        <Field
          as={Checkbox}
          label="Arbeidsøkende"
          value="JOB_SEEKER"
          validate={required}
        />
        <Field as={Checkbox} label="Annet" value="OTHER" validate={required} />
        <Condition when="kycEmploymentStatus" includes="OTHER">
          <PdfTextInput
            name="kycExplainEmploymentStatus"
            label="Beskriv ditt arbeidsforhold"
            isPDFRender={isPDFRender}
          />
        </Condition>
      </Fieldset>
      <Condition when="kycEmploymentStatus" is="OTHER">
        <PdfTextInput
          name="kycExplainEmploymentStatus"
          label="Beskriv ditt arbeidsforhold"
          isPDFRender={isPDFRender}
        />
      </Condition>
    </Stack>
  );
};

export default EmploymentStatus;
