import Immutable from 'immutable';

import { DOMAIN_URL, COMPONENTS_URL } from 'constants/Jaws';
import { get } from './fetch';

function isBoolString(val) {
  return typeof val === 'string' && (val === 'false' || val === 'true');
}

function convertStringsToBools(val) {
  if (isBoolString(val)) {
    return JSON.parse(val);
  }

  return val;
}

export function normalizeDomainServiceResponse(response) {
  return Immutable.fromJS(response)
    .first()
    .map(convertStringsToBools);
}

export function getByItemSector(itemSector) {
  return get(DOMAIN_URL + `?itemSector=${itemSector}`)
    .then(res => res.json())
    .then(result => normalizeDomainServiceResponse(result));
}

export function getItemAndSectorFromItemSector(itemSector) {
  if (itemSector == null) {
    return null;
  }
  const ix = itemSector.lastIndexOf('.');
  if (ix < 1) {
    return [itemSector, null];
  }
  return [itemSector.substr(0, ix), itemSector.substr(ix + 1)];
}

export async function getAskTradeableInstruments() {
  const result = await get(
    `${COMPONENTS_URL}?source=feed.sben.quotes.ASK&columns=ITEM_SECTOR`,
  );
  if (!result.ok) throw await result.text();
  const { rows } = await result.json();

  return rows.map(item => item.values.ITEM_SECTOR);
}
