import { Children } from 'react';

/**
 * Compliance in SB1 would like proof that the user has seen the text,
 * so this method extracts the actual text that was shown to the user from
 * the react children property
 */
export default function buildString(element) {
  if (!element) return '';

  const elements = Children.map(element.props.children, child =>
    typeof child === 'string' ? child : buildString(child),
  );

  return elements.join(' ');
}
