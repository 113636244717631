import React from 'react';
import { TextMarquee } from '@oms/ui-marquee';
import { formatNumber } from '@oms/utils';
import styled from '@xstyled/emotion';
import {
  useTickers,
  useTrendColor,
  tickerMapDefault,
  getLastId,
  getChangeId,
  Entry,
} from './utils';

const Container = styled.div`
  background-color: secondary;
  position: relative;
  height: 64px;

  > div {
    font-family: system-ui;
    width: 100%;
    position: absolute;
    left: 0;
    padding-top: 21px;
    color: white;
  }

  > div > div > span[data-speed] {
    box-sizing: border-box;
    backface-visibility: hidden;
    display: grid !important;
    grid-auto-flow: column;
    gap: 16px;
    grid-gap: 16px;
  }

  > div > div > span[data-speed] > span {
    box-sizing: border-box;
    display: inline-flex;
    gap: 8px;
    width: 240px;
  }
`;

const JawsUpdater = () => {
  // This component uses jaws a bit differently.
  // The jaws hooks internal state updates and animated components
  // are mismatch leading to some serious performance issues.
  // By using effects and mutating the DOM directly we avoid the Marquee component
  // from re-rendering.
  const jaws = useTickers({});
  const getColor = useTrendColor();

  React.useLayoutEffect(() => {
    const handler = (entry: Entry) => {
      const id = entry?.ITEM_SECTOR;
      const lastNode = document.getElementById(getLastId(id));
      const changeNode = document.getElementById(getChangeId(id));
      const last = formatNumber(entry?.LAST);
      const change = `(${formatNumber(entry?.CHANGE_PCT)}%)`;

      if (lastNode && last !== lastNode.innerText) {
        lastNode.innerText = last;
      }
      if (changeNode && change !== changeNode.innerText) {
        changeNode.style.color = getColor(entry?.CHANGE_PCT);
        changeNode.innerText = change;
      }
    };

    Object.values(jaws.entries).forEach(handler);
    return () => {};
  }, [jaws, getColor]);

  return null;
};

export function Marquee() {
  return (
    <Container>
      <div>
        <TextMarquee
          // This value needs to be calculated manually.
          // Example: y × item width + (y - 1) × gap width
          // 8 × 240px + (8 - 1) × 16px
          contentWidth="2032px"
          speed={25}
        >
          {Object.entries(tickerMapDefault).map(([itemSector, name]) => (
            <span key={itemSector}>
              {name}
              <span>
                <span id={getLastId(itemSector)}>-</span>
                <span
                  style={{ fontSize: 'small', marginLeft: '2px' }}
                  id={getChangeId(itemSector)}
                >
                  -
                </span>
              </span>
            </span>
          ))}
        </TextMarquee>
        <JawsUpdater />
      </div>
    </Container>
  );
}
//
