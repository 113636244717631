import React from 'react';
import { OrderDepth as OMSOrderDepth } from '@oms/components-order-depth';
import { Card, CardProps } from 'components/Card';
import { useItemSector } from 'context/ItemSectorContext';
import useUser from 'utils/hooks/useUser';
import { Button } from '@oms/ui-button';
import { Icon, light } from '@oms/ui-icon';
import { Link } from '@oms/ui-link';
import { Text } from '@oms/ui-text';
import { Stack } from '@oms/ui-stack';
import styled from '@xstyled/emotion';

const OMSOrderDepthStyled = styled(OMSOrderDepth)`
  tbody tr td:nth-child(2) {
    font-weight: bold;
  }
  tbody tr td:nth-child(5) {
    font-weight: bold;
  }
`;

const OMSOrderDepthStyledLvl2 = styled(OMSOrderDepth)`
  tbody tr:first-child td:nth-child(3) {
    font-weight: bold;
  }
  tbody tr:first-child td:nth-child(6) {
    font-weight: bold;
  }
`;

export interface OrderDepthProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const OrderDepth = ({ itemSector, ...props }: OrderDepthProps) => {
  const {
    data: { hasLevel2 },
  } = useUser();
  const itemSectorValue = useItemSector(itemSector);
  return (
    <Card
      title="Ordredybde"
      gridColumn={hasLevel2 ? { sm: 'span 2 / span 1' } : undefined}
      {...props}
    >
      {hasLevel2 ? (
        <OMSOrderDepthStyledLvl2
          itemSector={itemSectorValue}
          hasLevel2={hasLevel2}
        />
      ) : (
        <OMSOrderDepthStyled
          itemSector={itemSectorValue}
          hasLevel2={hasLevel2}
        />
      )}
      {!hasLevel2 && (
        <Stack alignment="center" mb={4}>
          <Text>Klikk her for å oppgradere til fullstendig ordredybde</Text>
          <Button
            as={Link}
            variant="link"
            size="sm"
            rightElement={<Icon icon={light.faCaretRight} />}
            to="/#RealtimeSubscriptionBox"
          >
            Oppgrader streaming
          </Button>
        </Stack>
      )}
    </Card>
  );
};
