import { Notification, NotificationProps } from '@oms/ui-notification';
import { useToast } from '@oms/ui-toast';

export type NotifyOptions = {
  status: NotificationProps['status'];
  title: string;
  children?: React.ReactChild;
  duration?: number;
};

export const useNotification = () => {
  const toast = useToast();
  return ({ status, title, children, duration = 8000 }: NotifyOptions) =>
    toast(
      toastProps => (
        <Notification title={title} status={status} {...toastProps}>
          {children}
        </Notification>
      ),
      { duration, position: 'bottom-left' },
    );
};
