const H_TEST_GROUPS = [
  { name: 'Børsnoterte aksjer', type: 'EQUITIES_EXCHANGE_NOTED' },
  { name: 'Egenkapitalbevis', type: 'PCC_EXCHANGE_NOTED' },
];

export const EXCLUDED_H_TEST_GROUPS = [
  { name: 'Unoterte aksjer', type: 'EQUITIES_NOT_EXCHANGE_NOTED' },
  { name: 'Tegningsretter', type: 'RIGHTS_EXCHANGE_NOTED' },
  { name: 'Euronext Growth', type: 'MERK_EXCHANGE_NOTED' },
  { name: 'ETN-er', type: 'ETN' },
];

export const RETAKEABLE_TESTS = [
  'RIGHTS_EXCHANGE_NOTED',
  'MERK_EXCHANGE_NOTED',
  'ETN',
];

/**
 * Instruments that previously had tests but have been removed must be
 * forcefully disabled as there will be uses that have failed the test before it
 * was removed and thus still see the warning message
 */
export const FORCE_HIDE_SECTORS = ['MERK'];

export const ALL_PRODUCT_GROUPS = [...H_TEST_GROUPS, ...EXCLUDED_H_TEST_GROUPS];

export const SHOW_WARNING_ONCE = H_TEST_GROUPS.map(group => group.type);
export const productTypes = ALL_PRODUCT_GROUPS.map(group => group.type);

/**
 * Maps h-test type to knowlege test ID for fetching questions.
 *
 * TODO: Map this somewhere else, many-to-many between
 * entity_h_test_products and knowledge_tests?
 */
export const hTestKnowlegeTestMap = {
  ETN: 1,
  MERK_EXCHANGE_NOTED: 2,
  RIGHTS_EXCHANGE_NOTED: 3,
};

export default H_TEST_GROUPS;
