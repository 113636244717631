import {
  Dialog,
  DialogContent,
  DialogDisclosure,
  DialogHeader,
  useDialog,
} from '@oms/ui-dialog';
import { Button } from '@oms/ui-button';
import AssessmentTestForm from './AssessmentTestForm';

import { Notification } from '@oms/ui-notification';

type Props = {
  testId: number;
};
export function AssessmentTestDialog({ testId }: Props) {
  const dialog = useDialog();

  return (
    <>
      <Notification status="warning" title="Kunnskapstest">
        Du må gjennomføre en kunnskapstest for å få lov til å handle dette
        instrumentet.
      </Notification>
      <DialogDisclosure {...dialog} as={Button}>
        Ta testen her
      </DialogDisclosure>
      <Dialog {...dialog} aria-label="Velg konto" hideOnClickOutside={false}>
        <DialogHeader>Kunnskapstest</DialogHeader>
        <DialogContent>
          <AssessmentTestForm id={testId} onClose={dialog.hide} />
        </DialogContent>
      </Dialog>
    </>
  );
}
