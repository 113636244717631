import { USER_CATEGORIES_URL } from 'constants/Jaws';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, post } from 'utils/fetch';
import useUser from './useUser';

export enum CustomerCategory {
  /** Ikke Børsnotering og emisjoner */
  NOTING = '51',
  /** Ikke Markeds/Aksjeoppdateringer */
  UPDATES = '52',
  /** Ikke Seminar */
  SEMINAR = '53',
}
type CustomerCategories = {
  customerCategory: CustomerCategory;
  description: string;
  serviceFeeNo?: any;
  feeDescription: string;
  feePrice: number;
};

const fetchCustomerCategories = async (customerId: string) => {
  const response = await get(`${USER_CATEGORIES_URL}/${customerId}`);
  if (response.status === 404) return [];
  if (!response.ok) {
    throw new Error(await response.text());
  }
  const { data }: { data: CustomerCategories[] } = await response.json();
  return data?.map(category => category.customerCategory) || [];
};

export const useCustomerCategories = () => {
  const {
    data: { clientId = '' },
  } = useUser();
  return useQuery(
    ['customerCategories', clientId],
    () => fetchCustomerCategories(clientId),
    { enabled: !!clientId },
  );
};

const editCustomerCategories = async (
  newCustomerCategories: CustomerCategory[],
  customerId: string,
) => {
  if (!customerId) throw new Error('Mangler kunde-id');
  const payload = JSON.stringify({
    customerId,
    customerCategories: newCustomerCategories,
  });
  const response = await post(`${USER_CATEGORIES_URL}/${customerId}`, payload);
  if (!response.ok) {
    throw new Error(await response.text());
  }
  return response;
};

export const useEditCustomerCategories = () => {
  const queryClient = useQueryClient();
  const {
    data: { clientId = '' },
  } = useUser();

  return useMutation(
    (newCustomerCategories: CustomerCategory[]) =>
      editCustomerCategories(newCustomerCategories, clientId),
    { onSuccess: () => queryClient.invalidateQueries('customerCategories') },
  );
};
