import {
  useInstrumentChart,
  UseInstrumentChartArguments,
} from '@oms/components-instrument-chart';

type UseGraphDataArgs = {
  itemSector: string;
  selection?: UseInstrumentChartArguments['selection'];
};
export const useGraphData = ({
  itemSector,
  selection = 'SELECTION_ONE_MONTH',
}: UseGraphDataArgs) => {
  const { main, ...state } = useInstrumentChart({
    itemSector,
    labels: {
      candlestick: 'N/A',
      dividends: 'N/A',
      news: 'N/A',
      transactions: 'N/A',
      turnover: 'N/A',
      volume: 'N/A',
    },
    selection,
  });

  const series = {
    name: 'Hovedindeksen',
    data: main.series?.data,
  };

  return { data: { series }, ...state };
};

export default useGraphData;
