import { Sheet, SheetHeader } from '@oms/ui-sheet';
import { sheetId } from 'components/Sheets';
import MarginDefinitions from './MarginDefinitions';

export function MarginDefinitionsSheet() {
  return (
    <Sheet sheetId={sheetId.marginDefinitions} aria-label="Kredittdefinisjoner">
      <SheetHeader>Kredittdefinisjoner</SheetHeader>
      <MarginDefinitions />
    </Sheet>
  );
}
