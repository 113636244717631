import background from 'images/cityscape.jpg';
import appThumb from 'images/appThumb.png';
import logo from 'images/logo.svg';

import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';

import { SIGNING_URL } from 'constants/Jaws';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { post } from 'utils/fetch';
import { getSigningStatus } from 'utils/hooks/useExpediencyTest/helpers';
import useUser from 'utils/hooks/useUser';
import { Notification } from '@oms/ui-notification';
import { Loading } from '@oms/ui-loading';

import * as S from './styles';
import { useState } from 'react';
import LoadingPage from 'components/TEMP/LoadingPage';
import { useCheckExpediencyTest } from 'utils/hooks/useExpediencyTest';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { Account } from 'types/auth';

export const SignicatSuccessPage: React.FC = () => {
  const [shouldPollSigningStatus, setShouldPollSigningStatus] = useState(true);
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const queryClient = useQueryClient();
  const history = useHistory();
  const {
    data: { selectedAccount },
  } = useSelectedAccount();
  const {
    data: { me, userId = '', accounts },
    isLoading,
  } = useUser();
  const { refetch, isCorpoAccount } = useCheckExpediencyTest(
    userId,
    selectedAccount as Account,
    accounts,
  );
  const principalTypeCustomer = me?.principals.some(
    (principal: unknown) => (principal as any)?.principalType === 'customer',
  );

  const principalTypeSigning = me?.principals.some(
    (principal: unknown) => (principal as any)?.principalType === 'signing',
  );

  const { isLoading: isPolling } = useQuery(
    'signing-status',
    () => getSigningStatus(userId, isCorpoAccount, selectedAccount?.id),
    {
      refetchInterval: shouldPollSigningStatus && 1000,
      enabled: !isLoading && !!userId,
      onSuccess: async signingStatus => {
        if (principalTypeCustomer) {
          return history.push('/');
        }
        if (signingStatus?.status === 'COMPLETED' && principalTypeSigning) {
          setShouldPollSigningStatus(false);
          await refetch();
          post(SIGNING_URL)
            .then(res => {
              setIsLoggingIn(false);
              if (!res.ok) {
                throw Error(res.statusText);
              }

              if (res.redirected) {
                return window.location.assign(res.url);
              }

              queryClient
                .refetchQueries('expediency-flag')
                .then(() => history.push('/'));
            })
            .catch(e => {
              console.error('Signing failed? Redirecting to error page', e);
              history.push('/login?message=GENERIC_ERROR');
            });
        }
      },
    },
  );

  if (isLoading) return <LoadingPage />;

  return (
    <Stack width="100%" height="100%" pb={12}>
      <Box maxHeight="400px" overflow="hidden" height="calc(100% - 210px)">
        <S.Logo src={logo} alt="Sparebank1 Logo" />
        <S.Background
          src={background}
          alt="Skyskraper med himmel og skyer som bakgrunn"
        />
      </Box>
      <S.Box>
        <Stack orientation="horizontal" height="70px" alignment="center">
          <S.AppThumb src={appThumb} alt="Mobil med teksten KR" />
          <Stack gap={0}>
            <Text variant="heading4">Aksjehandel</Text>
          </Stack>
        </Stack>

        {(isPolling || isLoggingIn) && (
          <Notification title="Suksess!" status="success">
            <Loading>
              Du har nå fullført signeringsløpet. Logger deg inn..
            </Loading>
          </Notification>
        )}
      </S.Box>
    </Stack>
  );
};
