import { Form } from 'react-final-form';
/* import { FORM_ERROR } from 'final-form';  */
import { Field } from '@oms/ui-field';
import { Notification } from '@oms/ui-notification';
import { useUser } from 'utils/hooks/useUser';
import { TextInput } from '@oms/ui-text-input';
import { Textarea } from '@oms/ui-textarea';
import { Button } from '@oms/ui-button';
import { Stack } from '@oms/ui-stack';
import { Card, CardProps } from 'components/Card';
import { useContactForm } from './useContactForm';
import { useCustomerInformation } from 'utils/hooks/useUser';

export interface ContactFormProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
}

const validate = (values: any) => {
  const errors: { [key: string]: any } = {};

  if (!values?.title) {
    errors.title = 'Påkrevet';
  }
  if (!values?.inquiry) {
    errors.inquiry = 'Påkrevet';
  }
  return errors;
};

type FormProps = {
  fullName: string;
  inquiry: string;
  probrokerId: string;
  title: string;
};

export const ContactForm = (props: ContactFormProps) => {
  return (
    <Card title="Kontakt oss" {...props}>
      <ContactFormComponent />
    </Card>
  );
};

export const ContactFormComponent = () => {
  const { data } = useUser();
  const { fullName, email } = useCustomerInformation();

  const probrokerId = data?.customerData?.masterAccount?.id;
  const { mutateAsync: postForm } = useContactForm();

  const submit = (values: FormProps) => {
    const payload = JSON.stringify({
      ...values,
      probrokerId: probrokerId,
      fullName: fullName,
      email: email,
    });
    return postForm(payload);
  };

  return (
    <Form
      initialValues={{
        title: undefined,
        inquiry: undefined,
      }}
      onSubmit={submit}
      validate={validate}
    >
      {({
        handleSubmit,
        submitSucceeded,
        hasValidationErrors,
        submitError,
      }) => {
        return (
          <Stack
            as="form"
            gap={4}
            onSubmit={handleSubmit}
            mx="auto"
            p={2}
            width="100%"
          >
            <Field as={TextInput} name="title" label="Tittel" placeholder="" />
            <Field
              name="inquiry"
              as={Textarea}
              label="Henvendelse"
              placeholder=""
              style={{ minHeight: '8rem' }}
            />
            {submitSucceeded && (
              <Notification
                status="success"
                title="Innsending vellykket"
                children={null}
              />
            )}
            {submitError && (
              <Notification
                status="error"
                title="Innsending mislykket"
                children={null}
              />
            )}
            <Button
              title="Send"
              disabled={hasValidationErrors || submitSucceeded}
              onClick={() => {
                handleSubmit();
              }}
            >
              Send
            </Button>
          </Stack>
        );
      }}
    </Form>
  );
};
