import React from 'react';
import { InstrumentChart as OMSInstrumentChart } from '@oms/components-instrument-chart';
import { useItemSector } from 'context/ItemSectorContext';
import {
  useTimeSeriesSelect,
  TimeSeriesSelect,
} from 'components/TimeSeriesSelect';
import { Box } from '@oms/ui-box';
import { Spinner } from '@oms/ui-spinner';
export interface InstrumentChartProps {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

const options = {
  chart: {
    height: 380,
  },
};

export const InstrumentChart = ({ itemSector }: InstrumentChartProps) => {
  const itemSectorValue = useItemSector(itemSector);
  const timeSeries = useTimeSeriesSelect({
    initialSelected: 'SELECTION_INTRADAY',
  });
  return (
    <React.Suspense
      fallback={
        <Box>
          <Spinner size="lg" />
        </Box>
      }
    >
      <Box display="flex" flexDirection="column">
        <OMSInstrumentChart
          seriesColors={['#03236B']}
          itemSector={itemSectorValue}
          selection={timeSeries.selected}
          options={options}
          enableStreaming={true}
        />
        <Box
          display="inline-block"
          ml={{ base: 'auto' }}
          mr={{ base: 'auto', md: 'initial' }}
          mb={{ base: 4, md: 'initial' }}
        >
          <TimeSeriesSelect {...timeSeries} />
        </Box>
      </Box>
    </React.Suspense>
  );
};
