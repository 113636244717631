import { useQuery } from 'react-query';
import { post } from 'utils/fetch';
import { ORDERS_URL } from 'constants/Jaws';
import { normalizeNumber } from 'utils/form';
import { useUser } from 'utils/hooks/useUser';

export const getCalculateCommisson = async (
  userId: string,
  accountId: string,
  payload: any,
) => {
  const response = await post(
    `${ORDERS_URL}/${userId}/orders/calculateCommission?accountId=${accountId}`,
    JSON.stringify(payload),
  );
  if (!response.ok) {
    return 'calculated commisson failed';
  }
  return await response.json();
};

export const useCalculateCommisson = (
  accountId: string,
  values: any,
  hidden: boolean,
) => {
  const {
    tradeType,
    orderType = 'normal',
    ticker,
    exchange,
    volume,
    limit,
    useOpenVolume,
    openVolume,
    useTriggerCriterion,
    expirationDate,
  } = values;

  const normalizedLimit = normalizeNumber(limit);
  const normalizedVolume = normalizeNumber(volume);
  const normalizedOpenVolume = normalizeNumber(openVolume);
  const {
    data: { userId = '' },
  } = useUser();

  const body = {
    data: {
      accountId,
      tradeType,
      ticker,
      exchange,
      expirationDate,
      volume: normalizedVolume,
      useOpenVolume,
      openVolume: normalizedOpenVolume,
      useTriggerCriterion,
      limit: normalizedLimit,
      orderType,
    },
  };

  return useQuery(
    ['commisson', { userId, accountId, body }],
    () => getCalculateCommisson(userId, accountId, body),
    {
      suspense: true,
      enabled: hidden === false && !!limit && !!volume,
    },
  );
};
