import { useWebTVContext } from 'context/WebTVContext';
import { useWebTVLinks } from 'utils/hooks/useWebTV';
import { Stack } from '@oms/ui-stack';
import WebTVCard from './WebTVCard';

const WebTVList = () => {
  const { data: webTvLinks } = useWebTVLinks();
  const { webTVUrl, setWebTVUrl } = useWebTVContext();
  return (
    <Stack m={2} gap={2}>
      {webTvLinks?.map(link => {
        const isActive = webTVUrl === link.url;
        return (
          <WebTVCard
            key={link.url}
            webTvLink={link}
            isActive={isActive}
            toggleActive={() => {
              if (isActive) return setWebTVUrl(undefined);
              setWebTVUrl(link.url);
            }}
          />
        );
      })}
    </Stack>
  );
};

export default WebTVList;
