import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import useUser from '../useUser';
import { addToWatchlist, getWatchlist, removeFromWatchlist } from './helpers';
import { useNotification } from '../useNotification';
import { Button } from '@oms/ui-button';
import { ONE_MINUTE } from 'constants/timeInMs';

export const useWatchlist = (listName = 'watchlist') => {
  const {
    data: { userId = '' },
  } = useUser();

  const { data, ...queryState } = useQuery(
    ['watchlists', listName],
    () => getWatchlist(userId, listName),
    {
      enabled: !!userId,
      staleTime: ONE_MINUTE,
    },
  );

  const checkIsInWatchlist = (itemSector: string) =>
    data?.some(item => item === itemSector);

  return { data, checkIsInWatchlist, ...queryState };
};

export const useAddToWatchlist = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const notify = useNotification();

  return useMutation(addToWatchlist, {
    onSuccess: (res, vars) => {
      notify({
        status: 'success',
        title: `${vars.itemSector} ble lagt til i min liste!`,
        duration: 4000,
        children: (
          // TODO: ui-link wouldn't work, why?
          <Button
            variant="link"
            onClick={() =>
              history.push({
                pathname: '/market',
                hash: 'myWatchlist',
              })
            }
          >
            Gå til min liste
          </Button>
        ),
      });
      queryClient.invalidateQueries('watchlists');
    },
  });
};

export const useRemoveFromWatchlist = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const notify = useNotification();

  return useMutation(removeFromWatchlist, {
    onSuccess: (res, vars) => {
      notify({
        status: 'success',
        title: `${vars.itemSector} ble fjernet fra min liste!`,
        duration: 2000,
        children: (
          // TODO: ui-link wouldn't work, why?
          <Button
            variant="link"
            onClick={() =>
              history.push({
                pathname: '/market',
                hash: 'myWatchlist',
              })
            }
          >
            Gå til min liste
          </Button>
        ),
      });
      queryClient.invalidateQueries('watchlists');
    },
  });
};

export default useWatchlist;
