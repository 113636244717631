import { Component, ErrorInfo } from 'react';
import human from 'assets/human.svg';
import voidImg from 'assets/void.svg';
import { Stack } from '@oms/ui-stack';
import { Notification } from '@oms/ui-notification';
import { Box } from '@oms/ui-box';

export class ExceptionCatcher extends Component {
  state: { exception?: Error; errorInfo?: ErrorInfo } = {
    exception: undefined,
    errorInfo: undefined,
  };
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      exception: error,
      errorInfo,
    });
  }

  // static getDerivedStateFromError(error: Error) {
  //   return {};
  // }

  render() {
    const { children } = this.props;
    const { exception } = this.state;

    if (exception) {
      return (
        <Box center height="100%" pt={12}>
          <Stack gap={2} height="100%" alignment="center">
            <img
              alt="Illustrasjon av menneske"
              src={Math.random() > 0.5 ? human : voidImg}
              style={{
                maxHeight: '240px',
                height: '100%',
                objectFit: 'contain',
              }}
            />
            <Notification
              title="Oisann, her skjedde det noe galt"
              maxWidth="30rem"
            >
              Under lasting av denne siden skjedde det noe uventet. Vi beklager
              eventuelle ulemper dette medfører.
            </Notification>

            <code
              style={{
                border: '1px solid red',
                padding: '24px',
                borderRadius: '8px',
              }}
              role="alert"
            >
              {exception.message}
            </code>
            <a style={{ textAlign: 'center', marginTop: '24px' }} href="/">
              Tilbake til forsiden
            </a>
          </Stack>
        </Box>
      );
    }

    return <>{children}</>;
  }
}

export default ExceptionCatcher;
