import { RenderField } from '@oms/ui-field';
import { Switch } from '@oms/ui-switch';
import { Stack } from '@oms/ui-stack';
import { Heading } from '@oms/ui-heading';
import {
  CustomerCategory,
  useCustomerCategories,
  useEditCustomerCategories,
} from 'utils/hooks/useUserCategories';
import { useCallback } from 'react';

const EmailAlerts = ({ hideLabels = false }) => {
  const { data: customerCategories = [] } = useCustomerCategories();
  const { mutate: setNewCustomerCategories } = useEditCustomerCategories();

  const toggleCustomerCategory = useCallback(
    (category: CustomerCategory) => {
      if (customerCategories.includes(category)) {
        return setNewCustomerCategories(
          customerCategories.filter(c => c !== category),
        );
      }
      setNewCustomerCategories([...customerCategories, category]);
    },
    [customerCategories, setNewCustomerCategories],
  );

  return (
    <Stack gap={2}>
      <Heading as="h6" variant="heading6">
        Epost-varslinger
      </Heading>
      <RenderField
        name="noting"
        label="Børsnotering og Emisjoner"
        as={Switch}
        stateLabel={
          hideLabels
            ? undefined
            : {
                on: 'på',
                off: 'av',
              }
        }
        checked={!customerCategories?.includes(CustomerCategory.NOTING)}
        onChange={(event: React.FormEvent<HTMLInputElement>) =>
          toggleCustomerCategory(CustomerCategory.NOTING)
        }
      />
      <RenderField
        name="updates"
        label="Markeds/Aksjeoppdateringer"
        as={Switch}
        stateLabel={
          hideLabels
            ? undefined
            : {
                on: 'på',
                off: 'av',
              }
        }
        checked={!customerCategories?.includes(CustomerCategory.UPDATES)}
        onChange={(event: React.FormEvent<HTMLInputElement>) =>
          toggleCustomerCategory(CustomerCategory.UPDATES)
        }
      />
      <RenderField
        name="seminar"
        label="Seminar"
        as={Switch}
        stateLabel={
          hideLabels
            ? undefined
            : {
                on: 'på',
                off: 'av',
              }
        }
        checked={!customerCategories?.includes(CustomerCategory.SEMINAR)}
        onChange={(event: React.FormEvent<HTMLInputElement>) =>
          toggleCustomerCategory(CustomerCategory.SEMINAR)
        }
      />
    </Stack>
  );
};

export default EmailAlerts;
