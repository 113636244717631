import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { Textarea } from '@oms/ui-textarea';

import WrappedText from 'components/FormComponents/WrappedlText';

type Props = {
  name: string;
  label: string;
  autoComplete?: string;
  isPDFRender: boolean;
  asTextArea?: boolean;
};

export const required = (value?: any) => {
  return value ? undefined : 'Påkrevet';
};

const PdfTextInput = ({
  name,
  label,
  autoComplete,
  isPDFRender,
  asTextArea,
}: Props) => {
  if (isPDFRender !== true) {
    if (asTextArea) {
      return (
        <span style={{ minWidth: '600px' }}>
          <Field
            name={`${name}`}
            as={Textarea}
            style={{
              minWidth: '600px',
              minHeight: '100px',
              resize: 'none',
            }}
            label={label}
            validate={required}
          />
        </span>
      );
    }
    return (
      <div>
        <br />
        <Field
          name={`${name}`}
          as={TextInput}
          label={label}
          autoComplete={autoComplete}
          style={{ width: '100%' }}
          width="100%"
          validate={required}
        />
      </div>
    );
  }

  return <WrappedText label={label} name={`${name}`} show={true} />;
};

export default PdfTextInput;
