import { useQuery } from 'react-query';
import { Form, FormProps } from 'react-final-form';
import { Stack } from '@oms/ui-stack';
import { Button } from '@oms/ui-button';
import { useHistory } from 'react-router-dom';
import { FileUpload } from './FileUpload';
import { useUser } from 'utils/hooks/useUser';
import { Heading } from '@oms/ui-heading';
import { MARGIN_URL } from 'constants/Jaws';
import { get, put } from 'utils/fetch';

export interface MarginApplyProps extends Partial<FormProps> {
  children?: JSX.Element | JSX.Element[];
}

const buildDataset = ({ acceptTerms, ...values }: any) => {
  const instruments = values.instruments?.filter(
    (instrument: any) =>
      instrument.instrument && instrument.volume && instrument.price,
  );

  return {
    ...values,
    instruments,
  };
};

export const MarginApplyUpdate = ({ children, ...props }: MarginApplyProps) => {
  const {
    data: { userId = '' },
  } = useUser();
  const history = useHistory();
  const { data } = useQuery(
    ['applications', userId],
    () =>
      get(`${MARGIN_URL}/${userId}/applications`).then(async response => {
        const result = await response.json();
        return {
          applications: result.data,
          id: result.data[0]?.id,
        };
      }),
    {
      enabled: !!userId,
    },
  );

  const onUpdate = (values: any) => {
    const payload = buildDataset({
      ...values,
    });

    return put(
      `${MARGIN_URL}/${userId}/applications/${data?.id}`,
      JSON.stringify({ data: payload }),
    ).then(
      () => history.push('/'),
      error => {
        throw new Error(error);
      },
    );
  };

  return (
    <Form onSubmit={onUpdate} {...props} noValidate>
      {({ handleSubmit, values }) => {
        return (
          <>
            <Stack
              as="form"
              onSubmit={handleSubmit}
              maxWidth="80rem"
              width="100%"
              mx="auto"
              my={8}
              gap={12}
            >
              <Heading variant="heading4">
                Vi har mottatt din søknad og du hører tilbake fra oss snart
              </Heading>
              <FileUpload title="Vil du laste opp ytterligere filer?" />
              <Button type="submit">Oppdater søknad</Button>
            </Stack>
          </>
        );
      }}
    </Form>
  );
};
