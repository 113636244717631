import { Button } from '@oms/ui-button';
import { Heading } from '@oms/ui-heading';
import { Link } from '@oms/ui-link';
import { Stack } from '@oms/ui-stack';
import { useEnsureItemSector } from 'pages/Instrument/useEnsureItemSector';
import { createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';

const context = createContext<string | undefined>(undefined);
const { Provider } = context;

export const useItemSector = (arg?: string) => {
  const value = useContext(context);
  if (typeof arg === 'string') {
    return arg;
  } else {
    if (!value) console.warn('No itemSector provided');
    return (value as unknown) as string;
  }
};

interface InstrumentProviderProps {
  itemSector: string;
  children: React.ReactNode;
}

export function ItemSectorProvider({
  itemSector,
  children,
}: InstrumentProviderProps) {
  const { data: exists } = useEnsureItemSector(itemSector);
  const history = useHistory();

  if (!exists) {
    return (
      <Stack alignment="center" p={4}>
        <Heading as="h1" variant="heading3">
          Mangler instrument!
        </Heading>
        <Button variant="secondary" onClick={history.goBack}>
          Tilbake
        </Button>
        <Link to="/market">Aksjehandel</Link>
      </Stack>
    );
  }

  return <Provider value={itemSector}>{children}</Provider>;
}
