import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useUser from './useUser';

export const useAccounts = () => {
  const {
    data: { customerData },
    ...userQueryState
  } = useUser();

  const accounts = customerData?.accounts || [];

  // TODO: Wrap or not? Consistency is key, so change elsewhere as well!
  const data = {
    accounts,
  };

  return {
    data,
    ...userQueryState,
  };
};

export const useAccount = (id: string) => {
  const {
    data: { accounts },
    ...queryState
  } = useAccounts();

  const account = accounts.find(account => account.id === id);

  // TODO: Wrap or not? Consistency is key, so change elsewhere as well!
  const data = {
    account,
  };

  return {
    data,
    ...queryState,
  };
};

export const useMasterAccount = () => {
  const {
    data: { customerData },
    ...userQueryState
  } = useUser();

  const data = {
    masterAccount: customerData?.masterAccount,
  };

  return {
    data,
    ...userQueryState,
  };
};

export const useSelectedAccount = () => {
  const { selectedAccountId } = useSelectedAccountId();
  const {
    data: { account },
    ...accountQueryState
  } = useAccount(selectedAccountId);

  const data = {
    selectedAccount: account,
  };

  return { data, ...accountQueryState };
};
