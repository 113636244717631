import { Notification } from '@oms/ui-notification';
import { Table, Tbody, Td, Th, Thead, Tr } from '@oms/ui-table';
import { decimalPlaces } from '@oms/utils';
import { TradeButton } from 'components/Buttons';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useMarginInfo from 'utils/hooks/useMarginInfo';
import { useInstrumentMarginInfo } from './useInstrumentMarginInfo';

interface Props {
  itemSector: string;
  showTradeButton?: boolean;
}

export const MarginInfo = ({ itemSector, showTradeButton = false }: Props) => {
  const { selectedAccountId } = useSelectedAccountId();
  const {
    last = 0,
    longName,
    marginRate,
    tradableSum,
    tradableVol,
  } = useInstrumentMarginInfo({ itemSector });
  const { data } = useMarginInfo({ accountId: selectedAccountId });
  const { collateral = 0 } = data || {};

  if (!marginRate) {
    const [item] = itemSector.split('.');
    return (
      <Notification mt={4} status="info" title={`Kan ikke handles på margin`}>
        {longName || item} mangler margingrad og kan derfor ikke handles på
        margin.
      </Notification>
    );
  }

  return (
    <>
      {!last ? (
        <Notification mt={4} title="Mangler siste kurs">
          Kan ikke vise all informasjon
        </Notification>
      ) : (
        <Notification title="">
          Med nåværende kurs kan du handle <strong>{longName}</strong> for{' '}
          {tradableSum} ({tradableVol} stk)
        </Notification>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th type="number">Siste</Th>
            <Th type="percent">Margingrad</Th>
            <Th type="numberWithUnit">Din sikkerhet</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td type="number" fractionDigits={decimalPlaces(last)}>
              {last}
            </Td>
            <Td type="percent">{marginRate}</Td>
            <Td
              type="numberWithUnit"
              suffix=" kr"
              fractionDigits={decimalPlaces(collateral)}
            >
              {collateral}
            </Td>
          </Tr>
        </Tbody>
      </Table>
      {showTradeButton && <TradeButton itemSector={itemSector} />}
    </>
  );
};
