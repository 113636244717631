import { useQuotelist } from '@oms/components-quotelist';
import { Scroll } from '@oms/ui-scroll';
import {
  Table,
  Th,
  MemoizedTd as Td,
  Tr,
  Thead,
  Tbody,
  ThSortable,
} from '@oms/ui-table';
import { useSortable } from 'utils/hooks/useSortable';
import { useMedia } from '@oms/ui-media';
import {
  LoadingState,
  QuotelistInstrument,
  getSpec,
  getSorted,
  getMatched,
} from './helpers';

const MILLION = 1_000_000;

export const QuoteList = ({ market, filter }: any) => {
  const { isMobile } = useMedia();
  const { handleSort, isReversed, sortByKey } = useSortable('CHANGE_PCT', true);
  const { data, jawsFetching } = useQuotelist({ spec: getSpec(market) });

  if (jawsFetching) return <LoadingState />;

  const rawJawsData: QuotelistInstrument[] = Object.values(data);

  const instruments =
    filter !== ''
      ? getMatched(rawJawsData, filter)
      : getSorted(rawJawsData, sortByKey, isReversed);

  return (
    <Scroll flex={1} showFade={false} maxHeight="30rem">
      <Table layout="auto">
        <Thead>
          <Tr>
            <ThSortable
              isSorted={sortByKey === 'ITEM'}
              isSortedDesc={isReversed}
              onClick={handleSort('ITEM')}
              type="text"
            >
              Selskap
            </ThSortable>
            <Th type="number">Siste</Th>
            <ThSortable
              isSorted={sortByKey === 'CHANGE_PCT'}
              onClick={handleSort('CHANGE_PCT')}
              isSortedDesc={isReversed}
              type="percent"
            >
              +/-%
            </ThSortable>
            <Th type="number">Kjøp</Th>
            <Th type="number">Selg</Th>
            {!isMobile && (
              <Th type="numberWithUnit" align="right">
                Omsetning
              </Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {instruments.map(
            ({
              ITEM_SECTOR,
              ASK,
              BID,
              CHANGE_PCT,
              ITEM,
              LAST,
              LONG_NAME,
              TIME,
              TURNOVER,
            }: QuotelistInstrument) => {
              return (
                <Tr key={ITEM_SECTOR}>
                  <Td
                    type="symbol"
                    ticker={ITEM}
                    name={LONG_NAME}
                    linkTo={`/instrument/${ITEM_SECTOR}`}
                  />

                  <Td type="number" timestamp={TIME} flash>
                    {LAST}
                  </Td>
                  <Td type="percent" timestamp={TIME} flash trend>
                    {CHANGE_PCT}
                  </Td>
                  <Td type="number">{BID}</Td>
                  <Td type="number">{ASK}</Td>
                  {!isMobile && (
                    <Td
                      type={
                        (TURNOVER || 0) >= MILLION
                          ? 'numberWithUnit'
                          : 'integer'
                      }
                    >
                      {TURNOVER}
                    </Td>
                  )}
                </Tr>
              );
            },
          )}
        </Tbody>
      </Table>
    </Scroll>
  );
};
