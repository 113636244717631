import { Button, ButtonProps } from '@oms/ui-button';
import { Link } from '@oms/ui-link';
import { BankidIkon } from '@sb1/ffe-icons-react';
import styled from '@xstyled/emotion';

const StyledButton: typeof Button = styled(Button)`
  display: inline-flex;
  align-items: center;
  color: primary;
  border: none;
  border-radius: sm;
  background: white;
  box-shadow: menu;

  span {
    margin-top: 0.375rem;
    fill: primary;
  }

  &:hover {
    background-color: primary;
    color: white;

    span {
      fill: white;
    }
  }
`;

export const BankIDButton = ({
  children = 'Logg inn for handel',
  ...props
}: Omit<ButtonProps, 'children'>) => {
  return (
    <StyledButton
      leftElement={<BankidIkon height={18} />}
      alignItems="center"
      as={Link}
      to="/login"
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default BankIDButton;
