import styled from '@xstyled/emotion';
import {
  Dialog,
  DialogActions,
  DialogDisclosure,
  DialogHeader,
  useDialog,
} from '@oms/ui-dialog';
import { Text } from '@oms/ui-text';
import { Badge, BadgeProps } from '@oms/ui-badge';
import { Box } from '@oms/ui-box';
import { AccountSwitcher } from './AccountSwitcher';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { Button } from '@oms/ui-button';

const Clickable = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  margin: 0;
  text-align: left;
  cursor: pointer;
  border-radius: md;
  &:hover,
  &:focus {
    background-color: surface-hover;
  }
  &:focus {
    box-shadow: focus;
  }
`;

export function AccountSwitcherDialog({
  includeActions = true,
  showBorder = false,
}: {
  includeActions?: boolean;
  showBorder?: boolean;
}) {
  const dialog = useDialog();
  const {
    data: { selectedAccount },
  } = useSelectedAccount();

  const AccountDetails = styled.div`
    display: flex;
    color: var(--xstyled-colors-secondary, #002776);
    align-items: center;
    margin-right: 0.5rem;
    font-size: 0.875rem;
  `;

  const isAsk = selectedAccount?.ask;
  const headerBadge = {
    variant: isAsk ? 'ask' : 'default',
    borderWidth: showBorder ? 'sm' : 'undefined',
    borderStyle: showBorder ? 'solid' : 'undefined',
  } as BadgeProps;
  return (
    <>
      <DialogDisclosure {...dialog} as={Clickable}>
        <Text variant="body1" color="secondary">
          Konto
        </Text>
        <AccountDetails>
          {selectedAccount?.id}
          <Box as="span" pl={1}>
            <Badge {...headerBadge}>{isAsk ? 'ASK' : 'AH'}</Badge>
          </Box>
        </AccountDetails>
      </DialogDisclosure>
      <Dialog {...dialog} aria-label="Velg konto">
        <DialogHeader>Velg konto</DialogHeader>
        <AccountSwitcher />
        {includeActions ? (
          <DialogActions>
            <Button onClick={dialog.hide}>Gå til</Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </>
  );
}
