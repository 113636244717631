import { useMemo } from 'react';
import { IndexMapComponent, IndexMapFetcher } from '@oms/components-index-map';
import { Box } from '@oms/ui-box';
import styled from '@xstyled/emotion';
import { colors } from 'theme';
import { useDebounce } from 'use-debounce';

const itemSectors = [
  'OSEBX.OSE',
  'OMXN40.OMX',
  'I:DAX.GER',
  'I:N100.EUR',
  'I:COMP.NAS',
  'I:DJI.DJ',
  'I:HSI.HSI',
  'NI225.OSA',
  'C:PBROUSDBR\\SP.IDCENE',
  'C:PWTIUSDBR\\SP.IDCENE',
] as const;

const Container = styled(Box)`
  &::after {
    position: absolute;
    content: '';
    height: 32px;
    width: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(transparent 0%, white 100%);
  }
`;

const options = {
  chart: {
    backgroundColor: '#fff',
    height: (9 / 16) * 75 + '%',
  },
  plotOptions: {
    series: { color: 'white' },
    map: { nullColor: '#D3D3EA', borderColor: colors.secondary },
  },
};

const Debounced = ({ values }: any) => {
  const [debouncedValues] = useDebounce(values, 1000, { maxWait: 1500 });

  return useMemo(
    () => (
      <IndexMapComponent initialOptions={options} values={debouncedValues} />
    ),
    [debouncedValues],
  );
};

export const IndexMap = () => {
  return (
    <Container
      position="relative"
      maxHeight="400px"
      maxWidth="min(98vw, 80rem)"
      mx="auto"
      height="32vw"
      width="100%"
      overflow="hidden"
    >
      <IndexMapFetcher itemSectors={itemSectors as any}>
        {({ data }) => <Debounced key="debouncedIndexMap" values={data} />}
      </IndexMapFetcher>
    </Container>
  );
};
