import { Caption, Table, Tbody, Td, Th, Thead, Tr } from '@oms/ui-table';
import { Text } from '@oms/ui-text';
import { List, ListItem } from '@oms/ui-list';
import { decimalPlaces } from '@oms/utils';
import {
  StockOrder,
  useContractNotes,
  useDeleteActiveOrder,
} from 'utils/hooks/useActiveOrders';
import { mapOrderStatus, mapOrderType } from 'utils/orders';
import { Button } from '@oms/ui-button';
import { Stack } from '@oms/ui-stack';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogHeader,
  useDialog,
  DIALOG_HEADER_ID,
} from '@oms/ui-dialog';
import Edit from 'components/Trade/Edit/Edit';
import { useEffect } from 'react';
import { useContentStateContext } from 'context/ContentStateContext';
import { Wrap, WrapItem } from '@oms/ui-wrap';
import { Box } from '@oms/ui-box';
import {
  useTableDisclosure,
  TableDisclosureButton,
  TableDisclosureContent,
  UseTableDisclosureHeadingReturn,
} from 'components/TableDisclosure';
import { Skeleton } from '@oms/ui-skeleton';
import { BankIDButton } from 'components/Buttons';

type Props = {
  accountId: string;
  userId: string;
  order: StockOrder;
  hasTradingRights: boolean;
  itemSector: string;
  last: number;
  isMobile: boolean;
} & UseTableDisclosureHeadingReturn;

/**
 * Expandable rows often lead to pretty broken up code or many components with a lot of duplication.
 * Tried to hit a middle ground here, but the result isn't as readable as I'd like
 */
export const ExpandableRow = ({
  accountId,
  userId,
  hasTradingRights,
  order,
  itemSector,
  last,
  isMobile,
  headers,
}: Props) => {
  const { setHideQuickTrade } = useContentStateContext();
  const dialog = useDialog();
  const deleteOrderDialog = useDialog();
  const disclosure = useTableDisclosure();
  const { data: contractNotes = [], isLoading } = useContractNotes({
    userId,
    accountId,
    orderId: order.ORDER_ID,
    enabled: disclosure.visible,
  });
  const {
    mutateAsync: deleteOrder,
    isLoading: isDeleting,
  } = useDeleteActiveOrder();
  const {
    ORDER_ID,
    TYPE,
    TICKER_CODE,
    STOCK_EXCHANGE_ID,
    ORDER_STATUS,
    EXPIRATION_DATE,
    TOTAL_VOLUME,
    REMAINING_VOLUME,
    LIMIT,
  } = order;

  // Hide QuickTrade as that form collides with the edit-form in modal
  useEffect(() => {
    if (dialog.visible) {
      setHideQuickTrade(true);
    } else {
      setHideQuickTrade(false);
    }
  }, [setHideQuickTrade, dialog.visible]);

  return (
    <>
      <Tr key={ORDER_ID}>
        <Td>{mapOrderType(TYPE)}</Td>
        <Td
          type="symbol"
          linkTo={`/instrument/${itemSector}`}
          ticker={TICKER_CODE}
          name={STOCK_EXCHANGE_ID}
        />
        <Td>{mapOrderStatus(ORDER_STATUS)}</Td>
        <Td type="dateTime" width="6rem">
          {EXPIRATION_DATE}
        </Td>

        {!isMobile && (
          <>
            <Td textAlign="right" type="integer">
              {TOTAL_VOLUME}
            </Td>
            <Td textAlign="right" type="integer">
              {REMAINING_VOLUME}
            </Td>
            <Td type="number" fractionDigits={decimalPlaces(LIMIT)}>
              {LIMIT}
            </Td>
            <Td type="number" fractionDigits={decimalPlaces(last)}>
              {last}
            </Td>
          </>
        )}

        <TableDisclosureButton {...disclosure} />
      </Tr>

      <TableDisclosureContent
        headers={headers}
        colSpan={isMobile ? 5 : 9}
        {...disclosure}
      >
        <Stack gap={4}>
          {isMobile && (
            <Table variant="keyValue">
              <Tr>
                <Th type="integer">Antall</Th>
                <Td textAlign="right" type="integer">
                  {TOTAL_VOLUME}
                </Td>
              </Tr>
              <Tr>
                <Th type="integer">Rest</Th>
                <Td textAlign="right" type="integer">
                  {REMAINING_VOLUME}
                </Td>
              </Tr>
              <Tr>
                <Th type="number">Pris/Limit</Th>
                <Td type="number" fractionDigits={decimalPlaces(LIMIT)}>
                  {LIMIT}
                </Td>
              </Tr>
              <Tr>
                <Th type="number">Siste</Th>
                <Td type="number" fractionDigits={decimalPlaces(last)}>
                  {last}
                </Td>
              </Tr>
            </Table>
          )}
          <Wrap
            gap={8}
            overflow="hidden"
            justifyContent={isMobile ? 'center' : undefined}
          >
            <WrapItem>
              <Stack mt={4} orientation="horizontal">
                {hasTradingRights ? (
                  <>
                    <Button
                      maxWidth="8rem"
                      onClick={() => {
                        dialog.show();
                      }}
                    >
                      Endre
                    </Button>
                    <Button
                      variant="danger"
                      maxWidth="8rem"
                      onClick={deleteOrderDialog.show}
                    >
                      Slett
                    </Button>
                  </>
                ) : (
                  <BankIDButton>Logg inn for endring</BankIDButton>
                )}
              </Stack>
            </WrapItem>
            <WrapItem flex="1 0 14rem" maxWidth="30rem">
              {order.ORDER_STATUS === 0 && (
                <Box>
                  <Text mb={2}>
                    OBS! Din ordre er ikke sendt til børs. Det kan være flere
                    årsaker til dette.
                  </Text>
                  <List variant="bullet">
                    <ListItem>
                      Limit avviker mer enn 50% fra sist omsatt og kan ikke
                      sendes til børs. Du må kontakte oss for å få ordren lagt
                      inn.
                    </ListItem>
                    <ListItem>
                      Børsen er stengt - Børsen er åpen for handel fra 09:00 -
                      16:25.
                    </ListItem>
                    <ListItem>Aksjen er suspendert/ børspause.</ListItem>
                    <ListItem>Andre årsaker - kontakt oss.</ListItem>
                  </List>
                  <Text mt={2}>
                    Ring oss på 24 14 74 80, dersom du trenger mer informasjon.
                  </Text>
                </Box>
              )}
              {!contractNotes.length ? (
                <Box height="2.75rem" center my={8}>
                  {isLoading && <Skeleton animated height="1rem" width="60%" />}
                  {!isLoading && (
                    <Text fontSize="lg">Fant ingen delhandler for ordren</Text>
                  )}
                </Box>
              ) : (
                <Table>
                  <Caption textAlign="left" pt={2} fontSize="lg">
                    Delhandler
                  </Caption>
                  <Thead>
                    <Tr>
                      <Th>Tidspunkt</Th>
                      <Th>Pris per aksje</Th>
                      <Th>Antall</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {contractNotes.map(contractNote => (
                      <Tr>
                        <Td type="dateTime">{contractNote.EXECUTED_DATE}</Td>
                        <Td
                          type="number"
                          fractionDigits={decimalPlaces(contractNote.PRICE)}
                        >
                          {contractNote.PRICE}
                        </Td>
                        <Td type="integer">{contractNote.VOLUME}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </WrapItem>
          </Wrap>
        </Stack>
      </TableDisclosureContent>

      <Dialog {...dialog} aria-labelledby={DIALOG_HEADER_ID}>
        <DialogHeader>Endre ordre</DialogHeader>
        {dialog.visible && (
          <DialogContent>
            <Edit accountId={accountId} order={order} onClose={dialog.hide} />
          </DialogContent>
        )}
      </Dialog>
      <Dialog
        {...deleteOrderDialog}
        aria-label="Bekreft sletting av ordre"
        title="Dui e"
      >
        <DialogHeader>
          Er du sikker på at du ønsker å slette ordren?
        </DialogHeader>

        <DialogActions>
          <Button variant="danger" onClick={deleteOrderDialog.hide}>
            Avbryt
          </Button>
          <Button
            isPending={isDeleting}
            onClick={() =>
              deleteOrder({
                accountId,
                userId,
                orderId: order.ORDER_ID,
              }).then(() => {
                deleteOrderDialog.hide();
                disclosure.hide();
              })
            }
          >
            Bekreft
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
