import { QueryParamProvider } from 'use-query-params';
import { Route } from 'react-router-dom';
import { OMSProvider } from './OMSContext';
import { SelectedAccountProvider } from './SelectedAccountContext';
import { WebTVProvider } from './WebTVContext';
import { Providers as AdminProviders } from './admin';
import { UserPreferencesProvider } from './UserPreferencesContext';
import { ContentStateProvider } from './ContentStateContext';

type Props = {
  OMSProviderProps?: {
    omitLink: boolean;
  };
};

// Something like this maybe?
export const Providers: React.FC<Props> = ({ children, OMSProviderProps }) => (
  <QueryParamProvider ReactRouterRoute={Route}>
    <UserPreferencesProvider>
      <OMSProvider {...OMSProviderProps}>
        <ContentStateProvider>
          <WebTVProvider>
            <SelectedAccountProvider>{children}</SelectedAccountProvider>
          </WebTVProvider>
        </ContentStateProvider>
      </OMSProvider>
    </UserPreferencesProvider>
  </QueryParamProvider>
);

export {
  OMSProvider,
  SelectedAccountProvider,
  WebTVProvider,
  UserPreferencesProvider,
  AdminProviders,
};
