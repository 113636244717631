import { Table, Thead, Tbody, Tr, Td, Th } from '@oms/ui-table';
import { TradeButton } from 'components/Buttons';
import { Icon } from '@oms/ui-icon';
import { formatNumber, formatInteger } from '@oms/utils';
import { mapRecommendation } from 'utils/recommendations';
import { useMediaQuery } from '@oms/ui-utils';
import { REPORT_URL } from 'constants/Jaws';
import { Link } from '@oms/ui-link';
import { handleBlankPdfLink } from 'utils/app';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';
import { useJaws } from '@oms/jaws-react';
import {
  useTableDisclosure,
  TableDisclosureButton,
  TableDisclosureContent,
  UseTableDisclosureHeadingReturn,
} from 'components/TableDisclosure';

type Props = {
  analysis: any;
  data: any;
} & UseTableDisclosureHeadingReturn;

export const HoldingsRow = ({ headers, analysis }: Props) => {
  const disclosure = useTableDisclosure();
  const isMobile = useMediaQuery({ maxWidth: '500px' });
  const { report, ticker, recommendation, recommendationPrice = 0 } = analysis;

  const spec = useMemo(() => {
    return {
      generators: [
        { source: 'feed.ose.quotes.EQUITIES+PCC+ETPS+RIGHTS' },
        { source: 'feed.oax.quotes.EQUITIES' },
        { source: 'feed.merk.quotes.EQUITIES' },
      ],
      columns:
        'LONG_NAME, LAST, TURNOVER, ITEM, ITEM_SECTOR, CHANGE, CHANGE_PCT, LONG_NAME, ASK_VOL, ASK, BID, BID_VOL, TIME, OPEN, HIGH, LOW',
      filter: `ITEM==s${ticker}`,
    };
  }, [ticker]);

  const { items } = useJaws(spec);

  const data = items?.toJS();
  const item = Object?.values(data)[0] as any;

  return (
    <>
      <Tr>
        <Td
          type="symbol"
          ticker={ticker}
          name={isMobile ? '' : item?.LONG_NAME}
          linkTo={`/instrument/${item?.ITEM_SECTOR}`}
        />
        <Td type="dateTime">{report.endTime}</Td>
        <Td type="text">{mapRecommendation(recommendation)}</Td>
        <Td type="text">{recommendationPrice}</Td>
        <Td>
          {report.document ? (
            <Link
              as="a"
              variant="link"
              href={`${REPORT_URL}/file/${report.document.id}`}
              onClick={handleBlankPdfLink}
            >
              <Icon icon={faFilePdf as any} color="primary" />
            </Link>
          ) : (
            'Ingen PDF vedlagt'
          )}
        </Td>
        <TableDisclosureButton {...disclosure} />
      </Tr>
      <TableDisclosureContent colSpan={6} headers={headers} {...disclosure}>
        <Table>
          <Tr>
            <Td colSpan={2} fontWeight={700}>
              {report.title}
            </Td>
          </Tr>
          <Tr>
            <Td colSpan={2}>{report.ingress}</Td>
          </Tr>
        </Table>
        <Table layout={!isMobile ? 'auto' : 'fixed'}>
          <Thead>
            <Tr>
              <Th type="number" width={isMobile ? '3rem' : ''}>
                Siste
              </Th>
              <Th type="number">Kjøp</Th>
              <Th type="text">Selg</Th>
              <Th type="text" width={isMobile ? '4rem' : ''}>
                Endring
              </Th>
              {!isMobile && <Th type="text">Endring i %</Th>}
              {!isMobile && <Th type="numberWithUnit">Omsetning</Th>}
              <Th type="dateTime">Tid</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data ? (
              <Tr>
                <Td type="text">{item?.LAST}</Td>
                <Td type="text" fontWeight="bold">
                  {item?.BID}
                </Td>
                <Td type="text" fontWeight="bold">
                  {item?.ASK}
                </Td>
                {!isMobile ? (
                  <>
                    <Td type="text">{formatNumber(item?.CHANGE, 2)}</Td>
                    <Td type="text">
                      {formatNumber(item?.CHANGE_PCT, 2) + '%'}
                    </Td>
                  </>
                ) : (
                  <Td type="text">
                    {formatNumber(item?.CHANGE, 2) +
                      '-' +
                      formatNumber(item?.CHANGE_PCT, 2) +
                      '%'}
                  </Td>
                )}

                {!isMobile && <Td>{formatInteger(item?.TURNOVER)}</Td>}
                <Td type="dateTime">{item?.TIME}</Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>

        {data ? <TradeButton itemSector={item?.ITEM_SECTOR} /> : null}
      </TableDisclosureContent>
    </>
  );
};

export default HoldingsRow;
