import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';

export const Container = styled.div`
  display: grid;
  grid-gap: 2;
  grid-auto-flow: column;
  grid-template-columns: ${(props: any) =>
    `repeat(${props?.children?.length || 2}, 2rem)`};
`;

export const Button = styled.button`
  appearance: none;
  ${th('iconButton.default')}
  ${th('iconButton.variants.primary')}
  ${th('iconButton.sizes.md')}
  border: none;
  outline: none;
  svg {
    fill: currentColor;
  }


`;

// Keep these styles aligned with components/Header/styles.ts > DrawerLink
export const DrawerButton = styled.button`
  appearance: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  text-align: left;
  border: none;
  cursor: pointer;
  font-size: 1.675rem;
  text-decoration: none;
  color: link;

  &:visited {
    color: link;
  }
`;
