import { Stack } from '@oms/ui-stack';
import styled from '@xstyled/emotion';

export const Background = styled.img`
  width: 100%;
`;
export const Logo = styled.img`
  width: 200px;
`;
export const AppThumb = styled.img`
  height: 100%;
`;

export const Box = styled(Stack)`
  background: white;
  border-radius: md;
  box-shadow: 2xl;
  margin: auto;
  margin-top: -60px !important;
  padding: 2rem;
  max-width: 30rem;
  width: 90%;
`;
