import { Box } from '@oms/ui-box';
import {
  ContentSwitcher,
  ContentSwitcherOption,
  useContentSwitcher,
} from '@oms/ui-content-switcher';
import { Stack } from '@oms/ui-stack';
import { ActiveOrdersTable } from 'components/ActiveOrdersTable';
import { SettledOrdersTable } from 'components/SettledOrdersTable';

type UseContentSwitcherStateReturn = ReturnType<typeof useContentSwitcher>;

export interface OrderSwitcherProps extends UseContentSwitcherStateReturn {
  selected: 'activeOrders' | 'settledOrders';
}

const useOrderSwitcher = () =>
  useContentSwitcher({
    orientation: 'horizontal',
    initialSelected: 'activeOrders',
  }) as OrderSwitcherProps;

const OrderSwitcher = (props: OrderSwitcherProps) => {
  return (
    <Box display="inline-block" mb={2}>
      <ContentSwitcher variant="primary" {...props}>
        <ContentSwitcherOption value="activeOrders">
          Aktive
        </ContentSwitcherOption>
        <ContentSwitcherOption value="settledOrders">
          Fullførte
        </ContentSwitcherOption>
      </ContentSwitcher>
    </Box>
  );
};

const MyOrders = () => {
  const switcher = useOrderSwitcher();

  return (
    <Stack>
      <OrderSwitcher {...switcher} />
      {switcher.selected === 'activeOrders' && <ActiveOrdersTable />}
      {switcher.selected === 'settledOrders' && <SettledOrdersTable />}
    </Stack>
  );
};

export default MyOrders;
