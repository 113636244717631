import { formatNumber as numberToFormattedString } from '@oms/utils';

export function objContainsValue(object, value) {
  return Object.keys(object).some(o => object[o] === value);
}

export function normalizeNumber(input) {
  if (!input || typeof input === 'number') {
    return input;
  }

  if (typeof input !== 'string') {
    const msg = `FormUtils.normalizeNumber: Unexpected input \`${JSON.stringify(
      input,
    )}\``;
    throw new Error(msg);
  }

  return Number(input.replace(',', '.').replace(/[^0-9.]/g, ''));
}

export function formatNumber(input) {
  if (!input) {
    return input;
  }

  let result = input;

  // Strip non-acceptable characters and ensure input is a string
  result = result.toString().replace(/[^0-9,.]/g, '');

  if (/[,.]/g.test(result)) {
    if (result.endsWith('.') || result.endsWith(',')) {
      return `${numberToFormattedString(
        result.replace(',', '.'),
        0,
        'plain',
      )},`;
    }

    // Does not end with , || .
    result = result.replace(',', '.');
    const splitInput = result.split(result.includes('.') ? '.' : ',');
    const decimals = splitInput[splitInput.length - 1];
    const decimalPlaces = decimals.length;
    return numberToFormattedString(result, decimalPlaces, 'plain');
  }

  // Does not contain , || .
  return numberToFormattedString(result, 0, 'plain');
}

export function isValidEmail(input) {
  /*eslint-disable*/
  const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  /* eslint-enable*/
  return EMAIL_REGEXP.test(input);
}
