import { Field, Fieldset } from '@oms/ui-field';
import { Stack } from '@oms/ui-stack';
import { TextInput } from '@oms/ui-text-input';
import { Select } from '@oms/ui-select';
import { countryOptions } from 'utils/locales';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { required } from '../validate';
import Condition from 'components/FormComponents/Condition';
import styled from '@emotion/styled';
import { Checkbox } from '@oms/ui-checkbox';
import { Heading } from '@oms/ui-heading';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

const Divider = styled.hr`
  margin: 1rem 0;
  color: #ccc;
`;

const Space = styled.div`
  padding: 4px 4px;
`;

export const AdditionalCustomerInformation = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  const filteredCountryOptions = countryOptions.filter(
    country => country.value !== 'NOR',
  );
  return (
    <Stack gap={4} mt={4}>
      <Heading>LEI (Legal Entity Identifier)</Heading>
      <PdfTextInput
        name="lei.lei"
        label="LEI (Legal Entity Identifier)"
        isPDFRender={isPDFRender}
      />
      <Fieldset
        as={RadioGroup}
        name="lei.companyTaxOtherCountry"
        legend="Er selskapet skattepliktig til et annet land enn Norge?"
      >
        <Field as={Radio} validate={required} label="Ja" value="true" />
        <Field as={Radio} validate={required} label="Nei" value="false" />
      </Fieldset>

      <Condition when="lei.companyTaxOtherCountry" is="true">
        <Field
          name="lei.country"
          as={Select}
          items={filteredCountryOptions}
          validate={required}
          placeholder="Velg land"
          label="Statsborgerskap"
          required
        />
        <PdfTextInput
          name="lei.taxNumber"
          label="Skattenummer"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <Space />
    </Stack>
  );
};

export default AdditionalCustomerInformation;
