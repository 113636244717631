import { useMemo } from 'react';
//@ts-ignore
import Sentry from 'utils/sentry';
import { useUser } from 'utils/hooks/useUser';
import { FormValues, Payload } from 'components/ExpediencyTestCorpo/types';
import {
  useCheckExpediencyTest,
  useDelExpediencyTest,
  useGetExpediencyTest,
  usePostExpediencyTest,
} from 'utils/hooks/useExpediencyTest';
import { postSigning } from 'utils/hooks/useExpediencyTest/helpers';
import {
  checkIsPDFRender,
  checkIsCorpoAccount,
  getFormValues,
  getPayload,
  checkAccountId,
} from 'utils/expediencyTest';
import { FORM_ERROR } from 'final-form';
import { Redirect } from 'react-router-dom';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { useLocation } from 'react-router-dom';
import { Account } from 'types/auth';
import ExpediencyTestCorpo from 'components/ExpediencyTestCorpo';

/** Previously called HTestPage */
export const ExpediencyTestPage = () => {
  const location = useLocation();

  const isPDFRender = checkIsPDFRender(location);
  const corpoAccount = checkIsCorpoAccount(location);
  const accountId = checkAccountId(location);
  const {
    data: { selectedAccount },
  } = useSelectedAccount();
  const userData = useUser();

  const {
    data: { userId = '', accounts },
  } = userData;
  const selAccount = selectedAccount as Account;
  let {
    data: expediencyTestFlag,
    isLoading,
    isCorpoAccount,
  } = useCheckExpediencyTest(userId as string, selAccount, accounts);

  if (corpoAccount) {
    isCorpoAccount = true;
  }

  if (userData?.data?.customerData?.mustTakeExpediencyTest) {
    expediencyTestFlag = true;
  }

  const { data: expediencyTest } = useGetExpediencyTest(
    isPDFRender ? accountId : selAccount?.id,
    isCorpoAccount,
  );
  const { mutateAsync: deleteExpediencyTest } = useDelExpediencyTest();
  const { mutateAsync: postExpediencyTest } = usePostExpediencyTest(
    selAccount,
    isCorpoAccount,
  );

  const submit = (values: FormValues) => {
    const expediencyTestPayload: Payload = getPayload(values, isCorpoAccount);
    return submitResults(expediencyTestPayload, selAccount, isCorpoAccount);
  };

  const submitResults = async (
    expediencyTestPayload: Payload,
    account: Account,
    isCorpoAccount: boolean,
  ) => {
    Sentry.withScope(scope => {
      scope.setLevel('info');
      scope.setTag('type', 'expediency-test');
      scope.setExtras({
        expediencyTestPayload,
      });
      Sentry.captureMessage(
        `About to submit expediency test for user ${userId}`,
      );
    });

    try {
      if (expediencyTest) {
        await deleteExpediencyTest({
          userId,
          accountId: account?.id,
          corpoAccount: isCorpoAccount,
        });
      }
      await postExpediencyTest({
        payload: { ...expediencyTestPayload, corpoAccount: isCorpoAccount },
        userId,
        account,
        corpoAccount: isCorpoAccount,
      });
      await postSigning({
        userId,
        accountId: account?.id,
        isCorpoAccount,
      }).then((result: any) => {
        window.location.assign(result?.redirectUri);
      });
    } catch (e) {
      return { [FORM_ERROR]: e };
    }
  };

  const initialData = useMemo(() => {
    return getFormValues(expediencyTest as Payload);
  }, [expediencyTest]);

  if (!expediencyTestFlag && !isLoading && !isPDFRender) {
    return <Redirect to="/" />;
  }

  return (
    <main>
      <ExpediencyTestCorpo
        initialData={initialData}
        onSubmit={submit}
        isPDFRender={isPDFRender}
        isCorpoAccount={isCorpoAccount}
        selectedAccount={selectedAccount as Account}
      />
    </main>
  );
};

export default ExpediencyTestPage;
