import { useState } from 'react';
import { useDebounce } from 'use-debounce';

import { QuoteList } from './Quotelist';
import Inputs from './Inputs';
import { Markets } from './helpers';

const QuoteListToggle = () => {
  const [list, setList] = useState<Markets>('obx');
  const [search, setSearch] = useState('');

  const [debouncedSearch] = useDebounce(search, 400);

  return (
    <>
      <Inputs
        market={list}
        setMarket={market => setList(market)}
        filter={search}
        setFilter={setSearch}
      />
      <QuoteList market={list} filter={debouncedSearch} />
    </>
  );
};

export default QuoteListToggle;
