import styled, { css } from '@xstyled/emotion';
import { breakpoints } from '@xstyled/system';

export const GridContainer = styled.div`
  max-width: 103rem;
  margin: 0px auto;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  grid-auto-rows: minmax(9.375rem, auto);
  grid-auto-flow: row dense;
  width: 100%;
  ${breakpoints({
    sm: css`
      padding: 4;
    `,
  })}
`;

export const GridMyOverview = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  grid-auto-rows: minmax(9.375rem, auto);
  grid-auto-flow: row dense;
  width: 100%;
  ${breakpoints({
    sm: css`
      padding: 4;
    `,
  })}
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(20rem, 1fr));
  gap: 1rem;
  margin: 0px auto;
  grid-auto-flow: row dense;
  width: 100%;
  max-width: 103rem;
  padding: 4;
`;

export const TopGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  ${breakpoints({
    md: css`
      padding: 4;
    `,
  })}
`;
