import createDecorator from 'final-form-calculate';

const handleDecline = (termName: string) => (
  fieldValue: boolean,
  values: any,
) => {
  if (fieldValue === false)
    return values.additionalInformation?.updates[termName];
  return false;
};

// It works, but..
export const termsDecorator = createDecorator(
  {
    field: 'additionalInformation.updates.productsAndServices', // when productsAndServices changes...
    updates: {
      'additionalInformation.updates.productsAndServices': val => val,
      'additionalInformation.updates.stockExchangeListings': val => val,
      'additionalInformation.updates.marketAndStocks': val => val,
      'additionalInformation.updates.invitations': val => val,
      'additionalInformation.updates.declineUpdates': (val, values: any) => {
        if (val === true) {
          return false;
        }
        return values?.additionalInformation?.updates?.declineUpdates;
      },
    },
  },
  {
    field: 'additionalInformation.updates.declineUpdates', // when declineUpdates changes...
    updates: {
      'additionalInformation.updates.productsAndServices': handleDecline(
        'productsAndServices',
      ),
      'additionalInformation.updates.stockExchangeListings': handleDecline(
        'stockExchangeListings',
      ),
      'additionalInformation.updates.marketAndStocks': handleDecline(
        'marketAndStocks',
      ),
      'additionalInformation.updates.invitations': handleDecline('invitations'),
      'additionalInformation.updates.declineUpdates': val => val,
    },
  },
);
