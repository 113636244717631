import React from 'react';
import { Field } from 'react-final-form';
import { ErrorFieldMessage } from '@sb1/ffe-form-react';

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const required = value => {
  return value === undefined || value === '' ? 'Påkrevet' : undefined;
};

export const posNumber = value =>
  Number(value) >= 0 ? undefined : 'Ugyldig tall';

export const lessThanMaxYear = value =>
  Number(value) < 100 ? undefined : 'Ugyldig tall';

export const lessThanMax = value =>
  Number(value) < 1000000
    ? undefined
    : 'Antall handler overskrider max handler sist år';

export const Error = ({ name }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <ErrorFieldMessage>{error}</ErrorFieldMessage> : null
    }
  />
);
