import { Spec } from '@oms/jaws-react';
import template from 'url-template';
import URLEncodedFormData from 'utils/URLEncodedFormData';
import { COMPONENTS_SECURE_URL } from '../constants/Jaws';

type Resource =
  | string
  | {
      url: string;
      [key: string]: any;
    };

type Headers = { [key: string]: string };

const buildResource = (resource: Resource) => {
  if (typeof resource === 'string') {
    return resource;
  } else if (resource.url) {
    const { url, ...variables } = resource;
    const parsedUrl = template.parse(url);

    return parsedUrl.expand(variables);
  }

  throw new Error(
    'Resource must be either a string or an object with a url template property and variables. ' +
      `Got ${resource}.`,
  );
};

type Body = FormData | URLEncodedFormData | string;
const getContentType = (body?: Body) => {
  if (body instanceof FormData) {
    return 'multipart/form-data; charset=UTF-8';
  }
  if (body instanceof URLEncodedFormData) {
    return 'application/x-www-form-urlencoded; charset=UTF-8';
  }

  return 'application/json; charset=UTF-8';
};

export const get = (resource: Resource, headers?: Headers) => {
  return fetch(buildResource(resource), {
    credentials: 'same-origin',
    headers: headers ? new Headers(headers) : {},
  });
};

export const post = (resource: Resource, body?: Body, headers?: Headers) => {
  return fetch(buildResource(resource), {
    method: 'POST',
    credentials: 'same-origin',
    body: body ? body.toString() : undefined,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });
};

export const put = (resource: Resource, body: Body, headers?: Headers) => {
  return fetch(buildResource(resource), {
    method: 'PUT',
    credentials: 'same-origin',
    body: body ? body.toString() : undefined,
    headers: new Headers({
      'Content-Type': getContentType(body),
      ...headers,
    }),
  });
};

export const del = (resource: Resource, headers?: Headers) => {
  return fetch(buildResource(resource), {
    method: 'DELETE',
    credentials: 'same-origin',
    headers: headers ? new Headers(headers) : {},
  });
};

export const getComponent = async (spec: Spec) => {
  const response = await get({ url: `${COMPONENTS_SECURE_URL}{?spec*}`, spec });
  if (!response.ok)
    throw new FetchError({
      message: 'Components-kall feilet',
      response,
    });
  const data = await response.json();
  return data;
};

/**
 * An error that is thrown in in the context of data fetching for the component
 */
export class FetchError extends Error {
  method: any;
  request: any;
  response?: Response;

  constructor({
    message,
    method,
    request,
    response,
  }: {
    message: string;
    method?: string;
    request?: any;
    response?: Response;
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent
    // constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only
    // available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.message = message;
    this.method = method;
    this.request = request;
    this.response = response;
  }
}
