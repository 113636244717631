import createDecorator from 'final-form-calculate';

export const launderingDecorator = createDecorator({
  field: [
    'additionalInformation.laundering.originInAbroad',
    'additionalInformation.laundering.tradeBehalfOfOthers',
    'additionalInformation.laundering.otherTradeBehalfOfYou',
    'additionalInformation.laundering.storedOnBehalfOfOthers',
  ],
  updates: {
    sendEmail: (fieldValue, values: any) => {
      if (fieldValue === true) {
        return false;
      }
      const {
        originInAbroad,
        tradeBehalfOfOthers,
        otherTradeBehalfOfYou,
        storedOnBehalfOfOthers,
      } = values.additionalInformation.laundering || {};
      const obj = {
        originInAbroad,
        tradeBehalfOfOthers,
        otherTradeBehalfOfYou,
        storedOnBehalfOfOthers,
      };

      if (Object?.values(obj)?.includes('yes')) return true;
      return false;
    },
  },
});
