import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import { OrderDetails } from '@oms/components-orders';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useUser from 'utils/hooks/useUser';
import { useHistory, useParams } from 'react-router-dom';
import { useTransactionDetails } from 'utils/hooks/useTransactions';
import { transactionNormalizer } from './Transactions';
import { useJaws } from '@oms/jaws-react';
import { useOrderDetails } from 'utils/hooks/useOrders';
import { Button } from '@oms/ui-button';
import styled from '@xstyled/emotion';

/** Could place more style-tuning here, but a lot need to be facilitated in COMP first */
const StyledOrderDetails = styled(OrderDetails)`
  @media print {
    margin: 2cm;

    .printButton {
      display: none;
    }
  }

  button {
    max-height: 2rem;
  }
  .OrderDetails-headerTable {
    max-width: 10rem;
    th {
      color: secondary;
      font-weight: bold;
    }
  }
  .OrderDetails-footerTable {
    flex: 1;
  }
`;

export const TransactionDetails = () => {
  const history = useHistory();
  const { transactionId, orderId } = useParams<any>();
  const { selectedAccountId } = useSelectedAccountId();
  const {
    data: { userId = '' },
  } = useUser();
  const { data } = useTransactionDetails({
    userId,
    accountId: selectedAccountId,
    transactionId,
    orderId,
  });
  const { data: orderDetails } = useOrderDetails({
    userId,
    accountId: selectedAccountId,
    orderId,
  });
  const spec = data?.ITEM_SECTOR
    ? { itemSector: data.ITEM_SECTOR, columns: 'ISIN' }
    : undefined;
  const { items } = useJaws(spec);
  const ISIN = items.first()?.get('ISIN');

  if (!data) return null;
  const normalizedTransaction = transactionNormalizer(data);
  normalizedTransaction['isin'] = ISIN;

  return (
    <Stack margin="auto" p={{ base: 4, md: 12 }}>
      <Box
        p={{ base: 4, md: 8 }}
        width="100%"
        maxWidth="60rem"
        mx="auto"
        boxShadow="menu"
      >
        <StyledOrderDetails
          instrumentType="stock"
          data={{
            transactionData: normalizedTransaction,
            createdDate: orderDetails?.CREATED_DATE,
          }}
        />
      </Box>
      <Box mx="auto">
        <Button onClick={() => history.goBack()}>Tilbake</Button>
      </Box>
    </Stack>
  );
};

export default TransactionDetails;
