import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useROI from 'utils/hooks/useROI';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '@oms/ui-box';

const config: Highcharts.Options = {
  title: undefined,
  chart: {
    type: 'line',
  },
  credits: {
    enabled: false,
  },
  stockTools: {
    gui: {
      enabled: false,
    },
  },
  navigator: {
    enabled: false,
  },
  plotOptions: {
    line: {
      compare: 'percent',
      tooltip: {
        followPointer: false,
        followTouchMove: false,
      },
    },
  },
  tooltip: {
    shared: true,
    pointFormat:
      '<span style="color:{series.color}">' +
      '{series.name}</span>: <b>{point.change}%</b><br/>',
    valueDecimals: 2,
    xDateFormat: '%A, %e. %b, %Y',
  },

  legend: {
    align: 'left',
    verticalAlign: 'top',
  },

  xAxis: {
    type: 'datetime',
  },

  yAxis: {
    title: undefined,
    labels: {
      x: 0,
      y: 6,
      format: '{value:,.2f}',
      //@ts-ignore
      formatter() {
        //@ts-ignore
        return `${this.value}%`;
      },
    },
  },
};

export const ROIChart = () => {
  const { selectedAccountId } = useSelectedAccountId();
  const {
    data: { series },
    isLoading,
  } = useROI({ accountId: selectedAccountId });
  return (
    <Box>
      {!isLoading && !series.length ? (
        <p>Ingen data</p>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{ ...config, series }}
        />
      )}
    </Box>
  );
};

export default ROIChart;
