import { COMPETENCY_ASSESSMENT_URL } from 'constants/Jaws';
import { FetchError, get, put } from 'utils/fetch';

export const getLongName = (name: AssessmentTest['name']) => {
  const longNameMap: Record<string, string> = {
    etn_test: 'ETN-er',
    merk_test: 'Merkur Market',
    rights_test: 'Tegningsretter',
  };

  return longNameMap[name] || name;
};

export type Question = {
  question: string;
  alternatives: string[];
  url: string;
  urlLabel: string;
  correct: number[];
  ans?: any;
};

export type Data = Record<string, Question>;

export type AssessmentTest = {
  id: number;
  name: string;
  data: Data;
};

export const fetchTests = async () => {
  const response = await get(COMPETENCY_ASSESSMENT_URL);
  if (!response.ok)
    throw new FetchError({
      message: 'Henting av kompetansetester feilet',
      response,
    });
  const { data } = await response.json();
  return data as AssessmentTest[];
};

export const fetchTest = async (id: string | number) => {
  const response = await get(`${COMPETENCY_ASSESSMENT_URL}/${id}`);
  if (!response.ok)
    throw new FetchError({
      message: 'Henting av kompetansetest feilet',
      response,
    });
  const { data } = await response.json();
  return data as AssessmentTest;
};

export const editTest = async (assessmentTest: AssessmentTest) => {
  const payload = JSON.stringify(assessmentTest.data);
  const response = await put(
    `${COMPETENCY_ASSESSMENT_URL}/${assessmentTest.id}`,
    //@ts-ignore
    encodeURI(`data=${payload}`),
    { 'Content-Type': 'application/x-www-form-urlencoded' },
  );
  if (!response.ok)
    throw new FetchError({
      message: 'Lagring av kompetansetest feilet',
      response,
    });
  return response;
};
