import { Td } from '@oms/ui-table';
import { Account } from 'types/auth';
import useHoldings from 'utils/hooks/useHoldings';
import { HakeIkon } from '@sb1/ffe-icons-react';
import { useTheme } from '@xstyled/emotion';
import { Badge } from '@oms/ui-badge';
import { Spinner } from '@oms/ui-spinner';
import { Clickable } from '@oms/ui-clickable';

type Props = {
  account: Pick<Account, 'ask' | 'vpsAccountNo' | 'id' | 'fullName'>;
  onClick: (id: string, instant: boolean) => void;
  isSelected: boolean;
  isLoading: boolean;
  /** Hides marketvalue from row */
  isMobile: boolean;
};
const AccountRow = ({
  account,
  onClick,
  isSelected,
  isLoading,
  isMobile,
}: Props) => {
  const { colors } = useTheme();

  /** TODO: Streaming marketvalue */
  const { data } = useHoldings({ accountId: account.id });
  const { ask, fullName } = account;
  return (
    <Clickable
      as="tr"
      key={account.id}
      onClick={() => onClick(account.id, isMobile)}
      // TODO: This still needs styling (SBEN-1557)
      data-selected={isSelected ? 'true' : undefined}
      style={{ cursor: 'pointer' }}
    >
      {isMobile ? (
        <Td
          type="symbol"
          linkTo={undefined}
          ticker={account.id}
          name={fullName}
        />
      ) : (
        <Td width="5rem">{account.id}</Td>
      )}
      <Td textAlign="center" width="3.75rem">
        <Badge variant={ask ? 'ask' : 'default'}>{ask ? 'ASK' : 'AH'}</Badge>
      </Td>
      {!isMobile && <Td>{fullName}</Td>}

      <Td type="numberWithUnit" suffix=" kr">
        {data?.marketValue ?? 0}
      </Td>

      {!isMobile && (
        <Td width="3rem" verticalAlign="top">
          {isSelected && <HakeIkon height={16} fill={colors.primary} />}
          {isLoading && <Spinner size="sm" segmentColor={colors.primary} />}
        </Td>
      )}
    </Clickable>
  );
};

export default AccountRow;
