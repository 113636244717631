import { normalizeNumber } from 'utils/form';

function isBetween(n: any, a: any, b: any) {
  return (n - a) * (n - b) <= 0;
}

export const getMatchingOrder = (
  tradeType: any,
  item: any,
  limit: any,
  activeOrders: any,
) => {
  const normalizedLimit = normalizeNumber(limit);
  return activeOrders.find((order: any) => {
    const orderIsBuy = order.TYPE === 0;
    const orderIsSell = order.TYPE === 1;
    const stopLossLimitOrder = order.STOP_LOSS_LIMIT;
    const orderLimit = order.LIMIT;
    const canMatchStopLoss = isBetween(
      normalizedLimit,
      stopLossLimitOrder,
      orderLimit,
    );

    if (order.TICKER_CODE !== item) return false;
    // Only check for matching crossing orders
    if (orderIsBuy && tradeType === 'BUY') return false;
    if (orderIsSell && tradeType === 'SELL') return false;
    if (stopLossLimitOrder && !canMatchStopLoss) return false;
    // Verify limits are such that they can match the order
    // TODO what about partial matching?
    if (tradeType === 'BUY' && normalizedLimit < orderLimit) return false;
    if (tradeType === 'SELL' && normalizedLimit > orderLimit) return false;
    return true;
  });
};
