import { Stack } from '@oms/ui-stack';
import { Card } from 'components/Card';

import CustomerInformation from '../CustomerInformation';
import CustomerEducation from '../CustomerEducation';
import Education from '../Education';
import Profession from '../Profession';
import Laundering from '../Laundering';
import TermsAndDisclaimer from '../TermsAndDisclaimer';
import PoliticallyExposedPDF from '../PoliticallyExposed/PoliticallyExposedPDF';
import KnowledgeLevelStocks from '../Knowledge/KnowledgeLevelStocks';
import KnowledgeLevelEquityCertificate from '../Knowledge/KnowledgeLevelEquityCertificate';
import AdditionalCustomerInformation from '../AdditionalCustomerInformation';
import ExchangTradedFunds from '../Knowledge/ExchangTradedFunds';

const PDFView = () => {
  return (
    <Stack>
      <Card title="Kundeinformasjon">
        <CustomerInformation />
        <AdditionalCustomerInformation />
      </Card>

      <Card title="Yrke, utdanning og erfaring">
        <CustomerEducation />
        <Education />
        <Profession />
      </Card>

      <Card title="Kunnskap og erfaring med finansielle instrumenter">
        <KnowledgeLevelStocks />
        <KnowledgeLevelEquityCertificate />
        <ExchangTradedFunds />
      </Card>

      <Card title="Midlenes opprinnelse">
        <Laundering />
      </Card>

      <Card title="Politisk eksponert person">
        <PoliticallyExposedPDF />
      </Card>

      <Card title="Vilkår">
        <TermsAndDisclaimer />
      </Card>
    </Stack>
  );
};

export default PDFView;
