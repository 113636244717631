import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';

export const MonthlyTransactions = () => {
  return (
    <Stack gap={4}>
      <Heading>
        Hvor ofte forventer du å handle aksjer via Aksjetjenesten?
      </Heading>
      <Fieldset
        as={RadioGroup}
        name="kycMonthlyTransactions"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="Hyppig/Daglig"
          value="DAILY"
          validate={required}
        />
        <Field as={Radio} label="Ukentlig" value="WEEKLY" validate={required} />
        <Field
          as={Radio}
          label="Månedlig"
          value="MONTHLY"
          validate={required}
        />
        <Field
          as={Radio}
          label="Årlig/Sjeldnere"
          value="ANNUAL"
          validate={required}
        />
      </Fieldset>
    </Stack>
  );
};

export default MonthlyTransactions;
