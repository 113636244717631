import React from 'react';
import { Card } from '../Card';
import { Field } from '@oms/ui-field';
import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { Box } from '@oms/ui-box';

export interface ApplicationProps {}

export function Application(_props: ApplicationProps) {
  return (
    <Card title="2. Søknad">
      <Box
        display="grid"
        gridGap={4}
        px={{ base: 3, sm: 'initial' as any }}
        pb={{ base: 3, sm: 'initial' as any }}
      >
        <Field
          as={NumberInput}
          lang="nb"
          name="requestedCredit"
          label="Ønsket kredittramme**"
        />
        <Field
          as={NumberInput}
          lang="nb"
          name="totalEquity"
          label="Egenkapital*** utgjør til sammen"
        />
      </Box>
    </Card>
  );
}

export type ApplicationType = {
  requestedCredit: number;
  totalEquity: number;
  [key: string]: any;
};

export const validationRules = (values: ApplicationType) => {
  let errors: Record<keyof ApplicationType, string | undefined> = {};
  if (!values.requestedCredit) {
    errors['requestedCredit'] = 'Påkrevd';
  }
  if (!values.totalEquity) {
    errors['totalEquity'] = 'Påkrevd';
  }
  return errors;
};
