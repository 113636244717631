import moment from 'moment';
import { isValidLotSize } from '@oms/utils';

import { validateLimit, validateOpenVolume } from 'utils/validation/trade';
import * as FormUtils from 'utils/form';

export function validateInstrument({ instrument, isAsk, instrumentSet }) {
  if (instrument && instrumentSet && isAsk) {
    const itemSector = `${instrument.ITEM}.${instrument.SECTOR}`;
    if (!instrumentSet.includes(itemSector)) {
      return 'Det er begrenset hvilke finansielle instrumenter som tillates i aksjespareløsningen. Dersom du ønsker å handle dette verdipapiret, kan du opprette ordinær aksjehandelstjeneste i din nettbank.';
    }
  }

  if (!instrument) {
    return 'Vennligst velg et instrument å handle på';
  }

  return undefined;
}

export function validateVolume(volume, { data: lotSizeRule }) {
  const lotSize = lotSizeRule;
  const normalizedVolume = FormUtils.normalizeNumber(volume);
  const isPositiveAndPresent = volume && normalizedVolume > 0;
  if (volume && !isValidLotSize(normalizedVolume, lotSize)) {
    // Allow empty while we are loading lotSize
    if (!lotSize) {
      return undefined;
    }

    return `Antall: Lot Size er ${lotSize.lotSize} for dette instrumentet`;
  }

  if (!isPositiveAndPresent) {
    return 'Volum er ikke gyldig heltall (kun heltall lovlig)';
  }

  return undefined;
}

export function validateValidity(validity) {
  // Present, and within time span of now <--> 15 days from now
  const now = moment().startOf('day');
  const futureDate = moment()
    .add(15, 'days')
    .endOf('day');

  const isMoment = moment.isMoment(validity);

  if (!isMoment || validity.isBefore(now) || validity.isAfter(futureDate)) {
    return 'Ugyldig dato';
  }
  return undefined;
}

export default function validate(values) {
  return {
    instrument: validateInstrument(values),
    volume: validateVolume(values),
    limit: validateLimit(values),
    validity: validateValidity(values),
    openVolume: validateOpenVolume(values),
  };
}
