import React from 'react';
import { useJaws } from '@oms/jaws-react';
import { Wrap } from '@oms/ui-wrap';
import { Stack } from '@oms/ui-stack';

import { CardProps } from 'components/Card';
import { useItemSector } from 'context/ItemSectorContext';
import { FormattedBox } from './FormattedBox';

export interface InstrumentQuoteProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const InstrumentQuote = ({ itemSector }: InstrumentQuoteProps) => {
  const itemSectorValue = useItemSector(itemSector);
  return <InstrumentQuoteComponent itemSector={itemSectorValue} />;
};

function buildSpec(itemSector: string) {
  return {
    columns:
      'LAST, TURNOVER, LONG_NAME, ASK_VOL, ASK, BID, BID_VOL, TIME, OPEN, HIGH, LOW',
    itemSector,
    filter: `ITEM_SECTOR==s${itemSector}`,
  };
}

export const InstrumentQuoteComponent = ({
  itemSector,
}: {
  itemSector: string;
}) => {
  const spec = buildSpec(itemSector);
  const { items, jawsFetching } = useJaws(spec);

  const item = items?.first()?.toJS();
  return (
    <Stack>
      <Wrap
        gap={5}
        px={2}
        py={2}
        justifyContent={{ base: 'space-evenly', md: 'flex-end' }}
        gridGap={4}
      >
        <FormattedBox
          isPending={jawsFetching}
          value={item?.OPEN}
          title="Åpning"
          type="number"
          flex={{ base: '0 0 5rem', md: 'initial' }}
        />
        <FormattedBox
          isPending={jawsFetching}
          value={item?.HIGH}
          title="Høy"
          type="number"
          flex={{ base: '0 0 5rem', md: 'initial' }}
        />
        <FormattedBox
          isPending={jawsFetching}
          value={item?.LOW}
          title="Lav"
          type="number"
          flex={{ base: '0 0 5rem', md: 'initial' }}
        />
        <FormattedBox
          isPending={jawsFetching}
          value={item?.TURNOVER}
          title="Omsatt"
          type="integer"
          flex={{ base: '0 0 5rem', md: 'initial' }}
        />
        <FormattedBox
          isPending={jawsFetching}
          value={item?.BID}
          title="Kjøp"
          type="number"
          flex={{ base: '0 0 5rem', md: 'initial' }}
          fontWeight="bold"
        />
        <FormattedBox
          isPending={jawsFetching}
          value={item?.ASK}
          title="Salg"
          type="number"
          flex={{ base: '0 0 5rem', md: 'initial' }}
          fontWeight="bold"
        />
      </Wrap>
    </Stack>
  );
};
