import { useQuery } from 'react-query';
import { REPORT_URL } from 'constants/Jaws';

export const reportTypes = {
  MORNINGREPORT: 'Morgenrapport',
  WEEKREPORT: 'Månedsportefølje',
  STRATEGY: 'Strategi',
};

export type Report = {
  reportId: number;
  type: ReportType;
  title: string;
  ingress: string;
  analystName: string;
  startTime: number;
  endTime: number;
  document: Document;
};

export type ReportType = keyof typeof reportTypes;

export type Document = {
  id: number;
  mediaType: string;
  fileName: string;
  file: any;
};

type UseReportOptions = {
  type: ReportType;
  count?: number;
};

const getReports = (options: UseReportOptions) => {
  const { count = 10, type } = options;
  return fetch(`${REPORT_URL}/${type}?count=${count}`)
    .then(res => {
      if (!res.ok) {
        return;
      }
      return res.json();
    })
    .then(data => data as Report[]);
};
export const useReports = (options: UseReportOptions) => {
  const { count = 10, type } = options;
  return useQuery(
    ['report', { count, type }],
    () => getReports({ count, type }),
    {
      suspense: true,
    },
  );
};

export default useReports;
