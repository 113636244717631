import { AdminPrincipal, CustomerPrincipal, ME } from 'types/auth';
import ShiroTrie from 'shiro-trie';
import { Role } from 'types/admin';

export const getAdminPrincipal = (principals?: ME['principals']) => {
  if (!principals) return;
  return principals.find(principal => principal?.iss === 'adminAuthcRealm') as
    | AdminPrincipal
    | undefined;
};

export const getCustomerPrincipal = (principals?: ME['principals']) => {
  if (!principals) return;
  const principal = principals.find(
    principal =>
      principal?.iss === 'bankIdAuthcRealm' ||
      principal?.iss === 'customerAuthcRealm',
  ) as CustomerPrincipal | undefined;

  return principal;
};

export const getUserId = (user?: ME) => {
  if (!user) return;
  const customerPrincipal = getCustomerPrincipal(user.principals);
  if (customerPrincipal) return customerPrincipal.sub;

  const adminPrincipal = getAdminPrincipal(user.principals);
  if (adminPrincipal) return adminPrincipal.sub;

  return;
};

export const checkIsLoggedIn = (user?: ME) => {
  if (!user) return false;
  return user.authenticated;
};

export const checkIsTakeover = (user?: ME) => {
  if (!user) return false;
  const somePrincipalIsTakeover = user.principals.some(principal => {
    if (principal?.iss === 'anonymousRealm') return false;
    if (principal?.iss === 'adminAuthcRealm') return false;
    return principal?.supportTakeover;
  });

  return somePrincipalIsTakeover;
};

export const checkIsAdmin = (user?: ME) => {
  if (!user) return false;
  const adminPrincipal = getAdminPrincipal(user.principals);
  if (adminPrincipal) return true;
  return false;
};

export const checkIsOwnTakeover = (user?: ME) =>
  checkIsAdmin(user) && checkIsTakeover(user);

export const checkIsExternalTakeover = (user?: ME) =>
  !checkIsAdmin(user) && checkIsTakeover(user);

const checkCustomerPermission = (customer: ME, permission: string) => {
  if (!permission)
    throw new Error(`Permission is required, got: "${permission}"`);
  if (!customer) return false;
  const customerPrincipal = getCustomerPrincipal(customer.principals);
  if (!customerPrincipal) return false;

  const { permissions, entitledServices } = customerPrincipal;
  if (!entitledServices) return false;

  const shiro = ShiroTrie.newTrie().add(...permissions, ...entitledServices);
  return shiro.check(permission);
};

export const checkHasTradingRights = (user?: ME) => {
  if (!user) return false;
  const customerPrincipal = getCustomerPrincipal(user.principals);
  if (!customerPrincipal) return false;
  return customerPrincipal.authenticatedToTrade;
};

export const checkHasStreaming = (user?: ME) => {
  if (!user) return false;
  return checkCustomerPermission(user, 'collect:streaming');
};

export const checkHasLevel2 = (user?: ME) => {
  if (!user) return false;
  return checkCustomerPermission(user, 'collect:level2');
};

export const checkHasRole = (user: ME, roleName: Role['name']) => {
  return user.principals.some(principal => {
    if (principal?.iss === 'anonymousRealm') return false;
    return principal?.roles.some(role => role.name === roleName);
  });
};

// TODO: isOwnTakeover, isExternalTakeover, and so on IF they are needed. And please add comments detailing why they're needed
