import { Form } from 'react-final-form';
import { Field, RenderField } from '@oms/ui-field';
import { useSuggest } from '@oms/components-suggest';
import { Select } from '@oms/ui-select';
import { Wrap } from '@oms/ui-wrap';
import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { Button } from '@oms/ui-button';
import { Stack } from '@oms/ui-stack';
import { validate } from './validate';
import {
  alertInstrumentTypeOptions,
  alertTriggerOptions,
  alertInstrumentOptions,
  getAlertTriggerCriterion,
} from 'utils/alerts';
import { Box } from '@oms/ui-box';
import { calculator } from './calculator';
import { Alert } from 'utils/hooks/useAlerts';
import { FORM_ERROR } from 'final-form';
import { Notification } from '@oms/ui-notification';

export type MarketAlertFormValues = {
  item?: string;
  sector?: string;
  active: boolean;
  thresholdTrigger?: Alert['thresholdTrigger'];
  intradayTrigger?: Alert['intradayTrigger'];
  instrumentType: typeof alertInstrumentTypeOptions[number]['value'];
  instrument?: string;
  trigger: typeof alertTriggerOptions[number]['value'];
  triggerCriterion: 'GTE' | 'LTE'; // ugh
  triggerValue: string;
};

const defaultInitialValues = {
  instrumentType: 'stocks',
  trigger: 'LAST',
};
type Opt = {
  value: any;
  label: string;
};
type Props = {
  // initialValues: Partial<FormValues>;
  initialValues?: any;
  onSubmit: (values: MarketAlertFormValues) => Promise<Response>;
};

export const MarketAlertForm = ({
  initialValues = defaultInitialValues,
  onSubmit,
}: Props) => {
  if (!onSubmit) throw new Error('Needs an onSubmit!');

  const Suggest = useSuggest();

  const handleSubmit = async (values: MarketAlertFormValues) => {
    try {
      await onSubmit(values);
    } catch (e) {
      return { [FORM_ERROR]: 'Innsending feilet' };
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      decorators={[calculator as any]}
      validate={validate}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        values,
        form,
        submitting,
        initialValues,
        hasSubmitErrors,
        submitError,
        dirtySinceLastSubmit,
      }) => {
        return (
          <Stack as="form" gap={4} onSubmit={handleSubmit} width="100%">
            <Wrap gap={4}>
              <Box flex={1} minWidth="12rem">
                <RenderField
                  as={Select}
                  name="instrumentType"
                  label="Varsle om"
                  items={alertInstrumentTypeOptions}
                  onChange={(opt: Opt) =>
                    form.change('instrumentType', opt.value)
                  }
                  value={alertInstrumentTypeOptions.find(
                    opt => opt.value === values.instrumentType,
                  )}
                />
              </Box>
              <Box flex={1} minWidth="12rem">
                {values.instrumentType === 'stocks' ? (
                  <RenderField
                    as={Suggest}
                    label="Ticker"
                    name="instrument"
                    onChange={({ ITEM, SECTOR }: any) => {
                      form.batch(() => {
                        form.change('item', ITEM);
                        form.change('sector', SECTOR);
                      });
                    }}
                    placeholder={values.item}
                  />
                ) : (
                  <RenderField
                    as={Select}
                    name="instrument"
                    placeholder="Velg"
                    label={
                      alertInstrumentTypeOptions.find(
                        opt => opt.value === values.instrumentType,
                      )?.label || ''
                    }
                    items={alertInstrumentOptions[values.instrumentType]}
                    //@ts-ignore
                    value={alertInstrumentOptions[values.instrumentType].find(
                      (opt: Opt) => opt.value === values.instrument,
                    )}
                    onChange={(opt: Opt) =>
                      form.change('instrument', opt.value)
                    }
                  />
                )}
              </Box>
            </Wrap>
            <Wrap gap={4}>
              <Box flex={1} minWidth="12rem">
                <RenderField
                  as={Select}
                  name="trigger"
                  label="Når"
                  items={alertTriggerOptions.map(opt => {
                    if (
                      initialValues.trigger !== 'HIGH_5YEARS' &&
                      opt.value === 'HIGH_5YEARS'
                    ) {
                      return { ...opt, disabled: true };
                    }

                    return opt;
                  })}
                  value={alertTriggerOptions.find(
                    (opt: Opt) => opt.value === values.trigger,
                  )}
                  onChange={(opt: Opt) => form.change('trigger', opt.value)}
                  disabled={initialValues.trigger === 'HIGH_5YEARS'}
                />
              </Box>
              <Box flex={1} minWidth="12rem">
                {values.trigger !== 'HIGH_5YEARS' && (
                  <RenderField
                    as={Select}
                    name="triggerCriterion"
                    label="Kriterie"
                    placeholder="Velg"
                    items={getAlertTriggerCriterion(values.trigger)}
                    //@ts-ignore
                    value={getAlertTriggerCriterion(values.trigger)?.find(
                      (opt: Opt) => opt.value === values.triggerCriterion,
                    )}
                    onChange={(opt: Opt) =>
                      form.change('triggerCriterion', opt.value)
                    }
                  />
                )}
              </Box>
              <Box flex={1} minWidth="12rem">
                {values.trigger !== 'HIGH_5YEARS' && (
                  <Field
                    as={NumberInput}
                    lang="nb"
                    name="triggerValue"
                    label="Verdi"
                  />
                )}
              </Box>
            </Wrap>
            <Button type="submit" maxWidth="12rem" isPending={submitting}>
              Lagre
            </Button>
            {hasSubmitErrors && !dirtySinceLastSubmit && (
              <Notification status="error" title="Feil">
                {submitError}
              </Notification>
            )}
          </Stack>
        );
      }}
    </Form>
  );
};
