import { Losers as OMSLosers } from '@oms/components-losers';
import { Card, CardProps } from 'components/Card';
import { generatorSpec } from 'utils/Spec';

export interface LosersProps extends Omit<CardProps, 'children'> {}

export const Losers = (props: LosersProps) => (
  <Card title="Tapere" {...props}>
    <OMSLosers showMoreButton spec={generatorSpec()} />
  </Card>
);
