import { ONE_HOUR, ONE_MINUTE } from 'constants/timeInMs';
import moment from 'moment';
import { useQuery } from 'react-query';
import { colors } from 'theme';
import { getComponent } from 'utils/fetch';
import useGraphData from '../useGraphdata';

const oneMonthAgo = moment()
  .subtract(1, 'month')
  .startOf('day');

const useROI = ({ accountId }: { accountId: string }) => {
  const {
    data: { series: compareSeries },
  } = useGraphData({ itemSector: 'OSEBX.OSE' });
  const spec = {
    columns: 'DATE, ROI_VALUE',
    filter: `ITEM==s${accountId}`,
    source: 'feed.sben.roi.probroker.CUSTOMERS',
    space: 'DAY',
    start: oneMonthAgo.valueOf(),
    stop: 'now',
    type: 'history',
    ranking: 'DATE',
  };
  const { data = [], ...queryState } = useQuery(
    ['roiChart', spec],
    () => getComponent(spec).then(data => data.rows),
    { cacheTime: ONE_HOUR, staleTime: ONE_MINUTE * 15 },
  );

  const roi = data.map((day: { key: string; values: any }) => day.values);
  const series = generateSeries(roi, compareSeries);

  return { data: { series }, ...queryState };
};

export default useROI;

type Series = { name: string; data?: [number, number][]; color?: string };
const generateSeries = (
  roi: Array<{ DATE: string; ROI_VALUE: number }>,
  compareSeries: Series,
) => {
  const openDays = [];
  if (compareSeries.data?.length) {
    const newCompareSeriesData: Series['data'] = [];
    for (let i = 0; i < compareSeries.data.length; i++) {
      const entry = compareSeries.data[i];
      const day = moment(entry[0]).startOf('day');
      openDays.push(day.format('YYYYMMDD'));
      newCompareSeriesData.push([day.valueOf(), entry[1]]);
    }

    compareSeries.data = newCompareSeriesData;
    compareSeries.color = colors.tertiary;
  }

  const series = [compareSeries];

  if (compareSeries.data?.length && roi.length) {
    let previousValue = 0;
    const roiData: Series['data'] = [];
    for (const roiEntry of roi) {
      if (!openDays.includes(roiEntry.DATE)) {
        continue;
      }

      let value;
      if (roiData.length === 0) {
        value = compareSeries.data[0][1];
      } else {
        value = previousValue + previousValue * (roiEntry.ROI_VALUE / 100);
      }

      previousValue = value;
      roiData.push([moment(roiEntry.DATE, 'YYYYMMDD').valueOf(), value]);
    }
    series.push({
      name: 'Avkastning',
      data: roiData,
      color: colors.primary,
    });
  }

  return series;
};
