import { tabIds as marketInstrumentTableIds } from 'components/MarketInstrumentTables';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const getIdFromHash = (hash: string) => {
  const parsedId = hash.replace('#', '');

  if (marketInstrumentTableIds.includes(parsedId))
    return 'marketInstrumentTable';
  return parsedId;
};

/** Some simple scroll handling, like scrolling to top on location change, and scrolling to hash if present */
const useScrollHandling = () => {
  const history = useHistory();

  useEffect(() => {
    let previousPathname = history.location.pathname;
    // history.listen returns cleanup function
    return history.listen((location, action) => {
      const { hash, pathname } = location;

      const pathnameChanged = pathname !== previousPathname;
      // Update previousPathname before we start branching logic, so we only have to set it once
      previousPathname = pathname;

      // If hash is present we want to scroll to element if found
      if (hash) {
        setTimeout(() => {
          const id = getIdFromHash(hash);
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 10);
        return; // return so we don't also scroll to top
      }

      // Reason we check for POP-action is to preserve scroll position when clicking back in browser
      if (action !== 'POP' && pathnameChanged) {
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 10);
      }
    });
  }, [history]);

  return null;
};

export default useScrollHandling;
