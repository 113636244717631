import styled from '@emotion/styled';
import EmploymentStatus from '../EmploymentStatus';

import { Stack } from '@oms/ui-stack';

const Divider = styled.hr`
  margin: 1rem 0;
  color: lightgray;
`;

export const Profession = () => {
  return (
    <Stack gap={4}>
      <EmploymentStatus />
    </Stack>
  );
};

export default Profession;
