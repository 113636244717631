import { useState, useEffect } from 'react';
import { Field, RenderField } from '@oms/ui-field';
import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { TradeUtil, formatDate } from 'utils/trade';
import { warningValidation } from 'utils/validation/tradeWarningValidation';
import { TextInput } from '@oms/ui-text-input';

import { useForm, useFormState } from 'react-final-form';
import { useItemSector } from 'context/ItemSectorContext';
import { Checkbox } from '@oms/ui-checkbox';
import { InfoBubble } from '@oms/ui-info-bubble';
import { DateInput } from '@oms/ui-date-input';
import { Table, Td, Th } from '@oms/ui-table';
import { Stack } from '@oms/ui-stack';
import moment from 'moment';
import { useGetCloseDates } from 'components/Trade/Queries/useGetCloseDates';
import { Text } from '@oms/ui-text';
import { useLast } from '../Queries/useLast';
import { normalizeNumber } from 'utils/form';
import { Notification } from '@oms/ui-notification';

export const getItemAndSector = (itemSectorValue: any) => {
  const [item = '', useSector = ''] = itemSectorValue?.split('.');
  return {
    item,
    useSector,
  };
};

type Props = {
  editMode: boolean;
  hidden: boolean;
  buy: React.ReactChild;
};

export const BuyForm = ({ buy, hidden, editMode }: Props) => {
  const form = useForm();
  const { values, errors } = useFormState();

  const { data: closeDates } = useGetCloseDates();
  const itemSectorValue = useItemSector();
  const { data: last } = useLast(itemSectorValue);
  let lastDay = new Date();
  lastDay.setDate(lastDay.getDate() + 14);
  const closeTime = '15:20';
  const endOfDay = '00:59';
  let currentTime = moment();
  const currentDate = moment().format('LL');
  const closeTimeTo = moment(`${currentDate} ${closeTime}`);
  const nextDay = moment()
    .add(1, 'days')
    .format('YYYY-MM-DD')
    .toString();
  const endOfDayTo = moment(`${nextDay} ${endOfDay}`, 'YYYY-MM-DD HH:mm');
  const isMarketClose = currentTime >= closeTimeTo && currentTime <= endOfDayTo;
  const [start, setStart] = useState(
    isMarketClose
      ? new Date(nextDay)
      : values.expirationDate || TradeUtil.getDefault('start'),
  );
  const minDate = isMarketClose ? new Date(nextDay) : new Date();
  useEffect(() => {
    form.change('expirationDate', `${moment(start).format('YYYY-MM-DD')}`);
  }, [form, start]);
  const expirationDate = (date: Date) => {
    setStart(formatDate(date));
  };
  return (
    <div hidden={hidden}>
      <Stack gap={5} /*p={2}*/ width="100%">
        <Field as={TextInput} name="ticker" label="Ticker / Selskap" disabled />
        {errors?.instrument && (
          <Notification status="error" title="Feil">
            {errors?.instrument}
          </Notification>
        )}
        <Field
          as={NumberInput}
          lang="nb"
          inputMode="numeric"
          name="volume"
          label="Totalt Antall/Volum"
          min="0"
          flex={1}
        />

        <Stack gap={4} orientation="horizontal" width="100%">
          <Field
            name="useOpenVolume"
            as={Checkbox}
            label="Jeg vil skjule deler av min ordre"
            onChange={() => form?.resetFieldState('openVolume')}
            disabled={editMode}
          />

          <InfoBubble aria-label="Info" variant="default">
            {editMode
              ? 'For å endre på dette må du trekke ordren og legge inn en ny.'
              : `Du kan velge å skjule deler av din ordre ved å her taste inn det
            volumet som skal være synlig. Ordrestørrelse må være større enn 100 000 NOK`}
          </InfoBubble>
        </Stack>
        {values?.useOpenVolume && (
          <Field
            as={NumberInput}
            lang="nb"
            inputMode="numeric"
            name="openVolume"
            label="Synlig volum"
          />
        )}
        <Field
          as={NumberInput}
          lang="nb"
          inputMode="decimal"
          name="limit"
          min="0"
          label="Pris/Limit"
          flex={1}
          message={
            values.limit && (
              <Text color="warning">
                {warningValidation(values.limit, last)}
              </Text>
            )
          }
        />

        <RenderField
          as={DateInput}
          name="expirationDate"
          label="Gyldig til"
          onChange={expirationDate}
          value={new Date(start)}
          excludeDates={closeDates?.data}
          minDate={minDate}
          maxDate={lastDay}
        />

        <Table density="dense" variant="keyValue">
          <tbody>
            <tr />
            <tr>
              <Th>Ordre</Th>
              <Td type="number" suffix="kr">
                {values?.limit && values?.volume
                  ? normalizeNumber(values?.limit) *
                    normalizeNumber(values?.volume)
                  : 0}
              </Td>
            </tr>
            <tr>
              <Th>Total</Th>
              <Td type="number" suffix="kr">
                {values?.limit && values?.volume
                  ? normalizeNumber(values?.limit) *
                    normalizeNumber(values?.volume)
                  : 0}
              </Td>
            </tr>
          </tbody>
        </Table>
        {buy}
      </Stack>
    </div>
  );
};
