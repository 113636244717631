import React, { useState, useEffect } from 'react';
import { Link } from '@oms/ui-link';
import { Table, Tbody, Td, Th, Thead, Tr } from '@oms/ui-table';
import { Loading, NoData } from '@oms/components-core';
import { useResponsiveValue } from '@oms/ui-media';
import { useJaws } from '@oms/jaws-react';
import { AnalysisData } from './AnalysisData';
import { useWeekPortfolioList } from 'utils/hooks/useWeekPortfolio';

export interface WeeklyPortfolioProps {}

export const WeeklyPortfolio = () => {
  const [itemSectors, setItemSectors] = useState<string>();

  const { data: weekPortfolio } = useWeekPortfolioList();

  useEffect(() => {
    if (!weekPortfolio) return;

    if (weekPortfolio.items.length > 0) {
      const itemSectorsStrings = weekPortfolio.items
        .map(item => item.ticker)
        .join();

      setItemSectors(itemSectorsStrings);
    }
  }, [weekPortfolio]);

  const { items, initialized, resource, emptyData } = useJaws(
    {
      columns:
        'ITEM, SECTOR, ITEM_SECTOR, LONG_NAME, ITEM_SECTOR, TIME, LAST, CHANGE_PCT',
      itemSector: itemSectors,
    },
    { disableAutoSubscribe: !Boolean(itemSectors) },
  );

  const isMobile = useResponsiveValue({ isMobile: true });
  if (!initialized) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    //@ts-ignore
    return <NoData text={`No return found`} />;
  }

  return (
    <Table variant={isMobile ? 'flipped' : 'normal'}>
      <Thead>
        <Tr>
          <Th type="text">Ticker</Th>
          <Th type="number">Siste</Th>
          <Th type="percent">+/-%</Th>
          <Th type="dateTime" width={!isMobile && '80px'}>
            Dato
          </Th>
          <Th type="number">Kurs</Th>
          <Th type="numberWithUnit">%</Th>
          <Th type="text" width={!isMobile && '85px'}>
            Anbefaling
          </Th>
          <Th type="text">Kursmål</Th>
          <Th type="text">Analyse</Th>
          <Th type="text" width={!isMobile && '90px'}>
            {' '}
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {weekPortfolio?.items?.map(instrument => {
          const { ticker, total } = instrument;

          const jawsItem = items.find(
            item => item.get('ITEM_SECTOR') === ticker,
          );

          if (!jawsItem) return null;
          const ITEM_SECTOR = jawsItem.get('ITEM_SECTOR');
          const ITEM = jawsItem.get('ITEM');
          const LAST = jawsItem.get('LAST');
          const CHANGE_PCT = jawsItem.get('CHANGE_PCT');
          const changeTotal = ((LAST - total.value) / total.value) * 100;

          return (
            <Tr key={ticker}>
              <Td
                type="symbol"
                linkTo={`/instrument/${ITEM_SECTOR}`}
                ticker={ITEM}
                name={''}
              />

              <Td type="number" trend>
                {LAST}
              </Td>
              <Td type="percent" fractionDigits={2} trend flash>
                {CHANGE_PCT}
              </Td>
              <Td type="dateTime" formatString="DD. MMM">
                {total.timestamp}
              </Td>
              <Td type="number">{total.value}</Td>
              <Td type="percent" fractionDigits={2} trend flash>
                {changeTotal}
              </Td>
              <AnalysisData itemSector={ITEM_SECTOR} />
              <Td>
                <Link to={`/instrument/${ITEM_SECTOR}`} size="xs" variant="buy">
                  Kjøp
                </Link>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
