import { createContext, useCallback, useContext, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

export const STORAGE_KEY = '@oms/sb1-user-preferences';
export const DEFAULT_PREFERENCES: Partial<UserPreferences> = {
  streaming: true,
  notifications: {
    disable: false,
  },
};

interface UserPreferences {
  //darkMode
  streaming?: boolean;
  // TODO: We need to decide the level of control over notifications
  notifications?: {
    disable: boolean;
    // orderUpdates: boolean,
    // orderCompletes: boolean,
    // sound/audio: boolean
  };
}
export type UserPreferencesContextType = {
  preferences: UserPreferences;
  updatePreference: <Key extends keyof UserPreferences>(
    key: Key,
    newValue: UserPreferences[Key],
  ) => void;
};

const context = createContext<UserPreferencesContextType | null>(null);
const { Provider } = context;

export const useUserPreferences = () => {
  const ctx = useContext(context);
  if (ctx === null)
    throw new Error(
      'useUserPreferences must be used within UserPreferencesProvider',
    );
  return ctx;
};

interface UserPreferencesProps {
  children: React.ReactNode;
}

export function UserPreferencesProvider({ children }: UserPreferencesProps) {
  const [preferences, setPreferences] = useLocalStorage<UserPreferences>(
    STORAGE_KEY,
    DEFAULT_PREFERENCES,
  );

  const updatePreference = useCallback<
    UserPreferencesContextType['updatePreference']
  >(
    (key, newValue) =>
      //@ts-ignore TODO
      setPreferences(prevValues => ({ ...prevValues, [key]: newValue })),
    [setPreferences],
  );

  const value = useMemo(
    () => ({
      preferences: preferences || DEFAULT_PREFERENCES, // Shouldn't be necessary, but TS typing of useLocalStorage says it is
      updatePreference,
    }),
    [preferences, updatePreference],
  );

  return <Provider value={value}>{children}</Provider>;
}

context.displayName = 'UserPreferences';
