import React, { useEffect, useMemo } from 'react';
import { Text } from '@oms/ui-text';
import { useFetchBrokerageLimit } from './helpers';
import { formatNumber } from '@oms/utils';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { audit } from 'utils/audit';
import useUser from 'utils/hooks/useUser';
import { Notification } from '@oms/ui-notification';

type OrderDetails = {
  ticker: string;
  price: number;
  volume: number;
  commission: number;
  totalPrice: number;
};

const setAudit = (userId: string, accountId: string, message: string) => {
  const textAndKey = {
    key: 'brokerageFeeLimitExceeded',
    text: message,
  };
  return audit(userId, accountId, textAndKey);
};

const Warning = ({
  percentageCommission,
  ticker,
  commission,
  volume,
  price,
  totalPrice,
}: OrderDetails & { percentageCommission: number }) => {
  const { selectedAccountId: accountId } = useSelectedAccountId();
  const {
    data: { userId = '' },
  } = useUser();
  const message = `
    Kurtasjen ved å gjennomføre denne ordren er ${formatNumber(
      percentageCommission,
      2,
    )}% av
    totalverdien. Er du sikker på at du ønsker ordren gjennomført?
    `;
  const auditMessage = useMemo(
    () => ({
      ticker,
      commission,
      volume,
      price,
      totalPrice,
      percentageCommission,
      message,
    }),
    [
      ticker,
      commission,
      volume,
      price,
      totalPrice,
      percentageCommission,
      message,
    ],
  );

  useEffect(() => {
    setAudit(userId, accountId, JSON.stringify(auditMessage));
  }, [auditMessage, userId, accountId]);

  return (
    <Notification status="info" title="Kurtasjeandel">
      <Text>{message}</Text>
    </Notification>
  );
};

const BrokerageLimitWarning = (props: OrderDetails) => {
  const { data: brokerageLimit } = useFetchBrokerageLimit();
  const percentageCommission = Number(
    props.totalPrice ? (props.commission / props.totalPrice) * 100 : 0,
  );
  const showMessage =
    brokerageLimit &&
    percentageCommission &&
    percentageCommission > brokerageLimit;

  if (!showMessage) return null;

  return <Warning percentageCommission={percentageCommission} {...props} />;
};

export default BrokerageLimitWarning;
