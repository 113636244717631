import { useQuery } from 'react-query';
import { FormProps } from 'react-final-form';
import { useUser } from 'utils/hooks/useUser';
import { MARGIN_URL } from 'constants/Jaws';
import { MarginApplyForm } from './MarginApplyForm';
import { MarginApplyUpdate } from './MarginApplyUpdate';
import { get } from 'utils/fetch';

export interface MarginApplyProps extends Partial<FormProps> {
  children?: JSX.Element | JSX.Element[];
}

export const MarginApply = ({ children, ...props }: MarginApplyProps) => {
  const {
    data: { userId = '' },
  } = useUser();
  const { data, status } = useQuery(
    ['applications', userId],
    () =>
      get(`${MARGIN_URL}/${userId}/applications`).then(async response => {
        const result = await response.json();
        return {
          applications: result.data,
          id: result.data[0]?.id,
        };
      }),
    {
      enabled: !!userId,
    },
  );

  if (status === 'loading') return null;
  if (status === 'success' && data?.applications.length) {
    return <MarginApplyUpdate />;
  }

  return <MarginApplyForm />;
};
