import {
  ADMIN_LOGIN_URL,
  ADMIN_LOGOUT_URL,
  USER_LOGOUT_URL,
} from 'constants/Jaws';
import { get, post } from 'utils/fetch';

type Credentials = { username: string; password: string };

export const authenticateAdmin = async ({
  username,
  password,
}: Credentials) => {
  const response = await post(
    ADMIN_LOGIN_URL,
    `username=${username}&password=${password}`,
    {
      credientials: 'include',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  );
  if (!response.ok) throw response;
  return response;
};

export const logoutAdmin = async () => {
  const response = await get(ADMIN_LOGOUT_URL);
  if (!response.ok) throw response;
  return response;
};

export const logoutUser = async () => {
  const response = await get(USER_LOGOUT_URL);
  if (!response.ok) throw response;
  return response;
};
