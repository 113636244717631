import createDecorator from 'final-form-calculate';
import { calculateExpediency } from 'utils/expediencyTest';
import {
  EquityCertificates,
  Knowledge,
  Stocks,
  ExchangTradedFundsType,
} from '../types';

export const knowledgeDecorator = createDecorator(
  {
    field: 'knowledge.stocks',
    updates: {
      'knowledge.expediencyResults.stocks': (
        { knowledgeLevel, yearsExperience }: Stocks = {} as any,
      ) => calculateExpediency(knowledgeLevel, Number(yearsExperience)),
    },
  },
  {
    field: 'knowledge.equityCertificates',
    updates: {
      'knowledge.expediencyResults.equityCertificates': (
        { knowledgeLevel, yearsExperience }: EquityCertificates = {} as any,
      ) => calculateExpediency(knowledgeLevel, Number(yearsExperience)),
    },
  },
  {
    field: 'knowledge.etf',
    updates: {
      'knowledge.expediencyResults.etf': (
        { knowledgeLevel, yearsExperience }: ExchangTradedFundsType = {} as any,
      ) => calculateExpediency(knowledgeLevel, Number(yearsExperience)),
    },
  },
  {
    field: 'knowledge.expediencyResults',
    updates: {
      'knowledge.showWarning': ({
        stocks,
        equityCertificates,
        etf,
      }: Knowledge['expediencyResults'] = {}) => {
        if (stocks === 'NO' || equityCertificates === 'NO' || etf === 'NO')
          return true;
        return false;
      },
    },
  },
);
