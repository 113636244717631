import { Card, CardProps } from 'components/Card';
import { TickerTable } from '@oms/components-ticker-table';
import styled, { useTheme } from '@xstyled/emotion';

const StyledTickerTable: typeof TickerTable = styled(TickerTable)`
  [data-row] {
    cursor: default;
    &:active {
      background-color: surface-hover;
    }
  }
`;

export function MarketToday(props: Omit<CardProps, 'children'>) {
  const { colors } = useTheme();

  return (
    <Card title="Marked i dag" {...props}>
      <div />
      <StyledTickerTable
        showSparklines
        sparklineOptions={{ color: colors['primary'] }}
      />
    </Card>
  );
}
