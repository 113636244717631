import { isValidTickSize, getLimitRule } from '@oms/utils';
import { OPEN_VOLUME_MIN_PRICE } from 'utils/trade';
import { formatNumber } from '@oms/utils';
import * as FormUtils from 'utils/form';

export function validateLimit(limit, { data: tickSizeRules }) {
  const normalizedLimit = FormUtils.normalizeNumber(limit);
  const limitPositiveAndPresent = limit && normalizedLimit > 0;
  const validTickSize = isValidTickSize(normalizedLimit, tickSizeRules);

  if (limitPositiveAndPresent && !validTickSize) {
    // Allow empty while we are loading tickSize
    if (!tickSizeRules) {
      return undefined;
    }

    const tickSize = getLimitRule(normalizedLimit, tickSizeRules).accuracy;
    return `Tick Size er ${tickSize} for dette instrumentet`;
  }

  if (!limitPositiveAndPresent) {
    return 'Ugyldig limit';
  }

  return undefined;
}

export function validateOpenVolume({
  useOpenVolume,
  openVolume,
  volume,
  peakSize,
  limit,
}) {
  // Is less than volume and greater than min peakSize
  const normalizedOpenVolume = FormUtils.normalizeNumber(openVolume);
  const normalizedVolume = FormUtils.normalizeNumber(volume);

  if (useOpenVolume) {
    if (!openVolume) {
      return 'Ugyldig synlig volum';
    }

    if (normalizedOpenVolume >= normalizedVolume) {
      return 'Synlig volum må være mindre enn totalt antall';
    }

    /*
     * Minimum peak size for iceberg orders (EMS)
     * Defines the minimum visible size (“the peak”) when entering
     * orders where a part of the total volume is hidden
     * (“iceberg orders”).
     */

    if (normalizedOpenVolume < peakSize) {
      return `Synlig volum må være større enn eller lik ${peakSize}`;
    }

    /*
     * Order size must be minimum 10000 EUR when using hidden
     * volume. We validate against NOK, and this value is adjusted
     * yearly.
     */
    if (normalizedVolume * limit < OPEN_VOLUME_MIN_PRICE) {
      return `Totalbeløpet må være større enn ${formatNumber(
        OPEN_VOLUME_MIN_PRICE,
      )} kroner`;
    }
  }

  return undefined;
}

export function validateStopLoss({ useStopLoss, stopLossLimit, limit }) {
  // Is less than volume and greater than min peakSize
  const normalizedStopLoss = FormUtils.normalizeNumber(stopLossLimit);
  const normalizedLimit = FormUtils.normalizeNumber(limit);

  if (useStopLoss) {
    if (!stopLossLimit) {
      return 'Skriv inn en triggerpris';
    }

    if (normalizedStopLoss < normalizedLimit) {
      return `Limit må være mindre enn eller lik Stop Loss (${stopLossLimit})`;
    }
  }

  return undefined;
}
