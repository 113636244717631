import { Spec, useJaws } from '@oms/jaws-react';
import { ONE_SECOND } from 'constants/timeInMs';
import { useQueries, useQuery } from 'react-query';
import { getComponent } from 'utils/fetch';
import { calculateMarketValue } from 'utils/holdings';
import { useAccounts } from '../useAccounts';

// TODO: This is from previous solution,
// I think we should look into using a generator to get the marked data if it looks old/empty compared to ordinary jaws-query
const generateHoldingsSpec = (accountId: string | number) => {
  if (!accountId) return undefined;

  return {
    type: 'secure/portfolio',
    backend: 'probroker',
    columns:
      'ITEM, SECTOR, ITEM_SECTOR, LONG_NAME, LAST, LASTNZ_DIV, CLOSE,' +
      'POSITION_VOLUME, VOLUME, COST_BUY_PRICE, MARKET_VALUE, REALIZED_PROFIT,' +
      'UNREALIZED_PROFIT, CHANGE_PCT',
    portfolioId: accountId,
    initiatorComponent: 'UseHoldings',
  };
};

export type HoldingsRow = {
  COST_BUY_PRICE?: number;
  ITEM?: string;
  ITEM_SECTOR?: string;
  LAST?: number;
  LASTNZ_DIV?: number;
  LONG_NAME?: string;
  POSITION_VOLUME?: number;
  REALIZED_PROFIT?: number;
  SECTOR?: string;
  VOLUME?: number;
  CHANGE_PCT?: number;
  CLOSE?: number;
};

export type Arguments = {
  accountId: string;
  spec?: Spec;
};

export const getHoldings = async (accountId: string, spec?: Spec) => {
  const completeSpec = {
    ...generateHoldingsSpec(accountId),
    ...spec,
  };

  const data = await getComponent(completeSpec).then(data => data.rows);

  const rows = data
    .map((item: { key: string; values: HoldingsRow }) => item.values)
    .filter(
      (item: HoldingsRow) =>
        !!item.POSITION_VOLUME && item.SECTOR !== 'UNKNOWN',
    ) as HoldingsRow[];

  rows.sort((a, b) => {
    if (a.ITEM && b.ITEM) return a.ITEM.localeCompare(b.ITEM);
    return 0;
  });

  const { marketValue } = calculateMarketValue(rows);

  return { rows, marketValue, accountId };
};

export const useHoldings = ({ accountId, spec }: Arguments) => {
  const { data, ...queryState } = useQuery(
    ['holdings', accountId, spec],
    () => getHoldings(accountId, spec),
    { staleTime: ONE_SECOND * 30, enabled: !!accountId },
  );

  return { data, ...queryState };
};

export const useMarketValue = () => {
  const {
    data: { accounts },
  } = useAccounts();

  const allHoldings = accounts.map(account => ({
    queryKey: ['holdings', account.id, undefined],
    queryFn: () => getHoldings(account.id),
  }));

  const queries = useQueries(allHoldings);
  const allRows = queries
    .flatMap((query: any) => query?.data?.rows)
    .filter(Boolean);

  const totalMarketValue = calculateMarketValue(allRows);
  const marketValuePerAccount = Object.fromEntries(
    queries.map((query: any) => {
      const { accountId, rows = [] } = query?.data || {};
      const marketValue = calculateMarketValue(rows);
      return [accountId, marketValue];
    }),
  ) as { [accountId: string]: ReturnType<typeof calculateMarketValue> };

  return {
    getMarketValueOnAccount: (id: string) => marketValuePerAccount[id],
    totalMarketValue,
  };
};

/** @deprecated Caused too many requests, we'd have to place items in a store of some kind ^^ */
export const useHoldingsJaws = ({ accountId, spec }: Arguments) => {
  const { items, ...jawsState } = useJaws({
    ...generateHoldingsSpec(accountId),
    ...spec,
  });

  const data = items
    .sortBy(item => item.get('ITEM')) // TODO: implement sortBy-argument?
    .valueSeq()
    .toJS() as HoldingsRow[];

  const marketValue = calculateMarketValue(data);

  return { data, marketValue, ...jawsState };
};

export default useHoldings;
