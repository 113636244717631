import { Page } from 'components/Page';

import { Heading } from '@oms/ui-heading';
import {
  MarketAlertForm,
  AlertsList,
  AlertContactInformation,
  MarketAlertFormValues,
} from 'components/Alerts';

import { Stack } from '@oms/ui-stack';
import { useEffect, useMemo, useState } from 'react';
import {
  Alert,
  useCreateAlert,
  useDeleteAlert,
  useUpdateAlert,
} from 'utils/hooks/useAlerts';
import {
  createMarketAlertPayload,
  createNewsAlertPayload,
  getFormValuesFromAlert,
  getFormValuesFromItemSector,
} from 'utils/alerts';
import NewsAlertForm, {
  NewsAlertFormValues,
} from 'components/Alerts/NewsAlertForm';
import {
  ContentSwitcher,
  ContentSwitcherOption,
  useContentSwitcher,
} from '@oms/ui-content-switcher';
import { IconButton } from '@oms/ui-icon-button';
import { light } from '@oms/ui-icon';
import { Box } from '@oms/ui-box';
import { useSearchParam } from 'react-use';
import { Card } from 'components/Card';
import { ErrorBoundary } from '@oms/ui-error-boundary';

export default function Alerts() {
  const itemSectorFromSearch = useSearchParam('itemSector');
  const contentSwitcher = useContentSwitcher({
    orientation: 'horizontal',
    initialSelected: 'marketAlert',
  });
  const [activeAlert, setActiveAlert] = useState<Alert>();

  const { mutateAsync: updateAlert } = useUpdateAlert();
  const { mutateAsync: createAlert } = useCreateAlert();
  const { mutateAsync: deleteAlert } = useDeleteAlert();

  useEffect(() => {
    if (!activeAlert) return undefined;

    const type = activeAlert.type;
    if (!type) throw new Error(`Ukjent type alarm! "${type}"`);
    if (type === 'NEWS') {
      contentSwitcher.onChange('newsAlert');
    }
    if (type === 'INTRADAY' || type === 'THRESHOLD') {
      contentSwitcher.onChange('marketAlert');
    }
  }, [activeAlert, contentSwitcher]);

  const initialValues = useMemo(() => {
    if (itemSectorFromSearch) {
      return getFormValuesFromItemSector(itemSectorFromSearch);
    }
    if (!activeAlert) return undefined;

    const type = activeAlert.type;
    if (!type) throw new Error(`Ukjent type alarm! "${type}"`);

    if (activeAlert) return getFormValuesFromAlert(activeAlert);
    return undefined;
  }, [activeAlert, itemSectorFromSearch]);

  const handleMarketAlertSubmit = (values: MarketAlertFormValues) => {
    const type = values.trigger === 'HIGH_5YEARS' ? 'INTRADAY' : 'THRESHOLD';
    const payload = createMarketAlertPayload(type, values);

    if (activeAlert) return updateAlert({ payload, type, id: activeAlert.id });
    return createAlert({ payload, type });
  };

  const handleNewsAlertSubmit = (values: NewsAlertFormValues) => {
    const payload = createNewsAlertPayload(values);

    if (activeAlert)
      return updateAlert({ payload, type: 'NEWS', id: activeAlert.id });
    return createAlert({ payload, type: 'NEWS' });
  };

  return (
    <Page
      documentTitle="Alarmer"
      topElement={
        <Stack my={8} gap={8} minHeight="8rem" mx="auto" maxWidth="60rem">
          <Box center>
            <Heading as="h1" variant="heading2">
              Alarmer
            </Heading>
          </Box>
          <AlertContactInformation />
          <Stack gap={4}>
            {activeAlert ? (
              <Stack orientation="horizontal" alignment="center">
                <IconButton
                  size="sm"
                  icon={light.faTimes}
                  tooltip="Lukk redigering"
                  onClick={() => setActiveAlert(undefined)}
                />
                <Heading>
                  Redigerer{' '}
                  {activeAlert.type === 'NEWS' ? 'Nyhetsalarm' : 'Markedsalarm'}
                </Heading>
              </Stack>
            ) : (
              <div>
                <Box display="inline-block">
                  <ContentSwitcher variant="primary" {...contentSwitcher}>
                    <ContentSwitcherOption value="marketAlert">
                      Markedsalarm
                    </ContentSwitcherOption>
                    <ContentSwitcherOption value="newsAlert">
                      Nyhetsalarm
                    </ContentSwitcherOption>
                  </ContentSwitcher>
                </Box>
              </div>
            )}

            {contentSwitcher.selected === 'marketAlert' && (
              <ErrorBoundary>
                <MarketAlertForm
                  initialValues={initialValues}
                  onSubmit={handleMarketAlertSubmit}
                />
              </ErrorBoundary>
            )}
            {contentSwitcher.selected === 'newsAlert' && (
              <ErrorBoundary>
                <NewsAlertForm
                  initialValues={initialValues}
                  onSubmit={handleNewsAlertSubmit}
                />
              </ErrorBoundary>
            )}
          </Stack>
        </Stack>
      }
    >
      <Box my={8} mx="auto" maxWidth="60rem">
        <Card title="Alle alarmer">
          <AlertsList
            handleToggleAlert={alert => {
              const values = getFormValuesFromAlert(alert);

              const payload =
                alert.type === 'NEWS'
                  ? createNewsAlertPayload(values)
                  : createMarketAlertPayload(alert.type, values as any);

              return updateAlert({
                type: alert.type,
                id: alert.id,
                payload: {
                  ...payload,
                  active: !alert.active,
                },
              });
            }}
            handleEditAlert={alert => setActiveAlert(alert)}
            handleDeleteAlert={alert =>
              deleteAlert({ id: alert.id, type: alert.type })
            }
          />
        </Card>
      </Box>
    </Page>
  );
}

export { Alerts };
