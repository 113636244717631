import { useReportsSwitcher } from './ReportsSwitcher';
import { ReportArticle } from './ReportArticle';
import { ReportList } from './ReportList';

export const Reports = (props: any) => {
  const switcher = useReportsSwitcher();

  return (
    <>
      <ReportArticle type={switcher.selected} />
      {switcher.selected !== 'MORNINGREPORT' ? (
        <ReportList type={switcher.selected} />
      ) : null}
    </>
  );
};
