import { enforce } from './enforce';

/**
 * Creates an empty iFrame, and uses the URL to send data to native iOS.
 * @param {object} [payload] - Payload to be sent as JSON.
 */
export function sendIosEvent(payload) {
  var src =
    'sb1mobilbank://event/?' + encodeURIComponent(JSON.stringify(payload));
  var iframe = document.createElement('iframe');
  iframe.setAttribute('src', src);
  document.documentElement.appendChild(iframe);
  iframe.parentNode.removeChild(iframe);
  iframe = null;
}

/* eslint-disable no-console */
export function sendAndroidEvent() {
  var eventId =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('eventId');

  try {
    var _window$Android;

    for (
      var _len = arguments.length,
        params = Array(_len > 1 ? _len - 1 : 0),
        _key = 1;
      _key < _len;
      _key++
    ) {
      params[_key - 1] = arguments[_key];
    }

    (_window$Android = window.Android)[eventId].apply(_window$Android, params);
  } catch (e) {
    console.warn('window.Android.' + eventId + ' failed: ' + e);
  }
}

export function sendAndroidMenuEvent() {
  var eventId =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('eventId');

  try {
    var _window$Android_menu;

    for (
      var _len2 = arguments.length,
        params = Array(_len2 > 1 ? _len2 - 1 : 0),
        _key2 = 1;
      _key2 < _len2;
      _key2++
    ) {
      params[_key2 - 1] = arguments[_key2];
    }

    (_window$Android_menu = window.Android_menu)[eventId].apply(
      _window$Android_menu,
      params,
    );
  } catch (e) {
    console.warn('window.Android_menu.' + eventId + ' failed: ' + e);
  }
}
/* eslint-enable no-console */
