import { useJaws } from '@oms/jaws-react';
import { useFormState } from 'react-final-form';
import { Table, Thead, Tbody, Tr, Td, Th } from '@oms/ui-table';
import { decimalPlaces } from '@oms/utils';

function buildSpec(itemSector: string) {
  return {
    columns: 'LAST, LONG_NAME, ASK_VOL, ASK, BID, BID_VOL, TIME, ITEM',
    itemSector,
    filter: `ITEM_SECTOR==s${itemSector}`,
  };
}

export const QuickTradeTable = ({ form, buy, sell, hidden, sidebar }: any) => {
  const { values } = useFormState();
  const itemSector = values?.ticker + '.' + values?.exchange;
  if (!itemSector) throw new Error('Mangler instrument-data ved henting info');

  const spec = buildSpec(itemSector);
  const { items } = useJaws(spec);
  const data = items.sortBy(item => item.get('ITEM')).toJS();

  return (
    <div>
      <Table layout="auto" my="10px">
        <Thead>
          <Tr>
            <Th type="text" pl={sidebar && '3px'}>
              Navn
            </Th>
            <Th type="number" pl={sidebar && '1px'}>
              Siste
            </Th>
            <Th type="number">Kjøp vol</Th>
            <Th type="number">Kjøp</Th>
            <Th type="number">Selg</Th>
            <Th type="number">Selg vol</Th>
            {!sidebar && <Th type="dateTime">Tid</Th>}
          </Tr>
        </Thead>
        {Object.entries(data).map(([key, values]: any) => {
          const {
            ITEM,
            LASTNZ_DIV,
            CLOSE,
            LAST,
            ASK_VOL,
            BID,
            ASK,
            BID_VOL,
            TIME,
          } = values;
          const last = LASTNZ_DIV || CLOSE || LAST || 0;
          return (
            <Tbody>
              <Tr key={key} py="10px">
                <Td type="text" pl={sidebar ? '3px' : '10px'}>
                  {ITEM}
                </Td>
                <Td
                  type="number"
                  pl={sidebar && '0px'}
                  fractionDigits={decimalPlaces(last)}
                >
                  {last}
                </Td>
                <Td type="integer">{BID_VOL}</Td>
                <Td type="number" fontWeight="bold">
                  {BID}
                </Td>
                <Td type="number" fontWeight="bold">
                  {ASK}
                </Td>
                <Td type="integer">{ASK_VOL}</Td>
                {!sidebar && (
                  <Td type="dateTime" pl="10px">
                    {TIME}
                  </Td>
                )}
              </Tr>
            </Tbody>
          );
        })}
      </Table>
    </div>
  );
};
