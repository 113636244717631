import { MarketAlertFormValues } from '.';

export const validate = (values: MarketAlertFormValues) => {
  const errors: { [key: string]: any } = {};
  if (!values?.instrumentType) {
    errors.instrumentType = 'Påkrevet';
  }
  if (!values?.item && !values?.instrument) {
    errors.instrument = 'Påkrevet';
    errors.item = 'Påkrevet';
  }
  if (!values?.trigger) {
    errors.trigger = 'Påkrevet';
  }
  if (values?.trigger !== 'HIGH_5YEARS') {
    if (!values?.triggerCriterion) {
      errors.triggerCriterion = 'Påkrevet';
    }
    if (!values?.triggerValue) {
      errors.triggerValue = 'Påkrevet';
    }
  }
  return errors;
};
