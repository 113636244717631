import { HoldingsRow } from './hooks/useHoldings';

export const calculateMarketValue = (holdings: HoldingsRow[]) => {
  const result = holdings.reduce(
    (sum, item) => {
      const {
        LAST,
        CLOSE,
        LASTNZ_DIV,
        POSITION_VOLUME = 0,
        COST_BUY_PRICE = 0,
      } = item;

      const last = LASTNZ_DIV || CLOSE || LAST || 0;

      const marketValue = POSITION_VOLUME * last;
      const unrealized = marketValue - COST_BUY_PRICE;

      return {
        marketValue: sum.marketValue + marketValue,
        unrealized: sum.unrealized + unrealized,
        cost: sum.cost + COST_BUY_PRICE,
      };
    },
    { marketValue: 0, unrealized: 0, cost: 0 },
  );

  const unrealizedPct = (result.unrealized / result.cost) * 100;

  return { ...result, unrealizedPct };
};
