import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { Stack } from '@oms/ui-stack';

export const CustomerInformation = () => {
  return (
    <Stack gap={4} mt={4}>
      <Field
        as={TextInput}
        name="personalia.name"
        label="Bedriftsnavn"
        disabled
      />

      <Field
        name="personalia.companyNumber"
        as={TextInput}
        label="Organisasjonsnummer"
        disabled
      />

      <Field
        name="personalia.address"
        as={TextInput}
        label="Adresse"
        disabled
      />

      <Field
        name="personalia.zipCode"
        as={TextInput}
        label="Postnummer"
        disabled
      />

      <Field
        name="personalia.phoneNumber"
        as={TextInput}
        label="Telefon"
        disabled
      />

      <Field name="personalia.email" as={TextInput} label="E-post" disabled />

      <Field
        name="personalia.vpsAccountNo"
        as={TextInput}
        label="VPS-konto"
        disabled
      />

      <Field
        name="personalia.bankAccountNo"
        as={TextInput}
        label="Bankkonto"
        disabled
      />
    </Stack>
  );
};

export default CustomerInformation;
