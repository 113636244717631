import { Tr, Td, Table, Th, Tfoot } from '@oms/ui-table';
import { decimalPlaces } from '@oms/utils';
import { HoldingsRow as HoldingsRowType } from 'utils/hooks/useHoldings';
import { TradeButton } from 'components/Buttons';
import {
  useTableDisclosure,
  TableDisclosureButton,
  TableDisclosureContent,
  UseTableDisclosureHeadingReturn,
} from 'components/TableDisclosure';
import { formatNumber } from '@oms/utils';
import { getTrendColor } from 'utils/trend';
import { Box } from '@oms/ui-box';

type Props = {
  row: HoldingsRowType;
  /** If true, moves 'Siste' and 'Kostpris' to dropdown */
  isMobile: boolean;
} & UseTableDisclosureHeadingReturn;

export const HoldingsRow = ({ row, isMobile, headers }: Props) => {
  const disclosure = useTableDisclosure();
  const {
    ITEM = '-',
    LONG_NAME = '-',
    ITEM_SECTOR,
    LASTNZ_DIV,
    CLOSE,
    LAST = 0,
    POSITION_VOLUME = 0,
    COST_BUY_PRICE = 0,
    CHANGE_PCT = 0,
    REALIZED_PROFIT = 0,
  } = row;

  const last = LASTNZ_DIV || CLOSE || LAST;
  const MARKET_VALUE = POSITION_VOLUME * last;
  const UNREALIZED_PROFIT = MARKET_VALUE - COST_BUY_PRICE;
  const AVG_COST_BUY_PRICE = COST_BUY_PRICE / POSITION_VOLUME;
  const UNREALIZED_PROFIT_PCT =
    UNREALIZED_PROFIT && COST_BUY_PRICE
      ? (UNREALIZED_PROFIT / COST_BUY_PRICE) * 100
      : 0;

  return (
    <>
      <Tr key={ITEM_SECTOR}>
        <Td
          type="symbol"
          linkTo={ITEM_SECTOR && `/instrument/${ITEM_SECTOR}`}
          ticker={ITEM}
          name={LONG_NAME}
        />
        {!isMobile ? (
          <Td textAlign="right" type="percent" trend>
            {CHANGE_PCT}
          </Td>
        ) : null}
        {!isMobile ? (
          <Td type="number" fractionDigits={decimalPlaces(last)}>
            {last}
          </Td>
        ) : null}

        <Td type="numberWithUnit" suffix=" kr" trend>
          {MARKET_VALUE}
        </Td>

        <Td textAlign="right">
          <Box
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={{ md: 'flex-end' }}
          >
            {formatNumber(UNREALIZED_PROFIT)}
            {isMobile ? (
              <Box
                as="span"
                color={getTrendColor(UNREALIZED_PROFIT) ?? 'text'}
                fontSize={{ base: 'sm', md: 'inherit' } as any}
              >
                {formatNumber(UNREALIZED_PROFIT_PCT)}%
              </Box>
            ) : (
              <Box as="span" ml={1}>
                (
                <Box
                  as="span"
                  color={getTrendColor(UNREALIZED_PROFIT) ?? 'text'}
                >
                  {formatNumber(UNREALIZED_PROFIT_PCT)}%
                </Box>
                )
              </Box>
            )}
          </Box>
        </Td>

        {!isMobile ? <Td type="integer">{POSITION_VOLUME}</Td> : null}
        <TableDisclosureButton {...disclosure} />
      </Tr>
      <TableDisclosureContent colSpan={7} headers={headers} {...disclosure}>
        <Table variant="keyValue" width="initial">
          {isMobile ? (
            <>
              <Tr>
                <Th type="percent">+/-%</Th>
                <Td type="percent" trend>
                  {CHANGE_PCT}
                </Td>
              </Tr>
              <Tr>
                <Th type="number">Kurs</Th>
                <Td type="number" fractionDigits={decimalPlaces(last)}>
                  {last}
                </Td>
              </Tr>
              <Tr>
                <Th type="integer">Antall</Th>
                <Td type="integer">{POSITION_VOLUME}</Td>
              </Tr>
            </>
          ) : null}
          <Tr>
            <Th type="numberWithUnit">Kostpris</Th>
            <Td
              type="number"
              fractionDigits={decimalPlaces(AVG_COST_BUY_PRICE)}
            >
              {AVG_COST_BUY_PRICE}
            </Td>
          </Tr>
          <Tr>
            <Th type="numberWithUnit">Total kostpris</Th>
            <Td type="numberWithUnit" suffix=" kr">
              {COST_BUY_PRICE}
            </Td>
          </Tr>
          <Tr>
            <Th type="numberWithUnit">Markedsverdi</Th>
            <Td type="numberWithUnit" suffix=" kr">
              {MARKET_VALUE}
            </Td>
          </Tr>
          <Tr>
            <Th type="numberWithUnit">Realisert gevinst/tap</Th>
            <Td type="numberWithUnit" suffix=" kr">
              {REALIZED_PROFIT}
            </Td>
          </Tr>
          <Tr>
            <Th type="numberWithUnit">Urealisert gevinst/tap</Th>
            <Td type="numberWithUnit" suffix=" kr">
              {UNREALIZED_PROFIT}
            </Td>
          </Tr>
          <Tfoot>
            <Tr>
              <Td colSpan={2}>
                Kurtasjeomkostninger er inkludert i kostpris. Manglende kostpris
                kan korrigeres ved å sende oss korrekt kostpris i
                kontaktskjemaet på Min side.
              </Td>
            </Tr>
          </Tfoot>
        </Table>
        {ITEM_SECTOR && <TradeButton itemSector={ITEM_SECTOR} />}
      </TableDisclosureContent>
    </>
  );
};

export default HoldingsRow;
