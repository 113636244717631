import { Text } from '@oms/ui-text';
import { Box, BoxProps } from '@oms/ui-box';
import { formatNumber, decimalPlaces, formatInteger } from '@oms/utils';
import { Skeleton } from '@oms/ui-skeleton';

const formatTitle = (type: Props['type'], title: string) => (
  <Text
    color="primary"
    fontWeight="medium"
    as="dt"
    pb={1}
    uppercase
    textAlign={type === 'string' ? undefined : { md: 'right' }}
  >
    {title}
  </Text>
);

const formatValue = (
  type: Props['type'],
  value: Props['value'],
  isPending = false,
) => {
  if (isPending)
    return (
      <Skeleton
        as="dd"
        animated
        height="1rem"
        width="100%"
        aria-label="laster"
      />
    );

  if (type === 'percentage') return formatPercentageText(value as number);
  if (type === 'number') return formatNumberText(value as number);
  if (type === 'integer') return formatNumberInteger(value as number);
  return <Text as="dd">{value}</Text>;
};

const formatPercentageText = (value: number) => (
  <Text as="dd" textAlign={{ md: 'right' }}>
    {Math.sign(value) === 1 ? '+' : ''}
    {formatNumber(value, 2)} %
  </Text>
);

const formatNumberText = (value: number) => (
  <Text as="dd" textAlign={{ md: 'right' }}>
    {value ? formatNumber(value, decimalPlaces(value)) : '-'}
  </Text>
);

const formatNumberInteger = (value: any) => (
  <Text as="dd" textAlign={{ md: 'right' }}>
    {value ? formatInteger(value) : '-'}
  </Text>
);

export type Props = {
  title: string;
  value: number | string | React.ReactChild;
  type: 'percentage' | 'number' | 'string' | 'integer';
  isPending?: boolean;
};

export const FormattedBox = ({
  title,
  value,
  type,
  isPending = false,
  ...props
}: Props & BoxProps) => (
  <Box {...props}>
    {formatTitle(type, title)} {formatValue(type, value, isPending)}
  </Box>
);
