import { Card } from '../Card';
import { Box } from '@oms/ui-box';
import FileUploadField from 'components/FileUpload/Field';

export interface FileUploadProps {}
export function FileUpload({ title }: any, _props: FileUploadProps) {
  return (
    <Card title={title}>
      <Box
        px={{ base: 3, sm: 'initial' as any }}
        pb={{ base: 3, sm: 'initial' as any }}
      >
        <FileUploadField />
      </Box>
    </Card>
  );
}
