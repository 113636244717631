const locales = [
  { code: 'NOR', name: 'Norge' },
  { code: 'ABW', name: 'Aruba' },
  { code: 'AFG', name: 'Afghanistan' },
  { code: 'AGO', name: 'Angola' },
  { code: 'AIA', name: 'Anguilla' },
  { code: 'ALA', name: 'Åland' },
  { code: 'ALB', name: 'Albania' },
  { code: 'AND', name: 'Andorra' },
  { code: 'ARE', name: 'De forente arabiske emirater' },
  { code: 'ARG', name: 'Argentina' },
  { code: 'ARM', name: 'Armenia' },
  { code: 'ASM', name: 'Amerikansk Samoa' },
  { code: 'ATA', name: 'Antarktis' },
  { code: 'ATF', name: 'De franske sørterritorier' },
  { code: 'ATG', name: 'Antigua og Barbuda' },
  { code: 'AUS', name: 'Australia' },
  { code: 'AUT', name: 'Østerrike' },
  { code: 'AZE', name: 'Aserbajdsjan' },
  { code: 'BDI', name: 'Burundi' },
  { code: 'BEL', name: 'Belgia' },
  { code: 'BEN', name: 'Benin' },
  { code: 'BES', name: 'Bonaire, Sint Eustatius og Saba' },
  { code: 'BFA', name: 'Burkina Faso' },
  { code: 'BGD', name: 'Bangladesh' },
  { code: 'BGR', name: 'Bulgaria' },
  { code: 'BHR', name: 'Bahrain' },
  { code: 'BHS', name: 'Bahamas' },
  { code: 'BIH', name: 'Bosnia-Hercegovina' },
  { code: 'BLM', name: 'Saint-Barthélemy' },
  { code: 'BLR', name: 'Hviterussland' },
  { code: 'BLZ', name: 'Belize' },
  { code: 'BMU', name: 'Bermuda' },
  { code: 'BOL', name: 'Bolivia' },
  { code: 'BRA', name: 'Brasil' },
  { code: 'BRB', name: 'Barbados' },
  { code: 'BRN', name: 'Brunei' },
  { code: 'BTN', name: 'Bhutan' },
  { code: 'BVT', name: 'Bouvetøya' },
  { code: 'BWA', name: 'Botswana' },
  { code: 'CAF', name: 'Den sentralafrikanske republikk' },
  { code: 'CAN', name: 'Canada' },
  { code: 'CCK', name: 'Kokosøyene' },
  { code: 'CHE', name: 'Sveits' },
  { code: 'CHL', name: 'Chile' },
  { code: 'CHN', name: 'Kina' },
  { code: 'CIV', name: 'Elfenbenskysten' },
  { code: 'CMR', name: 'Kamerun' },
  { code: 'COD', name: 'Den demokratiske republikken Kongo' },
  { code: 'COG', name: 'Republikken Kongo' },
  { code: 'COK', name: 'Cookøyene' },
  { code: 'COL', name: 'Colombia' },
  { code: 'COM', name: 'Komorene' },
  { code: 'CPV', name: 'Kapp Verde' },
  { code: 'CRI', name: 'Costa Rica' },
  { code: 'CUB', name: 'Cuba' },
  { code: 'CUW', name: 'Curaçao' },
  { code: 'CXR', name: 'Christmasøya' },
  { code: 'CYM', name: 'Caymanøyene' },
  { code: 'CYP', name: 'Kypros' },
  { code: 'CZE', name: 'Tsjekkia' },
  { code: 'DEU', name: 'Tyskland' },
  { code: 'DJI', name: 'Djibouti' },
  { code: 'DMA', name: 'Dominica' },
  { code: 'DNK', name: 'Danmark' },
  { code: 'DOM', name: 'Den dominikanske republikk' },
  { code: 'DZA', name: 'Algerie' },
  { code: 'ECU', name: 'Ecuador' },
  { code: 'EGY', name: 'Egypt' },
  { code: 'ERI', name: 'Eritrea' },
  { code: 'ESH', name: 'Vest-Sahara' },
  { code: 'ESP', name: 'Spania' },
  { code: 'EST', name: 'Estland' },
  { code: 'ETH', name: 'Etiopia' },
  { code: 'FIN', name: 'Finland' },
  { code: 'FJI', name: 'Fiji' },
  { code: 'FLK', name: 'Falklandsøyene' },
  { code: 'FRA', name: 'Frankrike' },
  { code: 'FRO', name: 'Færøyene' },
  { code: 'FSM', name: 'Mikronesiaføderasjonen' },
  { code: 'GAB', name: 'Gabon' },
  { code: 'GBR', name: 'Storbritannia' },
  { code: 'GEO', name: 'Georgia' },
  { code: 'GHA', name: 'Ghana' },
  { code: 'GIB', name: 'Gibraltar' },
  { code: 'GIN', name: 'Guinea' },
  { code: 'GLP', name: 'Guadeloupe' },
  { code: 'GMB', name: 'Gambia' },
  { code: 'GNB', name: 'Guinea-Bissau' },
  { code: 'GNQ', name: 'Ekvatorial-Guinea' },
  { code: 'GRC', name: 'Hellas' },
  { code: 'GRD', name: 'Grenada' },
  { code: 'GRL', name: 'Grønland' },
  { code: 'GTM', name: 'Guatemala' },
  { code: 'GUF', name: 'Fransk Guyana' },
  { code: 'GUM', name: 'Guam' },
  { code: 'GUY', name: 'Guyana' },
  { code: 'HKG', name: 'Hongkong' },
  { code: 'HMD', name: 'Heard- og McDonaldøyene' },
  { code: 'HND', name: 'Honduras' },
  { code: 'HRV', name: 'Kroatia' },
  { code: 'HTI', name: 'Haiti' },
  { code: 'HUN', name: 'Ungarn' },
  { code: 'IDN', name: 'Indonesia' },
  { code: 'IMN', name: 'Man' },
  { code: 'IND', name: 'India' },
  { code: 'IOT', name: 'Det britiske territoriet i Indiahavet' },
  { code: 'IRL', name: 'Irland' },
  { code: 'IRN', name: 'Iran' },
  { code: 'IRQ', name: 'Irak' },
  { code: 'ISL', name: 'Island' },
  { code: 'ISR', name: 'Israel' },
  { code: 'ITA', name: 'Italia' },
  { code: 'JAM', name: 'Jamaica' },
  { code: 'JEY', name: 'Jersey' },
  { code: 'JPN', name: 'Japan' },
  { code: 'JOR', name: 'Jordan' },
  { code: 'KAZ', name: 'Kasakhstan' },
  { code: 'KEN', name: 'Kenya' },
  { code: 'KGZ', name: 'Kirgisistan' },
  { code: 'KHM', name: 'Kambodsja' },
  { code: 'KIR', name: 'Kiribati' },
  { code: 'KNA', name: 'Saint Kitts og Nevis' },
  { code: 'KOR', name: 'Sør-Korea' },
  { code: 'KWT', name: 'Kuwait' },
  { code: 'LAO', name: 'Laos' },
  { code: 'LBN', name: 'Libanon' },
  { code: 'LBR', name: 'Liberia' },
  { code: 'LBY', name: 'Libya' },
  { code: 'LCA', name: 'Saint Lucia' },
  { code: 'LIE', name: 'Liechtenstein' },
  { code: 'LKA', name: 'Sri Lanka' },
  { code: 'LSO', name: 'Lesotho' },
  { code: 'LTU', name: 'Litauen' },
  { code: 'LUX', name: 'Luxembourg' },
  { code: 'LVA', name: 'Latvia' },
  { code: 'MAC', name: 'Macao' },
  { code: 'MAF', name: 'Saint-Martin' },
  { code: 'MAR', name: 'Marokko' },
  { code: 'MCO', name: 'Monaco' },
  { code: 'MDA', name: 'Moldova' },
  { code: 'MDG', name: 'Madagaskar' },
  { code: 'MDV', name: 'Maldivene' },
  { code: 'MEX', name: 'Mexico' },
  { code: 'MHL', name: 'Marshalløyene' },
  { code: 'MKD', name: 'Nord-Makedonia' },
  { code: 'MLI', name: 'Mali' },
  { code: 'MLT', name: 'Malta' },
  { code: 'MMR', name: 'Myanmar' },
  { code: 'MNE', name: 'Montenegro' },
  { code: 'MNG', name: 'Mongolia' },
  { code: 'MNP', name: 'Nord-Marianene' },
  { code: 'MOZ', name: 'Mosambik' },
  { code: 'MRT', name: 'Mauritania' },
  { code: 'MSR', name: 'Montserrat' },
  { code: 'MTQ', name: 'Martinique' },
  { code: 'MUS', name: 'Mauritius' },
  { code: 'MWI', name: 'Malawi' },
  { code: 'MYS', name: 'Malaysia' },
  { code: 'MYT', name: 'Mayotte' },
  { code: 'NAM', name: 'Namibia' },
  { code: 'NCL', name: 'Ny-Caledonia' },
  { code: 'NER', name: 'Niger' },
  { code: 'NFK', name: 'Norfolkøya' },
  { code: 'NGA', name: 'Nigeria' },
  { code: 'NIC', name: 'Nicaragua' },
  { code: 'NIU', name: 'Niue' },
  { code: 'NLD', name: 'Nederland' },
  { code: 'NPL', name: 'Nepal' },
  { code: 'NRU', name: 'Nauru' },
  { code: 'NZL', name: 'Ny-Zealand' },
  { code: 'OMN', name: 'Oman' },
  { code: 'PAK', name: 'Pakistan' },
  { code: 'PAN', name: 'Panama' },
  { code: 'PCN', name: 'Pitcairnøyene' },
  { code: 'PER', name: 'Peru' },
  { code: 'PHL', name: 'Filippinene' },
  { code: 'PLW', name: 'Palau' },
  { code: 'PNG', name: 'Papua Ny-Guinea' },
  { code: 'POL', name: 'Polen' },
  { code: 'PRI', name: 'Puerto Rico' },
  { code: 'PRK', name: 'Nord-Korea' },
  { code: 'PRT', name: 'Portugal' },
  { code: 'PRY', name: 'Paraguay' },
  { code: 'PSE', name: 'De palestinske territoriene' },
  { code: 'PYF', name: 'Fransk Polynesia' },
  { code: 'QAT', name: 'Qatar' },
  { code: 'REU', name: 'Réunion' },
  { code: 'ROU', name: 'Romania' },
  { code: 'RUS', name: 'Russland' },
  { code: 'RWA', name: 'Rwanda' },
  { code: 'SAU', name: 'Saudi-Arabia' },
  { code: 'SDN', name: 'Sudan' },
  { code: 'SEN', name: 'Senegal' },
  { code: 'SGP', name: 'Singapore' },
  { code: 'SGS', name: 'Sør-Georgia og Sør-Sandwichøyene' },
  { code: 'SHN', name: 'St. Helena, Ascension og Tristan da Cunha' },
  { code: 'SJM', name: 'Svalbard og Jan Mayen' },
  { code: 'SLB', name: 'Salomonøyene' },
  { code: 'SLE', name: 'Sierra Leone' },
  { code: 'SLV', name: 'El Salvador' },
  { code: 'SMR', name: 'San Marino' },
  { code: 'SOM', name: 'Somalia' },
  { code: 'SPM', name: 'Saint-Pierre og Miquelon' },
  { code: 'SRB', name: 'Serbia' },
  { code: 'SSD', name: 'Sør-Sudan' },
  { code: 'STP', name: 'São Tomé og Príncipe' },
  { code: 'SUR', name: 'Surinam' },
  { code: 'SVK', name: 'Slovakia' },
  { code: 'SVN', name: 'Slovenia' },
  { code: 'SWE', name: 'Sverige' },
  { code: 'SWZ', name: 'Swaziland' },
  { code: 'SXM', name: 'Sint Maarten' },
  { code: 'SYC', name: 'Seychellene' },
  { code: 'SYR', name: 'Syria' },
  { code: 'TCA', name: 'Turks- og Caicosøyene' },
  { code: 'TCD', name: 'Tsjad' },
  { code: 'TGO', name: 'Togo' },
  { code: 'THA', name: 'Thailand' },
  { code: 'TJK', name: 'Tadsjikistan' },
  { code: 'TKL', name: 'Tokelau' },
  { code: 'TKM', name: 'Turkmenistan' },
  { code: 'TLS', name: 'Øst-Timor' },
  { code: 'TON', name: 'Tonga' },
  { code: 'TTO', name: 'Trinidad og Tobago' },
  { code: 'TUN', name: 'Tunisia' },
  { code: 'TUR', name: 'Tyrkia' },
  { code: 'TUV', name: 'Tuvalu' },
  { code: 'TWN', name: 'Taiwan' },
  { code: 'TZA', name: 'Tanzania' },
  { code: 'UGA', name: 'Uganda' },
  { code: 'UKR', name: 'Ukraina' },
  { code: 'UMI', name: 'USAs ytre småøyer' },
  { code: 'URY', name: 'Uruguay' },
  { code: 'USA', name: 'USA' },
  { code: 'UZB', name: 'Usbekistan' },
  { code: 'VAT', name: 'Vatikanstaten' },
  { code: 'VCT', name: 'Saint Vincent og Grenadinene' },
  { code: 'VEN', name: 'Venezuela' },
  { code: 'VGB', name: 'De britiske Jomfruøyer' },
  { code: 'VIR', name: 'De amerikanske Jomfruøyer' },
  { code: 'VNM', name: 'Vietnam' },
  { code: 'VUT', name: 'Vanuatu' },
  { code: 'WLF', name: 'Wallis- og Futunaøyene' },
  { code: 'WSM', name: 'Samoa' },
  { code: 'YEM', name: 'Jemen' },
  { code: 'ZAF', name: 'Sør-Afrika' },
  { code: 'ZMB', name: 'Zambia' },
  { code: 'ZWE', name: 'Zimbabwe' },
];

export const countryOptions = locales.map(({ code, name }) => ({
  value: code,
  label: name,
}));

export const monthsOptions = [
  { value: 'January', label: 'Januar' },
  { value: 'February', label: 'Februar' },
  { value: 'March', label: 'Mars' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'Mai' },
  { value: 'June', label: 'Juni' },
  { value: 'July', label: 'Juli' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'Oktober' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'Desember' },
];

export default locales;
