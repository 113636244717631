import React from 'react';
import { Card } from '../Card';
import { Field } from '@oms/ui-field';
import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';
import { Checkbox } from '@oms/ui-checkbox';

export interface ConfirmApplyProps {}

export function ConfirmApply(_props: ConfirmApplyProps) {
  return (
    <Card title="4. Bekreft opplysninger og send inn lånesøknad">
      <Box
        display="grid"
        gridGap={4}
        px={{ base: 3, sm: 'initial' as any }}
        pb={{ base: 3, sm: 'initial' as any }}
      >
        <Text>
          Jeg bekrefter at opplysningene overfor er korrekte og at jeg er klar
          over at Sparebank 1 Markets vil kontakte bankforbindels(er), og/eller
          øvrige konsesjonsgitte kredittopplysningsbaser, for å kunne skaffe til
          veie de opplysninger som anses nødvendig for godkjennelse av denne
          søknaden. Sparebank 1 Markets forbeholder seg retten til å avslå
          søknaden.
        </Text>
        <Field as={Checkbox} name="acceptTerms" label="Jeg bekrefter" />
        <Text>Denne søknad er gyldig i 14 dager.</Text>
      </Box>
    </Card>
  );
}
