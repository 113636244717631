import { Field, Fieldset } from '@oms/ui-field';
import Condition from 'components/FormComponents/Condition';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { TextInput } from '@oms/ui-text-input';
import { Textarea } from '@oms/ui-textarea';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import { required, notEmptyArray, composeValidators } from '../validate';
import { InfoBubble } from '@oms/ui-info-bubble';
import { Box } from '@oms/ui-box';
import styled from '@emotion/styled';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

const SubForm = styled.div`
  padding-left: 20px;
  padding-block: 10px;
`;

export const OriginOfFunds = () => {
  const currentYear = new Date().getFullYear();
  const yearsOptions = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);

  return (
    <Stack gap={4}>
      <Heading>Selskapets virksomhet og formål? </Heading>
      <Box display="flex" alignItems="center">
        <PdfTextInput
          name="companyBusinness"
          label="Hva er selskapets virksomhet?"
          isPDFRender={isPDFRender}
          asTextArea
        />
        <InfoBubble aria-label="beskrivelse" ml={4}>
          Hva driver selskapet med?
        </InfoBubble>
      </Box>

      <Box display="flex" alignItems="center">
        <PdfTextInput
          name="companyMainIncome"
          label="Hva er virksomhetens hovedinntekskilde?"
          isPDFRender={isPDFRender}
          asTextArea
        />
        <InfoBubble aria-label="beskrivelse" ml={4}>
          Beskriv hva hovedinntekten til bedriften kommer fra.
        </InfoBubble>
      </Box>
      <Heading>Hvor kommer beløpet som du skal investere fra? </Heading>
      <Fieldset
        as={CheckboxGroup}
        name="kycOriginOfFunds"
        legend="Du kan velge flere alternativer"
      >
        <Field
          as={Checkbox}
          label="Investering"
          value="INVESTMENT"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Utbytte"
          value="DIVIDENT"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Annet"
          value="OTHER"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Condition when="kycOriginOfFunds" includes="OTHER">
          <SubForm>
            <PdfTextInput
              name="kycExplainOriginOfFunds"
              label="Beskrivelse"
              isPDFRender={isPDFRender}
            />
          </SubForm>
        </Condition>
      </Fieldset>
    </Stack>
  );
};

export default OriginOfFunds;
