import { BASE_SECURE_URL } from 'constants/Jaws';
import { useMutation, useQueryClient } from 'react-query';
import { post } from 'utils/fetch';

const postForm = (payload: any) => {
  return post(`${BASE_SECURE_URL}/contactForm`, payload);
};

export const useContactForm = () => {
  const queryClient = useQueryClient();
  return useMutation(postForm, {
    onSuccess: () => queryClient.invalidateQueries('contactForm'),
  });
};

export default useContactForm;
