import LoadingPage from 'components/TEMP/LoadingPage';
import { lazy, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, Route } from 'react-router-dom';
import { FetchError } from 'utils/fetch';
import { useNotification } from 'utils/hooks/useNotification';
import useUser from 'utils/hooks/useUser';
import { RouteProps } from './types';

const AdminApp = lazy(() => import('components/admin/App'));

/** Kinda WIP: Testing out adding a defualt onError to mutations */
const useNotifyOnError = () => {
  const queryClient = useQueryClient();
  const notify = useNotification();

  useEffect(() => {
    const currentOptions = queryClient.getDefaultOptions();
    queryClient.setDefaultOptions({
      ...currentOptions,
      mutations: {
        ...currentOptions.mutations,
        onError: err =>
          notify({
            status: 'error',
            title: 'Noe gikk galt',
            children: (err as FetchError)?.message,
          }),
      },
    });
  }, [notify, queryClient]);
};

export const AdminRoute = ({
  component: Component,
  path,
  exact,
  noWrapper,
}: RouteProps) => {
  useNotifyOnError();

  const {
    data: { isAdmin },
    isLoading,
  } = useUser();

  if (isLoading) return <LoadingPage />;

  if (!isAdmin) return <Redirect to="/admin/login" />;

  return (
    <Route
      path={path}
      exact={exact}
      render={props =>
        noWrapper ? (
          <Component {...props} />
        ) : (
          <AdminApp {...props}>
            <Component {...props} />
          </AdminApp>
        )
      }
    />
  );
};
