import { Button } from '@oms/ui-button';
import styled from '@xstyled/emotion';

export const SearchButtonMobile = styled.button`
  background-color: transparent;
  position: relative;
  border: none;
  appearance: none;
  cursor: pointer;
  height: 100%;
  padding-left: 1.25rem;
`;

export const SearchButton = styled.button`
  appearance: none;
  height: 3rem;
  padding: 2 5;
  border: sm;
  border-color: border;
  border-radius: rounded;
  min-width: 18rem;
  width: 70%;
  margin: auto;
  font-size: lg;
  font-family: body;
  text-align: left;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  color: text-placeholder;
  &:hover {
    border-color: contrast-border;
    background-color: input-hover;
    color: text;
  }
  &:focus {
    box-shadow: focus;
    color: text;
  }
  & * {
    cursor: pointer;
    user-select: none;
  }
`;

export const Text = styled.divBox``;

export const KdbContainer = styled.div`
  display: grid;
  grid-gap: 2;
  grid-auto-flow: column;
  align-items: center;
`;

export const Kbd = styled.kbd`
  border: sm;
  border-bottom-width: lg;
  border-color: border;
  padding: 1 2;
  border-radius: md;
  font-family: body;
  font-size: sm;
  color: primary;
  min-width: 1.875rem;
  text-align: center;

  abbr {
    text-decoration: none;
  }
`;

export const WatchlistButton = styled(Button)`
  display: flex;
  color: text-message;
`;
