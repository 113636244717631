import { MarketAlertFormValues, NewsAlertFormValues } from 'components/Alerts';
import {
  decimalPlaces,
  formatNumber,
  getItemAndSectorFromItemSector,
} from '@oms/utils';
import { Alert } from './hooks/useAlerts';
import { normalizeNumber } from './form';

export const alertInstrumentTypeOptions = [
  { value: 'stocks', label: 'Aksjer' },
  { value: 'indices', label: 'Indekser' },
  { value: 'commodities', label: 'Råvarer' },
  { value: 'currencies', label: 'Valuta' },
  { value: 'interests', label: 'Renter' },
] as const;

export const alertTriggerOptions = [
  { value: 'LAST', label: 'Sist omsatt' },
  { value: 'CHANGE', label: 'Endring intradag' },
  { value: 'CHANGE_PCT', label: 'Endring intradag %' },
  { value: 'HIGH_5YEARS', label: 'Høyest over 5 år' },
] as const;
export const alertTriggerCriterion = [
  { label: 'Faller minst', value: 'LTE' },
  { label: 'Stiger minst', value: 'GTE' },
] as const;
export const alertTriggerCriterionLast = [
  { label: 'Faller til minst', value: 'LTE' },
  { label: 'Stiger til minst', value: 'GTE' },
] as const;
export const alertTriggerCriterionChange = [
  { label: 'Faller med minst', value: 'LTE' },
  { label: 'Stiger med minst', value: 'GTE' },
] as const;

export const getAlertTriggerCriterion = (
  value?: 'LAST' | 'CHANGE' | 'CHANGE_PCT' | 'HIGH_5YEARS' | string,
) => {
  if (!value) return [];
  if (value === 'LAST') return alertTriggerCriterionLast;
  if (value === 'CHANGE' || value === 'CHANGE_PCT')
    return alertTriggerCriterionChange;
  return alertTriggerCriterion;
};

export const alertInstrumentOptions = {
  indices: [
    {
      label: 'OSEBX',
      value: 'OSEBX.OSE',
    },
    {
      label: 'AEX',
      value: 'I:AEX.EUR',
    },
    {
      label: 'CAC 40',
      value: 'I:PX1.EUR',
    },
    {
      label: 'DAX',
      value: 'I:DAX.GER',
    },
    {
      label: 'Euronext 100',
      value: 'I:N100.EUR',
    },
    {
      label: 'OMXSPI',
      value: 'OMXSPI.SSE',
    },
    {
      label: 'Dow Jones (IA)',
      value: 'I:DJI.DJ',
    },
    {
      label: 'Nasdaq Composite',
      value: 'I:COMP.NAS',
    },
    {
      label: 'Nikkei 225',
      value: 'NI225.OSA',
    },
  ],
  commodities: [
    {
      label: 'Brent Spot',
      value: 'C:PBROUSDBR\\SP.IDCENE',
    },
    {
      label: 'West Texas Intermediate',
      value: 'C:PWTIUSDBR\\SP.IDCENE',
    },
    {
      label: 'Sølv',
      value: 'C:PXAGUSDOZ\\SP.IDCIND',
    },
    {
      label: 'Palladium',
      value: 'C:PXPDUSDOZ\\SP.IDCIND',
    },
    {
      label: 'Gull',
      value: 'C:SXAUUSDOZ\\SP.IDCIND',
    },
    {
      label: 'Platinum',
      value: 'C:SXPTUSDOZ\\SP.IDCIND',
    },
  ],
  currencies: [
    {
      label: 'USD',
      value: 'X:SUSDNOK.IDCFX',
    },
    {
      label: 'Euro',
      value: 'X:SEURNOK.IDCFX',
    },
    {
      label: 'GBP',
      value: 'X:SGBPNOK.IDCFX',
    },
    {
      label: 'EUR vs. USD',
      value: 'X:SEURUSD.IDCFX',
    },
    {
      label: 'USD vs. JPY',
      value: 'X:SUSDJPY.IDCFX',
    },
    {
      label: 'SEK',
      value: 'X:SSEKNOK.IDCFX',
    },
  ],
  interests: [
    {
      label: 'US Bond 10Y',
      value: 'B:BMUS\\10Y.IDCFI',
    },
    {
      label: 'US Bond 30Y',
      value: 'B:BMUS\\30Y.IDCFI',
    },
  ],
} as const;

export const targetOptions = [
  { value: 'ALL', label: 'Alle' },
  { value: 'EMAIL', label: 'E-post' },
  { value: 'SMS', label: 'SMS' },
];

export const newsTypeOptions = [
  { value: 'ALL', label: 'Alle' },
  { value: 'INSIDER_TRADES', label: 'Innsidehandler' },
  { value: 'FINANCIAL_REPORTING', label: 'Resultat' },
];

export const getFormValuesFromItemSector = (itemSector: string) => {
  const [item, sector] = getItemAndSectorFromItemSector(itemSector);
  if (!sector) throw new Error('Klarte ikke å hente verdier fra instrument');

  return {
    active: true,
    item,
    sector,
    instrumentType: 'stocks',
  };
};

export const getFormValuesFromAlert = (alert: Alert) => {
  if (alert.type === 'NEWS')
    return {
      active: alert.active,
      newsType: alert.newsType,
      item: alert.item,
      sector: alert.sector,
    };

  const instrumentType =
    Object.entries(alertInstrumentOptions).find(([type, options]) => {
      if (
        options.some(
          (opt: any) => opt.value === `${alert.item}.${alert.sector}`,
        )
      ) {
        return true;
      }
      return false;
    })?.[0] || 'stocks';

  const formValues = {
    active: alert.active,
    instrumentType,
  } as MarketAlertFormValues;

  if (instrumentType !== 'stocks')
    formValues.instrument = `${alert.item}.${alert.sector}`;
  if (instrumentType === 'stocks') {
    formValues.item = alert.item;
    formValues.sector = alert.sector;
  }

  if (alert.type === 'INTRADAY') {
    formValues.trigger = alert.intradayTrigger?.field || 'HIGH_5YEARS';
    return formValues;
  }

  if (alert.type === 'THRESHOLD') {
    formValues.trigger = alert.thresholdTrigger?.field || 'LAST';
    formValues.triggerCriterion =
      alert.thresholdTrigger?.trigger || ('GTE' as any);
    formValues.triggerValue = alert.thresholdTrigger?.value.toString() || '0';
  }

  return formValues;
};

export const getAlertCriteriaFromParams = (alert: Alert) => {
  const { thresholdTrigger, intradayTrigger, newsType } = alert;

  if (thresholdTrigger) {
    const { field, value, trigger } = thresholdTrigger;
    const absValue = Math.abs(value);
    const direction = trigger.includes('LT') ? 'faller ' : 'stiger ';
    if (field.includes('CHANGE')) {
      return `Kursen ${direction} med minst
				${formatNumber(absValue, decimalPlaces(absValue))}${
        field.includes('PCT') ? '%' : ' kr'
      }   på én dag`;
    }

    if (field.includes('LAST')) {
      return `Siste ${direction} til minst ${formatNumber(
        absValue,
        decimalPlaces(absValue),
      )}kr`;
    }
  }

  if (intradayTrigger && intradayTrigger.field === 'HIGH_5YEARS')
    return 'Høyest over 5 år';

  if (newsType) {
    const label =
      newsTypeOptions.find(item => item.value === newsType)?.label || '';
    return `Nyhet: ${label}`;
  }

  if (alert.type === 'NEWS') {
    return 'Nyhet';
  }

  return ``;
};

type MarketAlertPayload = {
  item?: string;
  sector?: string;
  instrument?: string;
  active: boolean;
  rearmWhenTriggered: false;
  targets: ['EMAIL'];
};
interface ThresholdMarketAlertPayload extends MarketAlertPayload {
  thresholdTrigger: Alert['thresholdTrigger'];
}
interface IntradayMarketAlertPayload extends MarketAlertPayload {
  intradayTrigger: Alert['intradayTrigger'];
}
export const createMarketAlertPayload = (
  type: Exclude<Alert['type'], 'NEWS'>,
  values: MarketAlertFormValues,
): ThresholdMarketAlertPayload | IntradayMarketAlertPayload => {
  const { item, sector, instrument, active = true } = values;

  if (!instrument && !item) throw new Error('Mangler instrument!');

  const payload: any = {
    active,
    rearmWhenTriggered: false,
    targets: ['EMAIL'],
  };

  if (values.instrumentType === 'stocks' && item) {
    payload.item = item;
    payload.sector = sector;
  } else if (values.instrument) {
    const lastIndex = values.instrument.lastIndexOf('.');
    const item = values.instrument.slice(0, lastIndex);
    const sector = values.instrument.slice(
      lastIndex + 1,
      values.instrument.length,
    );

    payload.item = item;
    payload.sector = sector;
  }

  if (type === 'INTRADAY') {
    payload.intradayTrigger = {
      field: values.trigger,
    };
    return payload;
  }

  const { triggerCriterion, trigger, triggerValue = 0 } = values || {};

  const normalizedTriggerValue = normalizeNumber(triggerValue);

  const useNegativeTriggerValue =
    triggerCriterion === 'LTE' &&
    (trigger === 'CHANGE' || trigger === 'CHANGE_PCT');

  payload.thresholdTrigger = {
    field: trigger,
    trigger: triggerCriterion,
    value: useNegativeTriggerValue
      ? -Math.abs(Number(normalizedTriggerValue))
      : normalizedTriggerValue,
  };

  return payload;
};

export const createNewsAlertPayload = (values: NewsAlertFormValues) => {
  const payload = {
    active: true,
    item: values.item,
    sector: values.sector,
    rearmWhenTriggered: true,
    targets: ['EMAIL'],
    newsType: values.newsType,
  };
  return payload;
};
