import { useParams } from 'react-router-dom';
import { StockChart as OMSStockChart } from '@oms/components-stock-chart';
import { getItemAndSectorFromItemSector } from '@oms/utils';
import { useDocumentTitle } from '@oms/ui-utils';

export function StockChart() {
  const params = useParams<{ itemSector?: string }>();

  const ticker = params?.itemSector
    ? getItemAndSectorFromItemSector(params.itemSector)[0]
    : 'EQNR';

  useDocumentTitle({
    prefix: 'Sparebank1 Markets',
    suffix: 'Avansert graf',
    title: ticker,
  });

  return (
    <OMSStockChart
      initialMainInstrument={{
        itemSector: params?.itemSector || 'EQNR.OSE',
        ticker: ticker,
        name: ticker,
        instrumentType: 'EQUITIES',
      }}
      chartMinHeight="calc(94.5vh - 240px)"
      watermark="Sparebank1 Markets"
      // Note use %PUBLIC_URL%/ if it's not showing up in demo
      iconsURL="/stock-icons/"
      searchGroup="GRAPH_COMPARE"
    />
  );
}

export default StockChart;
