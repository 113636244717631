import { CUSTOMERTRANSACTIONS_URL } from 'constants/Jaws';
import { useQuery } from 'react-query';
import { get } from 'utils/fetch';

type Arguments = {
  userId: string;
  accountId: string;
  orderId: string;
  transactionId: string;
};

type TransactionDetails = {
  PRICE: number;
  SETTLEMENT_DATE: number;
  VOLUME: number;
  ITEM_SECTOR: string;
  SECTOR: string;
  INSTRUMENT_ID: string;
  TICKER_CODE: string;
  ITEM: string;
  EXECUTED_DATE: number;
  INSTRUMENT_ACCOUNT: string;
  INSTRUMENT_NAME: string;
  BANK_ACCOUNT: string;
  ORDER_ID: string;
  TRANSACTION_FEE: number;
  SETTLED_STATUS: number;
  TOTAL_PRICE: number;
  STOCK_EXCHANGE_ID: string;
  COMPANY_TICKER?: any;
  FULL_NAME: string;
  TRANSACTION_ID: string;
  TYPE: number;
};

export const useTransactionDetails = ({
  userId,
  accountId,
  orderId,
  transactionId,
}: Arguments) => {
  return useQuery(['transactions', orderId, transactionId], () =>
    get(
      `${CUSTOMERTRANSACTIONS_URL}/${userId}/orders/${orderId}/transactions/${transactionId}?accountId=${accountId}`,
    )
      .then(res => res.json())
      .then(result => result.data as TransactionDetails),
  );
};
