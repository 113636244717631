import { ONE_MINUTE } from 'constants/timeInMs';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getAskTradeableInstruments } from 'utils/instrument';

export const useAskTradeableList = () => {
  return useQuery('askTradeable', () => getAskTradeableInstruments(), {
    cacheTime: ONE_MINUTE * 15,
    staleTime: ONE_MINUTE * 15,
  });
};

export const useIsTradeableOnAsk = (itemSector: string | undefined) => {
  const { data = [], ...query } = useAskTradeableList();

  return useMemo(
    () => ({
      data: itemSector ? data.includes(itemSector) : undefined,
      ...query,
    }),
    [data, query, itemSector],
  );
};
