import { TakeoverProvider } from './TakeoverContext';

type Props = {};

// Something like this maybe?
export const Providers: React.FC<Props> = ({ children }) => (
  <TakeoverProvider>{children}</TakeoverProvider>
);

export { TakeoverProvider };
