import { useMemo } from 'react';
import { Form, FormProps } from 'react-final-form';
import { Stack } from '@oms/ui-stack';
import arrayMutators from 'final-form-arrays';
import { Button } from '@oms/ui-button';
import postalCodes from 'norway-postal-codes';
import { Application } from './Application';
import { Notification } from '@oms/ui-notification';
import { useHistory } from 'react-router-dom';
import { EquityCash } from './EquityCash';
import { FileUpload } from './FileUpload';
import { ConfirmApply } from './ConfirmApply';
import { CustomerInformation } from './CustomerInformation';
import { useCustomerInformation, useUser } from 'utils/hooks/useUser';
import { getLabel } from 'utils/sbenBankUtils';
import { MARGIN_URL } from 'constants/Jaws';
import { post } from 'utils/fetch';

export interface MarginApplyProps extends Partial<FormProps> {
  children?: JSX.Element | JSX.Element[];
}

export const validateAll = (...rules: any[]) => (values: any) => {
  return rules.reduce((errors, sectionRules) => {
    const sectionErrors = sectionRules(values);
    return { ...errors, ...sectionErrors };
  }, {} as Record<string, string | undefined>);
};

const validate = (values: any) => {
  const errors: { [key: string]: any } = {};
  if (!values?.requestedCredit) {
    errors.requestedCredit = 'Påkrevet';
  }
  if (!values?.totalEquity) {
    errors.totalEquity = 'Påkrevet';
  }
  if (!values?.acceptTerms) {
    errors.acceptTerms = 'Påkrevet';
  }
  return errors;
};

const buildDataset = ({ acceptTerms, ...values }: any) => {
  const instruments = values.instruments?.filter(
    (instrument: any) =>
      instrument.instrument && instrument.volume && instrument.price,
  );

  return {
    ...values,
    instruments,
  };
};

export const MarginApplyForm = ({ children, ...props }: MarginApplyProps) => {
  const {
    data: { userId = '' },
  } = useUser();
  const history = useHistory();

  const onSubmit = (values: any) => {
    return post(
      `${MARGIN_URL}/${userId}/applications`,
      JSON.stringify({ data: buildDataset(values) }),
    ).then(
      () => {
        history.push('/');
      },
      error => {
        throw new Error(error);
      },
    );
  };

  const {
    fullName,
    phoneNumber,
    email,
    postalCode,
    address1,
    ownerId,
    bankAccountNo,
  } = useCustomerInformation();

  const initialValues = useMemo(
    () => ({
      name: fullName,
      mobile: phoneNumber,
      email,
      zipCode: postalCode,
      address: address1,
      ssn: ownerId,
      bank: getLabel(bankAccountNo),
      city: postalCodes[postalCode],
      equityCash: 0,
      instruments: [{}],
    }),
    [
      fullName,
      phoneNumber,
      email,
      address1,
      ownerId,
      bankAccountNo,
      postalCode,
    ],
  );

  return (
    <Form
      onSubmit={onSubmit}
      {...props}
      initialValues={initialValues}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      subscription={{ submitError: true, submitting: true }}
    >
      {({ handleSubmit, submitError, submitting }) => {
        return (
          <>
            <Stack
              as="form"
              onSubmit={handleSubmit}
              maxWidth="80rem"
              width="100%"
              mx="auto"
              my={8}
              gap={12}
            >
              <CustomerInformation />
              <Application />
              <EquityCash />
              <FileUpload title="3. Legg ved dokumenter" />
              <ConfirmApply />
              <Button type="submit" isPending={submitting}>
                Send inn
              </Button>
              {submitError && (
                <Notification status="error" title="Noe gikk galt">
                  {submitError}
                </Notification>
              )}
            </Stack>
          </>
        );
      }}
    </Form>
  );
};
