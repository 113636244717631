import { Form } from 'react-final-form';
import { useSuggest } from '@oms/components-suggest';
import { Stack } from '@oms/ui-stack';
import { Wrap } from '@oms/ui-wrap';
import { Box } from '@oms/ui-box';
import { RenderField } from '@oms/ui-field';
import { Select } from '@oms/ui-select';
import { newsTypeOptions } from 'utils/alerts';
import { Button } from '@oms/ui-button';
import { FORM_ERROR } from 'final-form';
import { Notification } from '@oms/ui-notification';

export type NewsAlertFormValues = any;

export const NewsAlertForm = ({ onSubmit, initialValues }: any) => {
  if (!onSubmit) throw new Error('Needs an onSubmit!');

  const Suggest = useSuggest();

  const handleSubmit = async (values: NewsAlertFormValues) => {
    try {
      await onSubmit(values);
    } catch (e) {
      return { [FORM_ERROR]: 'Innsending feilet' };
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({
        handleSubmit,
        values,
        form,
        submitting,
        hasSubmitErrors,
        submitError,
        dirtySinceLastSubmit,
      }) => {
        return (
          <Stack as="form" gap={4} onSubmit={handleSubmit} width="100%">
            <Wrap gap={4}>
              <Box flex={1} minWidth="12rem">
                <RenderField
                  as={Select}
                  name="newsType"
                  label="Varsle om"
                  items={newsTypeOptions}
                  value={newsTypeOptions.find(
                    opt => opt.value === values.newsType,
                  )}
                  onChange={(opt: any) => form.change('newsType', opt.value)}
                />
              </Box>
              <Box flex={1} minWidth="12rem">
                <RenderField
                  as={Suggest}
                  label="Ticker"
                  name="instrument"
                  onChange={({ ITEM, SECTOR }: any) => {
                    form.batch(() => {
                      form.change('item', ITEM);
                      form.change('sector', SECTOR);
                    });
                  }}
                  placeholder={values.item}
                />
              </Box>
            </Wrap>

            <Button type="submit" maxWidth="12rem" isPending={submitting}>
              Lagre
            </Button>
            {hasSubmitErrors && !dirtySinceLastSubmit && (
              <Notification status="error" title="Feil">
                {submitError}
              </Notification>
            )}
          </Stack>
        );
      }}
    </Form>
  );
};

export default NewsAlertForm;
