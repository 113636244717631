import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import Condition from 'components/FormComponents/Condition';
import { required } from '../validate';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

export const Education = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <>
      <Fieldset
        as={RadioGroup}
        name="education.relevantEducation"
        legend="Relevant utdanning"
        message="Har du relevant utdanning ift. finansteori og finansielle plasseringer, eller har du ervervet kunnskap/erfaring på annen måte"
      >
        <Field as={Radio} validate={required} label="Ja" value="true" />
        <Field as={Radio} validate={required} label=" Nei" value="false" />
      </Fieldset>
      <Condition when="education.relevantEducation" is="true">
        <PdfTextInput
          name="education.relevantEducationDescription"
          label="Beskrivelse"
          isPDFRender={isPDFRender}
        />
      </Condition>
    </>
  );
};

export default Education;
