import background from 'images/cityscape.jpg';
import appThumb from 'images/appThumb.png';
import logo from 'images/logo.svg';

import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import { Link } from '@oms/ui-link';
import { Notification } from '@oms/ui-notification';

import * as S from './styles';

export const SignicatCancelledPage: React.FC = () => {
  return (
    <Stack width="100%" height="100%" pb={12}>
      <Box maxHeight="400px" overflow="hidden" height="calc(100% - 210px)">
        <S.Logo src={logo} alt="Sparebank1 Logo" />
        <S.Background
          src={background}
          alt="Skyskraper med himmel og skyer som bakgrunn"
        />
      </Box>
      <S.Box>
        <Stack orientation="horizontal" height="70px" alignment="center">
          <S.AppThumb src={appThumb} alt="Mobil med teksten KR" />
          <Stack gap={0}>
            <Text variant="heading4">Aksjehandel</Text>
          </Stack>
        </Stack>

        <Notification title="Avbrutt" status="error">
          Signeringsløpet ble avbrutt, for å få tilgang til portalen må du
          gjennomføre signeringen i sin helhet.
        </Notification>
        <Link variant="link" to="/expediency-test/page/0">
          Prøv på nytt
        </Link>
      </S.Box>
    </Stack>
  );
};
