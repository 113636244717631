import { Checkbox } from '@oms/ui-checkbox';
import { Box } from '@oms/ui-box';
import { Notification } from '@oms/ui-notification';
import ExpediencyWarning from 'components/ExpediencyWarning';
import { useEffect } from 'react';

function getName(stocksPassed: boolean, equityCertificatesPassed: boolean) {
  if (!stocksPassed && !equityCertificatesPassed) {
    return 'Aksjer og Egenkapitalsbevis';
  } else if (!equityCertificatesPassed) {
    return 'Egenkapitalsbevis';
  } else if (!stocksPassed) {
    return 'Aksjer';
  }

  return '';
}

type Props = {
  acknowledged: boolean;
  onAcknowledge: any;
  expediency?: {
    stocks?: 'YES' | 'NO';
    equityCertificates?: 'YES' | 'NO';
  };
};
function Warning({ acknowledged, onAcknowledge, expediency }: Props) {
  const stocksPassed = expediency?.stocks === 'YES';
  const equityCertificatesPassed = expediency?.equityCertificates === 'YES';
  const name = getName(stocksPassed, equityCertificatesPassed);

  useEffect(() => {
    onAcknowledge(acknowledged);
  }, [onAcknowledge, acknowledged]);

  return (
    <Notification title="Advarsel" status={acknowledged ? 'success' : 'error'}>
      <ExpediencyWarning name={name} location="ExpediencyTest" />
      <Box display="flex">
        <Checkbox
          className="notification-checkbox"
          mr={2}
          checked={acknowledged}
          onClick={() => onAcknowledge(!acknowledged)}
        />
        Gå videre likevel
      </Box>
    </Notification>
  );
}

export default Warning;
