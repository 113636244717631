import { Box } from '@oms/ui-box';
import { GdprInfo } from 'components/GdprInfo';

export default function Gdpr() {
  return (
    <Box maxWidth="70rem" mx="auto" mt={12}>
      <GdprInfo />
    </Box>
  );
}

export { Gdpr };
