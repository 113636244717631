import { Icon, light } from '@oms/ui-icon';
import { Heading } from '@oms/ui-heading';
import { Text } from '@oms/ui-text';
import { Card, CardProps } from 'components/Card';
import { Stack } from '@oms/ui-stack';

export interface ImportantInfoProps extends Omit<CardProps, 'children'> {}

export const ImportantInfo = (props: ImportantInfoProps) => {
  return (
    <Card
      title={
        <>
          <Icon icon={light.faInfoCircle} mr={2} /> Viktige ting å huske på
        </>
      }
      backgroundColor="#FDF8F5"
      py={{ base: 4 }}
      {...props}
    >
      <Stack px={{ base: 3 }}>
        <Heading>Kurtasje</Heading>
        <Text>
          Aksjer, ETN, ETF og tegningsretter: 0,05% av handelsbeløpet, min NOK
          95.- .
        </Text>
      </Stack>
    </Card>
  );
};
