import {
  useSmartSearchState,
  SmartSearchDisclosure,
  SmartSearchDialog,
  useSmartSearchImperativeHandle,
} from '@oms/components-smart-search';
import * as S from './styles';
import { useHistory } from 'react-router-dom';
import Preview from './Preview';
import React from 'react';
import { useMedia } from '@oms/ui-media';
import { useEvent } from 'react-use';
import { ForstorrelsesglassIkon } from '@sb1/ffe-icons-react';
import { ErrorBoundary } from '@oms/ui-error-boundary';

export interface SmartSearchProps {}

export function SmartSearch(_props: SmartSearchProps) {
  const { isMobile, isDesktop } = useMedia();
  const smartSearchState = useSmartSearchState();
  const history = useHistory();
  const [ref /*handlers*/] = useSmartSearchImperativeHandle();

  // TODO: Check if this is sufficient on both intel/m1 macs
  const isMacOs = /Mac/i.test(navigator.platform);

  // CTRL+K is a common browser shortcut, prevent default
  useEvent('keydown', (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'k') {
      e.preventDefault();
    }
  });

  return (
    <>
      {isMobile ? (
        <SmartSearchDisclosure {...smartSearchState} as={S.SearchButtonMobile}>
          <ForstorrelsesglassIkon fill="#002776" />
        </SmartSearchDisclosure>
      ) : (
        <SmartSearchDisclosure {...smartSearchState} as={S.SearchButton}>
          <S.Text>Søk ticker/selskap</S.Text>
          {isDesktop ? (
            <S.KdbContainer>
              <S.Text fontSize="sm" color="text-light">
                Hurtigtast
              </S.Text>
              <S.Kbd>
                {isMacOs ? (
                  <abbr title="Command">⌘</abbr>
                ) : (
                  <abbr title="Control">CTRL</abbr>
                )}
              </S.Kbd>
              <S.Kbd>K</S.Kbd>
            </S.KdbContainer>
          ) : null}
        </SmartSearchDisclosure>
      )}
      <ErrorBoundary>
        <SmartSearchDialog
          imperativeHandle={ref}
          showSearchHistory
          dialogState={smartSearchState}
          onChange={item => {
            history.push(`/instrument/${item.ITEM_SECTOR}`);
            // handlers?.reset?.();
          }}
          activationKeys="mod+k"
          renderPreview={({ item, hide }: any) =>
            item ? (
              <ErrorBoundary>
                <Preview item={item} hide={hide} />
              </ErrorBoundary>
            ) : (
              <React.Fragment />
            )
          }
        />
      </ErrorBoundary>
    </>
  );
}
