import { useQuery } from 'react-query';
import { getComponent } from 'utils/fetch';

/** LAST with fallback to LAST_NZ */
export const useLast = (itemSector: string | undefined) => {
  return useQuery(
    ['last', itemSector],
    () =>
      getComponent({
        itemSector,
        columns: 'LAST, LAST_NZ',
      }).then(data => {
        const values = data.rows?.[0]?.values;
        const last = values?.LAST || values?.LAST_NZ;
        return last as number;
      }),
    { enabled: !!itemSector },
  );
};
