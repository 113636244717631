import { useJaws } from '@oms/jaws-react';
import { Table, Tbody, Th, Td, Thead, Tr } from '@oms/ui-table';

function buildSpec(itemSector: string) {
  return {
    columns:
      'LAST, TURNOVER, CHANGE, CHANGE_PCT, LONG_NAME, ASK_VOL, ASK, BID, BID_VOL, TIME',
    itemSector,
    filter: `ITEM_SECTOR==s${itemSector}`,
  };
}

export const InstrumentOverview = ({ itemSector, holdings }: any) => {
  const spec = buildSpec(itemSector);
  const { items } = useJaws(spec);
  const data = items?.toJS();
  const item = Object.values(data)[0] as any;

  return (
    <Table layout="auto">
      <Thead>
        <Tr>
          <Th type="number">Siste</Th>
          <Th type="number">+/-</Th>
          <Th type="percent">+/- %</Th>
          {holdings > 0 && <Th type="number">Antall</Th>}
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Td type="number">{item?.LAST}</Td>
          <Td type="number">{item?.CHANGE}</Td>
          <Td type="percent">{item?.CHANGE_PCT}</Td>
          {holdings > 0 && <Td type="number">{holdings}</Td>}
        </Tr>
      </Tbody>
    </Table>
  );
};
