import {
  Sheet,
  SheetHeader,
  SheetContent,
  SHEET_HEADER_ID,
} from '@oms/ui-sheet';
import { sheetId } from 'components/Sheets';
import { HelpAccordion } from 'components/Help/HelpAccordion';
import { isIOSWebview } from 'utils/webview';
import { ErrorBoundary } from '@oms/ui-error-boundary';
// This file contains some iOS webview workarounds

export interface HelpSheetProps {}

export function HelpSheet(_props: HelpSheetProps) {
  return (
    <Sheet sheetId={sheetId.help} aria-labelledby={SHEET_HEADER_ID}>
      <SheetHeader>Hjelp</SheetHeader>
      <SheetContent
        p={0}
        pb={isIOSWebview() ? 'env(safe-area-inset-bottom)' : undefined}
      >
        <ErrorBoundary>
          <HelpAccordion />
        </ErrorBoundary>
      </SheetContent>
    </Sheet>
  );
}
