import { AccordionGroup, Accordion } from '@oms/ui-accordion';
import { isMarkup, sanitize } from '@oms/utils';
import useFAQ from 'utils/hooks/useFAQ';

export function HelpAccordion() {
  const { faqs } = useFAQ();
  return (
    <AccordionGroup>
      {faqs.map(faq => (
        <Accordion key={faq.question} header={faq.question}>
          {!isMarkup(faq.answer) ? (
            faq.answer
          ) : (
            <div dangerouslySetInnerHTML={sanitize(faq.answer)} />
          )}
        </Accordion>
      ))}
    </AccordionGroup>
  );
}
