export const STATIC_OMS = `https://prod.www.static.oms.no`;

export const HOSTNAME = `//${process.env.JAWS_URL || window?.location?.host}`;
export const BASE_URL = `${HOSTNAME}/server`;
export const BASE_SECURE_URL = `${BASE_URL}/secure`;
export const DOMAIN_URL = `${BASE_URL}/domain/`;
export const COMPONENTS_URL = `${BASE_URL}/components`;
export const COMPONENTS_SECURE_URL = `${BASE_SECURE_URL}/components`;
export const SUGGEST_URL = `${BASE_URL}/suggest`;
export const SUGGEST_SECURE_URL = `${BASE_SECURE_URL}/suggest`;
export const GRAPHDATA_URL = `${COMPONENTS_URL}/graphdata`;
export const GRAPHDATA_SECURE_URL = `${COMPONENTS_SECURE_URL}/graphdata`;
export const WATCHLIST_URL = `${BASE_SECURE_URL}/watchlists`;

export const TRANSACTIONS_URL = `${BASE_SECURE_URL}/transactions`;
export const MODEL_PORTFOLIO_URL = `${BASE_SECURE_URL}/modelPortfolios`;
export const BANKS_URL = `${BASE_SECURE_URL}/banks`;

export const CUSTOMER_URL = `${BASE_SECURE_URL}/customer`;
export const CUSTOMERS_URL = `${BASE_SECURE_URL}/customers`;
export const ACCOUNTS_URL = `${BASE_SECURE_URL}/accountCustomers`;
export const ORDERS_URL = `${BASE_SECURE_URL}/orderCustomers`;
export const CUSTOMERTRANSACTIONS_URL = `${BASE_SECURE_URL}/transactionCustomers`;
export const PRODUCTS_URL = `${BASE_SECURE_URL}/products`;
export const MARGIN_URL = `${BASE_SECURE_URL}/margin`;

export const EXCHANGE_URL = `${BASE_URL}/exchange`;

export const SESSION_KEEPALIVE_URL = `${BASE_SECURE_URL}/keepalive`;
export const USER_INFO_URL = `${BASE_SECURE_URL}/me`;
export const USER_CATEGORIES_URL = `${BASE_SECURE_URL}/customercategories`;

export const SNIPLETS_URL = `${BASE_URL}/sniplets`;
export const COMPETENCY_ASSESSMENT_URL = `${BASE_SECURE_URL}/competency-assessment-test`;
export const EXPEDIENCY_TEST_URL = `${BASE_SECURE_URL}/expediency-test`;
export const H_TEST_URL = `${BASE_SECURE_URL}/h-test`;
export const SIGNING_URL = `${BASE_SECURE_URL}/auth/login/signing`;
export const LAUNDERING_TEST_URL = `${BASE_SECURE_URL}/laundering-test`;
export const TERMS_URL = `${BASE_SECURE_URL}/terms`;
export const REPORT_URL = `${BASE_SECURE_URL}/report`;

export const REPORTS_URL = `${BASE_SECURE_URL}/reports`;
export const REPORT_RECOMMENDATION_TYPES_URL = `${BASE_URL}/recommendation-type`;
export const COMPANY_REPORTS_URL = `${BASE_URL}/company-reports`;
export const COMPANY_REPORT_SUBTYPES_URL = `${BASE_URL}/company-report-subtype`;

export const ADMIN_LOGIN_URL = `${BASE_SECURE_URL}/auth/login/admin`;
export const ADMIN_LOGOUT_URL = `${BASE_SECURE_URL}/auth/logout`;
export const ADMIN_CHANGE_PW_URL = `${BASE_SECURE_URL}/admin/change-password`;
export const ADMIN_USERS_URL = `${BASE_SECURE_URL}/admin`;
export const ADMIN_MODEL_PORTFOLIO_URL = `${BASE_SECURE_URL}/admin/modelPortfolios`;
export const ADMIN_CUSTOMERS_URL = `${BASE_SECURE_URL}/admin/customers`;
export const ADMIN_SNIPLETS_URL = `${BASE_SECURE_URL}/admin/sniplets`;
export const ADMIN_WHITELIST_URL = `${BASE_SECURE_URL}/admin/whitelist`;
export const ADMIN_BROKERAGE_LIMIT_URL = `${BASE_SECURE_URL}/brokerage-fee/limit`;

export const USER_LOGOUT_URL = ADMIN_LOGOUT_URL;

export const AUDIT_URL = `${BASE_SECURE_URL}/audit`;
export const ADMIN_AUDIT_LIVE_URL = `${BASE_SECURE_URL}/sessions/info`;
export const ADMIN_AUDIT_STATS_URL = `${AUDIT_URL}/auditStats`;
export const ADMIN_AUDIT_SEARCH_URL = `${AUDIT_URL}/search`;
export const ADMIN_AUDIT_GRAPH_STATS_URL = `${AUDIT_URL}/graphStats`;

export const ALERTS_URL = `${BASE_SECURE_URL}/jaws-alerts`;

export const PORTFOLIOS_URL = `${BASE_SECURE_URL}/portfolios`;
export const FICTIVE_PORTFOLIOS_URL = `${PORTFOLIOS_URL}/fictive`;
export const PORTFOLIO_INSTRUMENTS_URL = `${PORTFOLIOS_URL}/instruments`;

export const CONTACT_INFO_URL = `${BASE_SECURE_URL}/contactinfo`;
export const TRIGGERED_ALERTS_URL = `${BASE_SECURE_URL}/alerts/triggered`;

export const INSTRUMENTS_URL = `${BASE_SECURE_URL}/instruments`;

export const SIGNICAT_URL = `${BASE_SECURE_URL}/signicat`;
export const TRANSFER_POSITIONS_URL = `${BASE_SECURE_URL}/transferPositions`;

export const WEBTV_URL = `${BASE_URL}/video-links`;
export const ADMIN_WEBTV_URL = `${BASE_SECURE_URL}/video-links`;
