import { Field } from 'react-final-form';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import FileUpload, { Props, Attachments } from './';

const FileUploadAdapter = (props: any) => {
  return (
    <FileUpload
      {...props}
      onFilesSelected={(attachments: Attachments) => {
        props.input.onChange(attachments);
      }}
    />
  );
};

export const FileUploadField = (props: Props) => {
  return (
    <Field
      props={props}
      name="files"
      component={FileUploadAdapter}
      label={<VisuallyHidden>files</VisuallyHidden>}
    />
  );
};

export default FileUploadField;
