import { appSupportsOpenInWeb, openInWeb } from './events/open-in-web';
import {
  appSupportsOpenInternalView,
  openInternalView,
} from './events/open-internal-view';

var domChecks = [];

function pageClickCallback(e) {
  var length = domChecks.length;
  var domNode = e.target;
  var found = false;

  // Traverse the DOM from click target to root...
  while (domNode.parentNode && !found) {
    // Performing the dom check functions on each DOM node
    for (var i = 0; i < length && !found; i++) {
      found = domChecks[i](domNode, e);
    }
    domNode = domNode.parentNode;
  }
}

function addPageClickEventListener() {
  if (document.addEventListener) {
    document.addEventListener('click', pageClickCallback, false);
  } else {
    document.attachEvent('onclick', pageClickCallback);
  }
}

/**
 * Sends an event to open the target URL in the device's native browser when a link with "target=_blank" is clicked,
 * and prevents the default action.
 */
export function handleTargetBlankLinks() {
  if (appSupportsOpenInWeb()) {
    addPageClickEventListener();

    var targetBlankCheck = function targetBlankCheck(domNode, e) {
      if (domNode.tagName === 'A' && domNode.target === '_blank') {
        e.preventDefault();
        openInWeb(domNode.href);
        return true;
      }
      return false;
    };

    domChecks.push(targetBlankCheck);
  }
}

/**
 * Sends an event to open the target URL in an new, internal view, and prevents the default action.
 */
export function handleOpenInternalView() {
  if (appSupportsOpenInternalView()) {
    addPageClickEventListener();

    var openInternalViewCheck = function openInternalViewCheck(domNode, e) {
      if (domNode.dataset && domNode.dataset.openInternalView) {
        var title = domNode.dataset.openInternalView;
        var url = domNode.href;
        if (title && url) {
          e.preventDefault();
          openInternalView(url, title);
          return true;
        }
      }
      return false;
    };

    domChecks.push(openInternalViewCheck);
  }
}
