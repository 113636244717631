import React from 'react';
import { useAllAnalysis } from './useAnalysis';

import { Table, Thead, Tbody, Tr, Th } from '@oms/ui-table';
import { SkeletonTable } from '@oms/ui-skeleton';
import { useMediaQuery } from '@oms/ui-utils';
import { HoldingsRow } from './HoldingsRow';

import {
  useTableDisclosureHeading,
  TableDisclosureHeading,
} from 'components/TableDisclosure';

type Suggest = {
  item: string;
};

const LoadingState = () => <SkeletonTable animated columns={5} rows={6} />;

export const AllAnalysis = ({ item }: any) => {
  return (
    <React.Suspense fallback={<LoadingState />}>
      <AnalysisComponent item={item} />
    </React.Suspense>
  );
};

const AnalysisComponent = ({ item }: Suggest) => {
  const { data: analyses = [] } = useAllAnalysis();
  const isMobile = useMediaQuery({ maxWidth: '500px' });

  const headers = useTableDisclosureHeading();
  if (analyses.length === 0) {
    return null;
  }

  return (
    <Table layout={!isMobile ? 'auto' : 'fixed'}>
      <Thead>
        <Tr>
          <Th type="text" width={isMobile ? '3.5rem' : ''}>
            Ticker
          </Th>
          <Th type="text" width={isMobile ? '4.5rem' : ''}>
            Dato
          </Th>
          <Th type="text" width={isMobile ? '6rem' : ''}>
            Anbefaling
          </Th>
          <Th type="text" width={isMobile ? '4.1rem' : ''}>
            Kursmål
          </Th>
          <Th type="text">{!isMobile && 'PDF'}</Th>
          <TableDisclosureHeading {...headers}>Vis mer</TableDisclosureHeading>
        </Tr>
      </Thead>
      <Tbody>
        {analyses?.map(analysis => {
          return (
            <HoldingsRow
              analysis={analysis}
              data={item}
              {...headers}
              key={analysis.id}
            />
          );
        })}
      </Tbody>
    </Table>
  );
};

export default AllAnalysis;
