import { Field } from '@oms/ui-field';
import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { Table, Tbody, Td, Th, Thead, Tr } from '@oms/ui-table';
import { Button } from '@oms/ui-button';
import { TextInput } from '@oms/ui-text-input';
import { VisuallyHidden } from '@oms/ui-visually-hidden';

export const InstrumentsEquityField = (props: any) => (
  <Table>
    <Thead>
      <Tr>
        <Th>Verdipapirnavn</Th>
        <Th>Antall</Th>
        <Th>Verdi</Th>
      </Tr>
    </Thead>
    <Tbody>
      {props.fields.map((field: any, index: any) => (
        <Tr key={index}>
          <Td>
            <Field
              as={TextInput}
              name={`${field}.instrument`}
              label={<VisuallyHidden>Verdipapirnavn</VisuallyHidden>}
            />
          </Td>
          <Td>
            <Field
              name={`${field}.volume`}
              as={NumberInput}
              lang="nb"
              label={<VisuallyHidden>Antall</VisuallyHidden>}
            />
          </Td>
          <Td>
            <Field
              name={`${field}.price`}
              as={NumberInput}
              lang="nb"
              label={<VisuallyHidden>Verdi</VisuallyHidden>}
            />
          </Td>
        </Tr>
      ))}
      <Tr>
        <Td colSpan={3}>
          <Button type="button" onClick={() => props.fields.push({})}>
            + <span className="sr-only">Legg til instrument</span>
          </Button>
        </Td>
      </Tr>
    </Tbody>
  </Table>
);

export default InstrumentsEquityField;
