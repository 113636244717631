import { ADMIN_SNIPLETS_URL, SNIPLETS_URL } from 'constants/Jaws';
import { FetchError, get, put } from 'utils/fetch';

export type SnipletType = {
  id: number;
  name: string;
};

export type Language = {
  id: number;
  description: string;
  body: string;
  languageCode: string;
};

export type Sniplet = {
  id: number;
  name: string;
  type: SnipletType;
  bodies: {
    NO: Language;
  };
  published: boolean;
  publishedFrom?: number | null;
  publishedTo?: number | null;
};

// TODO: Look into this admin vs "public" logic, seems like customers also use admin-api?
export const fetchPublicSniplets = async () => {
  const response = await get(SNIPLETS_URL);
  if (!response.ok)
    throw new FetchError({
      message: 'Feil skjedde ved henting av sniplets',
      response,
    });

  const { data } = await response.json();
  return data as Sniplet[];
};

export const fetchSniplets = async () => {
  // Random query to ensure cache is cleared. Bug: SBEN-1877
  const randomNumber = Math.random().toFixed(2);
  const response = await get(`${ADMIN_SNIPLETS_URL}?q=${randomNumber}`);
  if (!response.ok)
    throw new FetchError({
      message: 'Feil skjedde ved henting av sniplets',
      response,
    });

  const { data } = await response.json();
  return data as Sniplet[];
};

export const fetchSniplet = async (id: number | string) => {
  const response = await get(`${ADMIN_SNIPLETS_URL}/${id}`);
  if (!response.ok)
    throw new FetchError({
      message: 'Feil skjedde ved henting av sniplet',
      response,
    });

  const { data } = await response.json();
  return data as Sniplet;
};

export const editSniplet = async (sniplet: Sniplet) => {
  const payload = JSON.stringify({ data: sniplet });
  const response = await put(`${ADMIN_SNIPLETS_URL}/${sniplet.id}`, payload);
  if (!response.ok)
    throw new FetchError({
      message: 'Feil skjedde ved redigering av sniplet',
      response,
    });
  await new Promise(resolve => setTimeout(resolve, 1000));
  return response;
};
