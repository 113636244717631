import { Popover, PopoverActions } from '@oms/ui-popover';
import { Button } from '@oms/ui-button';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@oms/ui-icon';
import { WebTVLink } from 'utils/hooks/useWebTV';
import { useWebTVContext } from 'context/WebTVContext';
import { Heading } from '@oms/ui-heading';
import moment from 'moment';
import { Text } from '@oms/ui-text';
interface Props {
  webTVLink: WebTVLink;
}

const WebTVButton = ({ webTVLink }: Props) => {
  const { webTVUrl, setWebTVUrl } = useWebTVContext();

  const isActive = webTVUrl === webTVLink.url;

  return (
    <Popover
      disclosure={
        <Button
          variant="link"
          leftElement={<Icon icon={faPlayCircle as any} />}
        >
          WebTV
        </Button>
      }
    >
      <Heading as="h3" variant="heading6">
        {moment(webTVLink.updated).format('lll')}
      </Heading>
      <Text mt={2}>{webTVLink.title}</Text>
      <PopoverActions>
        <Button
          size="xs"
          onClick={
            isActive
              ? () => setWebTVUrl(undefined)
              : () => setWebTVUrl(webTVLink.url)
          }
        >
          {isActive ? 'Stopp avspilling' : 'Spill av'}
        </Button>
      </PopoverActions>
    </Popover>
  );
};

export default WebTVButton;
