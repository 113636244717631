import { Badge } from '@oms/ui-badge';
import { Heading } from '@oms/ui-heading';
import { Skeleton } from '@oms/ui-skeleton';
import { Stack } from '@oms/ui-stack';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { Card, CardProps } from './Card';

/** TODO: WIP */
export const CardWithAccount = (props: CardProps) => {
  const {
    data: { selectedAccount },
    isLoading,
  } = useSelectedAccount();

  const { ask, vpsAccountNo } = selectedAccount || {};
  return (
    <Card
      {...props}
      headingElement={
        <Stack orientation="horizontal" alignment="center">
          <Heading ml={2} as="h3" variant="heading6" fontWeight="medium">
            {isLoading ? (
              <Skeleton width="6rem" height="1rem" animated />
            ) : (
              vpsAccountNo
            )}
          </Heading>
          {isLoading ? (
            <Skeleton height="1.25rem" width="2rem" animated />
          ) : (
            <Badge alignSelf="center" variant={ask ? 'ask' : 'default'}>
              {ask ? 'ASK' : 'AH'}
            </Badge>
          )}
        </Stack>
      }
    />
  );
};
