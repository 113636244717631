import { Table, Tbody, Td, Th, Tr } from '@oms/ui-table';
import { normalizeNumber } from 'utils/form';
import { decimalPlaces, formatNumber } from '@oms/utils';
import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';
import moment from 'moment';
import { ActiveMatchingOrderMessage } from 'utils/ActiveMatchingOrderMessage';
import { useCalculateCommisson } from './Queries/useCalculateCommisson';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import BrokerageLimitWarning from '../BrokerageLimit/BrokerageLimitWarning';

export const Checkout = ({
  hidden,
  back,
  submit,
  values,
  accountId,
  userId,
  isAsk,
  ticker,
  matchingOrder,
  hideActions,
}: any) => {
  const normalizedLimit = normalizeNumber(values.limit);
  const { selectedAccountId } = useSelectedAccountId();
  const normalizedVolume = normalizeNumber(values.volume);

  const { data: commisson } = useCalculateCommisson(
    selectedAccountId,
    values,
    hidden,
  );

  const netCommission = commisson?.data?.COMMISSION ?? 95;

  const totalPrice =
    values.tradeType === 'BUY'
      ? normalizedLimit * normalizedVolume + netCommission
      : normalizedLimit * normalizedVolume - netCommission;

  return (
    <div hidden={hidden}>
      <Box>
        {isAsk && (
          <p>
            Merk at du nå handler på din Aksjeparekonto. Din beholdning i aksjer
            registrert på din aksjesparekonto vil du finne på
            Aksjesparekontosidene dine både i Aksjehandelsløsningen og i nett-
            og mobilbanken. Vær oppmerksom på at det vil være et døgns
            forsinkelse på markedsverdien som vises i beholdningsoversikten i
            nett- og mobilbanken. I aksjetjenesten vil det ikke være tilsvarende
            forsinkelse.
          </p>
        )}
      </Box>
      <Table>
        <Tbody>
          <Tr>
            <Th scope="row">Type:</Th>
            <Td>{values.tradeType === 'BUY' ? 'Kjøpsordre' : 'Salgsordre'}</Td>
          </Tr>
          <Tr>
            <Th scope="row">Ticker:</Th>
            <Td>{values.ticker}</Td>
          </Tr>
          <Tr>
            <Th scope="row">Gyldighet:</Th>
            <Td>{moment(values.expirationDate).format('DD-MM-YYYY')}</Td>
          </Tr>
          <Tr>
            <Th scope="row">Antall:</Th>
            <Td>{values.volume}</Td>
          </Tr>
          {values.useOpenVolume && (
            <Tr>
              <Th scope="row">Åpent volum:</Th>
              <Td>{formatNumber(values.openVolume)}</Td>
            </Tr>
          )}
          <Tr>
            <Th scope="row">Pris/Limit:</Th>
            <Td>
              {normalizedLimit !== undefined
                ? formatNumber(normalizedLimit, decimalPlaces(normalizedLimit))
                : '-'}
            </Td>
          </Tr>
          {values.useStopLoss && (
            <Tr>
              <Th scope="row">Stop loss:</Th>
              <Td>{formatNumber(normalizeNumber(values.stopLossLimit))}</Td>
            </Tr>
          )}
          <Tr>
            <Th scope="row">Kurtasje:</Th>
            <Td>
              {netCommission}
              ,-
            </Td>
          </Tr>
          <Tr>
            <Th scope="row">Beregnet totalbeløp:</Th>
            <Td suffix="kr">{formatNumber(totalPrice, 2)}</Td>
          </Tr>
        </Tbody>
      </Table>
      {!!matchingOrder && (
        <ActiveMatchingOrderMessage
          matchingOrder={matchingOrder}
          instrument={ticker}
          userId={userId}
          accountId={accountId}
        />
      )}
      <Text m={2}>Kurtasje 0,05 % per handel, minimum NOK 95,-</Text>

      {!hidden && (
        <BrokerageLimitWarning
          ticker={ticker}
          price={normalizedLimit}
          volume={normalizedVolume}
          totalPrice={totalPrice}
          commission={netCommission}
        />
      )}

      {!hideActions && (
        <Box
          variant="body2"
          color="secondary"
          display="flex"
          alignItems="center"
          mr={2}
        >
          <Box py={4} px={2}>
            {back}
          </Box>
          <Box py={4} px={2}>
            {submit}
          </Box>
        </Box>
      )}
    </div>
  );
};
