import { Table, Tbody, Td, Th, Thead, Tr } from '@oms/ui-table';
import { useMemo } from 'react';
import { useJaws } from '@oms/jaws-react';
import useActiveOrders from 'utils/hooks/useActiveOrders';
import useUser from 'utils/hooks/useUser';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { SkeletonTable } from '@oms/ui-skeleton';
import { useMedia } from '@oms/ui-media';
import {
  useTableDisclosureHeading,
  TableDisclosureHeading,
  UseTableDisclosureHeadingReturn,
} from 'components/TableDisclosure';
import { ExpandableRow } from './ExpandableRow';

export const ActiveOrdersTable = () => {
  const headers = useTableDisclosureHeading();
  const { isMobile } = useMedia();

  const {
    data: { userId = '', hasTradingRights },
  } = useUser();
  const { selectedAccountId } = useSelectedAccountId();
  const { data: activeOrders = [], isLoading } = useActiveOrders(
    userId,
    selectedAccountId,
  );
  const spec = useMemo(() => {
    if (!activeOrders || !activeOrders.length) return undefined;

    const itemSectors = activeOrders.map(
      order => `${order.TICKER_CODE}.${order.STOCK_EXCHANGE_ID}`,
    );
    return {
      columns: 'ITEM_SECTOR, LAST, LAST_NZ',
      itemSector: itemSectors.join(),
    };
  }, [activeOrders]);

  const { items } = useJaws(spec);

  if (isLoading)
    return <SkeletonTable animated columns={isMobile ? 5 : 9} rows={6} />;

  return (
    <Table layout="auto" stickyHeader>
      {isMobile ? (
        <ShortHead {...headers} />
      ) : (
        <Thead>
          <Tr>
            <Th>Type</Th>
            <Th type="symbol">Ticker</Th>
            <Th>Status</Th>
            <Th type="dateTime">Gyldig til</Th>
            <Th type="integer">Antall</Th>
            <Th type="integer">Rest</Th>
            <Th type="number">Pris/Limit</Th>
            <Th type="number">Siste</Th>
            <TableDisclosureHeading {...headers}>
              Detaljer
            </TableDisclosureHeading>
          </Tr>
        </Thead>
      )}
      <Tbody>
        {!activeOrders.length && (
          <Tr>
            <Td colSpan={isMobile ? 5 : 9}>Ingen aktive ordre</Td>
          </Tr>
        )}
        {activeOrders.map(order => {
          const { TICKER_CODE, STOCK_EXCHANGE_ID } = order;

          const itemSector = `${TICKER_CODE}.${STOCK_EXCHANGE_ID}`;
          const item = items?.find(
            item => item.get('ITEM_SECTOR') === itemSector,
          );

          const last = item?.get('LAST') || item?.get('LAST_NZ') || 0;

          return (
            <ExpandableRow
              accountId={selectedAccountId}
              userId={userId}
              order={order}
              itemSector={itemSector}
              last={last}
              isMobile={isMobile}
              hasTradingRights={hasTradingRights}
              {...headers}
            />
          );
        })}
      </Tbody>
    </Table>
  );
};

const ShortHead = (headers: UseTableDisclosureHeadingReturn) => (
  <Thead>
    <Tr>
      <Th>Type</Th>
      <Th type="symbol">Ticker</Th>
      <Th>Status</Th>
      <Th type="dateTime" width="6rem">
        Gyldig til
      </Th>
      <TableDisclosureHeading {...headers}>Detaljer</TableDisclosureHeading>
    </Tr>
  </Thead>
);

export default ActiveOrdersTable;
