import createDecorator from 'final-form-calculate';
import { MarketAlertFormValues } from './MarketAlertForm';

export const calculator = createDecorator(
  {
    field: 'instrument', // when instrument changes...
    updates: {
      // reset item and sector
      item: (instrument, values: any) => (instrument ? undefined : values.item),
      sector: (instrument, values: any) =>
        instrument ? undefined : values.sector,
    },
  },
  {
    field: 'item', // when instrument changes...
    updates: {
      // reset instrument
      instrument: (item, values: any) => (item ? undefined : values.instrument),
    },
  },
  {
    field: 'instrumentType.value', // when instrumentType changes...
    updates: {
      // reset instrument
      instrument: (instrumentType: any, allValues: any, prevValues: any) => {
        const prevInstrumentType = prevValues?.instrumentType?.value;
        if (prevInstrumentType && instrumentType !== prevInstrumentType)
          return undefined;
        return allValues?.instrument;
      },
    },
  },
  {
    field: 'trigger', // when trigger changes...
    updates: {
      // and remove triggervalue if trigger is changed to HIGH_5YEARS
      triggerValue: (
        trigger: MarketAlertFormValues['trigger'],
        allValues: any,
      ) => {
        if (trigger === 'HIGH_5YEARS') return undefined;
        return allValues.triggerValue;
      },
      triggerCriterion: (
        trigger: MarketAlertFormValues['trigger'],
        allValues: any,
      ) => {
        if (trigger === 'HIGH_5YEARS') return undefined;
        return allValues.triggerCriterion;
      },
    },
  },
);
