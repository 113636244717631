import React from 'react';
import { Card } from '../Card';
import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { Box } from '@oms/ui-box';
import { Text } from '@oms/ui-text';

export interface CustomerInformationProps {}

export function CustomerInformation(_props: CustomerInformationProps) {
  return (
    <Card title="1. Kundeopplysninger">
      <Box
        display="grid"
        gridGap={4}
        gridTemplateColumns={{ sm: '1fr 1fr' }}
        px={{ base: 3, sm: 'initial' as any }}
        pb={{ base: 3, sm: 'initial' as any }}
      >
        <Field
          as={TextInput}
          name="name"
          label="For og etternavn / firmanavn*"
          disabled
        />

        <Field
          as={TextInput}
          name="ssn"
          label="Fødselsnr. (11 siffer) / foretaksnr. (9 siffer)"
          disabled
        />
        <Field as={TextInput} name="address" label="Adresse" disabled />
        <Field as={TextInput} name="city" label="Poststed" disabled />
        <Field as={TextInput} name="zipCode" label="Postnr" disabled />
        <Field as={TextInput} name="mobile" label="Mobil" />

        <Field as={TextInput} name="email" label="E-postadresse" />
        <Field
          as={TextInput}
          name="bank"
          label="Bankforbindelse (Tilhørende bank)"
        />
        <Field
          as={TextInput}
          name="responsiblePerson"
          label="Kontaktperson (firma)"
        />
        <Field as={TextInput} name="companyPhone" label="Tlf (firma)" />
        <Text>* Må vedlegge firmaattest, styrebeslutning</Text>
      </Box>
    </Card>
  );
}

export type CustomerInformationValues = {
  name: string;
  ssn: number;
  mobile: number;
  [key: string]: any;
};

export const validationRules = (values: CustomerInformationValues) => {
  let errors: Record<keyof CustomerInformationValues, string | undefined> = {};
  if (!values.name) errors['name'] = 'Påkrevd';
  if (!values.ssn) errors['ssn'] = 'Påkrevd';
  if (!values.mobile) errors['mobile'] = 'Påkrevd';
  return errors;
};
