import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { required } from '../validate';

export const CustomerEducation = () => {
  return (
    <>
      <Fieldset
        as={RadioGroup}
        name="education.highestCompleted"
        legend="Utdanning"
      >
        <Field
          as={Radio}
          validate={required}
          label="Grunnskole"
          value="PRIMARY"
        />
        <Field
          as={Radio}
          validate={required}
          label="Videregående"
          value="SECONDARY"
        />
        <Field
          as={Radio}
          validate={required}
          label=" Universitet/høyskole"
          value="COLLEGE"
        />
      </Fieldset>
    </>
  );
};

export default CustomerEducation;
