import { Text } from '@oms/ui-text';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Accordion, AccordionGroup } from '@oms/ui-accordion';
import { required } from '../validate';
import { Box } from '@oms/ui-box';
import PersonArray from './PersonArray';
import PoaArray from './PoaArray';
import { Stack } from '@oms/ui-stack';
import { Heading } from '@oms/ui-heading';
import { List, ListItem } from '@oms/ui-list';
import Condition from 'components/FormComponents/Condition';
import styled from '@emotion/styled';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

const SubForm = styled.div`
  padding-left: 20px;
  padding-block: 10px;
`;
const Space = styled.div`
  padding: 12px 4px;
`;

export const PoliticallyExposed = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <Stack gap={8} mt={2}>
      <Stack gap={2}>
        <Text>
          Hvitvaskingsregelverket forplikter oss til å avdekke om våre kunder
          er, eller har vært politiske eksponerte personer. En politisk
          eksponert person er en person som har, eller har hatt, viktige
          offentlige funksjoner i en stat eller i en internasjonal organisasjon,
          eller er nært familiemedlem eller kjent medarbeider av en slik PEP.
        </Text>
        <Text>
          En PEP er en person som har, eller har hatt, følgende verv eller
          stillinger:
        </Text>
      </Stack>
      <>
        <Box>
          <List mt="0.5rem" variant="alphabet">
            <ListItem>
              Statsoverhode, regjeringssjef, minister eller assisterende
              minister. Dette omfatter blant annet statssekretær.
            </ListItem>
            <ListItem>Medlem av nasjonalforsamling.</ListItem>
            <ListItem>Medlem av styrende organ i politisk parti.</ListItem>
            <ListItem>
              Medlem av høyere rettsinstans som treffer beslutninger som ikke
              bare unntaksvis kan ankes. Dette omfatter høyesterettsdommere og
              lagdommere.
            </ListItem>
            <ListItem>
              Medlem av styret i riksrevisjon, revisjonsdomstol eller
              sentralbank.
            </ListItem>
            <ListItem>
              Ambassadør, chargé d’affaires eller militær offiser av høyere
              rang.
            </ListItem>
            <ListItem>
              Medlem av administrativt, ledende eller kontrollerende organ i
              statlig foretak.
            </ListItem>
            <ListItem>
              Direktør, styremedlem eller annen person i øverste ledelse i
              internasjonal organisasjon.
            </ListItem>
          </List>
        </Box>
        <Box mt={6}>
          <AccordionGroup>
            <Accordion header="Se definisjon av ‘’nært familiemedlem’’">
              Med "nært familiemedlem" menes foreldre, ektefelle, registrert
              partner, barn, samt barns ektefelle, registrert partner eller
              samboer. Søsken omfattes ikke.
            </Accordion>
            <Accordion header="Se definisjon av ‘’kjente medarbeider’’">
              Med "kjent medarbeider" menes en fysisk person som er:
              <List mt="1rem" variant="alphabet">
                <ListItem>
                  Reell rettighetshaver i juridisk person, sammenslutning eller
                  utenlandsk juridisk arrangement i fellesskap med PEP.
                </ListItem>
                <ListItem>Ha nær forretningsforbindelse med PEP. </ListItem>
                <ListItem>
                  Være eneste reelle rettighetshaver i juridisk person,
                  sammenslutning eller utenlandske juridisk arrangement som i
                  realiteten er opprettet for å begunstige en PEP.
                </ListItem>
              </List>
            </Accordion>
          </AccordionGroup>
        </Box>
        <Box>
          <Text mt={4}>
            Har du, eller noen i selskapet, innehatt verv eller stillinger som
            angitt over?
          </Text>
          <Fieldset
            as={RadioGroup}
            validate={required}
            name="pepDetails.IamPep"
            legend=""
          >
            <Field as={Radio} validate={required} label="Ja" value="true" />
            <Field as={Radio} validate={required} label="Nei" value="false" />
          </Fieldset>
          <Condition when="pepDetails.IamPep" is="true">
            <PersonArray name="pepDetails.affiliatePepList" />
          </Condition>
        </Box>
        <Box>
          <Text>
            Er du eller noen i selskapet nært familiemedlem eller kjent
            medarbeider av en person som har, eller har innehatt, verv eller
            stillinger som angitt over?
          </Text>
          <Fieldset
            as={RadioGroup}
            validate={required}
            name="pepDetails.familyIsPep"
            legend=""
          >
            <Field as={Radio} validate={required} label="Ja" value="true" />
            <Field as={Radio} validate={required} label="Nei" value="false" />
          </Fieldset>
          <Condition when="pepDetails.familyIsPep" is="true">
            <PersonArray name="pepDetails.familyPepList" />
          </Condition>
        </Box>
        <Box>
          <Heading>Fullmakt</Heading>
          <Fieldset
            as={RadioGroup}
            validate={required}
            name="pepDetails.powerOfAttorney"
            legend="Vil noen andre bruke denne kontoen for handel?"
          >
            <Field as={Radio} validate={required} label="Ja" value="true" />
            <Field as={Radio} validate={required} label="Nei" value="false" />
          </Fieldset>
          <Condition when="pepDetails.powerOfAttorney" is="true">
            <PoaArray name="pepDetails.powerOfAttorneyList" />
          </Condition>
        </Box>
      </>
    </Stack>
  );
};

export default PoliticallyExposed;
