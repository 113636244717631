import { useMemo } from 'react';
import { useMe } from '../useAuth';
import {
  checkHasLevel2,
  checkHasRole,
  checkHasStreaming,
  checkIsAdmin,
  checkIsExternalTakeover,
  checkIsLoggedIn,
  checkIsOwnTakeover,
  checkIsTakeover,
  checkHasTradingRights,
  getAdminPrincipal,
  getCustomerPrincipal,
  getUserId,
} from 'utils/user';
import { CustomerPrincipal } from 'types/auth';
import { RoleName } from 'types/admin';
import { useMasterAccount, useSelectedAccount } from '../useAccounts';
import { getLabel } from 'utils/sbenBankUtils';

/** TODO: This is a WIP, might be a little cluttered with all the boolean-fields (since those could be done in each component needing them)
 *
 * Or perhaps move this creation into a async method that gets called with useQuery
 */
export const useUser = () => {
  const { data: me, ...queryState } = useMe();

  const data = useMemo(() => {
    const adminData = getAdminPrincipal(me?.principals);
    const customerData = getCustomerPrincipal(me?.principals);
    const accounts = (customerData?.accounts ||
      []) as CustomerPrincipal['accounts'];

    return {
      userId: getUserId(me),
      clientId: customerData?.clientId,
      adminData,
      customerData,
      accounts,
      isAdmin: checkIsAdmin(me),
      isTakeover: checkIsTakeover(me),
      isOwnTakeover: checkIsOwnTakeover(me),
      isExternalTakeover: checkIsExternalTakeover(me),
      isAuthenticated: checkIsLoggedIn(me),
      hasTradingRights: checkHasTradingRights(me),
      hasStreaming: checkHasStreaming(me),
      hasLevel2: checkHasLevel2(me),
      hasRole: (role: RoleName) => {
        if (!me) return false;
        return checkHasRole(me, role);
      },
      me,
    };
  }, [me]);

  return { data, ...queryState };
};

export const useCustomerInformation = () => {
  const {
    data: { selectedAccount },
  } = useSelectedAccount();
  const {
    data: { masterAccount },
  } = useMasterAccount();

  const { fullName = '-', bankAccountNo = '-', vpsAccountNo = '-' } =
    selectedAccount || {};

  const {
    phoneNumber = '-',
    email = '-',
    postalCode = '-',
    address1 = '-',
    group = '-',
    ask = '-',
    ownerId = '-',
    id = '-',
  } = masterAccount || {};
  return {
    fullName,
    bankAccountNo,
    bankName: getLabel(bankAccountNo) || '-',
    vpsAccountNo,
    phoneNumber,
    email,
    postalCode,
    address1,
    group,
    ask,
    ownerId,
    id,
  };
};

export default useUser;
