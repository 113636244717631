import { Field, Fieldset } from '@oms/ui-field';
import { Stack } from '@oms/ui-stack';
import { Select } from '@oms/ui-select';
import { countryOptions } from 'utils/locales';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { required } from '../validate';
import Condition from 'components/FormComponents/Condition';
import styled from '@emotion/styled';
import { Checkbox } from '@oms/ui-checkbox';
import { Heading } from '@oms/ui-heading';

const Divider = styled.hr`
  margin: 1rem 0;
  color: #ccc;
`;

const Space = styled.div`
  padding: 4px 4px;
`;

export const AdditionalPersonalInformation = () => {
  return (
    <Stack gap={4} mt={4}>
      <Divider />
      <Heading>Landtilhørighet</Heading>
      <Field
        name="additionalPersonalInformation.country"
        as={Select}
        items={countryOptions}
        validate={required}
        placeholder="Velg land"
        label="Statsborgerskap"
      />
      <Fieldset
        as={RadioGroup}
        name="additionalPersonalInformation.citizenship"
        legend="Har du dobbelt statsborgerskap?"
      >
        <Field as={Radio} validate={required} label="Ja" value="true" />
        <Field as={Radio} validate={required} label="Nei" value="false" />
      </Fieldset>
      <Condition when="additionalPersonalInformation.citizenship" is="true">
        <Field
          name="additionalPersonalInformation.secondCountry"
          as={Select}
          items={countryOptions}
          validate={required}
          placeholder="Velg land"
          label="Statsborgerskap #2"
        />
      </Condition>
      <Space />

      <Field
        name="additionalPersonalInformation.usaCitizen"
        as={Checkbox}
        label="Jeg bekrefter å ikke være amerikansk statsborger eller ha skatteplikt til USA"
        validate={required}
      />
      <Divider />
    </Stack>
  );
};

export default AdditionalPersonalInformation;
