import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';
import Condition from 'components/FormComponents/Condition';
import RightHolderArray from './RightHoldersArray';
import styled from '@emotion/styled';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { useLocation } from 'react-router-dom';
import PdfTextInput from 'components/FormComponents/PdfTextInput';

const SubForm = styled.div`
  padding-left: 20px;
  padding-block: 10px;
`;

export const CompanyOtherInformation = () => {
  const location = useLocation();
  const isPDFRender = checkIsPDFRender(location);
  return (
    <Stack gap={4}>
      <Heading>Eiere - hvem kontrollerer selskapet?</Heading>
      <Fieldset
        as={RadioGroup}
        validate={required}
        name="companyAdvInfo.agreementInGeneral"
        legend="Finnes det avtaler generelt, eksempelvis låneavtaler/fullmakter/aksjonæravtaler m.fl (Alle typer avtaler som regulerer eier-/kontrollaspektet)."
      >
        <Field as={Radio} label="Ja" value="true" />
        <Field as={Radio} label="Nei" value="false" />
      </Fieldset>
      <Condition when="companyAdvInfo.agreementInGeneral" is="true">
        <PdfTextInput
          name="companyAdvInfo.agreementInGeneralDescription"
          label="Vennligst beskriv dette"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <Heading>Har selskapet forskjellige aksjeklasser?</Heading>
      <Fieldset
        as={RadioGroup}
        validate={required}
        name="companyAdvInfo.differentShareClasses"
        legend=""
      >
        <Field as={Radio} label="Ja" value="true" />
        <Field as={Radio} label="Nei" value="false" />
      </Fieldset>
      <Condition when="companyAdvInfo.differentShareClasses" is="true">
        <PdfTextInput
          name="companyAdvInfo.differentShareClassesDescription"
          label="Vennligst beskriv dette"
          isPDFRender={isPDFRender}
        />
      </Condition>
      <Heading>Reelle rettighetshavere</Heading>
      <Fieldset
        as={RadioGroup}
        validate={required}
        name="companyAdvInfo.realRightsHolders"
        legend="En reell rettighetshaver er en eller flere fysiske (privat)personer, som til syvende og sist eier eller kontrollerer kunden. Eierskapet kan gå 
        gjennom flere ledd (for eksempel i konsern). For å identifisere reell rettighetshaver må man følge kontroll- og eierstrukturen i selskapet
        ledd for ledd, helt til man finner en fysisk person. Dersom en person eier mer enn 25% av eierandelene i et selskap, eller kontrollerer mer enn 25% av
        stemmene i et selskap, skal personen i utgangspunktet regnes som reell rettighetshaver. Hvor eierskapet går over flere ledd, skal den fysiske
        personen i enden av en eierkjede anses som reell rettighetshaver dersom hvert ledd i kjeden eier eller kontrollerer mer enn 25%. Nære 
        familiemedlemmer regnes samet (foreldre, ektefelle, registrert partner, samboer og barn, samt barns ektefelle, registrert partner eller samboer)."
      >
        <Field as={Radio} label="Ja" value="true" />
        <Field as={Radio} label="Nei" value="false" />
      </Fieldset>
      <Condition when="companyAdvInfo.realRightsHolders" is="true">
        <RightHolderArray name="companyAdvInfo.rightHolderList" />
      </Condition>
    </Stack>
  );
};

export default CompanyOtherInformation;
