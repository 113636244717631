import { safeguardCheck } from 'utils/trade';
import * as FormUtils from 'utils/form';

export const warningValidation = (LIMIT, LAST) => {
  // Shouldn't show while last is unknown.
  // This runs the risk of warning not showing up if query is super slow, any ideas? loading state?
  if (!LAST) return undefined;

  const normalizedLimit = FormUtils.normalizeNumber(LIMIT);
  const passSafeguardCheck = safeguardCheck?.(normalizedLimit, LAST);
  return passSafeguardCheck
    ? undefined
    : 'Limit avviker mer enn 30% fra sist omsatte kurs.';
};
