import { useState } from 'react';
import { Field, RenderField } from '@oms/ui-field';
import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { TradeUtil, formatDate } from 'utils/trade';
import { warningValidation } from 'utils/validation/tradeWarningValidation';
import { useSuggest } from '@oms/components-suggest';
import { useFormState } from 'react-final-form';
import { Box } from '@oms/ui-box';
import { DateInput } from '@oms/ui-date-input';
import { QuickTradeTable } from './QuickTradeTable';
import { Stack } from '@oms/ui-stack';
import moment from 'moment';
import { useGetCloseDates } from 'components/Trade/Queries/useGetCloseDates';
import { Text } from '@oms/ui-text';
import { useLast } from '../Queries/useLast';
import { Notification } from '@oms/ui-notification';
import { BankIDButton } from 'components/Buttons';
import { Link } from '@oms/ui-link';
import useUser from 'utils/hooks/useUser';

let currentTime = moment().format('LT');
let lastDay = new Date();
lastDay.setDate(lastDay.getDate() + 14);
const closeTime = '15:20';
const endOfDay = '00:59';
const nextDay = moment()
  .add(1, 'days')
  .format('YYYY-MM-DD')
  .toString();

const getItemSector = (ticker?: string, exchange?: string) => {
  if (!ticker || !exchange) return undefined;
  return `${ticker}.${exchange}`;
};

export const QuickTradeForm = ({
  form,
  error,
  buy,
  sell,
  suggestImperativeHandle,
  sidebar,
  setError,
}: any) => {
  const Suggest = useSuggest();
  const { data: closeDates } = useGetCloseDates();
  const [start, setStart] = useState(TradeUtil.getDefault('start'));
  const { values, errors } = useFormState();
  const itemSector = getItemSector(values.ticker, values.exchange);
  const showQuickTradeTable =
    values.ticker &&
    values.ticker !== 'undefined' &&
    values.exchange &&
    values.exchange !== 'undefined';
  const { data: last } = useLast(itemSector);
  const {
    data: { hasTradingRights },
  } = useUser();

  const updateExpirationDate = (date: Date) => {
    setStart(formatDate(date));
    form.change('expirationDate', `${moment(date).format('YYYY-MM-DD')}`);
  };

  return (
    <div>
      <Stack orientation={sidebar ? 'vertical' : 'horizontal'}>
        <RenderField
          as={Suggest}
          name="ticker"
          label="Ticker/selskap"
          value={values.ticker || ''}
          onChange={(value: any) => {
            if (value === '') {
              form.restart();
              setError('');
            }
            form.batch(() => {
              form.change('ticker', value ? `${value.ITEM}` : undefined);
              form.change('exchange', value ? `${value.SECTOR}` : undefined);
            });
          }}
          flex={1}
          imperativeHandle={suggestImperativeHandle}
          isClearable
          enableTabToSelect
        />
        {errors?.instrument && (
          <Notification status="error" title="Feil">
            {errors?.instrument}
          </Notification>
        )}
        <Field
          as={NumberInput}
          lang="nb"
          inputMode="numeric"
          name="volume"
          label="Totalt Antall/Volum"
          min="0"
          flex={1}
        />
        <Field
          as={NumberInput}
          lang="nb"
          inputMode="decimal"
          name="limit"
          min="0"
          label="Pris/Limit"
          flex={1}
          message={
            values.limit !== undefined
              ? {
                  text: (
                    <Text color="warning">
                      {warningValidation(values.limit, last)}
                    </Text>
                  ),
                  placement: 'bottom',
                }
              : undefined
          }
        />
        <RenderField
          as={DateInput}
          name="expirationDate"
          label="Gyldig til"
          onChange={updateExpirationDate}
          value={
            currentTime >= closeTime && currentTime <= endOfDay
              ? new Date(nextDay)
              : new Date(start)
          }
          excludeDates={closeDates?.data}
          minDate={new Date()}
          maxDate={lastDay}
          flex={1}
        />
        {sidebar && !hasTradingRights && (
          <Box center>
            <BankIDButton as={Link} to="/login">
              Logg inn for handel
            </BankIDButton>
          </Box>
        )}
        <Stack orientation="horizontal">
          <Box pt={5}>{buy}</Box>
          <Box pt={5} pr={2}>
            {sell}
          </Box>
        </Stack>
      </Stack>
      <Box pt={5}>{error}</Box>
      {showQuickTradeTable ? <QuickTradeTable sidebar={sidebar} /> : null}
    </div>
  );
};
