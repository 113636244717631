import Pie from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { formatInteger } from '@oms/utils';
import { colors } from 'theme';

const defaultColorThresholds = [
  { value: 0, color: colors.pos },
  { value: 51, color: colors.flat },
  { value: 76, color: colors.neg },
];

export type MarginDonutProps = {
  width?: number;
  height?: number;
  animate?: boolean;
  colorThresholds?: typeof defaultColorThresholds;
  value: number;
};

export const MarginDonut = ({
  width = 100,
  height = 100,
  colorThresholds = defaultColorThresholds,
  value,
}: MarginDonutProps) => {
  if (width < 10) return null;

  const data = [{ value }, { backgroundTrack: true, value: 100 - value }];

  const getArcColor = (isBackground?: boolean) => {
    if (isBackground) return colors.backdrop;
    let highestMatch;
    for (const threshold of colorThresholds) {
      if (
        value >= threshold.value &&
        (!highestMatch || threshold.value >= highestMatch.value)
      ) {
        highestMatch = threshold;
      }
    }
    if (!highestMatch) return 'transparent';
    return highestMatch.color;
  };

  const innerWidth = width;
  const innerHeight = height;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 10;

  return (
    <svg width={width} height={height}>
      <Group top={centerY} left={centerX}>
        <Pie
          data={data}
          pieValue={d => d.value}
          outerRadius={radius}
          innerRadius={radius - donutThickness}
          pieSort={null}
        >
          {pie =>
            pie.arcs.map(arc => {
              const arcPath = pie.path(arc);
              const arcFill = getArcColor(arc.data.backgroundTrack);
              return <path d={arcPath || ''} fill={arcFill} />;
            })
          }
        </Pie>
        <Text textAnchor="middle" verticalAnchor="middle" fontSize="1.375rem">
          {`${formatInteger(value)}%`}
        </Text>
      </Group>
    </svg>
  );
};

export default MarginDonut;
