import {
  Sheet,
  SheetHeader,
  SheetContent,
  SHEET_HEADER_ID,
} from '@oms/ui-sheet';
import { sheetId } from 'components/Sheets';
import WebTVList from './WebTVList';
import { ErrorBoundary } from '@oms/ui-error-boundary';

export function WebTVSheet() {
  return (
    <Sheet sheetId={sheetId.tv} aria-labelledby={SHEET_HEADER_ID}>
      <SheetHeader>WebTV</SheetHeader>
      <SheetContent p={2}>
        <ErrorBoundary>
          <WebTVList />
        </ErrorBoundary>
      </SheetContent>
    </Sheet>
  );
}
