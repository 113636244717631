import { useEffect } from 'react';

import { audit } from 'utils/audit';
import buildString from 'utils/buildString';
import { Notification } from '@oms/ui-notification';

const renderText = (type, ITEM) => (
  <Notification status="warning" px={2} title="Advarsel">
    Du har allerede en {type === 'BUY' && 'kjøpsordre'}
    {type === 'SELL' && 'salgsordre'} i {ITEM} som gjør at du risikerer å handle
    med deg selv. Vennligst endre limit for å unngå dette.
  </Notification>
);

const getType = matchingOrder => {
  const isBuy = matchingOrder?.TYPE === 0;
  const isSell = matchingOrder?.TYPE === 1;

  if (isBuy) return 'BUY';
  if (isSell) return 'SELL';
  return 'OTHER';
};

export const ActiveMatchingOrderMessage = ({
  userId,
  accountId,
  matchingOrder,
  instrument,
}) => {
  useEffect(() => {
    audit(userId, accountId, {
      key: 'activeOrderInInstrumentWarningDisplayed',
      text: JSON.stringify({
        text: buildString(renderText(getType(matchingOrder), instrument)),
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, matchingOrder, instrument, userId]);

  return renderText(getType(matchingOrder), instrument);
};
