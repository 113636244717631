import { Table, Tbody, Th, Thead, Tr } from '@oms/ui-table';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import { useAccounts } from 'utils/hooks/useAccounts';
import { useMedia } from '@oms/ui-media';
import AccountRow from './AccountRow';
import styled from '@xstyled/emotion';

const TbodyWithSpace = styled(Tbody)`
  ::before {
    height: 2px;
    display: table-row;
    content: '';
  }
`;

export const AccountSwitcher = () => {
  const { isMobile } = useMedia();
  const [pendingAccountId, setPendingAccountId] = useState<string>();
  const { selectedAccountId, setSelectedAccountId } = useSelectedAccountId();
  const {
    data: { accounts },
  } = useAccounts();

  // TODO: How did the UX of this pending-state end up? Is it better than previously?
  // Perhaps keep pending-state, but remove debounce?
  useDebounce(
    () => {
      if (!pendingAccountId) return;

      // If pending account ends up being the currencly selected account, end pending state without setting account
      if (pendingAccountId === selectedAccountId) {
        return setPendingAccountId(undefined);
      }

      setSelectedAccountId(pendingAccountId);
    },
    400,
    [pendingAccountId],
  );

  useEffect(() => {
    setPendingAccountId(undefined);
  }, [selectedAccountId]);

  const handleAccountSelection = (accountId: string, instant = false) => {
    // If not in a pending state, clicking on active account should do nothing
    if (!pendingAccountId && accountId === selectedAccountId) return;
    if (instant) return setSelectedAccountId(accountId);

    setPendingAccountId(accountId);
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th width={{ base: '8rem', sm: '7rem' }}>Konto</Th>
          <Th textAlign="center" width={{ base: '2.75rem', sm: '3.75rem' }}>
            Type
          </Th>
          {!isMobile && <Th>Eier</Th>}
          <Th textAlign="right">Markedsverdi</Th>
          {!isMobile && <Th width="3rem" children="" />}
        </Tr>
      </Thead>
      <TbodyWithSpace>
        {accounts.map(account => {
          const isLoading = pendingAccountId === account.id;
          const isSelected =
            !pendingAccountId && selectedAccountId === account.id;
          return (
            <AccountRow
              key={account?.id}
              account={account}
              onClick={handleAccountSelection}
              isSelected={isSelected}
              isLoading={isLoading}
              isMobile={isMobile}
            />
          );
        })}
      </TbodyWithSpace>
    </Table>
  );
};

export default AccountSwitcher;
