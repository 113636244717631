import { post, put, FetchError } from 'utils/fetch';
import { ORDERS_URL } from 'constants/Jaws';
import moment, { Moment } from 'moment';

export function validate(userId: string, data: any, callback: any) {
  return post(`${ORDERS_URL}/${userId}/orders/validate`).then((error: any) => {
    throw new Error(error);
  });
}

export const execute = async (userId: string, data: any, callback: any) => {
  const response = await post(
    `${ORDERS_URL}/${userId}/orders/execute`,
    JSON.stringify(data),
  );
  if (!response.ok)
    throw new FetchError({
      message: 'Gjennomføring feilet',
      response: response,
    });
  return response;
};

export const modify = (
  accountId: any,
  data: any,
  callback: any,
  orderId: any,
) =>
  put(`${ORDERS_URL}/${accountId}/orders/${orderId}/execute`, '').then(res => {
    if (!res.ok)
      throw new FetchError({ message: 'Endringen feilet', response: res });
    return res;
  });

// Se http://manawiki.osl.manamind.com/index.php/Meglersystemer
export const mapOrderStatus = (statusInt: number) => {
  return (
    {
      0: 'Ikke på børs', // Mottatt, ikke på børs
      1: 'Avvist',
      2: 'På børs',
      3: 'Venter',
      4: 'Utført',
      5: 'Kansellert',
      6: 'Utgått',
      7: 'Trukket',
      8: 'Stop loss',
      9: 'Manuell',
      10: 'Manuelt utgått',
    }[statusInt] || '-'
  );
};

export const mapOrderType = (typeInt: number) => {
  return (
    {
      0: 'Kjøp',
      1: 'Selg',
    }[typeInt] || '-'
  );
};

export function safeguardCheck(limit: number, last: number, guard = 30) {
  const deviation = Math.abs(100 * (100 * ((last - limit) / last)));
  return deviation <= guard * 100;
}

const FEE_MAX = Infinity;
const FEE_MIN = 95;

export function calculateCommission(
  limit: number,
  volume: number,
  minFee = FEE_MIN,
) {
  const total = limit * volume;
  const commission = total * 0.0005;
  return Math.min(FEE_MAX, Math.max(minFee, commission));
}

export const OPEN_VOLUME_MIN_PRICE = 100000; // Adjusted yearly

/** Formats `date` to string with format YYYY-MM-DD */
export function formatDate(date: Moment | Date) {
  if (date instanceof Date) return moment(date).format('YYYY-MM-DD');
  return date.format('YYYY-MM-DD');
}

const periodDefaults = {
  start: formatDate(moment()),
};

export const TradeUtil = {
  getDefault(period: keyof typeof periodDefaults) {
    return periodDefaults[period];
  },
};
