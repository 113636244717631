import { Payload } from 'components/ExpediencyTest/types';
import { EXPEDIENCY_TEST_URL } from 'constants/Jaws';
import { Account } from 'types/auth';
import { del, FetchError, get, post } from 'utils/fetch';
import { Person } from 'components/ExpediencyTest/PoliticallyExposed/types';

type ExpediencyTest = Payload;

// TODO: These helpers (and some others) wound up pretty duplicated, refactor?

export const getExpediencyTest = async (
  userId: string,
  accountId?: string,
  corpoAccount?: Boolean,
) => {
  if (!userId) return;

  const response = await get(
    `${EXPEDIENCY_TEST_URL}/${userId}?accountId=${accountId}&corpoAccount=${corpoAccount}`,
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av expediency test feilet',
      response,
    });
  }

  let data = (await response.json()) as ExpediencyTest;

  if (data?.contents?.pepDetails?.affiliatePepList) {
    const aff = data.contents.pepDetails.affiliatePepList.map(item => {
      const positionList = item.positionList.map(it => ({
        ...it,
        fromDate: it.fromDate ? new Date(it.fromDate) : null,
        toDate: it.toDate ? new Date(it.toDate) : null,
      }));
      return {
        ...item,
        positionList,
      };
    });
    data.contents.pepDetails = {
      ...data.contents.pepDetails,
      affiliatePepList: aff as Person[],
    };
  }
  if (data?.contents?.pepDetails?.familyPepList) {
    const fam = data.contents.pepDetails.familyPepList.map(item => {
      const positionList = item.positionList.map(it => ({
        ...it,
        fromDate: it.fromDate ? new Date(it.fromDate) : null,
        toDate: it.toDate ? new Date(it.toDate) : null,
      }));
      return {
        ...item,
        positionList,
      };
    });
    data.contents.pepDetails = {
      ...data.contents.pepDetails,
      familyPepList: fam as Person[],
    };
  }

  return data;
};

export const delExpediencyTest = async (data: {
  userId: string;
  accountId?: string;
  corpoAccount: boolean;
}) => {
  if (!data.userId) return;

  const response = await del(
    `${EXPEDIENCY_TEST_URL}/${data.userId}?accountId=${data.accountId}&corpoAccount=${data.corpoAccount}`,
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Sletting av eksisterende hensiktsmessighets-sjekk feilet',
      response,
    });
  }

  return response;
};

type MutateExpediencyTestVariables = {
  userId: string;
  payload: ExpediencyTest;
  account: Account;
  corpoAccount: boolean;
};

const dateToString = (date: Date | null) => {
  if (!date) return null;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const postExpediencyTest = async ({
  userId,
  payload,
  account,
  corpoAccount,
}: MutateExpediencyTestVariables) => {
  if (!userId) return;

  const normalizedAffiliatePepList = payload.contents.pepDetails?.affiliatePepList?.map(
    item => {
      const positionList = item.positionList.map(it => ({
        ...it,
        fromDate: dateToString(it.fromDate ?? null),
        toDate: dateToString(it.toDate ?? null),
      }));
      return {
        ...item,
        positionList,
      };
    },
  );

  const payloadCopy = normalizedAffiliatePepList
    ? {
        ...payload,
        contents: {
          ...payload.contents,
          pepDetails: {
            ...payload.contents.pepDetails,
            affiliatePepList: normalizedAffiliatePepList,
          },
        },
      }
    : payload;

  const response = await post(
    `${EXPEDIENCY_TEST_URL}/${userId}?accountId=${account?.id}&corpoAccount=${corpoAccount}`,
    JSON.stringify(payloadCopy),
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Lagring av hensiktsmessighets-test feilet',
      response: response.clone(),
    });
  }

  return response;
};

type PersonalInfo = {
  name: string;
  phoneNumber: string;
  email: string;
  address: string;
  postalCode: string;
  bankAccount: string;
  vpsAccount: string;
};

export const getPersonalInfo = async (
  userId: string,
  accountId: string,
  corpoAccount?: Boolean,
) => {
  if (!userId) return;
  const urlAddr = `${EXPEDIENCY_TEST_URL}/${userId}/personal-info?accountId=${accountId}&corpoAccount=${corpoAccount}`;
  const response = await get(urlAddr);

  if (!response.ok) {
    throw new FetchError({
      message: `Henting av personlig informasjon feilet: ${urlAddr}`,
      response,
    });
  }

  try {
    const data = await response.json();
    return data as PersonalInfo;
  } catch (error) {
    return;
  }
};

export const corpoAccount = (accountId?: string, accounts?: Account[]) => {
  let result = false;
  if (accounts && accountId) {
    result = accounts.some(
      item => item.id === accountId && item.isJuristicPerson === true,
    );
  }
  return result;
};

export const getExpediencyFlag = async (
  userId: string,
  isCorpoAccount: boolean,
  accountId?: string,
) => {
  if (!userId || !accountId) {
    return;
  }

  if (localStorage.getItem('debugChangeAccount') === 'true') {
    return false;
  }

  const response = await get({
    url: `${EXPEDIENCY_TEST_URL}/${userId}/must-take-test?accountId=${accountId}&corpoAccount=${isCorpoAccount}`,
  });

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av expediency test feilet',
      response,
    });
  }

  try {
    const data = await response.json();
    return data as Boolean;
  } catch (error) {
    return;
  }
};

type SigningStatus = {
  status:
    | 'CREATED'
    | 'COMPLETED'
    | 'REJECTED'
    | 'EXPIRED'
    | 'DELETED'
    | 'FAILED';
};
export const getSigningStatus = async (
  userId: string,
  isCorpoAccount: boolean,
  accountId?: string,
) => {
  if (!userId) return;

  const response = await get(
    `${EXPEDIENCY_TEST_URL}/${userId}/signing-status?accountId=${accountId}&corpoAccount=${isCorpoAccount}`,
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av signeringsstatus feilet',
      response,
    });
  }

  try {
    const data = await response.json();
    return data as SigningStatus;
  } catch (error) {
    return;
  }
};

type PostSigningData = {
  userId: string;
  accountId?: string;
  isCorpoAccount: boolean;
};

export const postSigning = async (data: PostSigningData) => {
  if (!data.userId) return;

  const response = await post(
    `${EXPEDIENCY_TEST_URL}/${data.userId}/sign?accountId=${data.accountId}&corpoAccount=${data.isCorpoAccount}`,
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Kunne ikke sende videre til signering',
      response,
    });
  }

  try {
    const data = await response.json();
    return data as unknown;
  } catch (error) {
    return;
  }
};
