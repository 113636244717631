import { RESERVATION_DECLINED, PAYMENT_DECLINED } from 'utils/products';

export const initialProductOptions = [
  {
    value: 'DELAYED',
    label: 'Realtid snapshot uten ordredybde - Gratis',
  },
  {
    value: 'REAL_TIME_LEVEL1',
    label: 'Streaming uten ordredybde - 30 NOK',
  },
  {
    value: 'REAL_TIME_LEVEL2',
    label: 'Streaming med ordredybde - 135 NOK',
  },
  {
    value: 'REAL_TIME_LEVEL2_PROFESSIONAL',
    label:
      'Streaming med ordredybde profesjonell (kun for foretak med mer enn 1 eier) - 640 NOK',
  },
];

export type Product = {
  cancelled: boolean;
  contractStatus: string;
  productType: string;
  custFeeId: string;
  pending: boolean;
  transStatus: string;
  name: string;
};

export const generateProductOptions = (activeProduct?: Product) => {
  return initialProductOptions.map(option => {
    if (!activeProduct && option.value === 'DELAYED') {
      return { ...option, label: `${option.label} (Aktiv)` };
    }

    if (option.value === activeProduct?.name) {
      const declined =
        activeProduct?.transStatus === RESERVATION_DECLINED ||
        activeProduct?.transStatus === PAYMENT_DECLINED;

      let statusText = 'Aktiv';
      if (activeProduct?.pending) {
        statusText = 'Bestilt';
        if (declined) {
          statusText = 'Avvist';
        }
      }
      return { ...option, label: `${option.label} (${statusText})` };
    }
    return option;
  });
};
