import { Badge, BadgeProps } from '@oms/ui-badge';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import useActiveOrders from 'utils/hooks/useActiveOrders';
import useUser from 'utils/hooks/useUser';

const ActiveOrdersBadge = (props: BadgeProps) => {
  const {
    data: { userId = '' },
  } = useUser();
  const { selectedAccountId } = useSelectedAccountId();
  const { data: activeOrders } = useActiveOrders(userId, selectedAccountId);
  const activeOrderCount = activeOrders?.length ?? 0;

  if (activeOrderCount === 0) return null;

  return (
    <Badge ml={2} variant="notification" {...props}>
      {activeOrderCount}
    </Badge>
  );
};

export default ActiveOrdersBadge;
