import { useJawsContext, JawsProvider, useJaws } from '@oms/jaws-react';
import { Notification } from '@oms/ui-notification';
import { useToast } from '@oms/ui-toast';
import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'react-use';
import Jaws from '@oms/jaws';
import { BASE_SECURE_URL } from 'constants/Jaws';
import { useUserPreferences } from 'context/UserPreferencesContext';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { Link } from '@oms/ui-link';
import { useQueryClient } from 'react-query';

const LOCAL_STORAGE_KEY = '@oms/sb1-order-notify-history';

const NOTIFY_ON_STATUSES = ['S'];

type OrderUpdate = {
  IN_ORDR_STATUS: string;
  IN_ORDER_SOURCE: string;
  IN_ISO_CURR: string;
  IN_ORDR_REST: number;
  IN_VP_NO: string;
  IN_ORDER_NO: string;
  TIME: number;
  IN_TIME_CREATED: string;
  IN_CUST_NO_SHORT: string;
  IN_LIMIT2: number;
  PROVIDER: string;
  _STATUS_SERVER: number;
  IN_DATE_TO: number;
  ITEM: string;
  _STATUS_CLIENT: number;
  IN_INSTRUMENT_ID: string;
  IN_TS_EXCH_ID: string;
  FEED: string;
};

const Notifier = () => {
  const queryClient = useQueryClient();
  const { selectedAccountId } = useSelectedAccountId();
  const { preferences } = useUserPreferences();
  const [notifyHistory = [], setNotifyHistory] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEY,
    [],
  );

  const spec = useMemo(() => {
    if (!selectedAccountId) return undefined;
    return {
      type: 'secure/probrokerFeed',
      accountId: selectedAccountId,
    };
  }, [selectedAccountId]);

  const toast = useToast();
  const { items } = useJaws(spec);

  useEffect(() => {
    queryClient.invalidateQueries('activeOrders');
    queryClient.invalidateQueries('contractNotes');

    const orders = items.entrySeq().toJS() as Array<[string, OrderUpdate]>;

    // TODO: Track amount of active notifications, max 3?
    for (const [orderId, order] of orders) {
      if (
        !notifyHistory.includes(orderId) &&
        NOTIFY_ON_STATUSES.includes(order.IN_ORDR_STATUS)
      ) {
        if (!preferences.notifications?.disable) {
          toast(
            toastProps => (
              <Notification
                title={'Handel gjennomført!'}
                status={'success'}
                {...toastProps}
              >
                Ordre {orderId} er gjennomført. Du kan se den i{' '}
                <Link variant="link" to="/transactions">
                  transaksjoner
                </Link>
                .
              </Notification>
            ),
            { position: 'bottom-left', duration: 8000 },
          );
        }

        setNotifyHistory([...notifyHistory, orderId]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, notifyHistory, preferences]);

  return null;
};

const NotifierWithProvider = () => {
  const streamingJaws = useMemo(() => {
    const instance = new Jaws({
      url: BASE_SECURE_URL,
      useWebSockets: true,
    });
    return { instance };
  }, []);

  return (
    <JawsProvider value={streamingJaws}>
      <Notifier />
    </JawsProvider>
  );
};

/** Conditionally spawns own Jaws with websockets if current Jaws is using http */
export const OrderUpdateNotifier = () => {
  const { instance } = useJawsContext();
  if (instance.useWebSockets) {
    return <Notifier />;
  }

  return <NotifierWithProvider />;
};

export default OrderUpdateNotifier;
