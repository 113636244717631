import { enforce } from '../util/enforce';
import { sendIosEvent, sendAndroidEvent } from '../util/send-event';
import {
  iosAppVersionIsEqualOrAbove,
  androidAppVersionIsEqualOrAbove,
} from '../util/device-helper';

var IOS_APP_VERSION = '2.3.4';
var ANDROID_APP_VERSION = '2.3.3';

var IOS_EVENT_ID = 'openInWeb';
var ANDROID_EVENT_ID = 'openInWeb';

export function appSupportsOpenInWeb() {
  return (
    iosAppVersionIsEqualOrAbove(IOS_APP_VERSION) ||
    androidAppVersionIsEqualOrAbove(ANDROID_APP_VERSION)
  );
}

/**
 * Open an url in the phone's browser
 * @param {string} url - The url path to be opened
 */
export function openInWeb() {
  var url =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('url');

  if (iosAppVersionIsEqualOrAbove(IOS_APP_VERSION)) {
    sendIosEvent({
      action: IOS_EVENT_ID,
      url: url,
    });
  } else if (androidAppVersionIsEqualOrAbove(ANDROID_APP_VERSION)) {
    sendAndroidEvent(ANDROID_EVENT_ID, url);
  }
}
