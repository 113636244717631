import { Stack } from '@oms/ui-stack';
import { Box } from '@oms/ui-box';
import { Wrap } from '@oms/ui-wrap';
import { Heading } from '@oms/ui-heading';
import { useMedia } from '@oms/ui-media';

import MarketValue from './MarketValue';
import { Cards } from '../../components/TickerTape';

export const tickerMapDefault = {
  'OSEBX.OSE': 'Oslo Børs',
  'C:PBROUSDBR\\SP.IDCENE': 'Brent spot',
} as const;

const MyOverviewHeader = () => {
  const { isDesktop } = useMedia();

  return (
    <Wrap gap={4}>
      {isDesktop && (
        <Box flex={1} py={8}>
          <Cards tickerMap={tickerMapDefault} isScrollable={false} />
        </Box>
      )}
      <Stack flex={2} py={8} px={4} gap={4} mx="auto" alignment="center">
        <Heading variant="heading2" textAlign="center">
          Totalbeholdning
        </Heading>
        <MarketValue />
      </Stack>
      {isDesktop && <Box flex={1}>{/* Empty space ! */}</Box>}
    </Wrap>
  );
};

export default MyOverviewHeader;
