import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';
import Condition from 'components/FormComponents/Condition';

export const TransactionTradeSize = () => {
  return (
    <Stack gap={4}>
      <Heading>
        Skal du flytte verdipapirer til VPS-kontoen med det samme du oppretter
        den?
      </Heading>
      <Fieldset
        as={RadioGroup}
        name="kycMoveExistingSecurities"
        legend="Velg det som passer best"
      >
        <Field as={Radio} label="Ja" value="true" validate={required} />
        <Field as={Radio} label="Nei" value="false" validate={required} />
      </Fieldset>
      <Condition when="kycMoveExistingSecurities" is="true">
        <Heading>
          Hvor stor er ca. markedsverdien på verdipapirene du skal flytte?
        </Heading>
        <Fieldset
          as={RadioGroup}
          name="kycMarketValueOfExistingSecurities"
          legend="Velg det som passer best"
        >
          <Field
            as={Radio}
            label="Under 10.000 kr"
            value="UNDER_10K"
            validate={required}
          />
          <Field
            as={Radio}
            label="Mellom 10.000 kr og 100.000 kr"
            value="BETWEEN_10K_AND_100K"
            validate={required}
          />
          <Field
            as={Radio}
            label="Mellom 100.000 kr og 1.000.000 kr"
            value="BETWEEN_100K_AND_ONE_M"
            validate={required}
          />
          <Field
            as={Radio}
            label="1.000.000 kr eller mer"
            value="ONE_M_OR_ABOVE"
            validate={required}
          />
        </Fieldset>
      </Condition>
    </Stack>
  );
};

export default TransactionTradeSize;
