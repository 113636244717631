import {
  validateLimit,
  validateOpenVolume,
  validateStopLoss,
} from 'utils/validation/trade';
import { validateVolume } from 'utils/validation/buyForm';

import { useHeaderQuote } from '@oms/components-header-quote';
import { useItemSector } from 'context/ItemSectorContext';
import { useTickSize } from 'components/Trade/Queries/useTickSize';
import { usePeakSize } from 'components/Trade/Queries/usePeakSize';
import { useLotSize } from 'components/Trade/Queries/useLotSize';
import { Account } from './Sell';

export type Trade = {
  limit: number;
  last?: number;
  volume: number;
  tradeType: 'SELL';
  validity?: any;
  openVolume: number;
  useStopLoss: boolean;
  useOpenVolume: boolean;
  stopLossLimit: number;
  visible?: boolean;
  account?: Account;
  ticker: string;
  exchange: string;
  expirationDate: any;
};

export const useValidate = () => {
  const itemSectorValue = useItemSector();
  const [ITEM = '', SECTOR = ''] = itemSectorValue?.split('.') || [];
  const { data = {} } = useHeaderQuote({ itemSector: itemSectorValue });
  const { data: tickSize = {} } = useTickSize(data);
  const { data: peakSizeValues = [] } = usePeakSize(ITEM, SECTOR);
  const { data: LotSizeValues } = useLotSize({ ITEM, SECTOR });
  const peakSize = peakSizeValues?.rows[0]?.values?.EXCHANGE_MARKET_SIZE || 0;

  const validate = (values: Trade) => {
    const errors: { [key: string]: any } = {};
    const limit = values?.limit;
    const volume = values?.volume;
    const useOpenVolume = values?.useOpenVolume;
    const openVolume = values?.openVolume;
    const limitValidate = validateLimit(limit, tickSize);
    const useStopLoss = values.useStopLoss;
    const stopLossLimit = values.stopLossLimit;
    if (limitValidate?.includes('Tick Size er')) {
      errors.limit = limitValidate;
    }

    //Validation for limit
    if (!values.limit) {
      errors.limit = 'Påkrevet';
    }

    if (values?.limit <= 0) {
      errors.limit = 'Limit må være et positivt tall';
    }

    //Validation for stop-loss
    if (values?.useStopLoss) {
      errors.stopLossLimit = validateStopLoss({
        useStopLoss,
        stopLossLimit,
        limit,
      });
      if (values?.stopLossLimit <= 0) {
        errors.stopLossLimit = 'Volume må være et positivt tall';
      }
    }

    if (!values?.volume) {
      errors.volume = 'Påkrevet';
    }

    //Validation for volume
    if (values?.volume) {
      errors.volume = validateVolume(volume, LotSizeValues);
    }

    if (values?.volume <= 0) {
      errors.volume = 'Volume må være et positivt tall';
    }

    if (!values?.volume) {
      errors.volume = 'Påkrevet';
    }

    //Validation for openVolume
    if (values?.useOpenVolume) {
      const data = { useOpenVolume, openVolume, volume, peakSize, limit };
      errors.openVolume = validateOpenVolume(data);
    }

    if (values?.openVolume <= 0) {
      errors.openVolume = 'Må være et positivt tall';
    }

    if (!values?.expirationDate) {
      errors.expirationDate = 'Påkrevet';
    }

    if (!values?.account) {
      errors.account = 'Påkrevet';
    }
    return errors;
  };
  return validate;
};
