import { useJawsContext } from '@oms/jaws-react';
import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useThrottledCallback } from 'use-debounce';

export const useJawsRefresh = () => {
  const queryClient = useQueryClient();
  const { instance: jaws } = useJawsContext();

  const refresh = useThrottledCallback(
    () => {
      if (jaws.useWebSockets) return;

      queryClient.invalidateQueries('holdings');
      jaws.reconnect();
    },
    2000,
    { trailing: false },
  );

  return useMemo(
    () => ({
      isAvailable: !jaws.useWebSockets,
      refresh,
    }),
    [refresh, jaws.useWebSockets],
  );
};

export default useJawsRefresh;
