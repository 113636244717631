import { MODEL_PORTFOLIO_URL } from 'constants/Jaws';
import { FetchError, get } from 'utils/fetch';

type Base = {
  timestamp: string;
  value: number;
};

type Item = {
  ticker: string;
  description: string;
  total: Base;
  period: Base;
};

type Portfolio = {
  basePeriod: Base;
  baseYtd: Base;
  baseTotal: Base;
};

type Osebx = {
  basePeriodTP: Base;
  baseYtdTP: Base;
  basePeriod: Base;
  baseYtd: Base;
  baseTotalTP: Base;
  baseTotal: Base;
};

type Summary = {
  portfolio: Portfolio;
  osebx: Osebx;
};

export type WeekPortfolio = {
  items: Item[];
  summary: Summary;
  id: number;
  name: string;
};

export const getWeekPortfolioList = async () => {
  const response = await get(`${MODEL_PORTFOLIO_URL}/week`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Modellportefølje-henting feilet',
      response,
    });
  }
  const { data }: { data: WeekPortfolio } = await response.json();
  data.items.sort((a, b) => a.ticker.localeCompare(b.ticker));

  return data;
};
