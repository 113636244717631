export const positionOptions = [
  {
    value: 'headOfGovernment',
    label:
      'Statsoverhode, regjeringssjef, minister eller assisterende minister',
  },
  {
    value: 'memberOfParliament',
    label: 'Medlem av nasjonalforsamling',
  },
  {
    value: 'partyOfficial',
    label: 'Medlem av styrende organ i politisk parti',
  },
  {
    value: 'highCourtJustice',
    label:
      'Medlem av høyere rettsinstans som treffer beslutning som ikke eller bare unntaksvis kan ankes',
  },
  {
    value: 'officerOfCentralBank',
    label: 'Medlem av styre i riksrevisjon, revisjonsdomstol eller sentralbank',
  },
  {
    value: 'ambassador',
    label: "Ambassadør, chargé d'affaires eller militær offiser av høyere rang",
  },
  {
    value: 'officerOfStateOwnedEnterprise',
    label:
      'Medlem av administrativt, ledende eller kontrollerende organ i statlig foretak',
  },
  {
    value: 'directorOfInternationalOrganisation',
    label:
      'Direktør, styremedlem eller annen person i øverste ledelse i internasjonal organisasjon',
  },
] as const;
