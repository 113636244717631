import { SkeletonTable } from '@oms/ui-skeleton';
import { generatorSpec } from 'utils/Spec';
import { matchSorter } from 'match-sorter';

export const LoadingState = () => (
  <SkeletonTable animated columns={6} rows={8} />
);

export type QuotelistInstrument = {
  ITEM_SECTOR?: string;
  ASK?: number;
  BID?: number;
  CHANGE_PCT?: string;
  ITEM: string;
  LAST?: number;
  LONG_NAME: string;
  SECTOR: string;
  TIME?: string;
  TURNOVER?: number;
};

export const getSpec = (market: Markets) => {
  const columns =
    'ITEM_SECTOR, LONG_NAME, CHANGE_PCT, TURNOVER, BID, ASK, LAST, ITEM, SECTOR, TIME';

  if (market === 'ALL') {
    return { ...generatorSpec(), columns };
  }

  if (market === 'obx') {
    return { source: getFeed('ose', '_OBX') };
  }

  return { source: getFeed(market), columns };
};

// This is copied from FIN, needs work
export const getSorted = (
  data: QuotelistInstrument[],
  ranking: string,
  reversed: boolean,
) => {
  if (ranking === 'ITEM')
    return data.sort((a, b) => {
      if (reversed) return a?.ITEM > b?.ITEM ? -1 : 1;
      return a?.ITEM < b?.ITEM ? -1 : 1;
    });
  return data?.sort((a: any, b: any) => {
    const valueA = a[ranking] || 0;
    const valueB = b[ranking] || 0;

    if (valueA < valueB) return reversed ? 1 : -1;
    if (valueA > valueB) return reversed ? -1 : 1;

    return 0;
  });
};

export const getMatched = (data: any[], filter: string) =>
  matchSorter(data, filter || '', {
    keys: ['ITEM', 'LONG_NAME'],
  });

export type Markets = 'ALL' | 'obx' | 'ose' | 'oax' | 'merk';

const getFeed = (market: Markets, addon = '') =>
  `feed.${market}.quotes.EQUITIES${addon}`;

export const markets = [
  { label: 'Alle norske', value: 'ALL' },
  { label: 'OBX', value: 'obx' },
  { label: 'Oslo Børs', value: 'ose' },
  { label: 'Euronext Expand', value: 'oax' },
  { label: 'Euronext Growth', value: 'merk' },
];
