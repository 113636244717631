import React from 'react';
import { Investors as OMSInvestors } from '@oms/components-investors';
import { Card, CardProps } from 'components/Card';
import { useItemSector } from 'context/ItemSectorContext';
export interface InvestorsProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const Investors = ({ itemSector, ...props }: InvestorsProps) => {
  const itemSectorValue = useItemSector(itemSector);
  return (
    <Card title="Investorer" {...props}>
      <OMSInvestors itemSector={itemSectorValue} />
    </Card>
  );
};
