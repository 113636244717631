// ua strings
const webview = 'Webview',
  iOS = '(iPhone|iPod|iPad)(?!.*Safari)',
  android = 'Android.*(wv|.0.0.0)',
  legacyAndroid = 'Linux; U; Android';

const buildRegex = (...rules: string[]) =>
  new RegExp(`(${rules.join('|')})`, 'ig');

export function isWebview(ua = navigator.userAgent) {
  const regex = buildRegex(webview, iOS, android, legacyAndroid);
  return !!ua.match(regex);
}

export function isAndroidWebview(ua = navigator.userAgent) {
  const regex = buildRegex(android, legacyAndroid);
  return !!ua.match(regex);
}

export function isIOSWebview(ua = navigator.userAgent) {
  const regex = buildRegex(iOS);
  return !!ua.match(regex);
}
