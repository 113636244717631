import { useParams } from 'react-router-dom';
import { Box } from '@oms/ui-box';
import { ItemSectorProvider } from 'context/ItemSectorContext';
import { Page } from 'components/Page';
import { Grid, GridContainer, TopGrid } from 'components/Grid';
import { Calendar } from 'components/Calendar';
import { OrderDepth } from 'components/OrderDepth';
import { InstrumentQuote } from 'components/InstrumentQuote';
import { Investors } from 'components/Investors';
import { News } from 'components/News';
import { Trades } from 'components/Trades';
import { CompanyInfo } from 'components/CompanyInfo';
import { Dividends } from 'components/Dividends';
import { HeaderQuote } from 'components/HeaderQuote';
import { HistoricalReturn } from 'components/HistoricalReturn';
import { Card } from 'components/Card';
import { TradeForm } from 'components/Trade/TradeForm';
import { Stack } from '@oms/ui-stack';
import { Button } from '@oms/ui-button';
import { useTheme } from '@xstyled/emotion';
import { BankIDButton, WatchlistButton } from 'components/Buttons';
import { Icon, light } from '@oms/ui-icon';
import { InstrumentChart } from 'components/InstrumentChart';
import useUser from 'utils/hooks/useUser';
import { Link } from '@oms/ui-link';
import { useMedia } from '@oms/ui-media';
import {
  Drawer,
  DrawerDisclosure,
  useDrawer,
  DrawerHeader,
  DrawerContent,
} from '@oms/ui-drawer';

import {
  InstrumentAnalysis,
  InstrumentAnalysisTable,
} from 'components/InstrumentAnalysis';
import { BackButton } from 'components/BackButton';
import { useWebTVLinkOnInstrument } from 'utils/hooks/useWebTV';
import WebTVButton from 'components/Buttons/WebTVButton';
import { getItemFromItemSector } from '@oms/utils';
import {
  useStockChart,
  StockChartToggleButton,
  StockChart,
} from 'components/StockChart';
import { ErrorBoundary } from '@oms/ui-error-boundary';
import { Suspense } from 'react';
import { Spinner } from '@oms/ui-spinner';

export interface InstrumentProps {}

export function Instrument(_props: InstrumentProps) {
  const {
    data: { hasTradingRights },
  } = useUser();
  const { colors } = useTheme();
  const { itemSector } = useParams<{ itemSector?: string }>(); // <--
  const webTVLink = useWebTVLinkOnInstrument(itemSector);
  const media = useMedia();
  const isMobile = media.isMobile;

  // const media = useMedia()
  // const condition = media.matches("isMobile") && history.state.trade;
  // initial options requires @oms/ui-drawer update
  const drawer = useDrawer(/* { visible:condition } */);

  const stockChart = useStockChart();

  if (!itemSector) throw new Error('Mangler instrument!');

  return (
    <Suspense
      fallback={
        <Box minHeight="20rem" p={4} center>
          <Spinner size="lg" />
        </Box>
      }
    >
      <ItemSectorProvider itemSector={itemSector}>
        <Page
          documentTitle={getItemFromItemSector(itemSector)}
          navElement={<BackButton />}
          topElement={
            <TopGrid>
              <Box px={{ base: 4, sm: 0 }}>
                <Box overflow="hidden">
                  <ErrorBoundary>
                    <HeaderQuote />
                  </ErrorBoundary>
                </Box>
                <Stack gap={4} alignment="start" mt={{ base: 4, md: 8 }}>
                  <StockChartToggleButton {...stockChart} />
                  <Link
                    variant="link"
                    leftElement={
                      <Icon icon={light.faBell} color={colors.primary} />
                    }
                    to={`/alerts?itemSector=${itemSector}`}
                  >
                    Sett alarm
                  </Link>
                  <WatchlistButton itemSector={itemSector} />
                  {webTVLink && <WebTVButton webTVLink={webTVLink} />}
                  {isMobile && hasTradingRights && (
                    <DrawerDisclosure {...drawer} as={Button}>
                      Handle
                    </DrawerDisclosure>
                  )}
                  {isMobile && !hasTradingRights && <BankIDButton />}
                </Stack>
              </Box>
              <Box
                gridColumn={{ md: '2 / span 3' }}
                display="flex"
                flexDirection="column"
                justifyContent={{ base: 'initial', md: 'flex-end' }}
              >
                <Box display="flex" justifyContent="space-between">
                  {!isMobile && (
                    <Box>
                      <ErrorBoundary>
                        <InstrumentAnalysis />
                      </ErrorBoundary>
                    </Box>
                  )}
                  {!isMobile && (
                    <ErrorBoundary>
                      <InstrumentQuote />
                    </ErrorBoundary>
                  )}
                </Box>
                <ErrorBoundary>
                  {stockChart.selected === 'advanced' ? (
                    <StockChart />
                  ) : (
                    <InstrumentChart />
                  )}
                </ErrorBoundary>
                {isMobile && (
                  <ErrorBoundary>
                    <InstrumentQuote />
                  </ErrorBoundary>
                )}
              </Box>
            </TopGrid>
          }
        >
          <GridContainer>
            <Grid>
              {!isMobile ? (
                <Card
                  title="Handle"
                  gridRow={
                    hasTradingRights ? { sm: 'span 2 / span 1' } : undefined
                  }
                >
                  {hasTradingRights ? (
                    <TradeForm />
                  ) : (
                    <Box minHeight="10rem" center>
                      <BankIDButton as={Link} to="/login">
                        Logg inn for handel
                      </BankIDButton>
                    </Box>
                  )}
                </Card>
              ) : (
                <Drawer placement="sheet" {...drawer}>
                  <div style={{ zIndex: 1, position: 'sticky', top: 0 }}>
                    <DrawerHeader>Handle</DrawerHeader>
                  </div>
                  <DrawerContent>
                    <ErrorBoundary>
                      <TradeForm />
                    </ErrorBoundary>
                    <div
                      // spacer div :(
                      // adds some space for the opened date picker
                      aria-hidden={true}
                      style={{ height: 240, minHeight: 240 }}
                    ></div>
                  </DrawerContent>
                </Drawer>
              )}
              {isMobile && (
                <ErrorBoundary>
                  <InstrumentAnalysisTable />
                </ErrorBoundary>
              )}
              {/* All the components below are wrapped by a `Card`-component, which contains an error boundary */}
              <OrderDepth />
              <Trades />
              <HistoricalReturn />
              <News gridColumn={{ sm: 'span 2 / span 1' }} />
              <Calendar />
              <Dividends />
              <Investors />
              <CompanyInfo />
            </Grid>
          </GridContainer>
        </Page>
      </ItemSectorProvider>
    </Suspense>
  );
}

export default Instrument;
