import { CUSTOMERTRANSACTIONS_URL, ORDERS_URL } from 'constants/Jaws';
import { ONE_MINUTE } from 'constants/timeInMs';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { FetchError, get } from 'utils/fetch';
import { useAccounts } from '../useAccounts';
import { useNotification } from '../useNotification';
import { deleteActiveOrder } from './helpers';

export type StockOrder = {
  CREATED_DATE: number;
  STOP_LOSS_LIMIT: number;
  TOTAL_VOLUME: number;
  ORDER_TYPE: number;
  LAST_MODIFIED_DATE: number;
  LIMIT: number;
  MEAN_RATE: number;
  ORDER_SPECIFICATION: number;
  INSTRUMENT_ID: string;
  TICKER_CODE: string;
  HIDDEN_VOLUME: number;
  INSTRUMENT_NAME: string;
  EXPIRATION_DATE: number;
  ORDER_ID: string;
  ORDER_SOURCE: string;
  STOCK_EXCHANGE_ID: string;
  STOP_LOSS_TRIGGER_CRITERION?: 'lt' | 'lte' | 'gte' | 'gt' | null;
  REMAINING_VOLUME: number;
  ORDER_STATUS: number;
  TYPE: number;
};

type StockOrderWithAccount = StockOrder & { accountId: string };

const includeStatuses = [0, 2, 3, 8, 9];
const getActiveOrders = async (userId: string, accountId: string) => {
  const response = await get({
    url: `${ORDERS_URL}/${userId}/orders/current{?accountId}`,
    accountId,
  });

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av aktive ordre feilet',
      response,
    });
  }
  const { data } = (await response.json()) as { data: StockOrder[] };
  const activeOrders = data
    .filter(order => includeStatuses.includes(order.ORDER_STATUS))
    .map(
      activeOrder => ({ ...activeOrder, accountId } as StockOrderWithAccount),
    );

  activeOrders.sort((a, b) => b.EXPIRATION_DATE - a.EXPIRATION_DATE);

  return activeOrders;
};

export const useActiveOrders = (userId: string, accountId: string) => {
  return useQuery(
    ['activeOrders', accountId],
    () => getActiveOrders(userId, accountId),
    { enabled: !!userId && !!accountId },
  );
};

export const useAllActiveOrders = (userId: string) => {
  const {
    data: { accounts },
  } = useAccounts();

  const activeOrderQueries = accounts.map(account => ({
    queryKey: ['activeOrders', account.id],
    queryFn: () => getActiveOrders(userId, account.id),
  }));

  const queries = useQueries(activeOrderQueries);
  const allActiveOrders = queries
    .flatMap(query => query.data)
    .filter(Boolean) as StockOrderWithAccount[];
  return allActiveOrders;
};

export type ContractNote = {
  PRICE: number;
  VOLUME: number;
  EXECUTED_DATE: string;
};

const getContractNotes = async (
  userId: string,
  accountId: string,
  orderId: string,
) => {
  const response = await get({
    url: `${CUSTOMERTRANSACTIONS_URL}/${userId}/orders/${orderId}/transactions{?accountId}`,
    accountId,
  });

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av delhandler feilet',
      response,
    });
  }
  const { data } = await response.json();

  return data as ContractNote[];
};

type UseContractNotesArguments = {
  userId: string;
  accountId: string;
  orderId: string;
  enabled?: boolean;
};
export const useContractNotes = ({
  userId,
  accountId,
  orderId,
  enabled = true,
}: UseContractNotesArguments) =>
  useQuery(
    ['contractNotes', accountId, orderId],
    () => getContractNotes(userId, accountId, orderId),
    { enabled },
  );

export const useDeleteActiveOrder = () => {
  const notify = useNotification();
  const queryClient = useQueryClient();
  return useMutation(deleteActiveOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries('activeOrders');
      notify({ status: 'success', title: 'Sletting gjennomført' });
    },
    onError: (error: Error) => {
      notify({
        status: 'error',
        title: 'Kunne ikke slette ordren',
        children: error?.message,
        duration: ONE_MINUTE, // Infinity didn't work
      });
    },
  });
};

export default useActiveOrders;
