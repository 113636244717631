import React from 'react';
import moment from 'moment';
import { REPORT_URL } from 'constants/Jaws';
import { useReports, ReportType } from './useReports';
import { Heading } from '@oms/ui-heading';
import { Text } from '@oms/ui-text';
import { Stack } from '@oms/ui-stack';
import styled from '@xstyled/emotion';
import { Link } from '@oms/ui-link';
import { handleBlankPdfLink } from 'utils/app';

const Pre = styled.pre`
  white-space: pre-wrap;
  font-family: body;
`;

export interface ReportProps {
  type: ReportType;
}

export const ReportArticle = ({ type }: ReportProps) => {
  return <AnalysisComponent type={type} />;
};

const AnalysisComponent = ({ type = 'MORNINGREPORT' }: ReportProps) => {
  const { data: reports = [] } = useReports({ type, count: 1 });

  if (!reports.length) {
    return (
      <Heading
        as="h4"
        variant="heading5"
        mt={{ base: 2, sm: 'initial' } as any}
        // same as padding-left on a table cell
        ml={{ base: '0.675rem', sm: 'initial' } as any}
      >
        Ingen tilgjenglige rapporter funnet av denne typen
      </Heading>
    );
  }

  const report = reports[0];

  return (
    <Stack as="article" px={{ base: '0.675rem', sm: 'initial' }}>
      <Heading
        as="h4"
        variant="heading5"
        mt={{ base: 2, sm: 'initial' } as any}
        // same as padding-left on a table cell
      >
        {report.title}
      </Heading>
      <Text as="pre" fontFamily="body" py={2}>
        <Pre>{report.ingress}</Pre>
      </Text>
      <p>
        Skrevet av: {report?.analystName} <br />
        {moment(report.startTime).format('DD.MM.YYYY')}
      </p>
      {!!report.document && !!report.document.id && (
        <div>
          <Link
            as="a"
            variant="link"
            href={`${REPORT_URL}/file/${report?.document?.id}`}
            onClick={handleBlankPdfLink}
          >
            Last ned PDF
          </Link>
        </div>
      )}
    </Stack>
  );
};

export default ReportArticle;
