import { HotStocks as OMSHotStocks } from '@oms/components-hot-stocks';
import { Card, CardProps } from 'components/Card';
import { generatorSpec } from 'utils/Spec';

export interface HotStocksProps extends Omit<CardProps, 'children'> {}

export const HotStocks = (props: HotStocksProps) => (
  <Card title="Unormal høy omsetning" {...props}>
    <OMSHotStocks showMoreButton spec={generatorSpec()} />
  </Card>
);
