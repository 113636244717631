import { useState, useEffect, useCallback } from 'react';
import { Form } from 'react-final-form';
import { useMasterAccount } from 'utils/hooks/useAccounts';
import { Select } from '@oms/ui-select';
import { Text } from '@oms/ui-text';
import { Button } from '@oms/ui-button';
import { Box } from '@oms/ui-box';
import { Notification } from '@oms/ui-notification';
import { getDetails } from 'utils/errorMessage';
import { LogoutButton } from 'components/Buttons';
import { Card, CardProps } from 'components/Card';
import {
  initialProductOptions,
  generateProductOptions,
  Product,
} from './utils';

import {
  getProducts,
  orderProduct,
  removeProduct,
  mapIdToProduct,
  SENT_TO_RESERVATION,
} from 'utils/products';
import useUser from 'utils/hooks/useUser';

import { Stack } from '@oms/ui-stack';
import { Heading } from '@oms/ui-heading';

type SubmitStatus = 'idle' | 'pending' | 'success' | 'error';
export interface UserServicesProps extends Omit<CardProps, 'children'> {}

export function UserServices(props: UserServicesProps) {
  const {
    data: { masterAccount },
  } = useMasterAccount();

  const accountId = masterAccount?.id;
  const {
    data: { userId = '' },
  } = useUser();
  const [products, setProducts] = useState<Product[]>([]);
  // eslint-disable-next-line
  const [productOptions, setProductOptions] = useState(initialProductOptions);

  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('idle');
  const [error, setError] = useState<string>();
  const [selectedProduct, setSelectedProduct] = useState<string>('DELAYED');

  const refreshProducts = useCallback(async () => {
    const productResponse = await getProducts(userId, accountId);

    const products =
      productResponse?.data?.map((product: Product) => {
        const productDetails = mapIdToProduct(product.productType);
        return { ...product, ...productDetails };
      }) || [];

    const [activeProduct]: [Product | undefined] = products;

    const newProductOptions = generateProductOptions(activeProduct);

    setProducts(products);
    setProductOptions(newProductOptions);
    setSelectedProduct(activeProduct?.name || 'DELAYED');
    // eslint-disable-next-line
  }, [userId, accountId]);

  useEffect(() => {
    refreshProducts();
  }, [refreshProducts]);

  const handleOrderProduct = async (productId?: string) => {
    if (!productId) {
      return null;
    }

    setSubmitStatus('pending');
    setError(undefined);

    try {
      if (products.length) {
        const currentProductId = products[0].custFeeId;
        await removeProduct(currentProductId, userId, accountId);
      }

      // DELAYED is equivalent to "no product". No need to order anything new
      if (productId !== 'DELAYED') {
        await orderProduct(productId, userId, accountId);
      }

      // Give the server a second to process the request,
      // then try to refresh. If it is not updated by then,
      // the user should have to come back later (we can
      // not be polling ProBroker).
      setTimeout(async () => {
        await refreshProducts();
        setSubmitStatus('success');
      }, 2000);
    } catch (err) {
      setSubmitStatus('error');
      setError(
        'En feil oppstod ved bestilling av ' +
          `${productId}: ${getDetails(err.response.text).join(', ')}`,
      );
    }
  };

  const disableOrderButton = products.some(
    (product: Product) =>
      product.pending && product.transStatus === SENT_TO_RESERVATION,
  );

  const [activeProduct] = products;

  const trade = (values: any) => {};
  const servicesProducts = [
    {
      label: 'Realtid snapshot uten ordredybde - Gratis',
      value: 'DELAYED',
    },
    {
      label: 'Streaming uten ordredybde - 30 NOK',
      value: 'REAL_TIME_LEVEL1',
    },
    {
      label: 'Streaming med ordredybde - 135 NOK',
      value: 'REAL_TIME_LEVEL2',
    },
    {
      label:
        'Streaming med ordredybde profesjonell (kun for foretak med mer enn 1 eier) - 640 NOK',
      value: 'REAL_TIME_LEVEL2_PROFESSIONAL',
    },
  ];

  return (
    <Form
      initialValues={{
        price: undefined,
        amount: undefined,
        date: undefined,
        _switch: false,
      }}
      onSubmit={trade}
    >
      {({ handleSubmit, submitError, values, form }) => {
        return (
          <Card
            title="Ditt abonnement"
            id="RealtimeSubscriptionBox"
            {...props}
            py={{ base: 4 }}
          >
            <Stack as="form" gap={3} onSubmit={handleSubmit} px={{ base: 3 }}>
              <Heading variant="heading4">
                Abonnement på kursinformasjon
              </Heading>
              <Text>
                Prisene er per mnd. inkl. mva. og inkluderer børsavgift til Oslo
                Børs.
              </Text>
              <Select
                name="product"
                value={servicesProducts.find(
                  option => option.value === selectedProduct,
                )}
                items={servicesProducts}
                onChange={(product: any) => {
                  setSelectedProduct(product.value);
                }}
              />
              {submitError && (
                <Notification status="error" title="Oops, something went wrong">
                  {submitError}
                </Notification>
              )}
              <Stack orientation="vertical" gap={3}>
                <Button
                  disabled={
                    selectedProduct === activeProduct?.name ||
                    disableOrderButton
                  }
                  title="Bestill"
                  onClick={() => handleOrderProduct(selectedProduct)}
                  isLoading={submitStatus === 'pending'}
                  flex={1}
                >
                  Bestill
                </Button>
                {submitStatus === 'success' &&
                  selectedProduct === activeProduct?.name && (
                    <Box
                      display="flex"
                      flexDirection={{ base: 'column', md: 'row' }}
                      gridGap="4"
                    >
                      <Text>
                        Logg ut og så inn igjen for å aktivere denne tjenesten!
                      </Text>
                      <LogoutButton />
                    </Box>
                  )}
                {disableOrderButton && (
                  <Text>
                    Du har en bestilling som allerede er reservert, for å unngå
                    flere trekk er endringer midlertidig utilgjengelig.
                  </Text>
                )}
                {submitStatus === 'error' && <Text>{error}</Text>}
              </Stack>
            </Stack>
          </Card>
        );
      }}
    </Form>
  );
}
