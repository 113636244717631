import { LinePath } from '@visx/shape';
import { scaleTime, scaleLinear } from '@visx/scale';

import { Threshold } from '@visx/threshold';
import { useComponentsContext } from '@oms/components-config-context';
import { Box } from '@oms/ui-box';
import {
  getSpaceFromSelection,
  getSeriesFromSelection,
  PERIODS,
  POINTS,
  buildURL,
} from '@oms/components-instrument-chart';
import { useQuery } from 'react-query';
import { Skeleton } from '@oms/ui-skeleton';
export const background = '#f3f3f3';

/**
 * [0] Date in unix
 * [1] TickValue
 */
type DataPoint = [number, number];

// accessors
const date = (d: DataPoint) => d[0];
const tick = (d: DataPoint) => d[1];

export type SparklineGraphProps = {
  width: number;
  height: number;
  itemSector: string;
  options?: {
    color?: string;
    strokeWidth?: number;
    negColor?: string;
    negFillColor?: string;
    posColor?: string;
    posFillColor?: string;
  };
};

export default function SparklineThresholdGraph({
  width,
  height,
  itemSector,
  options = {},
}: SparklineGraphProps) {
  const { data = [], isLoading } = useGraphData({
    itemSector,
    selection: 'SELECTION_INTRADAY',
  });
  if (isLoading)
    return (
      <Box height={`${height}px`} width={`${width}px`} center>
        <Skeleton animated height="100%" width="100%" />
      </Box>
    );
  if (width < 10 || data === undefined || data?.length === 0) return null;

  // scales
  const timeScale = scaleTime<number>({
    domain: [Math.min(...data.map(date)), Math.max(...data.map(date))],
  });
  const tickScale = scaleLinear<number>({
    domain: [
      Math.min(...data.map(d => Math.min(tick(d), tick(d)))),
      Math.max(...data.map(d => Math.max(tick(d), tick(d)))),
    ],
    nice: true,
  });

  const yMax = height - 3;
  const xMax = width - 3; // To make sure circle doesn't clip

  timeScale.range([3, xMax]);
  tickScale.range([yMax, 3]);

  const yStart = tickScale(tick(data[0]));
  const {
    strokeWidth = 1.5,
    posColor = 'green',
    posFillColor = 'green',
    negColor = 'red',
    negFillColor = 'red',
  } = options;

  return (
    <svg width={width} height={height}>
      <defs>
        <clipPath id="positive">
          <rect x={0} y={0} width={width} height={yStart} />
        </clipPath>
        <clipPath id="negative">
          <rect x={0} y={yStart} width={width} height={height} />
        </clipPath>
      </defs>
      <Threshold
        id={`${Math.random()}`}
        data={data}
        x={d => timeScale(date(d)) ?? 0}
        y0={d => tickScale(tick(d)) ?? 0}
        y1={yStart + 0.001} // Why do I have to do this? Without it sometimes the whole area switches sides
        clipAboveTo={0}
        clipBelowTo={yMax}
        belowAreaProps={{
          fill: negFillColor,
          fillOpacity: 0.4,
        }}
        aboveAreaProps={{
          fill: posFillColor,
          fillOpacity: 0.4,
        }}
      />

      <LinePath
        data={data}
        x={d => timeScale(date(d)) ?? 0}
        y={d => tickScale(tick(d)) ?? 0}
        stroke={posColor}
        strokeWidth={strokeWidth}
        clipPath="url(#positive)"
      />
      <LinePath
        data={data}
        x={d => timeScale(date(d)) ?? 0}
        y={d => tickScale(tick(d)) ?? 0}
        stroke={negColor}
        strokeWidth={strokeWidth}
        clipPath="url(#negative)"
      />
      <line
        x1={0}
        y1={yStart}
        x2={xMax}
        y2={yStart}
        stroke="#bbb"
        strokeWidth={0.2}
      />
      <circle
        cx={timeScale(date(data[data.length - 1]))}
        cy={tickScale(tick(data[data.length - 1]))}
        r="3"
        fill={posColor}
        clipPath="url(#positive)"
      />
      <circle
        cx={timeScale(date(data[data.length - 1]))}
        cy={tickScale(tick(data[data.length - 1]))}
        r="3"
        fill={negColor}
        clipPath="url(#negative)"
      />
    </svg>
  );
}

const getGraphSeries = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok)
    throw new Error(`Fetching graphdata with url "${url}" failed`);

  const { rows } = await response.json();
  const data = rows?.[0]?.values?.series?.s1?.data || [];
  return data as Array<DataPoint>;
};

type UseGraphDataArguments = {
  itemSector: string;
  selection: keyof typeof PERIODS;
};
const useGraphData = ({ itemSector, selection }: UseGraphDataArguments) => {
  const { baseUrl, graphdataUrl } = useComponentsContext();

  const url = buildURL(graphdataUrl, {
    baseUrl,
    itemSector: encodeURI(itemSector),
    space: getSpaceFromSelection(selection),
    series: getSeriesFromSelection(selection),
    query: {
      period: PERIODS[selection],
      points: POINTS[selection],
    },
  });

  const { data, ...queryState } = useQuery(
    ['graphData', itemSector],
    () => getGraphSeries(url),
    { staleTime: 30 * 1000 },
  );
  return { data, ...queryState };
};
