import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';

export const TransactionFundsSize = () => {
  return (
    <Stack gap={4}>
      <Heading>Hvor mye midler forventes investert pr. år?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycTransactionFundsSize"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="Under 100.000 kr."
          value="UNDER_100K"
          validate={required}
        />
        <Field
          as={Radio}
          label="Opptil 1.000.000 kr"
          value="UP_TO_1M"
          validate={required}
        />
        <Field
          as={Radio}
          label="Opptil 5.000.000 kr"
          value="UP_TO_5M"
          validate={required}
        />
        <Field
          as={Radio}
          label="Over 5.000.000 kr"
          value="5M_OR_ABOVE"
          validate={required}
        />
      </Fieldset>
    </Stack>
  );
};

export default TransactionFundsSize;
