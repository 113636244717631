import { useHistory } from 'react-router-dom';
import { Icon, light } from '@oms/ui-icon';
import { Button } from '@oms/ui-button';
import { Box } from '@oms/ui-box';
export const BackButton = () => {
  const history = useHistory();

  return (
    <Box px={{ base: 0, sm: 4 }}>
      <Button
        as={Button}
        variant="link"
        size="sm"
        onClick={() => history.goBack()}
        leftElement={<Icon icon={light.faArrowLeft} />}
      >
        Tilbake
      </Button>
    </Box>
  );
};

export default BackButton;
