import createDecorator from 'final-form-calculate';

const handlePepToggle = (listName: string) => (
  fieldValue: 'true' | 'false',
  values: any,
) => {
  // Toggled on and no list (should be every case), initialize with one person in array
  if (fieldValue === 'true' && !values?.pepDetails?.[listName]) return [{}];
  // I guess this is just a backup case where existing non-cleared list is returned
  if (fieldValue === 'true') return values?.pepDetails?.[listName];
  // If field is toggled off or removed completely just remove field alltogheter
  return undefined;
};

export const pepDecorator = createDecorator(
  {
    field: 'pepDetails.IamPep', // when IamPep changes...
    updates: {
      // handle related list
      'pepDetails.affiliatePepList': handlePepToggle('affiliatePepList'),
    },
  },
  {
    field: 'pepDetails.familyIsPep', // when familyIsPep changes...
    updates: {
      // handle related list
      'pepDetails.familyPepList': handlePepToggle('familyPepList'),
    },
  },
);
