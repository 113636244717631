import { FetchError, get, put } from '../../utils/fetch';
import { ADMIN_BROKERAGE_LIMIT_URL } from '../../constants/Jaws';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const putBrokerageLimit = async (value: number) => {
  const body = { key: 'EQUITIES', brokerageFeeLimitPercentage: value };
  const response = await put(
    `${ADMIN_BROKERAGE_LIMIT_URL}`,
    JSON.stringify(body),
  );
  if (!response.ok)
    throw new FetchError({
      message: 'Oppdater av Kurtasje feilet',
      response,
    });
  return null;
};

const fetchBrokerageLimit = async () => {
  const response = await get(`${ADMIN_BROKERAGE_LIMIT_URL}/EQUITIES`);

  if (!response.ok)
    throw new FetchError({
      message: 'Henting av Kurtasje feilet',
      response,
    });
  const { brokerageFeeLimitPercentage } = await response.json();
  return brokerageFeeLimitPercentage as number;
};

const brokerageQueryKey = 'brokerageLimit';

// Hooks ( Only admin with Editor role )
export const useUpdateBrokerageLimit = () => {
  const queryClient = useQueryClient();
  return useMutation((value: number) => putBrokerageLimit(value), {
    onSuccess: () => queryClient.invalidateQueries(brokerageQueryKey),
  });
};

export const useFetchBrokerageLimit = () =>
  useQuery([brokerageQueryKey], () => fetchBrokerageLimit());
