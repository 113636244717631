/**
 * A function used to display better error messages when required parameters are not supplied,
 * to be used in in public-facing (exported) functions.
 * @param {string} name - The name of the parameter to be checked.
 */
export function enforce(name) {
  if (name) {
    throw new Error('Required parameter "' + name + '" was not supplied.');
  } else {
    throw new Error('Required parameter was not supplied.');
  }
}
