import { Box } from '@oms/ui-box';
import { Table, Tbody, Td, Th, Tr } from '@oms/ui-table';
import { Tooltip } from '@oms/ui-tooltip';
import { Wrap } from '@oms/ui-wrap';
import { getItemFromItemSector } from '@oms/utils';
import { Card } from 'components/Card';
import { FormattedBox } from 'components/InstrumentQuote/FormattedBox';
import useInstrumentAnalysisPortfolio, {
  InstrumentAnalysis as Analysis,
} from 'components/WeeklyPortfolio/useInstrumentAnalysisPortfolio';
import { REPORT_URL } from 'constants/Jaws';
import { useItemSector } from 'context/ItemSectorContext';
import moment from 'moment';
import { handleBlankPdfLink } from 'utils/app';
import { mapRecommendation } from 'utils/recommendations';

interface Props {}

const AnalysisFileLink = ({ analysis }: { analysis?: Analysis }) => {
  if (!analysis?.report?.document) return null;
  return (
    <Tooltip content={analysis.report.title}>
      <Box
        as="a"
        display="block"
        maxWidth="14rem"
        overflow="hidden"
        textOverflow="ellipsis"
        href={`${REPORT_URL}/file/${analysis.report.document.id}`}
        onClick={handleBlankPdfLink}
      >
        {analysis.report.title}
      </Box>
    </Tooltip>
  );
};

export const InstrumentAnalysis = (props: Props) => {
  const itemSector = useItemSector();
  const item = getItemFromItemSector(itemSector);
  const { data: instrumentAnalysis } = useInstrumentAnalysisPortfolio({
    ticker: item,
  });

  if (!instrumentAnalysis) return null;

  const {
    ticker,
    recommendation,
    recommendationPrice,
    report,
  } = instrumentAnalysis;

  if (!recommendation || recommendation === 'BLANK') return null;

  return (
    <Wrap gap={5} px={2} py={2} gridGap={4}>
      <FormattedBox title="Ticker" value={ticker} type="string" />
      <FormattedBox
        title="Anbefaling"
        value={mapRecommendation(recommendation)}
        type="string"
      />
      <FormattedBox title="Kursmål" value={recommendationPrice} type="number" />
      <FormattedBox
        title="Dato"
        value={moment(report.startTime).format('DD.MM.YYYY')}
        type="string"
      />
      <FormattedBox
        title="Analyse"
        value={<AnalysisFileLink analysis={instrumentAnalysis} />}
        type="string"
      />
    </Wrap>
  );
};

export const InstrumentAnalysisTable = () => {
  const itemSector = useItemSector();
  const item = getItemFromItemSector(itemSector);
  const { data: instrumentAnalysis } = useInstrumentAnalysisPortfolio({
    ticker: item,
  });

  if (!instrumentAnalysis) return null;

  const {
    ticker,
    recommendation,
    recommendationPrice,
    report,
  } = instrumentAnalysis;

  if (!recommendation || recommendation === 'BLANK') return null;

  return (
    <Card title="Analyse">
      <Table variant="keyValue">
        <Tbody>
          <Tr>
            <Th>Ticker</Th>
            <Td>{ticker}</Td>
          </Tr>
          <Tr>
            <Th>Anbefaling</Th>
            <Td>{mapRecommendation(recommendation)}</Td>
          </Tr>
          <Tr>
            <Th>Kursmål</Th>
            <Td type="number">{recommendationPrice}</Td>
          </Tr>
          <Tr>
            <Th>Dato</Th>
            <Td type="dateTime" formatString="DD.MM.YYYY">
              {report.startTime}
            </Td>
          </Tr>
          <Tr>
            <Th>Analyse</Th>
            <Td verticalAlign="middle">
              <AnalysisFileLink analysis={instrumentAnalysis} />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Card>
  );
};
