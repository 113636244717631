export const getSorted = <TData extends any[]>(
  data: TData,
  ranking: string,
  reversed: boolean,
) => {
  return data.sort((a: any, b: any) => {
    let valueA = a[ranking] || 0;
    let valueB = b[ranking] || 0;

    const MARKET_VALUE_A = a?.POSITION_VOLUME * a?.LAST;
    const MARKET_VALUE_B = b?.POSITION_VOLUME * b?.LAST;

    const UNREALIZED_PROFIT_A = MARKET_VALUE_A - a?.COST_BUY_PRICE;
    const UNREALIZED_PROFIT_B = MARKET_VALUE_B - b?.COST_BUY_PRICE;

    switch (ranking) {
      case 'ITEM':
        valueA = a?.ITEM;
        valueB = b?.ITEM;
        break;
      case 'MARKET_VALUE':
        valueA = MARKET_VALUE_A;
        valueB = MARKET_VALUE_B;
        break;
      case 'UNREALIZED_PROFIT_PCT':
        valueA = UNREALIZED_PROFIT_A;
        valueB = UNREALIZED_PROFIT_B;
        break;
    }

    if (valueA < valueB) return reversed ? 1 : -1;
    if (valueA > valueB) return reversed ? -1 : 1;
    return 0;
  });
};
