import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { Text } from '@oms/ui-text';
import useMarginInfo from 'utils/hooks/useMarginInfo';
import styled from '@xstyled/emotion';
import { Wrap } from '@oms/ui-wrap';
import { Heading } from '@oms/ui-heading';
import { formatInteger } from '@oms/utils';
import { Accordion, AccordionGroup } from '@oms/ui-accordion';
import { MarginDonut } from 'components/Charts';
import MarginDetails from './MarginDetails';
import { IconButton } from '@oms/ui-icon-button';
import { light } from '@oms/ui-icon';
import { useNavigateSheet } from '@oms/ui-sheet';
import { sheetId } from 'components/Sheets';

const Dt = styled.dt`
  color: secondary;
  text-align: center;
`;
const Dd = styled.dd`
  text-align: center;
`;

/** Ensures a number is between 0 and 100 */
const percentage = (input: number) => Math.max(0, Math.min(100, input));

export const MarginOverview = () => {
  const navigate = useNavigateSheet();
  const { selectedAccountId } = useSelectedAccountId();
  const { data: marginInfo } = useMarginInfo({ accountId: selectedAccountId });
  if (!marginInfo) return null;
  const {
    collateral = 0,
    marginValue,
    sumLoan = 0,
    creditLimit = 0,
    capital = 0,
  } = marginInfo;

  const collateralBreached = collateral < 0;
  const availaibleMargin = marginValue ? (collateral / marginValue) * 100 : 0;
  const usedCredit =
    sumLoan && creditLimit ? Math.abs(sumLoan / creditLimit) * 100 : 0;

  return (
    <Stack pt={4} gap={8}>
      <Heading as="h3">
        Kreditt{' '}
        <IconButton
          icon={light.faInfoCircle}
          tooltip="Definisjoner"
          onClick={() => navigate(sheetId.marginDefinitions, undefined)}
        />
      </Heading>
      <Wrap gap={4} justifyContent="space-around">
        <Box>
          <Text as={Dt}>Kapital</Text>
          <Text as={Dd} fontSize="lg" color={capital >= 0 ? 'pos' : 'neg'}>
            {formatInteger(capital)}
          </Text>
        </Box>
        <Box>
          <Text as={Dt}>Sikkerhet</Text>
          <Text as={Dd} fontSize="lg" color={collateral >= 0 ? 'pos' : 'neg'}>
            {formatInteger(collateral)}
          </Text>
          {collateralBreached && (
            <Text fontSize="lg" color="danger" mt={2}>
              Brudd i sikkerhet
            </Text>
          )}
        </Box>
        <Box>
          <Text as={Dt}>Kredittramme</Text>
          <Text as={Dd} fontSize="lg" color={creditLimit >= 0 ? 'pos' : 'neg'}>
            {formatInteger(creditLimit)}
          </Text>
        </Box>
        <Box>
          <Text as={Dt}>Lånesaldo</Text>
          <Text as={Dd} fontSize="lg" color={sumLoan >= 0 ? 'pos' : 'neg'}>
            {formatInteger(sumLoan)}
          </Text>
        </Box>
      </Wrap>
      <Wrap gap={4} justifyContent="space-evenly">
        <Stack gap={2} alignment="center">
          <Heading>Benyttet sikkerhet</Heading>
          <MarginDonut value={percentage(100 - availaibleMargin)} />
        </Stack>
        <Stack gap={2} alignment="center">
          <Heading>Benyttet kreditt</Heading>
          <MarginDonut value={percentage(usedCredit)} />
        </Stack>
      </Wrap>
      <AccordionGroup>
        <Accordion header="Kredittdetaljer">
          <MarginDetails marginInfo={marginInfo} />
        </Accordion>
      </AccordionGroup>
    </Stack>
  );
};
