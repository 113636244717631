import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
// @ts-ignore

import { Field, Fieldset } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { DateInput } from '@oms/ui-date-input';
import { ValidationErrors } from 'final-form';
import { Stack } from '@oms/ui-stack';
import { Box } from '@oms/ui-box';
import { Heading } from '@oms/ui-heading';
import { Textarea } from '@oms/ui-textarea';
import { Button } from '@oms/ui-button';
import { InfoBubble } from '@oms/ui-info-bubble';
import { Link } from '@oms/ui-link';
import { Text } from '@oms/ui-text';
import { Notification } from '@oms/ui-notification';
import { calculateExpediency, createHTest, getHTest } from 'utils/hTest';
import { posNumber, lessThanMaxYear, composeValidators } from './validate';
import ExpediencyWarning from 'components/ExpediencyWarning';

import {
  createLaunderingTest,
  modifyLaunderingTest,
} from 'utils/launderingTest';
import { useCustomerInformation } from 'utils/hooks/useUser';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import styled from '@xstyled/emotion';

function getName(equitiesPassed: any, pccPassed: any) {
  if (!equitiesPassed && !pccPassed) {
    return 'Aksjer og Egenkapitalsbevis';
  } else if (!pccPassed) {
    return 'Egenkapitalsbevis';
  } else if (!equitiesPassed) {
    return 'Aksjer';
  }

  return '';
}

const LogoHeading = styled.div`
  width: 162px;
  display: block;
  margin: 0 auto;

  img {
    height: 45px;
  }
`;

export default function HTestPageLegacy() {
  const [state, setState] = useState<Record<string, any>>({});
  const [fetchMessage, setFetchMessage] = useState<
    'idle' | 'pending' | 'success' | 'error'
  >('idle');
  const { ownerId, id, fullName } = useCustomerInformation();

  const {
    data: { selectedAccount },
  } = useSelectedAccount();
  const launderingTestCompleted = selectedAccount?.launderingTestCompleted;
  const history = useHistory();
  const initialValues = React.useMemo(
    () => ({
      accountId: id,
      userName: fullName,
      userId: ownerId,
      date: new Date(),
      education: '',
      educationDescription: '',
      profession: '',
      experienced: '',
      experienceDescription: '',
      companyBusiness: '',
      products: {
        EQUITIES_EXCHANGE_NOTED: {
          knowledgeLevel: '',
          noOfTrades: '',
          yearsExperience: '',
        },
        PCC_EXCHANGE_NOTED: {
          knowledgeLevel: '',
          noOfTrades: '',
          yearsExperience: '',
        },
      },
      assetsOrigin: '',
      assetsOriginDescription: '',
      businessRelationshipPurpose: '',
      businessRelationshipPurposeDescription: '',
    }),
    [id, fullName, ownerId],
  );

  const required = (value: any) => {
    return value === undefined || value === '' ? 'Påkrevet' : undefined;
  };

  const validate = React.useCallback(
    (values: typeof initialValues) => {
      const errors: ValidationErrors = {
        education: required(values.education),
        educationDescription: required(values.educationDescription),
        profession: required(values.profession),
        experienced: required(values.experienced),
        products: {
          EQUITIES_EXCHANGE_NOTED: {
            knowledgeLevel: required(
              values.products.EQUITIES_EXCHANGE_NOTED.knowledgeLevel,
            ),
            noOfTrades: required(
              values.products.EQUITIES_EXCHANGE_NOTED.noOfTrades,
            ),
            yearsExperience: required(
              values.products.EQUITIES_EXCHANGE_NOTED.yearsExperience,
            ),
          },
          PCC_EXCHANGE_NOTED: {
            knowledgeLevel: required(
              values.products.PCC_EXCHANGE_NOTED.knowledgeLevel,
            ),
            noOfTrades: required(values.products.PCC_EXCHANGE_NOTED.noOfTrades),
            yearsExperience: required(
              values.products.PCC_EXCHANGE_NOTED.yearsExperience,
            ),
          },
        },
        assetsOrigin: required(values.assetsOrigin),
        businessRelationshipPurpose: required(
          values.businessRelationshipPurpose,
        ),
      };

      return errors;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownerId],
  );

  const submit = React.useCallback(
    ({
      accountId,
      date,
      assetsOrigin,
      businessRelationshipPurpose,
      assetsOriginDescription,
      businessRelationshipPurposeDescription,
      ...values
    }: typeof initialValues) => {
      const products = Object.entries(values.products).map(
        ([productKey, fieldValues]) => ({
          ...fieldValues,
          date: date,
          type: productKey,
          expediency: calculateExpediency(fieldValues),
        }),
      );

      const htestPayload = { ...values, products };
      const launderingPayload = {
        accountId,
        date,
        assetsOrigin:
          assetsOrigin === 'OTHER' ? assetsOriginDescription : assetsOrigin,
        businessRelationshipPurpose:
          businessRelationshipPurpose === 'OTHER'
            ? businessRelationshipPurposeDescription
            : businessRelationshipPurpose,
      };

      const equitiesPassed =
        products?.find(product => product?.type === 'EQUITIES_EXCHANGE_NOTED')
          ?.expediency === 'YES';

      const pccPassed =
        products?.find(product => product?.type === 'PCC_EXCHANGE_NOTED')
          ?.expediency === 'YES';

      if ((equitiesPassed && pccPassed) || state.showWarning === true) {
        submitResults(htestPayload, launderingPayload, accountId);
      } else {
        setState({
          showWarning: true,
          equitiesPassed,
          pccPassed,
          htestPayload,
          launderingPayload,
          accountId,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, state],
  );

  const submitResults = async (
    htestPayload: any,
    launderingPayload: any,
    accountId: any,
  ) => {
    setFetchMessage('pending');
    const resultHtest = await createHTest(htestPayload.userId, htestPayload);
    if (!resultHtest.ok) return setFetchMessage('error');

    const resultLaunering = await (launderingTestCompleted
      ? modifyLaunderingTest
      : createLaunderingTest)(accountId, launderingPayload);

    if (!resultLaunering.ok) return setFetchMessage('error');

    const htest = await getHTest(htestPayload.userId);
    if (htest.ok) {
      window.location.pathname = '/';
    }
  };

  return (
    <Form initialValues={initialValues} validate={validate} onSubmit={submit}>
      {({ handleSubmit, submitting }) => (
        <Box display="flex">
          <Box
            as="form"
            onSubmit={handleSubmit}
            display="flex"
            flexDirection="column"
            mx="auto"
            px={8}
            pb={2}
          >
            {/** pre) */}
            <Stack
              as="section"
              borderBottom="sm"
              borderBottomColor="border"
              pb={8}
            >
              <LogoHeading>
                <img id="sbenLogo" src="/logo.svg" alt="logo" />
              </LogoHeading>
              <Box backgroundColor="#d9e6f1" p={2}>
                <Heading as="h1" variant="heading4" textAlign="center" pb={3}>
                  HENSIKTSMESSIGHETSSKJEMA
                </Heading>
                <Heading as="h1" variant="heading6" textAlign="center">
                  Dette skjemaet må fylles ut før du kan logge deg på
                  aksjetjenesten. Årsaken til dette er for å kunne tilby deg som
                  kunde et utvidet produktspekter. Opplysningene er
                  konfidensielle og vil ikke bli delt med andre.
                </Heading>
                <Heading as="h1" pt={2} variant="heading6" textAlign="center">
                  - gjelder for ikke-profesjonelle og profesjonelle kunder -
                </Heading>
              </Box>
              <Field
                name="userName"
                label="Navn/Firma"
                as={TextInput}
                disabled
              />
              <Field
                name="userId"
                label="Kundenummer"
                as={TextInput}
                disabled
              />
              <Field name="date" label="Dato" as={DateInput} disabled />
            </Stack>
            {/** 1) */}
            <Stack as="section" gap={12}>
              <Stack borderBottom="sm" borderBottomColor="border" pb={8}>
                <Stack>
                  <Heading
                    as="h2"
                    variant="heading3"
                    p={2}
                    backgroundColor="#d9e6f1"
                  >
                    1. YRKE, UTDANNING OG ERFARING
                    <Heading
                      as="h2"
                      variant="heading4"
                      p={2}
                      backgroundColor="#d9e6f1"
                    >
                      1.1. Privatperson
                    </Heading>
                  </Heading>

                  <Fieldset
                    as={RadioGroup}
                    name="education"
                    legend="Utdannelse"
                  >
                    <Field as={Radio} label="Grunnskole" value="PRIMARY" />
                    <Field as={Radio} label="Videregående" value="SECONDARY" />
                    <Field
                      as={Radio}
                      label="Universitet/Høyskole"
                      value="COLLEGE"
                    />
                  </Fieldset>

                  <Field
                    as={TextInput}
                    name="educationDescription"
                    label="Beskrivelse"
                    message="Har du relevant utdanning ift. finansteori og finansielle plasseringer, eller har du ervervet kunnskap/erfaring på annen måte? Gi en kort redegjørelse."
                  />
                  <Field
                    as={TextInput}
                    name="profession"
                    label="Yrke"
                    message="Hva er ditt yrke?"
                  />
                  <Fieldset
                    as={RadioGroup}
                    orientation="horizontal"
                    name="experienced"
                    legend="Erfaring"
                    message="Arbeider/har du arbeidet innen finansiell sektor i minst ett år i en stilling som forutsetter kjennskap til de påtenkte finansielle plasseringene?"
                  >
                    <Field as={Radio} label="Nei" value="false" />
                    <Field as={Radio} label="Ja" value="true" />
                  </Fieldset>
                  <Field
                    as={TextInput}
                    name="experienceDescription"
                    label="Beskrivelse"
                    message="Hvis ja, beskrivelse"
                  />
                </Stack>

                <Stack>
                  <Heading
                    as="h3"
                    variant="heading4"
                    p={2}
                    backgroundColor="#d9e6f1"
                  >
                    1.2. Juridisk person (selskap)
                  </Heading>
                  <Field
                    as={TextInput}
                    name="companyBusiness"
                    label="Hva er selskapets primære forretningsområde?"
                  />
                </Stack>
              </Stack>
            </Stack>
            {/** 2) */}
            <Stack as="section">
              <Heading
                as="h2"
                variant="heading4"
                p={2}
                backgroundColor="#d9e6f1"
              >
                2. KUNNSKAP OG ERFARING MED FINANSIELLE INSTRUMENTER
              </Heading>
              <Stack borderBottom="sm" borderBottomColor="border" pb={8}>
                <Heading as="h3" variant="heading5">
                  Børsnoterte aksjer
                </Heading>
                <Fieldset
                  as={RadioGroup}
                  orientation="horizontal"
                  name="products.EQUITIES_EXCHANGE_NOTED.knowledgeLevel"
                  legend="Kunnskapsnivå"
                >
                  <Field as={Radio} label="Høy" value="HIGH" />
                  <Field as={Radio} label="Mid" value="MEDIUM" />
                  <Field as={Radio} label="Lav" value="LOW" />
                </Fieldset>
                <Field
                  as={NumberInput}
                  lang="nb"
                  name="products.EQUITIES_EXCHANGE_NOTED.noOfTrades"
                  label="Ant. handler siste år"
                  min={0}
                  validate={composeValidators(required, posNumber)}
                />
                <Field
                  as={NumberInput}
                  lang="nb"
                  name="products.EQUITIES_EXCHANGE_NOTED.yearsExperience"
                  label="Ant. år erfaring"
                  min={0}
                  validate={composeValidators(lessThanMaxYear, posNumber)}
                />
                <Box display="flex" alignItems="center">
                  <Heading as="h3" variant="heading5">
                    Egenkapitalbevis
                  </Heading>
                  <InfoBubble aria-label="beskrivelse" ml={4}>
                    Egenkapitalbevis er sparebankenes egenkapitalinstrument
                    (tidligere grunnfondsbevis). Egenkapitalbevis har klare
                    likhetstrekk med aksjer. Forskjellen knytter seg først og
                    fremst til eierrett til selskapsformuen og innflytelse i
                    bankens organer. Hovedprinsippet er at egenkapitalbevisenes
                    eierandel (eierandelskapitalen) og institusjonens øvrige
                    kapital (grunnfondet) underlegges samme regelverk ved
                    tildeling av henholdsvis utbytte og gavetildeling, og ved
                    avsetning til fond.
                  </InfoBubble>
                </Box>
                <Fieldset
                  as={RadioGroup}
                  orientation="horizontal"
                  name="products.PCC_EXCHANGE_NOTED.knowledgeLevel"
                  legend="Kunnskapsnivå"
                >
                  <Field as={Radio} label="Høy" value="HIGH" />
                  <Field as={Radio} label="Mid" value="MEDIUM" />
                  <Field as={Radio} label="Lav" value="LOW" />
                </Fieldset>
                <Field
                  as={NumberInput}
                  lang="nb"
                  name="products.PCC_EXCHANGE_NOTED.noOfTrades"
                  label="Ant. handler siste år"
                  min={0}
                  validate={composeValidators(required, posNumber)}
                />
                <Field
                  as={NumberInput}
                  lang="nb"
                  name="products.PCC_EXCHANGE_NOTED.yearsExperience"
                  label="Ant. år erfaring"
                  min={0}
                  validate={composeValidators(required, lessThanMaxYear)}
                />
              </Stack>
            </Stack>

            {/** 3) */}
            <Stack as="section">
              <Heading
                as="h2"
                variant="heading4"
                p={2}
                backgroundColor="#d9e6f1"
              >
                3. Midlenes opprinnelse
              </Heading>
              <Stack borderBottom="sm" borderBottomColor="border" pb={8}>
                <Fieldset
                  as={RadioGroup}
                  name="assetsOrigin"
                  legend="Midlenes opprinnelse"
                >
                  <Field as={Radio} label="Lønn/studielån" value="SALARY" />
                  <Field as={Radio} label="Arv/gave" value="INHERITANCE" />
                  <Field as={Radio} label="Sparing" value="SAVINGS" />
                  <Field
                    as={Radio}
                    label="Næringsvirksomhet"
                    value="COMMERCIAL_ACTIVITY"
                  />
                  <Field as={Radio} label="Annet" value="OTHER" />
                </Fieldset>
                <Field
                  // TODO render conditionally
                  as={TextInput}
                  name="assetsOriginDescription"
                  label="Hva er midlenes opprinnelse?"
                  message="Hvis annet, beskrivelse:"
                  maxLength={255}
                />

                <Fieldset
                  as={RadioGroup}
                  orientation="horizontal"
                  name="businessRelationshipPurpose"
                  legend="Kundeforholdets formål"
                >
                  <Field
                    as={Radio}
                    label="Avkastning på midler"
                    value="INVESTMENT"
                  />
                  <Field as={Radio} label="Annet" value="OTHER" />
                </Fieldset>
                <Field
                  // TODO render conditionally
                  as={TextInput}
                  name="businessRelationshipPurposeDescription"
                  label="Hva er formålet?"
                  message="Hvis annet, beskrivelse:"
                  maxLength={255}
                />
              </Stack>
            </Stack>

            {/** 4) */}
            <Stack as="section">
              <Heading
                as="h2"
                variant="heading4"
                p={2}
                backgroundColor="#d9e6f1"
              >
                4. Øvrige kommentarer
              </Heading>
              <Field as={Textarea} name="comments" label="Beskrivelse" />
            </Stack>
            {/** Footer */}
            <Stack alignment="start">
              <Text>
                Alle realtidsabonnementer har blitt resatt, dersom du ønsker å
                ha tilgang til realtidsabonnement må dette bestilles på nytt.
              </Text>
              <Text>
                Gå inn på Min side for å bestille abonnement på kursinformasjon
              </Text>
              <Text>Vennligst les vilkår for bruk av netthandel</Text>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.sb1markets.no/globalassets/vilkar-og-disclaimer/spesielleforretningsvilkarpm.pdf"
              >
                Vilkår for fysiske personer/PM
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.sb1markets.no/globalassets/vilkar-og-disclaimer/spesielleforretningsvilkarbm.pdf"
              >
                Vilkår for juridiske personer/BM
              </a>
              <Text>
                Vi har oppdatert våre forretningsvilkår, du finner disse her:
              </Text>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://sb1markets.no/vilkar-og-disclaimer"
              >
                Vilkår og disclaimer
              </a>

              {fetchMessage === 'error' && (
                <Notification status="error" title="Feil">
                  Noe gikk galt ved innsendingen. Ta kontakt med Sparebanken1.
                </Notification>
              )}
            </Stack>
            {state.showWarning ? (
              <Stack>
                <Notification
                  status="error"
                  title={getName(state.equitiesPassed, state.pccPassed)}
                >
                  <ExpediencyWarning
                    name={getName(state.equitiesPassed, state.pccPassed)}
                    location="HTest"
                  />
                </Notification>

                <Button type="submit" variant="primary" isPending={submitting}>
                  Godta vilkår og bekreft
                </Button>
                {window.history.length > 1 ? (
                  <Link variant="link" to="..">
                    Avbryt
                  </Link>
                ) : (
                  <Link variant="link" href="https://sparebank1.no">
                    Avbryt
                  </Link>
                )}
              </Stack>
            ) : (
              <Button type="submit" variant="primary" isPending={submitting}>
                Godta vilkår og bekreft
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Form>
  );
}
