import { Table, Tbody, Td, Th, Tr } from '@oms/ui-table';
import { normalizeNumber } from 'utils/form';
import { formatNumber } from '@oms/utils';
import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';

import {
  Dialog,
  DialogHeader,
  DialogActions,
  DialogContent,
} from '@oms/ui-dialog';

import moment from 'moment';
import { ActiveMatchingOrderMessage } from 'utils/ActiveMatchingOrderMessage';
import { useCalculateCommisson } from '../Queries/useCalculateCommisson';
import { Notification } from '@oms/ui-notification';
import BrokerageLimitWarning from '../../BrokerageLimit/BrokerageLimitWarning';
import { Stack } from '@oms/ui-stack';
import { useRequiredAssessmentTest } from 'utils/hooks/useAssessmentTestResults';
import AssessmentTestForm from 'components/AssessmentTests/AssessmentTestForm';
import { TradeSuccessMessage } from './TradeSuccessMessage';

export const QuickTradeCheckout = ({
  hidden,
  back,
  submit,
  error,
  values,
  isAsk,
  accountId,
  userId,
  ticker,
  exchange,
  matchingOrder,
  showOrderConfirmation,
  onHide,
  toMyOrders,
  submitError,
  initialFocusRef,
  ...dialogProps
}: any) => {
  const requiredAssessmentTest = useRequiredAssessmentTest(
    `${ticker}.${exchange}`,
  );
  const normalizedLimit = normalizeNumber(values.limit);
  const normalizedVolume = normalizeNumber(values.volume);
  const { data: commisson } = useCalculateCommisson(accountId, values, hidden);

  const netCommission = commisson?.data?.COMMISSION ?? 95;

  const totalPrice =
    values.tradeType === 'BUY'
      ? normalizedLimit * normalizedVolume + netCommission
      : normalizedLimit * normalizedVolume - netCommission;

  if (requiredAssessmentTest && values.tradeType === 'BUY') {
    return (
      <Dialog
        {...dialogProps}
        aria-label="Kunnskapstest"
        initialFocusRef={initialFocusRef}
      >
        <DialogHeader children="" />
        <DialogContent>
          <Notification status="warning" title="Kunnskapstest" mb={4}>
            Du må gjennomføre en kunnskapstest for å få lov til å handle dette
            instrumentet.
          </Notification>
          <AssessmentTestForm
            id={requiredAssessmentTest}
            onClose={dialogProps.hide}
          />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      {...dialogProps}
      aria-label="Oppsummering"
      initialFocusRef={initialFocusRef}
    >
      <DialogHeader>Bekreft ordre</DialogHeader>
      <DialogContent>
        <Box>
          {isAsk && (
            <p>
              Merk at du nå handler på din Aksjeparekonto. Din beholdning i
              aksjer registrert på din aksjesparekonto vil du finne på
              Aksjesparekontosidene dine både i Aksjehandelsløsningen og i nett-
              og mobilbanken. Vær oppmerksom på at det vil være et døgns
              forsinkelse på markedsverdien som vises i beholdningsoversikten i
              nett- og mobilbanken. I aksjetjenesten vil det ikke være
              tilsvarende forsinkelse.
            </p>
          )}
        </Box>
        <Table>
          <Tbody>
            <Tr>
              <Th scope="row">Type:</Th>
              <Td>
                {values.tradeType === 'BUY' ? 'Kjøpsordre' : 'Salgsordre'}
              </Td>
            </Tr>
            <Tr>
              <Th scope="row">Ticker:</Th>
              <Td>{values.ticker}</Td>
            </Tr>
            <Tr>
              <Th scope="row">Gyldighet:</Th>
              <Td>{moment(values.expirationDate).format('DD-MM-YYYY')}</Td>
            </Tr>
            <Tr>
              <Th scope="row">Antall:</Th>
              <Td>{values.volume}</Td>
            </Tr>
            {values.useOpenVolume && (
              <Tr>
                <Th scope="row">Åpent volum:</Th>
                <Td>{formatNumber(values.openVolume)}</Td>
              </Tr>
            )}
            <Tr>
              <Th scope="row">Pris/Limit:</Th>
              <Td>{formatNumber(normalizedLimit)}</Td>
            </Tr>
            {values.useStopLoss && (
              <Tr>
                <Th scope="row">Stop loss:</Th>
                <Td>{normalizeNumber(values.stopLossLimit)}</Td>
              </Tr>
            )}
            <Tr>
              <Th scope="row">Kurtasje:</Th>
              <Td>
                {netCommission}
                ,-
              </Td>
            </Tr>
            <Tr>
              <Th scope="row">Beregnet totalbeløp:</Th>
              <Td suffix="kr">{formatNumber(totalPrice, 2)}</Td>
            </Tr>
          </Tbody>
        </Table>

        <Text py={4} px={2}>
          Kurtasje 0,05 % per handel, minimum NOK 95,-
        </Text>

        <TradeSuccessMessage
          orderType={values.tradeType}
          isHidden={!showOrderConfirmation}
          isEdit={false}
          ticker={values.ticker}
          expirationDate={values.expirationDate}
          onClose={() => onHide()}
          toMyOrders={() => toMyOrders()}
        />

        <Stack>
          {dialogProps.visible && !showOrderConfirmation && (
            <BrokerageLimitWarning
              ticker={ticker}
              price={normalizedLimit}
              volume={normalizedVolume}
              totalPrice={totalPrice}
              commission={netCommission}
            />
          )}

          {!!matchingOrder && (
            <ActiveMatchingOrderMessage
              matchingOrder={matchingOrder}
              instrument={ticker}
              userId={userId}
              accountId={accountId}
            />
          )}

          {submitError && (
            <Notification status="error" title="Feil">
              {submitError}
            </Notification>
          )}
          {error && (
            <Notification status="error" title="Feil">
              {error}
            </Notification>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        {back}
        {submit}
      </DialogActions>
    </Dialog>
  );
};
