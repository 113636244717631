import React from 'react';
import {
  useContentSwitcher,
  ContentSwitcher,
  ContentSwitcherOption,
} from '@oms/ui-content-switcher';

export const selectionMap = {
  SELECTION_INTRADAY: '1D',
  SELECTION_WEEK: '1W',
  SELECTION_ONE_MONTH: '1M',
  SELECTION_THREE_MONTHS: '3M',
  SELECTION_ONE_YEAR: '1Y',
  SELECTION_FIVE_YEARS: '5Y',
};

export type Selection = keyof typeof selectionMap;

export type UseTimeSeriesSelectOptions = {
  initialSelected?: Selection;
};

export function useTimeSeriesSelect(options: UseTimeSeriesSelectOptions = {}) {
  const state = useContentSwitcher({
    initialSelected: options?.initialSelected || 'SELECTION_INTRADAY',
  });
  return state as Pick<typeof state, 'orientation' | 'onChange'> & {
    initialSelected: Selection;
    selected: Selection;
  };
}

export type TimeSeriesSelectProps = ReturnType<typeof useTimeSeriesSelect>;

export function TimeSeriesSelect(props: TimeSeriesSelectProps) {
  return (
    <ContentSwitcher variant="secondary" {...props}>
      {Object.entries(selectionMap).map(([selection, label]) => (
        <ContentSwitcherOption key={selection} value={selection}>
          {label}
        </ContentSwitcherOption>
      ))}
    </ContentSwitcher>
  );
}
