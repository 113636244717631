import { getErrors } from 'utils/getErrors';

export type ProBrokerResponse = {
  ACTIVE_SALES_ORDERS: number;
  AVAIL_POSITION: number;
  BACKEND_ID: 'probroker' | string;
  CUSTOMER_ID: string | number;
  ERROR_CODE: number;
  ERROR_MESSAGE: string;
  ORDER_ID: string | number;
  ORDER_STATUS: number | 'M' | 'B';
  REQUST_REFERENCE: string;
  SF_CAPITAL: number;
  SF_COLLATERAL: number;
  SF_EQUITY: number;
  SF_EXPOSURE: number;
};

export type ProBrokerResponseObject = {
  data: ProBrokerResponse;
};

export const fetchError = async (
  response: any,
  onErrorCallback: any,
  tradeType: any,
  isAsk: any,
  isMargin: any,
  FORM_ERROR: any,
) => {
  if (!response.ok && response.status === 200) {
    //OMS Error
    const errorResponse = JSON.parse(await response.text())?.errors;
    const errorFromBackend = getErrors({
      errorResponse,
      tradeType,
      isAsk,
      isMargin,
    });
    onErrorCallback(errorFromBackend);
    return;
  }

  if (!response.ok && response.status === 500) {
    const res = await response.json();
    const { errors } = res;
    const probrokerError = errors[0].detail;
    onErrorCallback({
      [FORM_ERROR]: probrokerError,
    });
    return;
  }

  if (!response.ok && response.status === 400) {
    const errorResponse = JSON.parse(await response.text())?.errors;
    const errorFromBackend = getErrors({
      errorResponse,
      tradeType,
      isAsk,
      isMargin,
    });

    onErrorCallback(errorFromBackend);
    return;
  }

  const { data }: ProBrokerResponseObject = await response.json();
  if (!!data.ERROR_MESSAGE || !!data.ERROR_CODE) {
    onErrorCallback({
      [FORM_ERROR]: data.ERROR_MESSAGE,
    });
    return;
  }
};
