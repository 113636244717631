import React from 'react';
import {
  useTickers,
  useTrendColor,
  tickerMapDefault,
  TickerMap,
} from './utils';
import { formatNumber } from '@oms/utils';
import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import { Text } from '@oms/ui-text';
import styled from '@xstyled/emotion';

const Container = styled.div`
  position: absolute;
  overflow: auto;
  width: 100vw;
  left: 0;
  transform: translateY(-3.5rem);
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: 4;
  padding-right: 4;
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3;
`;

type CardProps = {
  name: string;
  last: number;
  changePct: number;
};

const Card = React.memo(function Card({ name, last, changePct }: CardProps) {
  const getColor = useTrendColor();
  return (
    <Stack
      as="li"
      border="sm"
      borderColor="border"
      borderBottom="lg"
      borderBottomColor={getColor(changePct)}
      px={2}
      py={1}
      minWidth="150px"
      borderRadius="md"
      gap={2}
    >
      <Text variant="heading6" color="secondary">
        {name}
      </Text>
      <Box display="flex" justifyContent="space-between">
        <Text>{formatNumber(last)}</Text>{' '}
        <Text color={getColor(changePct)} variant="body1">
          {formatNumber(changePct)} %
        </Text>
      </Box>
    </Stack>
  );
});

export function Cards({
  tickerMap,
  isScrollable = true,
}: {
  tickerMap?: TickerMap;
  isScrollable?: boolean;
}) {
  const { entries } = useTickers({ tickerMap });
  const FullWidthWrapper = isScrollable ? Container : React.Fragment;

  return (
    <>
      <Box height="3.5rem" />
      <FullWidthWrapper>
        <List>
          {Object.entries(tickerMap || tickerMapDefault).map(
            ([itemSector, name]) => {
              const entry = Object.values(entries).find(
                entry => entry.ITEM_SECTOR === itemSector,
              )!;
              return (
                <Card
                  key={itemSector}
                  name={name}
                  last={entry?.LAST}
                  changePct={entry?.CHANGE_PCT}
                />
              );
            },
          )}
        </List>
      </FullWidthWrapper>
    </>
  );
}
