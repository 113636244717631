import { Tooltip } from '@oms/ui-tooltip';
import { IconButton } from '@oms/ui-icon-button';
import { light } from '@oms/ui-icon';
import { useNavigateSheet } from '@oms/ui-sheet';
import { sheetId } from 'components/Sheets';
import { faSync, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import * as S from './styles';
import useJawsRefresh from 'utils/hooks/useJawsRefresh';

export interface ActionButtonProps {
  icon: any;
  tooltip: string;
  sheetId: string;
}

export function ActionButton({
  icon,
  tooltip = 'tooltip',
  sheetId,
}: ActionButtonProps) {
  const navigate = useNavigateSheet();
  const onClick = () => {
    navigate(sheetId, undefined);
  };
  return (
    <Tooltip content={tooltip}>
      <IconButton icon={icon} onClick={onClick} />
    </Tooltip>
  );
}

export interface ActionsProps {}

export function Actions(_props: ActionsProps) {
  const { isAvailable, refresh } = useJawsRefresh();

  return (
    <S.Container>
      {isAvailable && (
        <IconButton
          tooltip="Oppdater kurser"
          icon={faSync as any}
          disabled={refresh.isPending()}
          onClick={refresh}
        />
      )}
      <ActionButton
        sheetId={sheetId.trade}
        tooltip="Handle"
        icon={faCartPlus}
      />

      <ActionButton
        sheetId={sheetId.help}
        tooltip="Hjelp"
        icon={light.faInfoCircle}
      />
      {/*<ActionButton*/}
      {/*  sheetId={sheetId.tv}*/}
      {/*  tooltip="WebTV"*/}
      {/*  icon={faPlayCircle as any}*/}
      {/*/>*/}
      {/*  Hidden temporarily: SBEN-1926 */}
    </S.Container>
  );
}

export interface ActionDrawerButtonProps {
  children: string;
  sheetId: string;
}

export function ActionLink({ sheetId, children }: ActionDrawerButtonProps) {
  const navigate = useNavigateSheet();
  const onClick = () => {
    navigate(sheetId, undefined);
  };
  return <S.DrawerButton onClick={onClick}>{children}</S.DrawerButton>;
}

export function ActionDrawerButtons() {
  return (
    <>
      <ActionLink sheetId={sheetId.help}>Hjelp</ActionLink>
      {/*<ActionLink sheetId={sheetId.tv}>WebTV</ActionLink>*/}
      {/*  Hidden temporarily: SBEN-1926 */}
    </>
  );
}
