import { FORM_ERROR } from 'final-form';

export const getErrors = ({ errorResponse, tradeType, isAsk, isMargin }) => {
  let errors = {};

  if (errorResponse[0].detail.includes('CANNOT_TRADE_ON_VP_TYPE')) {
    // Async field error
    errors[FORM_ERROR] =
      'Du har ikke godkjenning for å gjennomføre handel i dette instrumentet.';
  }

  if (errorResponse[0].detail.includes('LIMIT_ROUNDED')) {
    // Async field error
    errors[FORM_ERROR] = 'Limit avrundet';
  }
  if (errorResponse[0].detail.includes('STOP_LOSS_LIMIT_ROUNDED')) {
    // Async field error
    errors[FORM_ERROR] = 'Stop Loss avrundet.';
  }
  if (errorResponse[0].detail.includes('EXPIRATION_DATE')) {
    // Async field error
    // TODO rename field to "expirationDate"
    errors[FORM_ERROR] = 'Ugyldig dato.';
  }

  //NO_COVERAGE
  if (errorResponse[0].detail.includes('NO_COVERAGE')) {
    const sellMessage =
      'Du har ikke dekning for å selge angitt akjser. ' +
      'Sjekk din beholdning og dine aktive ordre.';
    let buyMessage = 'Du har ikke dekning for dette kjøpet. ';

    if (isMargin) {
      errors[FORM_ERROR] = 'Sjekk tilgjengelig kreditt/margin';
    } else {
      const accountType = isAsk ? 'aksjesparekonto' : 'bankkonto';
      errors[FORM_ERROR] = `Sjekk disponibel saldo på din ${accountType}`;

      errors[FORM_ERROR] = tradeType === 'BUY' ? buyMessage : sellMessage;
    }
  }
  return errors;
};
