import { Sectors as OMSSectors } from '@oms/components-treemaps';
import { Card, CardProps } from 'components/Card';
import styled from '@xstyled/emotion';
import { colors } from 'theme';

const StyledSectors = styled(OMSSectors)`
  .node > div.labels {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    cursor: default;
    &:hover {
      cursor: default;
    }
  }
`;

const sectorColors = {
  blendThreshold: 1.0,
  pos: colors.pos,
  neg: colors.neg,
  flat: 'white',
  blend: 'white',
};

export interface SectorsProps extends Omit<CardProps, 'children'> {}

export const Sectors = (props: SectorsProps) => (
  <Card title="Sektorkart" {...props}>
    <StyledSectors equalNodeSizing colors={sectorColors} />
  </Card>
);
