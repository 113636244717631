import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAccounts } from 'utils/hooks/useAccounts';

export const LOCAL_STORAGE_SELECTED_ACCOUNT = '@oms/sb1-selectedAccount';

export type SelectedAccountContextType = {
  selectedAccountId: string; // Whole account or just id?
  setSelectedAccountId: (newAccount: string) => void;
};

const context = createContext<SelectedAccountContextType | null>(null);
const { Provider } = context;

/** This pulls the `accountId` value and setter from the `SelectedAccountContext`,
 * use `useSelectedAccount` utility hook for the entire selectedAccount */
export const useSelectedAccountId = () => {
  const ctx = useContext(context);
  if (ctx === null)
    throw new Error(
      'useSelectedAccountId must be used within SelectedAccountProvider',
    );
  return ctx;
};

interface SelectedAccountContextProps {
  initialSelectedAccount?: SelectedAccountContextType['selectedAccountId'];
  children: React.ReactNode;
}

export function SelectedAccountProvider({
  initialSelectedAccount,
  children,
}: SelectedAccountContextProps) {
  const {
    data: { accounts },
  } = useAccounts();
  const [selectedAccountId, setSelectedAccountId] = useState(() => {
    if (initialSelectedAccount) return initialSelectedAccount;
    const fromLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_SELECTED_ACCOUNT,
    );

    if (fromLocalStorage) return fromLocalStorage;
    return '';
  });
  const initialStart = useRef(true);

  useEffect(() => {
    setSelectedAccountId(currentAccountId => {
      if (!accounts?.length) return currentAccountId;

      const defaultAccount = accounts.find(acc => acc.defaultAccount);
      const fallbackAccount = accounts[0] || ''; // Fall back to first account

      // If there's no current account, set one from accounts
      if (!currentAccountId && !!initialStart.current) {
        initialStart.current = false;
        if (defaultAccount) return defaultAccount.id;
        return fallbackAccount.id;
      }

      // There IS a current account, check that it's among the accounts to chose from.
      const hasCurrentAccount = accounts.some(
        acc => acc.id === currentAccountId,
      );
      initialStart.current = false;
      if (hasCurrentAccount) return currentAccountId;
      if (!hasCurrentAccount && defaultAccount) return defaultAccount.id;
      return fallbackAccount.id;
    });
  }, [accounts]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_SELECTED_ACCOUNT, selectedAccountId);
  }, [selectedAccountId]);

  const value = useMemo(() => ({ selectedAccountId, setSelectedAccountId }), [
    selectedAccountId,
  ]);

  return <Provider value={value}>{children}</Provider>;
}

context.displayName = 'SelectedAccount';
