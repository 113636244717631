import { Field } from '@oms/ui-field';
import { Form } from 'react-final-form';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { Button } from '@oms/ui-button';
import { Checkbox } from '@oms/ui-checkbox';
import { Box } from '@oms/ui-box';
import { useUser } from 'utils/hooks/useUser';
import { createTermsTimestamp } from 'utils/terms';

export const GdprInfo = () => {
  const {
    data: { userId = '' },
  } = useUser();
  const onUpdate = async () => {
    const result = await createTermsTimestamp(userId, 'GDPR');
    if (!result.ok) {
      throw new Error('Failed!');
    } else if (result.ok) {
      window.location.pathname = '/';
    }
  };

  return (
    <Form onSubmit={onUpdate} noValidate>
      {({ handleSubmit, submitting, values }) => {
        return (
          <>
            <Stack
              as="form"
              onSubmit={handleSubmit}
              maxWidth="80rem"
              width="100%"
              px="3px"
              my={4}
            >
              <Heading as="h1">
                Nye personvernregler – innføring av EUs personvernforordning
                (GDPR)
              </Heading>

              <Box as="article">
                <p>
                  EU har nå fått en ny felles lovgivning om behandling av
                  personopplysninger: Personvernforordningen (The General Data
                  Protection Regulation – GDPR) som styrker beskyttelsen av
                  personopplysninger. Endringene trådte i kraft i EU 25. mai
                  2018, og forventet gjennomføring i Norge er satt til juli
                  2018.
                </p>

                <p>
                  På grunn av ditt kundeforhold i SpareBank 1 Markets har vi
                  lagret personopplysninger om deg. Du har rett til å be om å få
                  innsyn i, korrigere eller slette personopplysningene, med
                  mindre vi har lovpålagt plikt til å oppbevare disse. Du har
                  også rett til å nekte eller begrense behandlingen av
                  personopplysningene dine. Du har videre rett til å motsette
                  deg behandling av personopplysningene til bruk i
                  markedsføring, og du kan be om å motta kopi av
                  personopplysningene dine.
                </p>
              </Box>

              <Box>
                <Heading as="h1">
                  Nye retningslinjer for personvern – SpareBank 1 Markets’
                  personvernerklæring
                </Heading>

                <Box as="article">
                  <p>
                    For oss i SpareBank 1 Markets er det viktig å beskytte våre
                    kunders personopplysninger, og vi har derfor oppdatert våre
                    retningslinjer for personvern.{' '}
                    <a
                      href="https://www.sb1markets.no/vilkar-og-disclaimer"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Du kan lese hele vår Personvern Policy HER.
                    </a>
                  </p>

                  <p>Med vennlig hilsen</p>

                  <p>SpareBank 1 Markets AS</p>

                  <p>
                    Vi har oppdatert våre forretningsvilkår, du finner disse
                    her:
                  </p>

                  <a
                    href="https://sb1markets.no/vilkar-og-disclaimer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Vilkår og disclaimer
                  </a>
                  <Box pt={5}>
                    <Field
                      as={Checkbox}
                      name="gdpr"
                      label="Jeg har lest og aksepterer vilkårene"
                      required
                    />
                  </Box>
                </Box>
              </Box>
              <Button
                type="submit"
                title="Bekreft"
                isPending={submitting}
                disabled={values.gdpr !== true}
              >
                Bekreft
              </Button>
            </Stack>
          </>
        );
      }}
    </Form>
  );
};
