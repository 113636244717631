import CustomerInformation from '../CustomerInformation';
import CustomerEducation from '../CustomerEducation';
import Education from '../Education';
import Profession from '../Profession';
import Knowledge from '../Knowledge';
import Laundering from '../Laundering';
import TermsAndDisclaimer from '../TermsAndDisclaimer';
import PoliticallyExposed from '../PoliticallyExposed';
import { Accordion, AccordionGroup } from '@oms/ui-accordion';
import PDFView from './PDFView';
import { checkIsPDFRender } from 'utils/expediencyTest';
import AdditionalInformation from '../AdditionalPersonalInformation';
import { useLocation } from 'react-router-dom';

export const Summary = () => {
  const location = useLocation();
  const isPDFrender = checkIsPDFRender(location);

  if (isPDFrender) return <PDFView />;

  return (
    <div>
      <AccordionGroup>
        <Accordion header="Kundeinformasjon">
          <CustomerInformation />
          <AdditionalInformation />
        </Accordion>

        <Accordion header="Yrke, utdanning og erfaring">
          <CustomerEducation />
          <Education />
          <Profession />
        </Accordion>

        <Accordion header="Kunnskap og erfaring med finansielle instrumenter">
          <Knowledge />
        </Accordion>

        <Accordion header="Midlenes opprinnelse">
          <Laundering />
        </Accordion>

        <Accordion header="Politisk eksponert person">
          <PoliticallyExposed />
        </Accordion>

        <Accordion header="Vilkår">
          <TermsAndDisclaimer />
        </Accordion>
      </AccordionGroup>
    </div>
  );
};

export default Summary;
