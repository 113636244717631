import { Text } from '@oms/ui-text';
import styled from '@xstyled/emotion';
import ActiveOrdersBadge from '../Badges/ActiveOrdersBadge';
import { useHistory } from 'react-router-dom';

const Clickable = styled.button`
  appearance: none;
  background-color: surface-1;
  border-color: border;
  border-style: solid;
  margin: 0;
  min-width: max-content;
  text-align: left;
  cursor: pointer;
  border-radius: md;
  &:hover,
  &:focus {
    border-color: primary;
  }
  &:focus {
    box-shadow: focus;
  }
`;

export const MineOrder = () => {
  const history = useHistory();
  return (
    <Clickable
      onClick={() => history.push({ pathname: '/market', hash: 'myOrders' })}
    >
      <Text
        variant="body2"
        color="secondary"
        display="flex"
        alignItems="center"
        py={2}
      >
        Mine ordre
        <ActiveOrdersBadge />
      </Text>
    </Clickable>
  );
};
