import React from 'react';
import { Box } from '@oms/ui-box';
import {
  ContentSwitcher,
  ContentSwitcherOption,
  useContentSwitcher,
} from '@oms/ui-content-switcher';

type UseContentSwitcherStateReturn = ReturnType<typeof useContentSwitcher>;

export interface IndexSwitcherProps extends UseContentSwitcherStateReturn {
  selected: 'indexMap' | 'index' | 'currency' | 'commodities';
}

export const useIndexSwitcher = (hideMapOption = false) => {
  return useContentSwitcher({
    orientation: 'horizontal',
    initialSelected: hideMapOption ? 'index' : 'indexMap',
  }) as IndexSwitcherProps;
};

export const IndexSwitcher = ({
  hideMapOption,
  ...props
}: IndexSwitcherProps & { hideMapOption?: boolean }) => {
  if (hideMapOption)
    return (
      <Box display="inline-block" mb={2} px={{ base: 3, sm: 0 }}>
        <ContentSwitcher variant="primary" {...props}>
          <ContentSwitcherOption value="index">Indeks</ContentSwitcherOption>
          <ContentSwitcherOption value="currency">Valuta</ContentSwitcherOption>
          <ContentSwitcherOption value="commodities">
            Råvarer
          </ContentSwitcherOption>
        </ContentSwitcher>
      </Box>
    );

  return (
    <Box display="inline-block" mb={2}>
      <ContentSwitcher variant="primary" {...props}>
        <ContentSwitcherOption value="indexMap">Verden</ContentSwitcherOption>
        <ContentSwitcherOption value="index">Indeks</ContentSwitcherOption>
        <ContentSwitcherOption value="currency">Valuta</ContentSwitcherOption>
        <ContentSwitcherOption value="commodities">
          Råvarer
        </ContentSwitcherOption>
      </ContentSwitcher>
    </Box>
  );
};
