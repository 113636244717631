import { Calendar as OMSCalendar } from '@oms/components-calendar';
import { Card, CardProps } from 'components/Card';
import { useItemSector } from 'context/ItemSectorContext';

export interface CalendarProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const Calendar = ({ itemSector, ...props }: CalendarProps) => {
  const itemSectorValue = useItemSector(itemSector);
  return (
    <Card title="Kalender" {...props}>
      <OMSCalendar
        itemSector={itemSectorValue}
        newsItemLink={null}
        archiveLink={null}
        showMoreButton
      />
    </Card>
  );
};
