import { Sheet, SheetHeader } from '@oms/ui-sheet';
import { QuickTrade } from 'components/Trade/QuickTrade';
import { sheetId } from 'components/Sheets';

export interface TradeSheetProps {}

export function SideBarTradeSheet(_props: TradeSheetProps) {
  return (
    <Sheet sheetId={sheetId.trade} aria-label="Handle">
      <SheetHeader>Handle</SheetHeader>
      <QuickTrade sidebar={true} />
    </Sheet>
  );
}
