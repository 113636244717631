import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';

export const YearlyIncomeBeforeTax = () => {
  return (
    <Stack gap={4}>
      <Heading>Hva er din årlige totale inntekt før skatt?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycYearlyIncomeBeforeTax"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="Under 250.000 kr"
          value="UNDER_250K"
          validate={required}
        />
        <Field
          as={Radio}
          label="Mellom 250.001 kr og 600.000 kr"
          value="BETWEEN_250K_AND_600K"
          validate={required}
        />
        <Field
          as={Radio}
          label="Mellom 600.001 kr og 1.000.000 kr"
          value="BETWEEN_600K_AND_ONE_M"
          validate={required}
        />
        <Field
          as={Radio}
          label="Mellom 1.000.001 kr og 2.000.000 kr"
          value="BETWEEN_ONE_M_AND_TWO_M"
          validate={required}
        />
        <Field
          as={Radio}
          label="Mellom 2.000.001 kr og 5.000.000 kr"
          value="BETWEEN_TWO_M_AND_FIVE_M"
          validate={required}
        />
        <Field
          as={Radio}
          label="5.000.000 kr eller mer"
          value="FIVE_M_OR_ABOVE"
          validate={required}
        />
      </Fieldset>
    </Stack>
  );
};

export default YearlyIncomeBeforeTax;
