export function mapRecommendation(recommendation: string | undefined) {
  switch (recommendation) {
    case 'BUY':
      return 'Kjøp';
    case 'SELL':
      return 'Selg';
    case 'UNDER_REVIEW':
      return 'Under vurdering';
    case 'NO_RECOMMENDATION':
      return 'Ingen anbefaling';
    case 'BLANK':
      return '';
    case 'NEUTRAL':
    default:
      return 'Nøytral';
  }
}
