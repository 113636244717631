import { useQuery } from 'react-query';
import { ONE_HOUR } from 'constants/timeInMs';

export const useEnsureItemSector = (itemSector: string | undefined) => {
  /* Using fetch rather than useJaws, because jaws doesn't tell us the instrument doesn't exist */
  return useQuery(
    ['ensureItemSector', { itemSector }],
    () =>
      fetch(`/server/components?itemSector=${itemSector}&columns=ITEM`).then(
        async response => {
          if (!response.ok) {
            const doesNotExist = (await response.text())
              .toLowerCase()
              .includes('ticker not found');

            if (doesNotExist) {
              return false;
            }
          }
          return true;
        },
      ),
    {
      suspense: true,
      enabled: !!itemSector,
      retry: false,
      cacheTime: ONE_HOUR,
      staleTime: ONE_HOUR,
    },
  );
};
