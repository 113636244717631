import React from 'react';
import { Trades as OMSTrades } from '@oms/components-trades';
import { Card, CardProps } from 'components/Card';
import { useItemSector } from 'context/ItemSectorContext';
export interface TradesProps extends Omit<CardProps, 'children'> {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

export const Trades = ({ itemSector, ...props }: TradesProps) => {
  const itemSectorValue = useItemSector(itemSector);
  return (
    <Card title="Siste handler" {...props}>
      <OMSTrades itemSector={itemSectorValue} />
    </Card>
  );
};
