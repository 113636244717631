import React, { useState, useEffect } from 'react';
import { StockChart as OMSStockChart } from '@oms/components-stock-chart';
import { getItemAndSectorFromItemSector } from '@oms/utils';
import { useMedia } from '@oms/ui-media';
import { Button } from '@oms/ui-button';
import { Icon, light } from '@oms/ui-icon';
import { useItemSector } from 'context/ItemSectorContext';
import { Box } from '@oms/ui-box';
import { Spinner } from '@oms/ui-spinner';

type Selection = 'simple' | 'advanced';

type UseStockChartOptions = {
  initialSelected?: Selection;
};

export function useStockChart(options: UseStockChartOptions = {}) {
  const [state, setState] = useState<Selection>(
    options?.initialSelected || 'simple',
  );
  const media = useMedia();
  useEffect(() => {
    // No advanced charting on mobile
    if (media.isMobile) {
      setState('simple');
    }
  }, [media.isMobile]);
  return {
    isMobile: media.isMobile,
    selected: state,
    toggle: () => setState(s => (s === 'simple' ? 'advanced' : 'simple')),
  };
}

type StockChartToggleButtonProps = ReturnType<typeof useStockChart>;

export function StockChartToggleButton(props: StockChartToggleButtonProps) {
  return props.isMobile ? null : (
    <Button
      variant="link"
      leftElement={<Icon icon={light.faChartLine} />}
      onClick={props.toggle}
    >
      {props.selected === 'simple' ? 'Avansert Graf' : 'Graf'}
    </Button>
  );
}

type StockChartProps = {
  itemSector?: string;
};
export function StockChart({ itemSector }: StockChartProps) {
  const itemSectorValue = useItemSector(itemSector);
  const [ticker] = getItemAndSectorFromItemSector(itemSectorValue);
  return (
    <React.Suspense
      fallback={
        <Box>
          <Spinner size="lg" />
        </Box>
      }
    >
      <OMSStockChart
        // Chart doesn't update when the initial main instrument changes
        key={itemSectorValue}
        includeMainInstrumentMenuBarItem={false}
        // allowChangeMainInstrument={false}
        initialMainInstrument={{
          itemSector: itemSectorValue,
          ticker: ticker,
          name: ticker,
          instrumentType: 'EQUITIES',
        }}
        initialDateRangeSelection="SELECTION_INTRADAY_TREE_HOURS"
        chartMinHeight="600px"
        watermark="Sparebank1 Markets"
        // Note use %PUBLIC_URL%/ if it's not showing up in demo
        iconsURL="/stock-icons/"
        searchGroup="GRAPH_COMPARE"
      />
    </React.Suspense>
  );
}

export default StockChart;
