import styled, { css } from '@xstyled/emotion';
import { breakpoints } from '@xstyled/system';
import { useDocumentTitle } from '@oms/ui-utils';
import { ErrorBoundary } from '@oms/ui-error-boundary';
const Column = styled.div`
  display: flex;
  flex-direction: column;
  /** 100vh - (nav + footer) */
  min-height: calc(100vh - 322px);
`;

const Top = styled.div`
  background-color: #fff;
`;
const Main = styled.div`
  flex: 1;
  ${breakpoints({
    sm: css`
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
    `,
    md: css`
      background-color: #d9e6f1;
    `,
    lg: css`
      border-top-left-radius: 2rem;
      border-top-right-radius: 2rem;
    `,
    xl: css`
      border-top-left-radius: 3rem;
      border-top-right-radius: 3rem;
    `,
  })}
`;

const Inner = styled.div`
  max-width: 103rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${breakpoints({
    base: css`
      padding: 0 0 4 0;
    `,
    sm: css`
      padding: 0 4 4 4;
    `,
  })}
`;

const Nav = styled.div`
  max-width: 103rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 4 2 0 4;
`;

export interface PageProps {
  topElement?: JSX.Element;
  children: React.ReactNode;
  navElement?: JSX.Element;
  documentTitle?: string;
}

/**
 * Page Layout component
 * NOTE: All props are wrapped with an `ErrorBoundary`
 * */
export function Page({
  topElement,
  children,
  navElement,
  documentTitle,
}: PageProps) {
  useDocumentTitle({
    prefix: 'Sparebank1 Markets',
    title: documentTitle || '',
  });
  return (
    <Column>
      <Top>
        <ErrorBoundary>
          <Nav>{navElement}</Nav>
        </ErrorBoundary>
        <ErrorBoundary>
          <Inner>{topElement}</Inner>
        </ErrorBoundary>
      </Top>
      <Main>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Main>
    </Column>
  );
}
