import { Form } from 'react-final-form';
import { useState, useMemo, FormEvent, useRef, useCallback } from 'react';
import { Button } from '@oms/ui-button';
import { Stack } from '@oms/ui-stack';
import { useValidate } from './validate';
import { FORM_ERROR } from 'final-form';
import { Notification } from '@oms/ui-notification';
import { post } from 'utils/fetch';
import { QuickTradeForm } from './QuickTradeForm';
import { TradeUtil } from 'utils/trade';
import { QuickTradeCheckout } from './QuickTradeCheckout';
import { normalizeNumber } from 'utils/form';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { useAllActiveOrders } from 'utils/hooks/useActiveOrders';
import { getMatchingOrder } from 'utils/validation/getMatchingOrder';
import { useUser } from 'utils/hooks/useUser';
import moment from 'moment';
import { ORDERS_URL } from 'constants/Jaws';
import { fetchError } from 'utils/validation/fetchError';
import { useDialog } from '@oms/ui-dialog';
import { useSuggestImperativeHandle } from '@oms/components-suggest';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import useIsStatusU from 'utils/hooks/useIsStatusU';

export type Account = {
  label: string;
  value: string;
  accountNo: number;
  instrumentSet: string;
  ask: boolean;
};

export type Trade = {
  limit?: number;
  last?: number;
  volume?: number;
  tradeType?: any;
  expirationDate?: any;
  useStopLoss: false;
  useOpenVolume: boolean;
  visible?: boolean;
  account?: Account;
  ticker: string;
  exchange: string;
};

export type ProBrokerResponse = {
  ACTIVE_SALES_ORDERS: number;
  AVAIL_POSITION: number;
  BACKEND_ID: 'probroker' | string;
  CUSTOMER_ID: string | number;
  ERROR_CODE: number;
  ERROR_MESSAGE: string;
  ORDER_ID: string | number;
  ORDER_STATUS: number | 'M' | 'B';
  REQUST_REFERENCE: string;
  SF_CAPITAL: number;
  SF_COLLATERAL: number;
  SF_EQUITY: number;
  SF_EXPOSURE: number;
};

export type ProBrokerResponseObject = {
  data: ProBrokerResponse;
};

export const QuickTrade = ({ id, sidebar }: any) => {
  const initialFocusRef = useRef();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [
    suggestImperativeHandle,
    suggestHandlers,
  ] = useSuggestImperativeHandle();

  const { selectedAccountId } = useSelectedAccountId();
  const dialog = useDialog();

  const {
    data: { userId = '', hasTradingRights },
  } = useUser();
  const [start] = useState(TradeUtil.getDefault('start'));
  const [error, setError] = useState('');
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  // eslint-disable-next-line
  const [showCheckout, setShowCheckout] = useState(false);
  const activeOrders = useAllActiveOrders(userId);
  const isBlocked = useIsStatusU();
  const {
    data: { selectedAccount },
  } = useSelectedAccount();

  const handleSuccessMessageClose = useCallback(
    form => {
      form.restart();
      suggestHandlers?.reset?.();
      dialog.hide();
      setShowOrderConfirmation(false);
      setShowCheckout(false);
    },
    [dialog, suggestHandlers],
  );

  const isAsk = selectedAccount?.ask;
  const isMargin = selectedAccount?.isMarginAccount;

  const initialValues = useMemo(
    () => ({
      ticker: '',
      exchange: '',
      useOpenVolume: false,
      useStopLoss: false,
      account: selectedAccount,
      useTriggerCriterion: false,
      expirationDate: start,
    }),
    [selectedAccount, start],
  );

  const validate = useValidate();

  const submit = async (values: any, form: any, onErrorCallback: any) => {
    const {
      tradeType,
      volume,
      limit,
      useOpenVolume,
      openVolume,
      useStopLoss,
      stopLossLimit,
      ticker,
      expirationDate,
      exchange,
    } = values;

    const normalizedLimit = normalizeNumber(limit);
    const normalizedVolume = normalizeNumber(volume);
    const normalizedOpenVolume = normalizeNumber(openVolume);

    // validation rule, should not be a part of submit
    if (!!getMatchingOrder(tradeType, ticker, limit, activeOrders)) {
      return {
        [FORM_ERROR]:
          'Du har en kryssende ordre i dette instrumentet som gjør at du ' +
          'risikerer å handle med deg selv. Vennligst endre limit for å ' +
          'unngå dette. ',
      };
    }

    const payload = {
      data: {
        accountId: selectedAccountId,
        tradeType,
        ticker,
        exchange,
        expirationDate: moment(expirationDate).format('YYYY-MM-DD'),
        volume: normalizedVolume,
        useOpenVolume: !!useOpenVolume,
        openVolume: useOpenVolume ? normalizedOpenVolume : null,
        useTriggerCriterion: useStopLoss,
        stopLossLimit: useStopLoss ? normalizeNumber(stopLossLimit) : undefined,
        limit: normalizedLimit,
        orderType: 'normal',
      },
    };

    try {
      const response = await post(
        `${ORDERS_URL}/${userId}/orders/execute`,
        JSON.stringify(payload),
      );

      if (!response.ok) {
        fetchError(
          response,
          onErrorCallback,
          tradeType,
          isAsk,
          isMargin,
          FORM_ERROR,
        );
        return;
      }

      queryClient.invalidateQueries('activeOrders');
      setShowOrderConfirmation(true);
    } catch (error) {
      onErrorCallback({
        [FORM_ERROR]: 'Noe gikk galt ved innsending',
      });
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
      key={selectedAccountId}
    >
      {({
        handleSubmit,
        submitting,
        submitError,
        values,
        form,
        dirtySinceLastSubmit,
        hasValidationErrors,
      }) => {
        const { tradeType, limit, ticker, exchange } = values;
        const matchingOrder = getMatchingOrder(
          tradeType,
          ticker,
          limit,
          activeOrders,
        );

        return (
          <Stack
            as="form"
            gap={5}
            p={2}
            onSubmit={(e: FormEvent) => e.preventDefault()} // Stop enter from submitting
            width="100%"
            id="quickTrade"
          >
            <QuickTradeForm
              form={form}
              sidebar={sidebar}
              values={values}
              setError={setError}
              error={
                error && (
                  <Notification status="error" title="Feil">
                    {error}
                  </Notification>
                )
              }
              buy={
                <Button
                  size="md"
                  minWidth="100px"
                  maxHeight="43px"
                  variant="buy"
                  type="button"
                  disabled={
                    hasValidationErrors || !hasTradingRights || isBlocked
                  }
                  onClick={() => {
                    // Guard
                    if (!hasValidationErrors) {
                      form.change('tradeType', `BUY`);
                      dialog.show();
                    }
                  }}
                >
                  Kjøp
                </Button>
              }
              sell={
                <Button
                  size="md"
                  variant="sell"
                  minWidth="100px"
                  maxHeight="43px"
                  type="button"
                  disabled={
                    hasValidationErrors || !hasTradingRights || isBlocked
                  }
                  onClick={() => {
                    // Guard
                    if (!hasValidationErrors) {
                      form.change('tradeType', `SELL`);
                      dialog.show();
                    }
                  }}
                >
                  Salg
                </Button>
              }
              suggestImperativeHandle={suggestImperativeHandle}
            />

            <QuickTradeCheckout
              {...dialog}
              matchingOrder={matchingOrder}
              accountId={selectedAccountId}
              ticker={ticker}
              exchange={exchange}
              userId={userId}
              values={values}
              isAsk={isAsk}
              initialFocusRef={initialFocusRef}
              showOrderConfirmation={showOrderConfirmation}
              onHide={() => {
                handleSuccessMessageClose(form);
              }}
              toMyOrders={() => {
                setShowOrderConfirmation(false);
                form.restart();
                suggestHandlers?.reset?.();
                dialog.hide();
                history.push({ pathname: '/market', hash: 'myOrders' });
              }}
              back={
                !showOrderConfirmation && (
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={dialog.hide}
                    disabled={submitting}
                    ref={initialFocusRef}
                  >
                    Tilbake
                  </Button>
                )
              }
              submit={
                !matchingOrder &&
                !showOrderConfirmation && (
                  <Button
                    onClick={handleSubmit}
                    isPending={submitting}
                    disabled={submitting || showOrderConfirmation}
                    form="quickTrade"
                  >
                    Bekreft
                  </Button>
                )
              }
              error={(!dirtySinceLastSubmit && error) || null}
              submitError={(!dirtySinceLastSubmit && submitError) || null}
            />
          </Stack>
        );
      }}
    </Form>
  );
};
