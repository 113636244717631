import { get, post, put } from './fetch';
import { LAUNDERING_TEST_URL } from 'constants/Jaws';

export function getLaunderingTest(accountId) {
  return get(`${LAUNDERING_TEST_URL}/${accountId}`);
}

export function createLaunderingTest(accountId, payload) {
  const data = {
    ...payload,
  };
  return post(`${LAUNDERING_TEST_URL}/${accountId}`, JSON.stringify({ data }));
}

export function modifyLaunderingTest(accountId, payload) {
  const data = {
    ...payload,
  };
  return put(`${LAUNDERING_TEST_URL}/${accountId}`, JSON.stringify({ data }));
}
