import React from 'react';
import styled from '@xstyled/emotion';

const StyledButton = styled.button`
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: rounded;
  background-color: transparent;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  svg {
    fill: primary;
  }
  &:hover,
  &:focus {
    background-color: surface-hover;
    svg {
      fill: secondary;
    }
  }
`;

export const IconButton = React.forwardRef(
  (
    {
      children,
      ...props
    }: { children: React.ReactNode } & JSX.IntrinsicElements['button'],
    ref: React.ForwardedRef<any>,
  ) => {
    return (
      <StyledButton ref={ref} {...props}>
        {React.cloneElement(children as any, { size: 24 })}
      </StyledButton>
    );
  },
);
