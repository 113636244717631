export const commoditiesTable =
  'C:PBROUSDBR\\SP.IDCENE, C:PWTIUSDBR\\SP.IDCENE, C:PXAGUSDOZ\\SP.IDCIND, C:SXAUUSDOZ\\SP.IDCIND, OMS:BRN0M.ICE-SYNTHETIC, OMS:BRN1M.ICE-SYNTHETIC, C:SXPTUSDOZ\\SP.IDCIN, OMS:WBS0M.ICE-SYNTHETIC, OMS:WBS1M.ICE-SYNTHETIC';

export const CurrencyTable =
  'X:SUSDNOK.IDCFX, X:SEURNOK.IDCFX, X:SDKKNOK.IDCFX, X:SCHFNOK.IDCFX, X:SGBPNOK.IDCFX, X:SJPYNOK.IDCFX, X:SAUDNOK.IDCFX, X:SCADNOK.IDCFX, X:SSEKNOK.IDCFX, X:SCNYNOK.IDCFX';

export const IndexTableNO =
  'OSEBX.OSE, OBX.OSE, OMXS30.OMX, OMXN40.OMX, I:DAX.GER, I:N100.EUR, I:DJI.DJ, I:COMP.NAS, I:SP500.SP, I:HSI.HSI, NI225.OSA';

export const defaultIndex = () => {
  return 'OSEBX.OSE';
};

export const initialSelected = (menuSelection: string) => {
  if (menuSelection === 'indexes') {
    return defaultIndex;
  }
  if (menuSelection === 'commodities') {
    return 'C:PBROUSDBR\\SP.IDCENE';
  }
  if (menuSelection === 'currency') {
    return 'X:SUSDNOK.IDCFX';
  }

  return 'OSEBX.OSE';
};

export const instrumentSet = (menuSelection: string) => {
  if (menuSelection === 'commodities') return commoditiesTable;
  if (menuSelection === 'currency') return CurrencyTable;
  if (menuSelection === 'indexes') {
    return IndexTableNO;
  }
};
