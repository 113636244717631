import { useState, useEffect } from 'react';
import * as React from 'react';
import FileUpload, { getFileContent } from '@sb1/ffe-file-upload-react';
import styled, { css } from '@xstyled/emotion';
import { th, breakpoints } from '@xstyled/system';

const maxUploadSizeInMB = 50;
const acceptedFileTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'text/plain',
];

export interface Props {
  onFilesSelected?: any;
  infoText?: string;
  infoSubText?: string;
  uploadTitle?: string;
  uploadMicroText?: string;
  label?: string;
  uploadSubText?: string;
}

export interface FileRef {
  name: string;
  size: number;
  type: string;
  error?: string;
  document?: string;
}

export interface Attachments {
  [filename: string]: FileRef;
}

const WrapperFileUpload = styled.div`
  .ffe-file-upload {
    max-width: 100%;
    ${breakpoints({
      base: css`
        display: flex;
        flex-direction: column;
      `,
      md: css`
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr 2fr;
      `,
    })}
  }

  #ffe-file-upload-example {
    display: none;
  }

  .ffe-file-upload__file-item-loaded {
    ${th('button.default')}
  }
  .ffe-file-upload__file-item-delete-button {
    padding-right: 16px;
    padding-top: 4px;
    margin-left: auto;
    background-color: transparent;
    border: none;
    line-height: 18px;
    position: relative;
    color: #0071cd;
    font-size: 14px;
    grid-column: 3;
    grid-row: 1;
  }

  .ffe-file-upload__upload-section-title {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .ffe-file-upload__upload-section {
    height: 150px;
    background-color: #dff1f9;
    border-radius: 10px;
    text-align: center;
    padding: 4px;
    grid-row: 3;
  }

  .ffe-file-upload__upload-section {
    margin-top: 0;
    height: 220px;
    padding: 0;
    grid-row: 2;
    grid-column: 2;
  }

  .ffe-file-upload__info-section {
    margin-bottom: 24px;
    grid-row: 2;
  }
  .ffe-file-upload__info-section-subtext {
    margin-top: 12px;
  }

  .ffe-file-upload__file-items-section {
    margin-top: 0;
    grid-row: 2;
  }

  .ffe-file-upload__file-item-filename {
    display: grid;
    grid-template-columns: 1fr;
  }

  .ffe-file-upload__upload-section-subtext {
    margin-top: 8px;
  }

  .ffe-file-upload__file-item-filename {
    grid-row: 1;
    grid-column: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 2px;
    color: #002776;
  }

  .ffe-file-upload__upload-section-border {
    margin: 4px;
    border: 2px dashed #0071cd;
    border-radius: 10px;
    position: relative;
    height: 213px;
    right: 0;
    bottom: 0;
  }

  .ffe-file-upload__upload-button {
    margin-top: 35px;
    cursor: pointer;
    background-color: #fff;
    border: 2px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 2px 1px rgb(0 0 0 / 5%);
    line-height: 24px;
    outline: none;
    overflow: hidden;
    padding: 8px 16px;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    width: 240px;
  }
`;
// @ts-ignore
export const FileUploadContainer = ({
  onFilesSelected: emitFiles,
  ...props
}: any) => {
  const [attachments, setAttachments] = useState<Attachments>({});
  useEffect(() => {
    if (typeof emitFiles === 'function') {
      emitFiles?.(attachments);
    }
  }, [attachments, emitFiles]);

  const onFilesSelected = (fileList: FileList) => {
    Array.from(fileList).forEach(file => initiateFileUpload(file));
  };

  const onFilesDropped = (event: DragEvent) => {
    event.preventDefault();
    if (event.dataTransfer) {
      Array.from(event.dataTransfer.files).forEach(file =>
        initiateFileUpload(file),
      );
    }
  };

  const updateAttachments = (ref: FileRef) => {
    setAttachments(prevAttachments => ({
      ...prevAttachments,
      [ref.name]: ref,
    }));
  };

  const deleteAttachment = (filename: string) => {
    const { [filename]: _ignored, ...newAttachments } = attachments;
    setAttachments(newAttachments);
  };

  const initiateFileUpload = async (file: File) => {
    const ref: FileRef = {
      name: file.name,
      size: file.size,
      type: file.type,
    };

    if (file.size > maxUploadSizeInMB * 1024 * 1024) {
      ref.error = 'Filen er for stor';
      updateAttachments(ref);
      return;
    } else if (!acceptedFileTypes.includes(file.type)) {
      ref.error = 'Feil filtype';
      updateAttachments(ref);
      return;
    }

    updateAttachments(ref);

    try {
      const fileContent = await getFileContent(file);
      ref.document = fileContent;
      updateAttachments(ref);
    } catch (err) {
      ref.error = 'Ukjent feil';
      updateAttachments(ref);
    }
  };

  return (
    <WrapperFileUpload>
      <FileUpload
        {...props}
        files={attachments}
        onFileDeleted={(e: React.MouseEvent<HTMLInputElement>) =>
          deleteAttachment(e.currentTarget?.id)
        }
        onFilesSelected={onFilesSelected}
        onFilesDropped={onFilesDropped}
        multiple
        label={'Velg filer'}
        cancelText={'Avbryt'}
        deleteText={'Slett'}
        uploadTitle={'Dra filene hit'}
        uploadMicroText={'Eller'}
        uploadSubText={'PDF-filer, maks 50 MB'}
        id="ffe-file-upload-example"
        infoText="Last opp dine tre siste lønnslipper, selvangivelse, og eventuell annen relevant informasjon som kan belyse hvorvidt du er egnet til belåning hos SpareBank1 Markets."
        infoSubText="Husk å ikke laste opp personsensitive opplysninger eller dokumenter som bedriften ikke vil dele."
      />
    </WrapperFileUpload>
  );
};

export default FileUploadContainer;
