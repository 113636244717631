import styled from '@xstyled/emotion';
import { Th, Td } from '@oms/ui-table';
import { useMarketValue } from 'utils/hooks/useHoldings';
import { formatNumber } from '@oms/utils';
import { getTrendColor } from 'utils/trend';
import { Box } from '@oms/ui-box';

const TFoot = styled.tfoot`
  position: sticky;
  bottom: 0;
  background-color: surface-1;

  td,
  th {
    border-top: sm;
    border-color: border;
  }
`;

type HoldingsFooterProps = {
  accountId: string;
  isMobile: boolean;
};

export function HoldingsFooter({ accountId, isMobile }: HoldingsFooterProps) {
  const { getMarketValueOnAccount } = useMarketValue();
  const values = getMarketValueOnAccount(accountId);

  return (
    <TFoot>
      <tr>
        <Th fontWeight={700}>Total</Th>
        <Td type="number" trend colSpan={isMobile ? undefined : 3} suffix=" kr">
          {values?.marketValue}
        </Td>
        <Td textAlign="right">
          <Box
            display="flex"
            flexDirection={{ base: 'column', md: 'row' }}
            justifyContent={{ md: 'flex-end' }}
          >
            {formatNumber(values?.unrealized)}
            {isMobile ? (
              <Box
                as="span"
                color={getTrendColor(values?.unrealized) ?? 'text'}
                fontSize={{ base: 'sm', md: 'inherit' } as any}
              >
                {formatNumber(values?.unrealizedPct)}%
              </Box>
            ) : (
              <Box as="span" ml={1}>
                (
                <Box
                  as="span"
                  color={getTrendColor(values?.unrealized) ?? 'text'}
                >
                  {formatNumber(values?.unrealizedPct)}%
                </Box>
                )
              </Box>
            )}
          </Box>
        </Td>
        <Td colSpan={isMobile ? undefined : 2}></Td>
      </tr>
    </TFoot>
  );
}
