import { useQuery } from 'react-query';
import { REPORT_URL } from 'constants/Jaws';
import { FetchError } from 'utils/fetch';

export type InstrumentAnalysis = {
  exchange: string;
  id: number;
  recommendation: string;
  recommendationPrice: number;
  ticker: string;
  report: Report;
};

export type Report = {
  analystName: string;
  endTime: number;
  ingress: string;
  reportId: number;
  startTime: number;
  title: string;
  type: string;
  document: Document;
};

type Document = {
  id: number;
  mediaType: string;
  fileName: string;
  file: any;
};

type UseInstrumentOptions = {
  ticker: string;
};

const getInstrumentAnalysis = async (options: UseInstrumentOptions) => {
  const { ticker } = options;

  const response = await fetch(`${REPORT_URL}/recommendation/${ticker}.OSE`);

  if (!response.ok) {
    throw new FetchError({ message: 'Henting av analyser feilet', response });
  }

  try {
    const data = await response.json();
    return data as InstrumentAnalysis;
  } catch (error) {
    return;
  }
};

export const useInstrumentAnalysisPortfolio = (
  options: UseInstrumentOptions,
) => {
  const { ticker } = options;
  return useQuery(
    ['instrumentAnalysisPortfolio', { ticker }],
    () => getInstrumentAnalysis({ ticker }),
    {
      suspense: true,
      enabled: !!ticker,
    },
  );
};

export default useInstrumentAnalysisPortfolio;
