import jsCookie from 'js-cookie';

import { enforce } from './enforce';

function compareVersionNumbers(v1, v2) {
  return v1.replace(/\./g, '') >= v2.replace(/\./g, '');
}

function padRight(str, len, char) {
  var out = str;
  while (out.length < len) {
    out += char;
  }
  return out;
}

function getNativeVersionFromUserAgent() {
  var userAgent = navigator.userAgent;
  var MOBILBANK_STRING = 'Mobilbank/';

  if (userAgent.indexOf(MOBILBANK_STRING) !== -1) {
    return userAgent.split(MOBILBANK_STRING)[1].split(' ')[0];
  }

  return null;
}

function getNativeVersionFromURL() {
  var queryParams = window.location.search.split('+');
  if (queryParams.length > 1) {
    var version = queryParams[1];
    while (version.charAt(0) === 'v') {
      version = version.substr(1);
    }

    return padRight(version, version - 1, '.0');
  }

  return null;
}

export function getOs() {
  var userAgent =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('userAgent');

  var returnObj = {};

  var windows = userAgent.match(/(Windows\sNT)\s([\d.]+)/i);
  var mac = userAgent.match(/(os\sx)\s([\d_]+)/i);
  var linux = userAgent.match(/(linux)\s([A-z|0-9]+)/i);

  var android = userAgent.match(/(Android)\s+([\d.]+)/);
  var iphone = userAgent.match(/(iPhone\sOS)\s([\d_]+)/);
  var ipad = userAgent.match(/(iPad).*OS\s([\d_]+)/);
  var webos = userAgent.match(/(webOS)\/([\d.]+)/);
  var symbian = userAgent.match(/(Symbian|SymbianOS)\/([\d.]+)/);
  var windowsPhone = userAgent.match(/Windows\sPhone(?:\sOS)?\s([\d.]+)/);

  if (windows && !windowsPhone) {
    returnObj.windows = true;
    returnObj.version = windows[2];
  } else if (mac) {
    returnObj.osx = true;
    returnObj.version = mac[2].replace(/_/g, '.');
  } else if (linux && !android) {
    returnObj.linux = true;
    returnObj.version = linux[2];
  } else if (windowsPhone) {
    returnObj.windowsPhone = true;
    returnObj.version = windowsPhone[1];
  } else if (android) {
    returnObj.android = true;
    returnObj.version = android[2];
  } else if (iphone && !ipad) {
    returnObj.iphone = true;
    returnObj.ios = true;
    returnObj.version = iphone[2].replace(/_/g, '.');
  } else if (ipad) {
    returnObj.ipad = true;
    returnObj.ios = true;
    returnObj.version = ipad[2].replace(/_/g, '.');
  } else if (webos) {
    returnObj.webos = true;
    returnObj.version = webos[2];
  } else if (symbian) {
    returnObj.symbian = true;
    returnObj.version = symbian[2];
  }

  return returnObj;
}

export function isNative() {
  return (
    jsCookie.get('isMobilbankApp') !== undefined ||
    !!getNativeVersionFromUserAgent() ||
    !!getNativeVersionFromURL()
  );
}

export function nativeVersionIsEqualOrAbove() {
  var versionRequired =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('versionRequired');

  var version = getNativeVersionFromUserAgent() || getNativeVersionFromURL();
  return version && compareVersionNumbers(version, versionRequired);
}

export function androidAppVersionIsEqualOrAbove() {
  var versionRequired =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('versionRequired');

  return (
    getOs(navigator.userAgent).android &&
    nativeVersionIsEqualOrAbove(versionRequired)
  );
}

export function iosAppVersionIsEqualOrAbove() {
  var versionRequired =
    arguments.length > 0 && arguments[0] !== undefined
      ? arguments[0]
      : enforce('versionRequired');

  return (
    getOs(navigator.userAgent).ios &&
    nativeVersionIsEqualOrAbove(versionRequired)
  );
}
