import * as React from 'react';
import { SearchDialogRenderItemProps } from '@oms/ui-search-dialog';
import { useForkRef } from '@oms/ui-utils';
import {
  useSmartSearchState,
  useSmartSearchImperativeHandle,
  SmartSearchDisclosure,
  SmartSearchDialog,
  SmartSearchProps,
} from '@oms/components-smart-search';
import useWatchlist, { useAddToWatchlist } from 'utils/hooks/useWatchlist';
import useUser from 'utils/hooks/useUser';
import { useNotification } from 'utils/hooks/useNotification';
import { Button } from '@oms/ui-button';
import { Icon, light } from '@oms/ui-icon';

type WatchlistDialogProps = {
  watchlist: Array<string>;
};

export const RenderItem = React.forwardRef(function RenderItem(
  {
    item,
    inputValue,
    isHighlighted,
    isSelected,
    itemProps,
    itemToString,
    ItemContainer,
    HighLightedText,
    HighlightedText = HighLightedText,
    isMobile,
    onClick,
    onPreview,
    hasPreview: _hasPreview,
    selectIcon: _selectIcon,
    ...containerProps
  }: SearchDialogRenderItemProps,
  ref: React.Ref<any>,
) {
  const innerRef = React.useRef<any>(null);
  const forkedRef = useForkRef(ref, innerRef);

  if (!HighlightedText) {
    return null;
  }

  return (
    <ItemContainer
      ref={forkedRef}
      {...containerProps}
      data-highlighted={isHighlighted}
      data-selected={isSelected}
      onClick={onClick}
    >
      <div>
        <HighlightedText
          display="block"
          search={inputValue || ''}
          autoEscape={true}
        >
          {item?.ITEM}
        </HighlightedText>
        <HighlightedText
          display="block"
          search={inputValue || ''}
          autoEscape={true}
          variant="body1"
        >
          {item?.LONG_NAME || ''}
        </HighlightedText>
      </div>
      {isHighlighted ? (
        <Icon
          ml="auto"
          size="lg"
          mr={2}
          icon={light.faLevelDown}
          rotation={90}
        />
      ) : null}
    </ItemContainer>
  );
});

export function WatchListDialog(_props: WatchlistDialogProps) {
  const dialogState = useSmartSearchState();

  const {
    data: { userId = '' },
  } = useUser();
  const { data: watchList } = useWatchlist();
  const { mutate: addToWatchlist, isLoading } = useAddToWatchlist();
  const notify = useNotification();
  const [ref, { reset }] = useSmartSearchImperativeHandle();

  const handleAddToWatchlist: SmartSearchProps['onChange'] = ({
    ITEM_SECTOR,
    ITEM,
  }) => {
    if (ITEM_SECTOR) {
      const isInWatchlist = watchList?.includes(ITEM_SECTOR);
      if (!isInWatchlist) {
        addToWatchlist({ itemSector: ITEM_SECTOR, userId });
      } else {
        notify({
          status: 'info',
          title: `${ITEM} er allerede i listen din`,
          duration: 4000,
        });
      }
      reset?.();
    }
  };

  return (
    <>
      <SmartSearchDisclosure
        as={Button}
        variant="secondary"
        leftElement={<Icon icon={light.faPlusCircle} />}
        isPending={isLoading}
        alignSelf="flex-end"
        {...dialogState}
      >
        Legg til
      </SmartSearchDisclosure>
      <SmartSearchDialog
        imperativeHandle={ref}
        onChange={handleAddToWatchlist}
        renderItem={RenderItem}
        dialogState={dialogState}
      />
    </>
  );
}
