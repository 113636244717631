import { Field, Fieldset } from '@oms/ui-field';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import styled from '@emotion/styled';
import { requiredWithMessage } from '../validate';
import { Box } from '@oms/ui-box';
import { Link } from '@oms/ui-link';
import { Stack } from '@oms/ui-stack';

const Terms = styled(Stack)`
  .Checkbox {
    flex-shrink: 0;
  }
`;

const Label = styled(Box)``;

export const TermsAndDisclaimer = (props: any) => {
  return (
    <Terms gap={2}>
      <Fieldset
        as={CheckboxGroup}
        name="additionalInformation.terms.termsAccepted"
        legend=""
      >
        <Field
          as={Checkbox}
          label={
            <Label>
              Jeg har lest og aksepterer
              <Link
                ml={1}
                variant="link"
                as="a"
                href="https://www.sb1markets.no/vilkar-og-disclaimer/"
              >
                vilkårene
              </Link>
            </Label>
          }
          validate={requiredWithMessage(
            'Du må akseptere vilkårene for å kunne opprettes som bruker i aksjetjenesten',
          )}
        />
      </Fieldset>

      <Fieldset
        as={CheckboxGroup}
        name="additionalInformation.terms.agreesToProvideData"
        legend=""
      >
        <Field
          as={Checkbox}
          label={
            <Label>
              Jeg samtykker i at SpareBank 1 Markets AS kan dele alle mine
              kunde- og transaksjonsopplysninger med min bank.
              <Link
                ml={1}
                variant="link"
                as="a"
                href="https://www.sparebank1.no"
              >
                Les mer
              </Link>
            </Label>
          }
          validate={requiredWithMessage(`
          Dersom du ikke ønsker å samtykke til utlevering av dine kunde- og
          transaksjonsopplysninger fra Sparebank 1 Markets til bank, kan du ta
          direkte kontakt med SpareBank 1 Markets sitt meglerbord på telefonnummer
          24147480 mellom kl. 10 og 14 for å manuelt opprette deg som kunde.
        `)}
        />
      </Fieldset>

      <Fieldset
        as={CheckboxGroup}
        name="additionalInformation.updates"
        legend=""
      >
        <Field
          as={Checkbox}
          label="Hold meg oppdatert på produkter og tjenester fra SpareBank 1 Markets på e-post"
          name="additionalInformation.updates.productsAndServices"
        />
        <Stack ml={8} gap={2}>
          <Field
            as={Checkbox}
            label="Børsnoteringer og emisjoner"
            name="additionalInformation.updates.stockExchangeListings"
          />
          <Field
            as={Checkbox}
            label="Markeds- og aksjeoppdateringer fra vårt analyseteam"
            name="additionalInformation.updates.marketAndStocks"
          />
          <Field
            as={Checkbox}
            label="Invitasjoner til seminarer"
            name="additionalInformation.updates.invitations"
          />
        </Stack>
        <Field
          as={Checkbox}
          label="Jeg ønsker ikke informasjon om produkter og tjenester fra SpareBank 1 Markets"
          name="additionalInformation.updates.declineUpdates"
        />
      </Fieldset>
    </Terms>
  );
};

export default TermsAndDisclaimer;
