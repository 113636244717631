import { Form } from 'react-final-form';
import { useState, useMemo, FormEvent, useCallback, useEffect } from 'react';
import { Button } from '@oms/ui-button';
import { Stack } from '@oms/ui-stack';
import { useValidate } from './validate';
import { FORM_ERROR } from 'final-form';
import { Notification } from '@oms/ui-notification';
import { post, put } from 'utils/fetch';
import { useItemSector } from 'context/ItemSectorContext';
import { BuyForm } from './BuyForm';
import { formatDate } from 'utils/trade';
import { Checkout } from '../Checkout';
import { normalizeNumber } from 'utils/form';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { useSelectedAccount } from 'utils/hooks/useAccounts';
import { useAllActiveOrders } from 'utils/hooks/useActiveOrders';
import { getMatchingOrder } from 'utils/validation/getMatchingOrder';
import { useUser } from 'utils/hooks/useUser';
import moment from 'moment';
import { ORDERS_URL } from 'constants/Jaws';
import { fetchError } from 'utils/validation/fetchError';
import { MarginInfo } from 'components/MarginCalculator/MarginInfo';
import { Heading } from '@oms/ui-heading';
import { InstrumentOverview } from '../InstrumentOverview';
import { OrderDepth as OMSOrderDepth } from '@oms/components-order-depth';
import { useMedia } from '@oms/ui-media';
import { useQueryClient } from 'react-query';
import { useInstrumentMarginInfo } from 'components/MarginCalculator/useInstrumentMarginInfo';
import { TradeSuccessMessage } from '../TradeSuccessMessage';
import { AssessmentTestDialog } from 'components/AssessmentTests/AssessmentTestDialog';
import { useRequiredAssessmentTest } from 'utils/hooks/useAssessmentTestResults';
import useIsStatusU from 'utils/hooks/useIsStatusU';

export type Account = {
  label: string;
  value: string;
  accountNo: number;
  instrumentSet: string;
  ask: boolean;
};

export type Trade = {
  limit?: number;
  last?: number;
  volume?: number;
  tradeType: 'BUY';
  expirationDate?: any;
  useStopLoss: false;
  useOpenVolume: boolean;
  visible?: boolean;
  account?: Account;
  ticker: string;
  exchange: string;
};

export type ProBrokerResponse = {
  ACTIVE_SALES_ORDERS: number;
  AVAIL_POSITION: number;
  BACKEND_ID: 'probroker' | string;
  CUSTOMER_ID: string | number;
  ERROR_CODE: number;
  ERROR_MESSAGE: string;
  ORDER_ID: string | number;
  ORDER_STATUS: number | 'M' | 'B';
  REQUST_REFERENCE: string;
  SF_CAPITAL: number;
  SF_COLLATERAL: number;
  SF_EQUITY: number;
  SF_EXPOSURE: number;
};

export type ProBrokerResponseObject = {
  data: ProBrokerResponse;
};

export const getItemAndSector = (itemSectorValue: any) => {
  const [item = '', useSector = ''] = itemSectorValue?.split('.');
  return {
    item,
    useSector,
  };
};

export const Buy = ({
  initialValues: initialValuesFromProps,
  orderId = null,
  onSubmitCompleted,
  includeInstrumentOverview = false,
}: any) => {
  const queryClient = useQueryClient();
  const isEdit = !!orderId;
  const itemSectorValue = useItemSector();
  const {
    data: { userId = '', hasLevel2 },
  } = useUser();
  const [showCheckout, setShowCheckout] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const requiredAssessmentTest = useRequiredAssessmentTest(itemSectorValue);

  const { selectedAccountId } = useSelectedAccountId();
  const activeOrders = useAllActiveOrders(userId);
  const {
    data: { selectedAccount },
  } = useSelectedAccount();
  const isAsk = selectedAccount?.ask;
  const isMargin = selectedAccount?.isMarginAccount;
  const { marginRate } = useInstrumentMarginInfo({
    itemSector: itemSectorValue,
  });
  const { isMobile } = useMedia();
  const isBlocked = useIsStatusU();

  const initialValues = useMemo(
    () => ({
      tradeType: 'BUY',
      useOpenVolume: false,
      useStopLoss: false,
      account: selectedAccount,
      useTriggerCriterion: false,
      expirationDate: formatDate(moment()),
      ticker: itemSectorValue
        ? getItemAndSector(itemSectorValue).item
        : undefined,
      exchange: itemSectorValue
        ? getItemAndSector(itemSectorValue).useSector
        : undefined,
      ...initialValuesFromProps,
    }),
    [initialValuesFromProps, itemSectorValue, selectedAccount],
  );

  useEffect(() => {
    setShowCheckout(false);
  }, [itemSectorValue]);

  const handleSuccessMessageClose = useCallback(
    form => {
      form.restart();
      setShowOrderConfirmation(false);
      setShowCheckout(false);
    },
    [setShowOrderConfirmation],
  );

  const validate = useValidate();
  if (!itemSectorValue) return null;
  const { item } = getItemAndSector(itemSectorValue);

  const submit = async (values: any, form: any, onErrorCallback: any) => {
    const {
      tradeType,
      volume,
      limit,
      useOpenVolume,
      openVolume,
      useStopLoss,
      stopLossLimit,
      ticker,
      expirationDate,
      exchange,
    } = values;

    const normalizedLimit = normalizeNumber(limit);
    const normalizedVolume = normalizeNumber(volume);
    const normalizedOpenVolume = normalizeNumber(openVolume);

    // validation rule, should not be a part of submit
    if (!!getMatchingOrder(tradeType, item, limit, activeOrders)) {
      return {
        [FORM_ERROR]:
          'Du har en kryssende ordre i dette instrumentet som gjør at du ' +
          'risikerer å handle med deg selv. Vennligst endre limit for å ' +
          'unngå dette.',
      };
    }

    const payload = {
      data: {
        accountId: selectedAccountId,
        tradeType,
        ticker,
        exchange,
        expirationDate: moment(expirationDate).format('YYYY-MM-DD'),
        volume: normalizedVolume,
        useOpenVolume: !!useOpenVolume,
        openVolume: useOpenVolume ? normalizedOpenVolume : null,
        useTriggerCriterion: useStopLoss,
        stopLossLimit: useStopLoss ? normalizeNumber(stopLossLimit) : undefined,
        limit: normalizedLimit,
        orderType: 'normal',
      },
    };

    try {
      let response: Response;
      if (isEdit) {
        response = await put(
          `${ORDERS_URL}/${userId}/orders/${orderId}/execute`,
          JSON.stringify(payload),
        );
      } else {
        response = await post(
          `${ORDERS_URL}/${userId}/orders/execute`,
          JSON.stringify(payload),
        );
      }
      if (!response.ok) {
        fetchError(
          response,
          onErrorCallback,
          tradeType,
          isAsk,
          isMargin,
          FORM_ERROR,
        );
        return;
      }

      queryClient.invalidateQueries('activeOrders');
      onSubmitCompleted?.();
      setShowOrderConfirmation(true);
    } catch (error) {
      onErrorCallback({
        [FORM_ERROR]: 'Noe gikk galt ved innsending',
      });
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submit}
      validate={validate}
      key={`${itemSectorValue}${selectedAccountId}`}
    >
      {({
        handleSubmit,
        submitting,
        submitError,
        values,
        form,
        dirtySinceLastSubmit,
        hasValidationErrors,
      }) => {
        if (requiredAssessmentTest) {
          return <AssessmentTestDialog testId={requiredAssessmentTest} />;
        }

        const { tradeType, limit, ticker } = values;
        const matchingOrder = getMatchingOrder(
          tradeType,
          ticker,
          limit,
          activeOrders,
        );

        return (
          <Stack
            as="form"
            gap={5}
            p={2}
            onSubmit={(e: FormEvent) => e.preventDefault()} // Prevent submit on enter
            width="100%"
          >
            {isMargin && marginRate && (
              <Stack gap={2}>
                <Stack gap={2} orientation="horizontal" alignment="center">
                  <Heading variant="heading6">Margin</Heading>
                </Stack>
                <MarginInfo itemSector={itemSectorValue} />
              </Stack>
            )}
            {(isMobile || includeInstrumentOverview) && (
              <>
                <InstrumentOverview itemSector={itemSectorValue} />
                <OMSOrderDepth
                  spec={
                    hasLevel2
                      ? {
                          convert: 'levels',
                          dataSet: 'orders',
                          limit: 5,
                        }
                      : undefined
                  }
                  itemSector={itemSectorValue}
                />{' '}
              </>
            )}
            <BuyForm
              editMode={isEdit}
              hidden={showCheckout || showOrderConfirmation}
              buy={
                <Button
                  title={isEdit ? 'Endre' : 'Kjøp'}
                  type="button"
                  disabled={hasValidationErrors || isBlocked}
                  onClick={() => {
                    // Guard
                    if (!hasValidationErrors) {
                      setShowCheckout(true);
                    }
                  }}
                >
                  {isEdit ? 'Endre' : 'Kjøp'}
                </Button>
              }
            />

            <Checkout
              matchingOrder={matchingOrder}
              accountId={selectedAccountId}
              ticker={ticker}
              userId={userId}
              hidden={!showCheckout}
              values={values}
              isAsk={isAsk}
              back={
                <Button
                  title="Tilbake"
                  type="button"
                  variant="secondary"
                  onClick={() => setShowCheckout(false)}
                  disabled={submitting}
                >
                  Tilbake
                </Button>
              }
              submit={
                !matchingOrder && (
                  <Button
                    isPending={submitting}
                    disabled={submitting}
                    onClick={handleSubmit}
                  >
                    Bekreft
                  </Button>
                )
              }
              hideActions={showOrderConfirmation}
              error={(!dirtySinceLastSubmit && submitError) || null}
            />

            <TradeSuccessMessage
              orderType={'BUY'}
              isHidden={!showOrderConfirmation}
              isEdit={isEdit}
              ticker={values.ticker}
              expirationDate={values.expirationDate}
              onClose={() => handleSuccessMessageClose(form)}
            />

            <>
              {!dirtySinceLastSubmit && submitError && (
                <Notification status="error" title="Feil">
                  {submitError}
                </Notification>
              )}
            </>
          </Stack>
        );
      }}
    </Form>
  );
};
