import React from 'react';
import { useItemSector } from 'context/ItemSectorContext';
import { Box } from '@oms/ui-box';
import { CustomHeaderQuote } from './styles';
import { Stack } from '@oms/ui-stack';
import { Skeleton } from '@oms/ui-skeleton';
import { useMedia } from '@oms/ui-media';

export interface HeaderQuoteProps {
  /** The itemSector to be used in fetching data */
  itemSector?: string;
}

const LoadingState = () => {
  const { isMobile } = useMedia();
  return (
    <Stack gap={2}>
      <Skeleton animated height="45px" width="100%" />
      <Skeleton animated height="45px" width="100%" />
      {!isMobile && <Skeleton animated height="120px" width="100%" />}
    </Stack>
  );
};

export const HeaderQuote = ({ itemSector }: HeaderQuoteProps) => {
  const itemSectorValue = useItemSector(itemSector);

  return (
    <React.Suspense fallback={<LoadingState />}>
      <Box width="100%" maxWidth="122rem">
        <CustomHeaderQuote itemSector={itemSectorValue} tickerAsHeading />
      </Box>
    </React.Suspense>
  );
};
