import { useQuery } from 'react-query';
import { EXCHANGE_URL } from 'constants/Jaws';
import { ONE_MINUTE } from 'constants/timeInMs';

type UseLotSizeOptions = {
  ITEM: string;
  SECTOR: string;
};

export const getLotSize = async ({ ITEM, SECTOR }: UseLotSizeOptions) => {
  const response = await fetch(
    `${EXCHANGE_URL}/${SECTOR}/ticker/${ITEM}/lot-size`,
  );
  if (!response.ok) {
    return 'fetch lotsize failed';
  }
  return await response.json();
};

export const lotQueryKey = ({ ITEM, SECTOR }: UseLotSizeOptions) =>
  ['lot-size', { ITEM, SECTOR }] as const;

export const useLotSize = ({ ITEM, SECTOR }: UseLotSizeOptions) => {
  return useQuery(
    lotQueryKey({ ITEM, SECTOR }),
    () => getLotSize({ ITEM, SECTOR }),
    {
      suspense: true,
      enabled: !!ITEM && !!SECTOR,
      staleTime: ONE_MINUTE * 5, // Don't really need to keep refetching for the same item sector.
    },
  );
};
