import { useAllAnalysis } from 'pages/Analysis/useAnalysis';
import useReports, { Report } from 'components/Reports/useReports';
import { useCallback, useEffect, useMemo, useState } from 'react';

// This could be done better, not very widely used so should be simple to refactor

export const useSeenTracker = (name: string) => {
  const storageKey = `@oms/sb1-seen-${name}`;
  const storedSeen = JSON.parse(localStorage.getItem(storageKey) || '[]');

  const [seen, setSeen] = useState<number[]>(storedSeen || []);

  const updateSeen = useCallback(
    (id: number | number[]) => {
      if (typeof id == 'number') return setSeen((prev = []) => [...prev, id]);
      setSeen((prev = []) => [...prev, ...id]);
    },
    [setSeen],
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(seen));
  }, [seen, storageKey]);

  const returnValue = useMemo(
    () => [seen, updateSeen] as [number[], typeof updateSeen],
    [seen, updateSeen],
  );

  return returnValue;
};

export const useUnseenReports = () => {
  const [seenIds, updateSeenReport] = useSeenTracker('Reports');
  const { data: morningReports = [] } = useReports({
    type: 'MORNINGREPORT',
    count: 1,
  });
  const { data: strategyReports = [] } = useReports({
    type: 'STRATEGY',
    count: 1,
  });
  const { data: weekReports = [] } = useReports({
    type: 'WEEKREPORT',
    count: 1,
  });

  const {
    morningReportUnseen,
    strategyReportUnseen,
    weekReportUnseen,
  } = useMemo(
    () => ({
      morningReportUnseen:
        morningReports[0] && !seenIds?.includes(morningReports[0].reportId),

      strategyReportUnseen:
        strategyReports[0] && !seenIds?.includes(strategyReports[0].reportId),

      weekReportUnseen:
        weekReports[0] && !seenIds?.includes(weekReports[0].reportId),
    }),
    [morningReports, strategyReports, weekReports, seenIds],
  );

  const totalCount = [
    morningReportUnseen,
    strategyReportUnseen,
    weekReportUnseen,
  ].filter(Boolean).length;

  const setReportAsSeen = useCallback(
    (type: Report['type']) => {
      if (type === 'MORNINGREPORT')
        morningReportUnseen && updateSeenReport(morningReports[0].reportId);
      if (type === 'STRATEGY')
        strategyReportUnseen && updateSeenReport(strategyReports[0].reportId);
      if (type === 'WEEKREPORT')
        weekReportUnseen && updateSeenReport(weekReports[0].reportId);
    },
    [
      updateSeenReport,
      morningReports,
      strategyReports,
      weekReports,
      morningReportUnseen,
      strategyReportUnseen,
      weekReportUnseen,
    ],
  );

  return {
    totalCount,
    morningReportUnseen,
    strategyReportUnseen,
    weekReportUnseen,
    setReportAsSeen,
  };
};

export const useUnseenAnalysesCount = () => {
  const [seenIds, updateSeenAnalyses] = useSeenTracker('Analyses');
  const { data: analyses = [] } = useAllAnalysis();

  const unseenIds = analyses
    .map(a => a.id)
    .filter(id => !seenIds?.includes(id));

  const setAnalysesAsSeen = useCallback(
    () => unseenIds.length && updateSeenAnalyses(unseenIds),
    [updateSeenAnalyses, unseenIds],
  );

  return { unseenAnalysesCount: unseenIds.length, setAnalysesAsSeen };
};

export default useSeenTracker;
