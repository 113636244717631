import { List, ListItem } from '@oms/ui-list';
import { Card, CardProps } from 'components/Card';
import { Box } from '@oms/ui-box';
export interface RealtimeInfoProps
  extends Omit<CardProps, 'children' | 'title' | 'backgroundColor'> {}

export const RealtimeInfo = (props: RealtimeInfoProps) => {
  return (
    <Card
      title="Realtidsabonnement"
      backgroundColor="#FDF8F5"
      {...props}
      py={{ base: 4 }}
    >
      <Box px={{ base: 3 }}>
        <List variant="bullet" px="3rem">
          <ListItem>
            Realtidskurser på nettsidene uten ordredybde, uten streaming
            (privat): 0,-
          </ListItem>
          <ListItem>
            Realtidskurser på nettsidene uten ordredybde, med streaming
            (privat): 30,-
          </ListItem>
          <ListItem>
            Realtidskurser på nettsidene med ordredybde, med streaming (privat):
            135,-
          </ListItem>
          <ListItem>
            Realtidskurser på nettsidene med ordredybde, med streaming
            (Profesjonelt bruk/Foretak): 640,-
          </ListItem>
        </List>
      </Box>
    </Card>
  );
};
