import { Scroll } from '@oms/ui-scroll';
import styled from '@xstyled/emotion';

const Dl = styled.dl`
  padding: 0 2;
`;
const Dt = styled.dt`
  color: secondary;
  font-size: lg;
  margin-bottom: 1;
`;
const Dd = styled.dd`
  line-height: 1.25;
  margin-left: 0;
  margin-bottom: 6;
`;

const MarginDefinitions = () => {
  return (
    <Scroll>
      <Dl>
        <Dt>Kontanter/Lån:</Dt>
        <Dd>Sum av kontanter og tilgjengelig kreditt</Dd>

        <Dt>Kjøpekraft:</Dt>
        <Dd>
          Viser hvor mye du kan handle for, begrenses av to parametere:
          sikkerhet og kapital
        </Dd>

        <Dt>Sikkerhet: </Dt>
        <Dd>
          Verdien av sikkerhet i aksjer. Er summen av belåningsverdi,
          reskontrosaldo og renter.
        </Dd>

        <Dt>Kapital:</Dt>
        <Dd>
          Verdien av kontanter. Summen av kredittramme, reskontrosaldo og renter
        </Dd>

        <Dt>Benyttet sikkerhet:</Dt>
        <Dd>
          Grafisk fremstilling av hvor mye sikkerhet som er benyttet vist i
          prosent.
        </Dd>

        <Dt>Sikkerhet/belåningsverdi:</Dt>
        <Dd>Verdien kan ikke overstige 100%</Dd>

        <Dt>Benyttet kreditt:</Dt>
        <Dd>
          Grafisk fremstilling av hvor mye av kreditten som er benyttet vist i
          prosent.
          <br />
          Utregningen inkluderer skyldig rente/rente til gode
          <br />
          (Lånesaldo + rente)/kredittramme
          <br />
          Verdien kan ikke overstige 100%
        </Dd>

        <Dt>Kredittramme:</Dt>
        <Dd>Viser innvilget kreditt</Dd>

        <Dt>Lånesaldo:</Dt>
        <Dd>Viser beløpet som er lånt</Dd>

        <Dt>Egenkapital:</Dt>
        <Dd>
          Verdien av aksjer og kontanter minus lån og renter
          <br />
          Summen av markedsverdi på porteføljen, reskontrosaldo og renter
        </Dd>

        <Dt>Reskontrosaldo:</Dt>
        <Dd>
          Kontanter/balanse på lånekonto. Ved ubenyttet lån vil reskontrosaldo
          være positiv, ved benyttet lån vil reskontrosaldo være negativ.
        </Dd>

        <Dt>Påløpt rente:</Dt>
        <Dd>
          Viser skyldig/ til gode rente
          <br />
          Positiv verdi er renter til gode, negativ verdi er skyldige renter.
        </Dd>

        <Dt>Belåningsverdi:</Dt>
        <Dd>
          Porteføljens belåningsverdi.
          <br />
          Markedsverdi x Margingrad
        </Dd>
      </Dl>
    </Scroll>
  );
};

export default MarginDefinitions;
