import { createContext, useContext, useMemo, useState } from 'react';

export type ContentStateContext = {
  hideQuickTrade: boolean;
  setHideQuickTrade: (hide: boolean) => void;
};

const context = createContext<ContentStateContext | null>(null);
const { Provider } = context;

export const useContentStateContext = () => {
  const ctx = useContext(context);
  if (ctx === null)
    throw new Error('useContentStateContext must be used within WebTVProvider');
  return ctx;
};

export const ContentStateProvider: React.FC = ({ children }) => {
  // TODO: Data structure probably needs to be different
  // Active tabs and other state is incoming
  const [hideQuickTrade, setHideQuickTrade] = useState(false);

  const value = useMemo(() => ({ hideQuickTrade, setHideQuickTrade }), [
    hideQuickTrade,
  ]);

  return <Provider value={value}>{children}</Provider>;
};

context.displayName = 'ContentState';
