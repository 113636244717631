import { useCallback } from 'react';
import { validateLimit } from 'utils/validation/trade';
import { validateVolume } from 'utils/validation/buyForm';
import { useQueryClient, QueryKey } from 'react-query';

import {
  getTickSize,
  tickQueryKey,
} from 'components/Trade/Queries/useTickSize';
import { getLotSize, lotQueryKey } from 'components/Trade/Queries/useLotSize';
import { Account } from './QuickTrade';
import { useAskTradeableList } from '../Queries/useTradeableOnAsk';

export type Trade = {
  limit: number;
  last?: number;
  volume: number;
  tradeType: 'BUY' | 'SELL';
  validity?: any;
  openVolume: number;
  useStopLoss: false;
  useOpenVolume: boolean;
  visible?: boolean;
  account?: Account;
  ticker: string;
  exchange: string;
  expirationDate: any;
};

export const useValidate = () => {
  const client = useQueryClient();
  const { data: askTradeableInstruments } = useAskTradeableList();

  const getQueryData = useCallback(
    function getQueryData<
      Key extends QueryKey,
      Fetcher extends () => Promise<unknown>
    >(key: Key, fetcher: Fetcher): Promise<ReturnType<typeof fetcher>> {
      return client.getQueryData(key) ?? client.fetchQuery(key, fetcher);
    },
    [client],
  );

  return useCallback(
    async (values: Trade) => {
      const errors: { [key: string]: any } = {};
      const ITEM = values?.ticker;
      const SECTOR = values?.exchange;
      const limit = values?.limit;
      const volume = values?.volume;

      if (values.account?.ask && ITEM && SECTOR) {
        const itemSector = `${ITEM}.${SECTOR}`;
        if (!askTradeableInstruments.includes(itemSector)) {
          errors.instrument =
            'Det er begrenset hvilke finansielle instrumenter som tillates i aksjespareløsningen. Dersom du ønsker å handle dette verdipapiret, kan du opprette ordinær aksjehandelstjeneste i din nettbank.';
        }
      }

      try {
        if (ITEM && SECTOR) {
          const tickSize: any = await getQueryData(
            tickQueryKey({ ITEM, SECTOR }),
            () => getTickSize({ ITEM, SECTOR }),
          );

          const lotSize: any = await getQueryData(
            lotQueryKey({ ITEM, SECTOR }),
            () => getLotSize({ ITEM, SECTOR }),
          );
          const limitValidate = validateLimit(limit, tickSize);

          if (limitValidate?.includes('Tick Size er')) {
            errors.limit = limitValidate;
          }

          //Validation for volume
          if (values?.volume) {
            errors.volume = validateVolume(volume, lotSize);
          }
        } else {
          errors.ticker = 'Påkrevet';
        }

        //Validation for limit
        if (values?.ticker && !values.limit) {
          errors.limit = 'Påkrevet';
        }

        if (values?.limit <= 0) {
          errors.limit = 'Limit må være et positivt tall';
        }

        if (values?.volume <= 0) {
          errors.volume = 'Volume må være et positivt tall';
        }

        if (values?.ticker && !values?.volume) {
          errors.volume = 'Påkrevet';
        }

        if (!values?.expirationDate) {
          errors.expirationDate = 'Påkrevet';
        }

        if (!values?.account) {
          errors.account = 'Påkrevet';
        }
        return errors;
      } catch (error) {
        // handle error
      } finally {
        // do something when done
      }
    },
    [getQueryData, askTradeableInstruments],
  );
};
