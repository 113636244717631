import { useJaws } from '@oms/jaws-react';
import { formatNumber, formatNumberWithUnit } from '@oms/utils';
import { useSelectedAccountId } from 'context/SelectedAccountContext';
import { useMarginInfo, MarginInfo } from 'utils/hooks/useMarginInfo';

/**
 * Calculates the buying power of an instrument.
 *
 * MarginRate is fetched via separate source:
 * datagetter feed.skb.probroker.INSTRUMENTS ITEM,MARGIN_RATE -view REALTIME
 */
export function calculateAvailable(
  marginInfo?: MarginInfo,
  marginRate?: number,
) {
  if (!marginInfo || !marginRate) {
    return 0;
  }

  const { buyingPowerMax, collateral, capital } = marginInfo;

  return Math.min(
    buyingPowerMax,
    Math.min(collateral, capital) / (1 - marginRate / 100),
  );
}

interface Props {
  itemSector?: string;
}

function buildSpec(itemSector?: string) {
  if (!itemSector) return undefined;
  return {
    generators: [
      {
        columns: 'LAST, LONG_NAME',
        itemSector,
      },
      {
        source: 'feed.sben.probroker.INSTRUMENTS',
        columns: 'MARGIN_RATE',
        filter: `ITEM_SECTOR==s${itemSector}`,
      },
    ],
  };
}

export const useInstrumentMarginInfo = ({ itemSector }: Props) => {
  const spec = buildSpec(itemSector);
  const { items } = useJaws(spec);
  const { selectedAccountId } = useSelectedAccountId();
  const { data: marginInfo } = useMarginInfo({ accountId: selectedAccountId });

  // LAST
  const lastGenerator = items?.find((item: any) => item.get('generator') === 0);
  const last = lastGenerator?.get('LAST');
  const longName = lastGenerator?.get('LONG_NAME');

  // MARGIN_RATE
  const marginGenerator = items?.find(
    (item: any) => item.get('generator') === 1,
  );
  const marginRate = marginGenerator?.get('MARGIN_RATE');

  const rawTradableSum = calculateAvailable(marginInfo, marginRate);
  const tradableSum = formatNumberWithUnit(rawTradableSum);
  const tradableVol = formatNumber(rawTradableSum / last, 0);

  return { last, marginRate, tradableSum, tradableVol, longName };
};
