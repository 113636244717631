import { Box } from '@oms/ui-box';
import { Heading } from '@oms/ui-heading';
import { Table, Tr, Th, Td, Tbody } from '@oms/ui-table';
import { Text } from '@oms/ui-text';
import { useCustomerInformation } from 'utils/hooks/useUser';

export const AlertContactInformation = () => {
  const { email = 'Ingen registrert e-post' } = useCustomerInformation();
  return (
    <Box>
      <Heading>Kontaktinformasjon</Heading>
      <Table variant="keyValue" maxWidth="40rem">
        <Tbody>
          <Tr>
            <Th>E-post</Th>
            <Td>
              <Text>{email}</Text>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default AlertContactInformation;
