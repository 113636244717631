import createDecorator from 'final-form-calculate';

const handlePepToggle = (listName: string) => (
  fieldValue: 'true' | 'false',
  values: any,
) => {
  // Toggled on and no list (should be every case), initialize with one person in array
  if (fieldValue === 'true' && !values?.companyAdvInfo?.[listName]) return [{}];
  // I guess this is just a backup case where existing non-cleared list is returned
  if (fieldValue === 'true') return values?.companyAdvInfo?.[listName];
  // If field is toggled off or removed completely just remove field alltogheter
  return undefined;
};

export const companyAdvInfoDecorator = createDecorator({
  field: 'companyAdvInfo.realRightsHolders',
  updates: {
    // handle related list
    'companyAdvInfo.rightHolderList': handlePepToggle('rightHolderList'),
  },
});
