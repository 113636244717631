import { Td } from '@oms/ui-table';
import { Link } from '@oms/ui-link';
import { useInstrumentAnalysisPortfolio } from '../useInstrumentAnalysisPortfolio';
import { REPORT_URL } from 'constants/Jaws';
import { handleBlankPdfLink } from 'utils/app';
import { mapRecommendation } from 'utils/recommendations';
import { getItemFromItemSector } from '@oms/utils';

export const AnalysisData = ({ itemSector }: any) => {
  const item = getItemFromItemSector(itemSector);
  const { data: portfolio } = useInstrumentAnalysisPortfolio({
    ticker: item,
  });

  return (
    <>
      <Td type="text">{mapRecommendation(portfolio?.recommendation)}</Td>
      <Td type="number">{portfolio?.recommendationPrice ?? 0}</Td>
      <Td textAlign="center">
        {portfolio?.report?.document?.id ? (
          <Link
            as="a"
            href={`${REPORT_URL}/file/${portfolio?.report?.document?.id}`}
            onClick={handleBlankPdfLink}
            variant="link"
            size="xs"
          >
            (PDF)
          </Link>
        ) : (
          '-'
        )}
      </Td>
    </>
  );
};
