// Se http://manawiki.osl.manamind.com/index.php/Meglersystemer
export const mapOrderStatus = (statusInt: number) => {
  return (
    {
      0: 'Ikke på børs', // Mottatt, ikke på børs
      1: 'Avvist',
      2: 'På børs',
      3: 'Venter',
      4: 'Utført',
      5: 'Kansellert',
      6: 'Utgått',
      7: 'Trukket',
      8: 'Stop loss',
      9: 'Manuell',
      10: 'Manuelt utgått',
    }[statusInt] || '-'
  );
};

export const mapOrderType = (typeInt: number) => {
  return (
    {
      0: 'Kjøp',
      1: 'Selg',
    }[typeInt] || '-'
  );
};
