import React from 'react';
import { useInstrumentAnalysis } from './useAnalysis';
import { Table, Thead, Tbody, Tr, Th } from '@oms/ui-table';
import moment from 'moment';
import { SkeletonTable } from '@oms/ui-skeleton';
import { useMediaQuery } from '@oms/ui-utils';

import { HoldingsRow } from './HoldingsRow';

import {
  useTableDisclosureHeading,
  TableDisclosureHeading,
} from 'components/TableDisclosure';

type Suggest = {
  item: string;
};

export type InstrumentAnalysisProps = Suggest;

const LoadingState = () => <SkeletonTable animated columns={5} rows={6} />;

export const InstrumentAnalysis = ({ item }: InstrumentAnalysisProps) => {
  return (
    <React.Suspense fallback={<LoadingState />}>
      <InstrumentAnalysisComponent item={item} />
    </React.Suspense>
  );
};

const InstrumentAnalysisComponent = ({ item }: Suggest) => {
  const from = moment()
    .subtract(10, 'year')
    .format('YYYY-MM-DD');
  const count = 100;
  const isMobile = useMediaQuery({ maxWidth: '500px' });

  const { data: analyses = [] } = useInstrumentAnalysis(item, from, count);

  const headers = useTableDisclosureHeading();

  return (
    <Table layout={!isMobile ? 'auto' : 'fixed'}>
      <Thead>
        <Tr>
          <Th type="text" width={isMobile ? '3.2rem' : ''}>
            Ticker
          </Th>
          <Th type="text" width={isMobile ? '4.5rem' : ''}>
            Dato
          </Th>
          <Th type="text" width={isMobile ? '6rem' : ''}>
            Anbefaling
          </Th>
          <Th type="text" width={isMobile ? '4.1rem' : ''}>
            Kursmål
          </Th>
          <Th type="text">{!isMobile && 'PDF'}</Th>
          <TableDisclosureHeading {...headers}>Vis mer</TableDisclosureHeading>
        </Tr>
      </Thead>
      <Tbody>
        {analyses?.map(analysis => {
          return (
            <HoldingsRow
              analysis={analysis}
              data={item}
              key={analysis.id}
              {...headers}
            />
          );
        })}
      </Tbody>
    </Table>
  );
};

export default InstrumentAnalysis;
