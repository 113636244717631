import { Page } from 'components/Page';
import { Form } from 'react-final-form';

import { Heading } from '@oms/ui-heading';
import { Field } from '@oms/ui-field';
import { AllAnalysis } from './AllAnalysis';
import { useUnseenReports } from 'utils/hooks/useSeenTracker';
import { Stack } from '@oms/ui-stack';
import { InstrumentAnalysis } from './InstrumentAnalysis';
import { Reports } from 'components/Reports/Reports';
import { useAllInstrumentWithAnalysis } from './useAnalysis';
import { Combobox } from '@oms/ui-combobox';

import {
  ContentSwitcher,
  ContentSwitcherOption,
  useContentSwitcher,
} from '@oms/ui-content-switcher';

import { Box } from '@oms/ui-box';
import { Card } from 'components/Card';

export default function Analysis() {
  const contentSwitcher = useContentSwitcher({
    orientation: 'horizontal',
    initialSelected: 'Analysis',
  });
  const { morningReportUnseen, setReportAsSeen } = useUnseenReports();

  const { data: reports = [] } = useAllInstrumentWithAnalysis();
  const instrumentWithAnalysis = [
    ...new Set(reports.map((item: any) => item.ticker)),
  ];

  const submit = () => {};

  return (
    <Form onSubmit={submit}>
      {({ handleSubmit, values, form }) => {
        return (
          <>
            <Page
              documentTitle="Analyser"
              topElement={
                <Stack
                  my={8}
                  gap={8}
                  minHeight="8rem"
                  mx="auto"
                  maxWidth="60rem"
                  as="form"
                  onSubmit={handleSubmit}
                >
                  <Box center>
                    <Heading as="h1" variant="heading2">
                      Analyser
                    </Heading>
                  </Box>
                  <Box px={{ base: 3, md: 0 }} pb={3} overflow="auto">
                    <ContentSwitcher variant="primary" {...contentSwitcher}>
                      <ContentSwitcherOption value="Analysis">
                        Analyser
                      </ContentSwitcherOption>
                      <ContentSwitcherOption value="MORNINGREPORT">
                        Morgenrapport{' '}
                      </ContentSwitcherOption>
                    </ContentSwitcher>
                  </Box>
                  {contentSwitcher.selected === 'Analysis' && (
                    <Box px={{ base: 3, sm: 0 }}>
                      <Field
                        as={Combobox}
                        label="Velg analyse"
                        name="instrument"
                        placeholder="Ticker"
                        isClearable
                        onChange={(value: any) => {
                          return form.change(
                            'instrument',
                            `${value.ITEM}.${value.SECTOR}`,
                          );
                        }}
                        items={instrumentWithAnalysis}
                      />
                    </Box>
                  )}
                </Stack>
              }
            >
              <Stack my={8} gap={8} minHeight="8rem" mx="auto" maxWidth="60rem">
                <Box px={{ base: 3, sm: 0 }}>
                  {contentSwitcher.selected === 'Analysis' && (
                    <Card
                      title={
                        Object.keys(values).length > 0
                          ? `Analyse for ${values.instrument}`
                          : 'Analyser'
                      }
                    >
                      {Object.keys(values).length > 0 &&
                      values.instrument !== '' ? (
                        <InstrumentAnalysis item={values?.instrument} />
                      ) : (
                        <AllAnalysis />
                      )}
                    </Card>
                  )}
                  {contentSwitcher.selected === 'MORNINGREPORT' && (
                    <Card title="Morgenrapport">
                      <Reports
                        unseenState={{
                          morningReportUnseen,
                        }}
                        setReportAsSeen={setReportAsSeen}
                      />
                    </Card>
                  )}
                </Box>
              </Stack>
            </Page>
          </>
        );
      }}
    </Form>
  );
}

export { Analysis };
