import { USER_INFO_URL } from 'constants/Jaws';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FetchError, get } from 'utils/fetch';
import { authenticateAdmin, logoutAdmin, logoutUser } from './helpers';
import { ME } from 'types/auth';
import { ONE_SECOND } from 'constants/timeInMs';

const fetchMe = async () => {
  const response = await get(USER_INFO_URL);
  if (!response.ok)
    throw new FetchError({
      message: 'Uthenting av brukerdata feilet',
      method: 'GET',
      response,
    });

  const me = await response.json();
  return me as ME;
};

export const useMe = () => {
  return useQuery('me', () => fetchMe(), {
    staleTime: ONE_SECOND * 30,
    cacheTime: 0,
  });
};

export const useAdminLogin = () => {
  const queryClient = useQueryClient();
  return useMutation(authenticateAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries('me');
    },
  });
};

export const useAdminLogout = () => {
  const queryClient = useQueryClient();
  return useMutation(logoutAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries('me');
    },
  });
};

export const useUserLogout = () => {
  const queryClient = useQueryClient();
  return useMutation(logoutUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('me');
    },
  });
};
